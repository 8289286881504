import React, {useState, useEffect , useRef} from "react";

import Button from '../../ui/Button.tsx'
import Input from '../../ui/input.jsx'
import {Loading} from "../../custom-icons";

import * as Yup from 'yup';
import {
   Control,
   FieldErrors,
   useForm,
   useFormState,
   useWatch,
   Controller
 } from 'react-hook-form';

import axios from 'axios';  
import { yupResolver } from '@hookform/resolvers/yup';
import {API_ENDPOINT,toast_anim , toast_anim_error} from '../config/Common'
import {useAuth} from '../../provider/AuthProvider'
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {Links} from '../../routes/PathLink'
import {deviceDetect} from "react-device-detect"

import OtpInput from "react-otp-input";
import {firebase , auth, authentication} from '../../FirebaseConfig';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
const SignupSchema = Yup.object().shape({
    phone_no: Yup.string()
      .required('You must need to provide your phone number.'),
});

const defaultValues = {  
   phone_no: '',
 };

const LoginWithMobileOTP = (props) => {
   const [show, setshow] = useState(false);
   const [loading, setLoading] = useState(false);
  
   const {user_type} = props;
   console.log("USER TYPE PROPS",user_type);
   const { login } = useAuth();
   let navigate = useNavigate();
   const [isOpen, setIsOpen] = useState(false)
   const [otp, setotp] = useState('');
  const [final, setfinal] = useState('');
  const [whereto, setWhereto] = useState('');
const [phone_no, setPhone_no] = useState(false);
  const [userData,setUserData] = useState([]);
      const { register, handleSubmit , control, reset, setValue, setError, formState: { errors },} = useForm({
      defaultValues: defaultValues,
      resolver: yupResolver(SignupSchema),
      shouldFocusError:false,
   });
const refphone = useRef(null);
   useEffect(() => {
     
      if(localStorage.getItem('access_token')){
         return navigate("/", { replace: true });
      }
   })

   const handleView = () => {
      setIsOpen(true)
   }

   


   const ValidateOtp = () =>{
       if (otp === null || final === null)
            return;
        final.confirm(otp).then((result) => {
         
            
            let input = [];
            input = {...input,phone_no:phone_no,
            user_type:user_type }
            input = {...input, device:deviceDetect()}

            fetch(`${API_ENDPOINT}/check_phoneno_exist`,{
               method: 'POST',
               headers : {        
                  'Content-Type': 'application/json',         
               },
               body: JSON.stringify(input)
            }).then(function (response) {
                  return response.json();
            }).then( (result)=> {
               
               setLoading(false)
               if(result.status === 1){
                   fetch(`${API_ENDPOINT}/user_login_phone`,{
                     method: 'POST',
                     headers : {        
                        'Content-Type': 'application/json',         
                     },
                     body: JSON.stringify(input)
                  }).then(function (response) {
                        return response.json();
                  }).then( (result)=> {
                     
                     setLoading(false)
                     if(result.status === 1){
                        localStorage.setItem('access_token',result.access_token);
                        localStorage.setItem('user_type',result.user_type);
                        localStorage.setItem('user_id',result.user_id);
                        localStorage.setItem('user_name',result.name);
                        //props.setLogin()
                        login({access_token:result.access_token, user_name:result.name})
                         if(result.user_type === 'dealer'){
                             
                              }else{
                                  if(props.closeOtpModal){
                                       props.closeOtpModal();
                                    }
                                   if(props.closeModal){
                                       props.closeModal();
                                    }
                              }
                        const timer = setTimeout(() => {
                            if(result.user_type === 'dealer'){
                                 return navigate(Links.AMC_BID, { replace: true });
                              }else{
                                  
                                 return navigate(Links.PROFILE, { replace: true });   
                              }
                         }, 1000);
                         return () => clearTimeout(timer);
                        //return navigate(Links.CHECKOUT, { replace: true });
                     }
                });
               }
               if(result.status === 0){
                  toast_anim_error("Your are not registered with us , please Register.")
               }
               
               //setErrors({phone_no:result.message})
            });
            // success
       
        }).catch((err) => {
            toast_anim_error("Wrong code");
        })
  
   }

   const closeModal = () => {
      setIsOpen(false)      
   }

     const loginsubmit = async (values) => {
       //props.setActivestepfun(2);
       setLoading(true);
       
        let input ={
                  phone_no:values?.phone_no,
                  from:'phoneno',
                } ;
       values = input;
       
         
       setPhone_no(values?.phone_no);
       values = {phone_no:values?.phone_no, user_type:user_type };    
       axios
      .post(API_ENDPOINT+'/check_phoneno_exist', values)
      .then((response) => {
         

         if(response.data?.status == 1){
            let verify = new firebase.auth.RecaptchaVerifier('recaptcha-container',{ size: "invisible" });
            

              auth.signInWithPhoneNumber("+"+values?.phone_no, verify).then((result) => {
               
                  setfinal(result);
                  //alert("code sent")
                  toast_anim("Code sent to your phone number.")
                  setshow(true);
                  setLoading(false);
              }).catch((err) => {
               setLoading(false);
                      alert(err);
                      window.location.reload()
            });
            
         }else{
            
            toast_anim_error("Your are not registered registered with us , please Register.")
            setLoading(false);
         }
         
        //setPost(response.data);
      });


       return false;
   }

   const setOtp = (val) =>{
      setotp(val);
   }
   return( <div className="flex flex-col justify-center items-center">
            {user_type == 'user' &&
             <div className="flex justify-center">
               <h1 className="text-2xl font-black text-accent">Hospital/Diagnostic Login</h1>
             </div> 
            }
             {user_type == 'customer' &&
             <div className="flex justify-center">
               <h1 className="text-2xl font-black text-accent">Customer Login</h1>
             </div>   
            }
              {user_type == 'dealer' &&
               <div className="flex justify-center">
               <h1 className="text-xl font-black text-accent">Dealer/Service Engineer/Bio Medical Login</h1>
               </div>       
            }

             
            <div className="flex h-full  w-screen flex-col justify-center bg-light  px-5  md:h-auto md:min-h-0 md:max-w-[480px] md:rounded-xl my-5">
                  {show ?
                  ( <><div style={{ display: show ? "block" : "none" }}>
                     <div className="flex flex-col justify-center align-center">
                        <div className="flex flex-col justify-center align-center">
                           <h1 class="text-2xl font-bold">OTP Verification</h1>
                           <div class="flex flex-col mt-4">
                             <span>Enter the OTP you received at</span>
                             <span class="font-bold">{phone_no}</span>
                            </div>
                        </div>  
                    
                           <OtpInput
                             inputStyle={{  
                               width: '2.5rem',  
                               height: '2.5rem',  
                               margin: '10px 0.5rem',  
                               fontSize: '1rem',  
                               borderRadius: 4,  
                               border: '2px solid rgba(0,0,0,0.3)',                      
                           }}  
                              value={otp}
                              width={'1.5em'}
                              onChange={setOtp}
                              numInputs={6} 
                              className="m-2 border h-10 w-10 text-center form-control rounded"
                              renderSeparator={<span>-</span>}
                              renderInput={(props) => <input type="text"  style={{width:'2em'}}   {...props}  />}
                             />
                    <br /><br />
                    <Button onClick={ValidateOtp}>Verify</Button>
                </div>
                </div> </>)
                  :
                  (<>
                  {/* <div className="flex justify-center">
                  <h1 className="text-xl font-black text-accent">Login</h1>
                  </div> */}      
                  <form onSubmit={handleSubmit(loginsubmit)}>  
                  {/*<p className="mt-1 mb-8 text-center text-sm text-body  md:text-base">
                     sign in with your Phone number and OTP
                  </p>*/}
                   
                       <div className="mb-5 w-full flex flex-col">
                           <label htmlFor="phone_no" className="block mb-3 text-sm font-semibold leading-none text-body-dark">Phone number <span className="text-red-500">*</span></label>
                           <Controller
                              name="phone_no"
                              control={control}
                              ref={refphone}
                              render={({ field }) => (
                                 <PhoneInput
                                    country="in"
                                    disableDropdown
                                    countryCodeEditable={false}
                                    inputClass="!p-0 ltr:!pr-4 rtl:!pl-4 ltr:!pl-14 rtl:!pr-14 !flex !items-center !w-full !appearance-none !transition !duration-300 !ease-in-out !text-heading !text-sm focus:!outline-none focus:!ring-0 !border !border-border-base !rounded focus:!border-accent !h-12"
                                    dropdownClass="focus:!ring-0 !border !border-border-base !shadow-350"
                                    {...field}
                                 />
                              )}
                           />
                           <p className="my-2 text-xs text-start text-red-500">{errors.phone_no?.message}</p>
                        </div>
                  
                     <div className="mt-8 flex justify-center">
                        {
                        !loading
                        ?
                        <Button className="w-full bg-black" >Send OTP</Button>
                        :
                        <Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600" />
                        }
                     </div>

                     <div className="relative mt-8 mb-6 flex flex-col items-center justify-center text-sm text-heading sm:mt-11 sm:mb-8"><hr className="w-full" /><span className="absolute -top-2.5 bg-light px-2 ltr:left-2/4 ltr:-ml-4 rtl:right-2/4 rtl:-mr-4">Or</span></div>
                     <div className="text-center text-sm text-body sm:text-base"> <Link to="#" onClick={props.closeOtpModal} className="font-semibold text-accent underline transition-colors duration-200 hover:text-accent-hover hover:no-underline focus:text-accent-hover focus:no-underline focus:outline-none ltr:ml-1 rtl:mr-1" >Go Back</Link></div>
               </form>
               
               </>
               )
            }
            </div>
             <div id="recaptcha-container"></div>
         </div>)
}

export default LoginWithMobileOTP