import React, {useState, useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import {
   Control,
   FieldErrors,
   useForm,
   useFormState,
   useWatch,
   Controller
} from 'react-hook-form';
import {
   API_ENDPOINT, 
   random_str, 
   toast_anim_error, 
   register_fee_hospital, 
   register_valid , 
   renew_per,
   RAZOR_KEY, 
   loadScript 
} from '../config/Common'

import { yupResolver } from '@hookform/resolvers/yup';
import Input from '../../ui/input.jsx'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import Button from '../../ui/Button.tsx'
import {useAuth} from '../../provider/AuthProvider'
import SelectInput from '../../ui/select-input.jsx'
import Label from '../../ui/label.tsx'
import {deviceDetect} from "react-device-detect"
import Checkbox from '../../ui/checkbox/checkbox.tsx'
import axios from 'axios';  



const SignupSchema = Yup.object().shape({
   state_id:Yup.object().nullable().required('State is required.'),
   city_id:Yup.object().nullable().required('City is required.'),
   phone_no: Yup.string()
      .required('You must need to provide your phone number.')
      .min(12, 'Invalid phone number.'),
   name: Yup.string()
      .required('You must need to provide your Name.'),
});


const HospitalSocialLogin = (props) => {

   const [loading, setLoading] = useState(false);
   const [states, setStates] = useState(null);
   const [cities, setCities] = useState(false);
   const { login } = useAuth();

   const defaultValues = {  
      phone_no:'',
      state_id:'',
      city_id:'',
      name:props.social_data.name
    };

   const { register, handleSubmit , control, reset, setValue, setError, formState: { errors },} = useForm({
      defaultValues: defaultValues,
      resolver: yupResolver(SignupSchema),
   });

   useEffect(() => {
      //console.log('props',props)
      getState()
      reset(defaultValues)
   },[])
   
   const getState = () => {
      fetch(`${API_ENDPOINT}/get_states`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			//console.log('states======',result)
			setStates(result.data)			
		})
   }

   const getCitiesByState = (state_id) => {
      fetch(`${API_ENDPOINT}/get_cities_by_state/${state_id}`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			//console.log('cities======',result)
			setCities(result.data)			
		})
   }

   const handleSelect = (val,name) => {  
      //console.log('cat val======',val,name.name)
      setError(name.name, null)
      setValue(name.name, val, true);
      if(name.name === 'state_id'){
         //setModels(null)
         getCitiesByState(val.id)
         setValue('city_id', null, true);
      }
   }

    

   const onSubmit = (values) => {
      //console.log('props social',props.social_data)
      setLoading(true)
      let input = values;
      input = {
         ...input, 
         device:deviceDetect(), 
         email:props.social_data.email,
         provider:props.social_data.provider,
         password:random_str(10),
         email_verified:true,
         user_type:'customer',
      }
      //console.log('on submit======',input)
      fetch(`${API_ENDPOINT}/customer_register`,{
         method: 'POST',
         headers : {        
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(input)
     }).then(function (response) {
         return response.json();
     }).then( (result)=> {
         setLoading(false)
         
         if(result.status === 1){
             localStorage.setItem('access_token',result.access_token);
             localStorage.setItem('user_type',result.user_type);
             localStorage.setItem('user_id',result.user_id);
             localStorage.setItem('user_name',result.user_name);
             localStorage.setItem('email',result.email);
             props.setLogin()
             login({access_token:result.access_token, user_name:result.user_name})
             
             //call from AMC ticket
             if(props.buyTicket){
                 props.buyTicket();
             }
         }
         /* if(result.status === 0){
             Object.entries(result.message).forEach((item,ky) =>{
                 if(item[0] === 'email'){
                     setErrors({email:item[1]})
                 }
                 if(item[0] === 'phone_no'){
                     setErrors({phone_no:item[1]})
                 }
             })
         } */
         //setErrors({phone_no:result.message})
     })
   }

   return(
      <>
         <div className="flex justify-center">
            <h1 className="text-2xl font-black text-accent mb-5">Customer Register</h1>
         </div> 
         <form onSubmit={handleSubmit(onSubmit)}>
            <Input                
               label={`Name`}
               {...register('name')}
               variant="outline"
               className="mb-5"
               error={errors.hospital_reg?.message}
               required
            />
            <div className="mb-5">
               <Label>State <span className="text-red-500"> *</span></Label>
               <SelectInput
                  name="state_id"                            
                  control={control}
                  getOptionLabel={(option: any) => option.name}
                  getOptionValue={(option: any) => option.id}                        
                  options={states}
                  onChange={handleSelect}
               />
               <p className="my-2 text-xs text-start text-red-500">{errors.state_id?.message}</p>
            </div>
            <div className="mb-5">
               <Label>City <span className="text-red-500"> *</span></Label>
               <SelectInput
                  name="city_id"                            
                  control={control}
                  getOptionLabel={(option: any) => option.city}
                  getOptionValue={(option: any) => option.id}                        
                  options={cities}
                  onChange={handleSelect}
               />
               <p className="my-2 text-xs text-start text-red-500">{errors.city_id?.message}</p>
            </div>
            <div className="mb-5">
               <label htmlFor="phone_no" className="block mb-3 text-sm font-semibold leading-none text-body-dark">Phone number <span className="text-red-500">*</span></label>
               <Controller
                  name="phone_no"
                  control={control}
                  render={({ field }) => (
                  <PhoneInput
                     country="in"
                     disableDropdown
                     countryCodeEditable={false}
                     inputClass="!p-0 ltr:!pr-4 rtl:!pl-4 ltr:!pl-14 rtl:!pr-14 !flex !items-center !w-full !appearance-none !transition !duration-300 !ease-in-out !text-heading !text-sm focus:!outline-none focus:!ring-0 !border !border-border-base !rounded focus:!border-accent !h-12"
                     dropdownClass="focus:!ring-0 !border !border-border-base !shadow-350"
                     {...field}
                  />
                  )}
               />
               <p className="my-2 text-xs text-start text-red-500">{errors.phone_no?.message}</p>
            </div>
             
            <div>
                  <Button 
                     className="w-full"
                     loading={loading}
                  >
                  Register
                  </Button>
               </div>
         </form>
      </>
   )
}

export default HospitalSocialLogin