import React from "react";
import {BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {Links} from './PathLink'

import Home from "../components/Home";
import ProductDetails from "../components/ProductDetails";
import ChangePassword from "../components/ChangePassword";
import Profile from "../components/Profile";
import Logout from "../components/Logout";
import MyWishlist from "../components/MyWishlist";
import {Authentication} from "./Authentication";

import Contact from "../components/static-pages/contact";
import About from "../components/static-pages/About";
import Help from '../components/static-pages/Help'
import Terms from '../components/static-pages/Terms'
import Privacy from '../components/static-pages/Privacy'
import WhyCTMRIHUB from "../components/static-pages/WhyCTMRIHub";
import AERBLicensing from "../components/static-pages/AERBLicensing";
import PartsRepair from '../components/static-pages/PartsRepair';
import ShippingPolicy from '../components/static-pages/ShippingPolicy';
import HireChainPullLabour from '../components/static-pages/HireChainPullLabour';

import Checkout from "../components/checkout/Checkout";
import OrderDetail from "../components/checkout/OrderDetail";
import MyOrders from "../components/MyOrders";
import {OrderProvider} from '../components/order/OrderProvider'

import Category from '../components/category/Category'

import DealerLogin from '../components/auth/DealerLogin'
import DealerRegister from '../components/auth/DealerRegister'

import ResetPassword from '../components/auth/ResetPassword'

import CreateAmcTicket from '../components/amc/CreateAmcTicket'
import AmcTicket from '../components/amc/AmcTicket'

import AmcBid from '../components/amc/AmcBid'
import MyProposal from '../components/amc/MyProposal'
import CmcTicket from '../components/amc/CmcTicket'

import AddProduct from '../Seller/AddProduct'
import ProductList from '../Seller/ProductList'
import EditProduct from '../Seller/EditProduct'
import ShowTestimonials from '../components/testimonial/ShowTestimonials'

import Carrier from '../components/carrier/carrier'
import UpcomingProducts from '../components/UpcomingProducts'
import FeaturedProducts from '../components/FeaturedProducts';
import PopularProducts from '../components/PopularProducts';
import FreeConsultation from '../components/free-consultation'

import Comingsoon from "../components/Comingsoon";
import PageNotFound from '../components/PageNotFound';
import DealerPlans from '../components/static-pages/DealerPlans';

// basename='/ctmri/'
// "homepage": "https://www.ctmrihub.com/",
const RoutePath = () => {
    return (        
        <Router basename='/' >
            <Routes>
                <Route path='/' element={<Home />} />
                {/*<Route path='/home' element={<Home />} />
                <Route path='/' element={<Comingsoon />} />  */}
                <Route path={Links.CHANGE_PASSWORD} element={<Authentication><ChangePassword /></Authentication>} />
                <Route path={Links.PROFILE} element={<Authentication><Profile /></Authentication>} />
                <Route path={Links.MY_WISHLIST} element={<Authentication><MyWishlist /></Authentication>} />

                <Route path={Links.CREATE_AMS_TICKET} element={<Authentication><CreateAmcTicket /></Authentication>} />
                <Route path={Links.AMS_TICKETS} element={<Authentication><AmcTicket /></Authentication>} />
                <Route path={Links.AMC_BID} element={<Authentication><AmcBid /></Authentication>} />
                <Route path={Links.MY_PROPOSALS} element={<Authentication><MyProposal /></Authentication>} />

                <Route path={Links.CMS_TICKETS} element={<Authentication><CmcTicket /></Authentication>} />

                <Route path={Links.ORDERS} element={<OrderProvider><Authentication><MyOrders /></Authentication></OrderProvider>} />

                <Route path={Links.CHECKOUT} element={<Checkout />} />

                <Route path={`${Links.ORDERS}/:invoice`} element={<OrderDetail />} />
                <Route path={Links.LOGOUT} element={<Logout />} />
                <Route path={Links.CONTACT} element={<Contact />} />
                <Route path={Links.ABOUT} element={<About />} />
                <Route path={Links.HELP} element={<Help />} />
                <Route path={Links.PARTSREPAIR} element={<PartsRepair />} />
                <Route path={Links.TERMS} element={<Terms />} />
                <Route path={Links.PRIVACY} element={<Privacy />} />
                <Route path={Links.SHIPPINGPOLICY} element={<ShippingPolicy />} />
                <Route path={Links.HIRECHAINPULLI} element={<HireChainPullLabour />} />
               <Route path={Links.DEALER_SUBSCRIPTION_PLANS} element={<DealerPlans />} />
                <Route path={Links.WHYCTMRIHUB} element={<WhyCTMRIHUB />} />
                <Route path={Links.AERBLICENSING} element={<AERBLicensing />} />
                <Route path={`${Links.PRODUCT}/:slug`} element={<ProductDetails />} />
                <Route path={Links.CATEGORY} element={<Category />} />
                <Route path={`${Links.CATEGORY}/:slug`} element={<Category />} />
                <Route path={`${Links.CATEGORY}/:slug/:sub_slug`} element={<Category />} />

                <Route path={Links.DEALER_LOGIN} element={<DealerLogin />} />
                <Route path={Links.DEALER_SIGNUP} element={<DealerRegister />} />
                <Route path={`${Links.RESET_PASSWORD}/:token`} element={<ResetPassword />} />

                <Route path={Links.TESTIMONIALS} element={<ShowTestimonials />} />
                <Route path={Links.CARRIER} element={<Carrier />} />
                <Route path={Links.UPCOMING_PRODUCTS} element={<UpcomingProducts />} />
                <Route path={Links.FEATURED_PRODUCTS} element={<FeaturedProducts />} />
                <Route path={Links.POPULAR_PRODUCTS} element={<PopularProducts />} />

                <Route path={Links.FREE_CONSULTATION} element={<FreeConsultation />} />
                
                <Route path={Links.ADD_PRODUCT} element={<Authentication><AddProduct /></Authentication>} />
                <Route path={Links.MY_PRODUCTS} element={<Authentication><ProductList /></Authentication>} />
                <Route path={`${Links.MY_PRODUCTS}/edit/:p_id`} element={<Authentication><EditProduct /></Authentication>} />
                <Route path="*" element={<PageNotFound text='This page could not be found' />} /> 
            </Routes>
        </Router>
    );
  };
  
  export default RoutePath;
  