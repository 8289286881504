import React from "react";
import { Link } from "react-router-dom";
import {Bed, Table, Sofa, Chair, Bucket, XrayIcon, MriIcon, CtScanIcon} from "../custom-icons";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import {API_ENDPOINT, APP_URL, APP_NAME, IMG_URL, amount_format,discount, LIMIT, replaceWithBr, showRating} from './config/Common'
import AOS from 'aos';
import 'aos/dist/aos.css';
import ScrollPageTop from '../routes/ScrollPageTop'

import { BiRupee } from "react-icons/bi";
import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import {Links} from '../routes/PathLink'
import Card from '../ui/card.tsx'
// import Swiper core and required modules
import { Navigation, Pagination, Thumbs, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import {ChevronLeft, ChevronRight, Loading} from "../custom-icons";
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';

import ProductCard from '../cards/ProductCard'
import BlogPostCard from '../cards/BlogPostCard';
import ListNonFeaturedProducts from './products/ListNonFeaturedProducts';
import InfiniteScroll from "react-infinite-scroll-component";
import SavePriceInd from '../ui/SavePriceInd'
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';

import HomeCategoryProduct from './HomeCategoryProduct'

// product gallery breakpoints
const galleryCarouselBreakpoints = {
	320: {
	  slidesPerView: 1,
	},
	480: {
	  slidesPerView: 2,
	},
	640: {
	  slidesPerView: 3,
	},
	
  };

const upcomingBreakpoints = {
	320: {
	  slidesPerView: 2,
	},
	510: {
	  slidesPerView: 3,
	},
	600: {
		slidesPerView: 4,
	},
	950: {
		slidesPerView: 5,
	},
	1300: {
	  slidesPerView: 6,
	},
};
const testimonialBreakpoints = {
	320: {
		slidesPerView: 1,
	 },
	 600: {
		slidesPerView: 2,
	},
	950: {
		slidesPerView: 3,
	},
	 
}
export default class Home extends React.Component {
	constructor(){
		super();
		this.state ={
			width:window.innerWidth,
			products:null,
			category:null,
			page:1,
			hasMore:true,
			sliderLoading:false,
			homeSlider:null,
			upcoming:null,
			testimonials:null,
			blogItems:[],
			user_type:'',
			catList:null
		}
		this.prevRef = React.createRef();
		this.nextRef = React.createRef();
		this.upPrevRef = React.createRef();
		this.upNextRef = React.createRef();
	}
	componentDidMount = () => {
		AOS.init();
		//this.getProducts(this.state.page)
		this.get_parent_category()
		this.getTestimonials()
		this.getBlogs();
		const user_type = localStorage.getItem('user_type');
		console.log("user_type",user_type);
		this.setState({
			user_type:user_type,
		})
		this.getHomeCatList();

		//this.getUpcomingProducts()
	}

	

	getProducts = (page) => {
		fetch(`${API_ENDPOINT}/get_homepage_products?page=${page}&limit=${LIMIT}`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			//console.log('products length======',result.data.length)
			if(page === 1){
				this.setState({
					products: result.data,
					page:this.state.page + 1
				})
			}else{
				this.setState({
					page:this.state.page + 1,
					products: [...this.state.products, ...result.data],
				})
			}

			if(result.data.length < LIMIT){
				this.setState({
					hasMore:false
				})
			}else{
				this.setState({
					hasMore:true
				})
			}
			
		})
	}
	get_parent_category = () => {

		fetch(`${API_ENDPOINT}/get_parent_category`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log('category======',result)
			this.setState({
				category: result.data
			})
			
		})
	}

	 getBlogs = () => {
      fetch(`${API_ENDPOINT}/get_blogs`,{
         method: 'GET',
         headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
         }
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         //console.log('Blogs=====',result)
         if(result.status === 1){
			this.setState({
				blogItems:result.data,
			})
		}
        
      })
   }

	fetchData = () => {
		this.getProducts(this.state.page)
	}

	/*getUpcomingProducts = () => {
		fetch(`${API_ENDPOINT}/upcoming_product_home`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			//console.log('upcoming product', result)
			if(result.status === 1){
				this.setState({
					upcoming:result.data
				})
			}
		})
	}
	*/

	getTestimonials = () => {
      fetch(`${API_ENDPOINT}/get_testimonials?page=1&limit=10`,{
         method: 'GET',
         headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
         }
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         //console.log('testimonials=====',result)
			this.setState({
				testimonials:result.data
			})
         //setDetails(result.data)
		})
	}

	getHomeCatList = () => {
      fetch(`${API_ENDPOINT}/get_cat_home`,{
         method: 'GET',
         headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
         }
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         //console.log('catList=====',result)
			this.setState({
				catList:result.data
			})
         //setDetails(result.data)
		})
	}

	pagination = {
		clickable: true,
		renderBullet: function (index, className) {
		  return '<span class="' + className + '">' + (index + 1) + "</span>";
		},
	 };

	render(){
		const siteMetadata = {
			url: `${APP_URL}`,
			title:  `Get Your Medical Equipment Delivered - ${APP_NAME}`,
			description: `Get Your Medical Equipment Delivered`,
			image: `./img/home-bg.jpg`,
		 }
		 const {category, homeSlider, sliderLoading, testimonials , blogItems , user_type} = this.state
	return (
		<ScrollPageTop>
		<ReactSEOMetaTags
			render={(el: React.ReactNode) => <Helmet>{el}</Helmet>}
			website={{ ...siteMetadata }}
			breadcrumb={[
        		{ title: 'Home', url: `${APP_URL}` },
			]}
		/>
		<Header />
		<div className="min-h-screen bg-light">
			
			
			{
			this.state.upcoming &&
			<div className="px-6 py-5 border-t md:p-8 border-border-200 bg-gray-300  dark:bg-gray-700">
				<div className="flex items-center justify-between">
					<h1 className="text-body text-center text-2xl mb-6">Upcoming Products</h1>
					<Link to={Links.UPCOMING_PRODUCTS} className="text-heading text-orange-500 text-md">More</Link>
				</div>				
				<div className="relative">
				<Swiper
					// install Swiper modules
					modules={[Navigation, Thumbs]}
					id="productGallery"
					spaceBetween={20}
					watchSlidesProgress={true}
					freeMode={true}
					observer={true}
					observeParents={true}
					breakpoints={upcomingBreakpoints}
					navigation={{
						prevEl: this.upPrevRef.current, // Assert non-null
						nextEl: this.upNextRef.current, // Assert non-null
					}}
					>
						
					{
						this.state.upcoming && this.state.upcoming.map((item, key) => (
							<SwiperSlide className="flex items-center justify-center selection:bg-transparent">
							
							<Link to={`${Links.PRODUCT}/${item.product_slug}`} key={`product-${item.p_id}`}>
									<article className="product-card cart-type-krypton h-full cursor-pointer overflow-hidden rounded border border-border-200 bg-light transition-shadow duration-200 hover:shadow-sm dark:bg-gray-700 dark:border-none w-72">
										<div className="relative flex h-48 items-center justify-center sm:h-52">
											{
												item.product_image
												?
												<img src={`${IMG_URL}${item.product_image}`} className="h-48 w-full sm:h-52 object-cover" alt={item.product_name}  />
												:
												<img src={`${APP_URL}/img/no-image-available.png`} className="h-48 w-full sm:h-52 object-cover" alt={item.product_name}  />
											}											
											{
											discount(item.price, item.sale_price) > 0 ?
											<div class="absolute top-3 rounded-full bg-accent px-1.5 text-xs font-semibold leading-6 text-light ltr:right-3 rtl:left-3 sm:px-2 md:top-4 md:px-2.5 ltr:md:right-4 rtl:md:left-4">{discount(item.price, item.sale_price)}%</div>
											:null
											}
										</div>

										<header className="relative p-3 md:p-5 md:py-6">
											<h3 role="button" className="mb-2 truncate text-sm font-semibold text-heading dark:text-zinc-400 capitalize">{item.product_name}</h3>
											
											<div className="relative mt-2 flex min-h-6 items-center justify-between">
												{
													//let item.price.toLocaleString()
												<div className="relative">
													{user_type == 'dealer' && item.dealer_price &&  item.dealer_price > 0 ?
						                        (<span className="text-sm font-semibold text-accent md:text-base flex items-center">
						                        {amount_format(item.dealer_price)}
						                        </span>)
						                        :
						                        ( <span className="text-sm font-semibold text-accent md:text-base flex items-center">
						                        {amount_format(item.sale_price)}
						                     </span>)
						                     }
												</div>
												}
											</div>
										</header>
									</article>
								</Link>
							</SwiperSlide>
						))
					}
					
					</Swiper>
					<div
						ref={this.upPrevRef}
						className="product-gallery-prev absolute top-2/4 z-10 -mt-4 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border border-border-200 border-opacity-70 bg-light text-heading shadow-xl transition-all duration-200 hover:bg-gray-100 ltr:-left-4 rtl:-right-4 md:-mt-5 md:h-9 md:w-9 ltr:md:-left-5 rtl:md:-right-5 dark:bg-gray-500 dark:text-light"
						>
							<ChevronLeft className="h-4 w-4" />
						</div>
					<div
						ref={this.upNextRef}
						className="product-gallery-next absolute top-2/4 z-10 -mt-4 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border border-border-200 border-opacity-70 bg-light text-heading shadow-xl transition-all duration-200 hover:bg-gray-100 ltr:-right-4 rtl:-left-4 md:-mt-5 md:h-9 md:w-9 ltr:md:-right-5 rtl:md:-left-5 dark:bg-gray-500 dark:text-light"
						>
							<ChevronRight className="h-4 w-4" />
					</div>
				</div>
			</div>
			}

			<div className="flex flex-1 bg-light dark:bg-gray-900 py-5 px-3 md:py-10 md:px-44 items-center justify-between gap-5 md:gap-10 border-b-2 border-accent">

				<div className="flex flex-col items-center justify-center">
					<div className="w-3/4 md:w-full">
						<LazyLoadImage src="/img/reliable_quality.png" />
					</div>
					<div className="text-xs md:text-sm py-2 text-center">Reliable Quality</div>
				</div>
				<div className="flex flex-col items-center justify-center">
					<div className="w-3/4 md:w-full">
						<LazyLoadImage src="/img/quick-suport.png" />
					</div>
					<div className="text-xs md:text-sm py-2 text-center">Quick Suport</div>
				</div>
				<div className="flex flex-col items-center justify-center">
					<div className="w-3/4 md:w-full">
						<LazyLoadImage src="/img/expert-guidance.png"  />
					</div>
					<div className="text-xs md:text-sm py-2 text-center">Expert Guidance</div>
				</div>
				<div className="flex flex-col items-center justify-center">
					<div className="w-3/4 md:w-full">
						<LazyLoadImage src="/img/global-reach.png" />
					</div>
					<div className="text-xs md:text-sm py-2 text-center">Global Reach</div>
				</div>
				<div className="flex flex-col items-center justify-center">
					<div className="w-3/4 md:w-full">
						<LazyLoadImage src="/img/aerb-licensing.png" />
					</div>
					<div className="text-xs md:text-sm py-2 text-center">AERB Licensing</div>
				</div>
			</div>

			{
				this.state.catList && this.state.catList?.slice(0, 1).map((item, key) => (
				<HomeCategoryProduct cat_id={item.cat_id} key={`hc_${item.cat_id}`} />
				))
			}

			<div className="md:flex flex-1 py-5 px-5 md:py-10 md:px-10 items-center justify-center gap-10 border-b-2 border-accent">
				<div className="flex items-center justify-center w-full md:w-1/2">
					<img src={`/img/home-about-us.png`} className="rounded lg:w-3/5 object-contain" />
				</div>
				<div className="w-full md:w-1/2">
					<h1 className="text-accent text-5xl leading-[60px]">A Hub of Medical Equipment</h1>
					<div className="text-body py-5">Explore our commitment to revolutionizing patient care through cutting-edge technology, unwavering quality standards, and a compassionate approach to healthcare delivery. Join us as we redefine the landscape of healthcare, one innovation at a time.</div>
					<div className="py-5">
					<Link to={Links.ABOUT} className="order-5 items-center justify-center rounded-full border-2 border-accent bg-accent px-3 py-2 text-sm font-semibold text-white transition-colors duration-300 hover:border-accent hover:bg-accent hover:text-light focus:border-accent focus:bg-accent focus:text-light focus:outline-none sm:order-4 sm:justify-start sm:px-5">
						<span>MORE ABOUT US</span>
					</Link>
					</div>
				</div>				
			</div>

			{
				this.state.catList && this.state.catList?.slice(1, 5).map((item, key) => (
				<HomeCategoryProduct cat_id={item.cat_id} key={`hc_${item.cat_id}`} />
				))
			}

			{
				testimonials && testimonials.length > 0 && 
				<div className="py-10 px-5 border-b-2 border-accent">
					
					{/*<h1 className="text-center text-4xl mb-10 text-accent">Customers Speak</h1>*/}
					<div className="flex flex-col md:flex-row items-center bg-orange-50 rounded-[20px]">
						<div className="w-full md:w-1/4 pt-5">
							<h1 className="text-3xl mb-5 text-accent text-center">Client Testimonials</h1>
							<div className="flex items-center justify-center">
								<div
									ref={this.prevRef}
									className="cursor-pointer"
									>
										<ChevronLeft className="h-10 w-10 text-body" />
									</div>
								<div
									ref={this.nextRef}
									className="cursor-pointer"
									>
										<ChevronRight className="h-10 w-10 text-body" />
								</div>
							</div>
						</div>
					
					<div className="w-full md:w-3/4 relative bg-accent rounded-[20px] px-5 pb-5">
						<Swiper
							// install Swiper modules
							modules={[Navigation, Thumbs, Autoplay]}
							id="productGallery-122"
							spaceBetween={20}
							watchSlidesProgress={true}
							freeMode={true}
							observer={true}
							observeParents={true}
							breakpoints={testimonialBreakpoints}
							pagination={{ clickable: true }}
							loop={true}
							autoplay={{
								delay: 6000,
								disableOnInteraction: false,
							}}
							navigation={{
								prevEl: this.prevRef.current, // Assert non-null
								nextEl: this.nextRef.current, // Assert non-null
							}}
						>
						{
							testimonials.map((val,key) => (
								<SwiperSlide className="">
									<div>
										<div className="flex items-center justify-center relative top-8 left-2">
											<div className="w-[25%] overflow-hidden flex items-center justify-center">
											<div className="rounded-full p-1 bg-accent">
											{
												val.image ?
												<LazyLoadImage src={`${IMG_URL}${val.image}`} alt={val.name} className="rounded-full w-15 h-15 object-cover" />
												:
												<LazyLoadImage src={`${APP_URL}/img/no-image-available.png`} alt={val.name} className="rounded-full w-15 h-15 object-cover" />
											}
											</div>
											</div>
											<div className="w-[75%] flex flex-col gap-4">
												<div className="flex items-start">{showRating(val.rating,20, '#ffb901')}</div>
												<div className="w-[90%] text-sm text-accent capitalize truncate">
													{val.name}
												</div>
											</div>
										</div>
										{val.testimony && 
											<div className="bg-light rounded-lg p-3 pt-10">
												<blockquote className="capitalize text-center text-sm text-body leading-6" dangerouslySetInnerHTML={{__html:replaceWithBr(val.testimony.substring(0, 200))}} />
											</div>
										}

									</div>
									{/*<div>
										<div className="flex items-center justify-center mb-4">{showRating(val.rating,25, '#009f7f')}</div>

										{val.testimony && 
											<blockquote className="text-center text-md text-gray-900 dark:text-white" dangerouslySetInnerHTML={{__html:replaceWithBr(val.testimony.substring(0, 200))}} />}

											
											<div className="rounded-full overflow-hidden w-20 h-20 mx-auto my-5">
											{
												val.image ?
												<LazyLoadImage src={`${IMG_URL}${val.image}`} alt={val.name} className="w-20 h-20 object-cover" />
												:
												<LazyLoadImage src={`${APP_URL}/img/no-image-available.png`} alt={val.name} className="w-20 h-20 object-cover" />
											}
											</div>
										
										<div className="text-center text-md text-gray-900 dark:text-white capitalize mb-6">
											{val.name}
										</div>
										
									</div>*/}
									
								</SwiperSlide>
							))
						}
						</Swiper>
					</div>
					</div>
				</div>
			}
			{
			category && 	
			<div className="bg-light py-10 px-5 md:px-5 border-b-2 border-accent">
				<h3 className="text-center text-4xl text-accent mt-4 mb-3 uppercase">Our <span className="bg-accent text-white px-2">Other</span> Equipment Range</h3>
				<div className="relative px-10">
				
				<Swiper
							// install Swiper modules
							modules={[Navigation, Thumbs, Autoplay]}
							id="category-slide-122"
							spaceBetween={20}
							watchSlidesProgress={true}
							freeMode={true}
							observer={true}
							observeParents={true}
							breakpoints={upcomingBreakpoints}
							pagination={{ clickable: true }}
							loop={true}
							autoplay={{
								delay: 5000,
								disableOnInteraction: false,
							}}
							navigation={{
								prevEl: this.upPrevRef.current, // Assert non-null
								nextEl: this.upNextRef.current, // Assert non-null
							}}
							style={{paddingLeft:'15px',paddingRight:'15px'}}

						>
				{
					category && category.map((val, key) => (

						<SwiperSlide className="my-5">
							<Link to={`${Links.CATEGORY}/${val.slug}`} title={val.cat_name}>
							<div className="shadow-md shadow-accent flex flex-col items-center justify-center gap-1 rounded">
								<div className="w-1/2 h-28 object-cover overflow-hidden">
									<img src={`${IMG_URL}/${val.cat_image}`} className="py-1 h-28 object-cover" />
								</div>
								<div className="text-base text-accent text-center py-2 truncate w-full px-3">{val.cat_name}</div>
							</div>
							</Link>
						</SwiperSlide>
					))
				}
				</Swiper>
			
				<div
						ref={this.upPrevRef}
						className="product-gallery-prev absolute top-2/4 z-10 -mt-4 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-accent text-light transition-all duration-200 md:-mt-5 md:h-9 md:w-9 left-0"
						>
							<ChevronLeft className="h-4 w-4" />
						</div>
					<div
						ref={this.upNextRef}
						className="product-gallery-next absolute top-2/4 z-10 -mt-4 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-accent text-light transition-all duration-200 md:-mt-5 md:h-9 md:w-9 right-0"
						>
							<ChevronRight className="h-4 w-4" />
					</div>
				</div>	
			</div>
			}

		</div>



		<div className="flex w-full justify-center my-10"><h1 className="text-center text-4xl text-accent">Latest Blog Posts</h1></div>
		<div className="flex w-full justify-center align-center self-center mx-auto px-5">
			<div className="md:w-1/12"></div>
			<div className="grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-10 w-full ">
			{blogItems && blogItems.map((val, key) => (
						<>
						<BlogPostCard  item={val} key={`blog-post-${val.id}`}   />
						</>
					))
				}
				</div>
				<div className="md:w-1/12"></div>
			</div>
			<div className="flex justify-center align-center w-full my-10">
				<Link to={Links.BLOG} target="_blank"  class="relative inline-flex items-center justify-start py-3 pl-4 pr-12 overflow-hidden font-semibold text-accent uppercase transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-gray-50 group">
				<span class="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-accent group-hover:h-full"></span>
				<span class="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
				<svg class="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
				</span>
				<span class="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
				<svg class="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
				</span>
				<span class="relative w-full text-left transition-colors duration-200 ease-in-out group-hover:text-white">Take me to the Blog</span>
				</Link>
			</div>

			{/*<LazyLoadComponent>
			<div className="px-6 py-5 border-t md:p-8 border-border-200 bg-light  dark:bg-gray-700">
				<div className="relative">
					
					<Swiper
					// install Swiper modules
					modules={[Navigation, Thumbs]}
					id="productGallery"
					spaceBetween={20}
					watchSlidesProgress={true}
					freeMode={true}
					observer={true}
					observeParents={true}
					breakpoints={galleryCarouselBreakpoints}
					navigation={{
						prevEl: this.prevRef.current, // Assert non-null
						nextEl: this.nextRef.current, // Assert non-null
					}}
					>
						<SwiperSlide className="flex items-center justify-center selection:bg-transparent">
							<LazyLoadImage src="./img/offer-5.webp" width={399} />
						</SwiperSlide>
						<SwiperSlide className="flex items-center justify-center selection:bg-transparent">
							<LazyLoadImage src="./img/offer-4.webp" width={399} />
						</SwiperSlide>
						<SwiperSlide className="flex items-center justify-center selection:bg-transparent">
							<LazyLoadImage src="./img/offer-3.webp" width={399} />
						</SwiperSlide>
						<SwiperSlide className="flex items-center justify-center selection:bg-transparent">
							<LazyLoadImage src="./img/offer-2.webp" width={399} />
						</SwiperSlide>
						<SwiperSlide className="flex items-center justify-center selection:bg-transparent">
							<LazyLoadImage src="./img/offer-1.webp" width={399} />
						</SwiperSlide>
					</Swiper>
					<div
						ref={this.prevRef}
						className="product-gallery-prev absolute top-2/4 z-10 -mt-4 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border border-border-200 border-opacity-70 bg-light text-heading shadow-xl transition-all duration-200 hover:bg-gray-100 ltr:-left-4 rtl:-right-4 md:-mt-5 md:h-9 md:w-9 ltr:md:-left-5 rtl:md:-right-5 dark:bg-gray-500 dark:text-light"
						>
							<ChevronLeft className="h-4 w-4" />
						</div>
					<div
						ref={this.nextRef}
						className="product-gallery-next absolute top-2/4 z-10 -mt-4 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border border-border-200 border-opacity-70 bg-light text-heading shadow-xl transition-all duration-200 hover:bg-gray-100 ltr:-right-4 rtl:-left-4 md:-mt-5 md:h-9 md:w-9 ltr:md:-right-5 rtl:md:-left-5 dark:bg-gray-500 dark:text-light"
						>
							<ChevronRight className="h-4 w-4" />
					</div>
				</div>
			</div>
			</LazyLoadComponent>*/}

		<Footer />
		</ScrollPageTop>
		);
	}
};
