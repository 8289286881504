import React, {useState, useEffect} from "react";
import {APP_URL,API_ENDPOINT ,  register_fee ,  loadScript,toast_anim_error,random_str,
RAZOR_KEY , register_valid} from '../config/Common'
import { Link , useNavigate } from "react-router-dom";
import {Links} from '../../routes/PathLink'
import axios from 'axios'; 
const PackageMessage = (props) => {
      const [accessToken, setAccessToken] = useState(localStorage.getItem('access_token'))
      const [currentpkg,setCurrentpkg] = useState(null);
      const [registration_packages, setRegistration_packages] = useState(null);
       let navigate = useNavigate();
     useEffect(() => {
     getCurrentPackage()
     getRegistrationPackage()
   },[])

   const getCurrentPackage = () => {
      fetch(`${API_ENDPOINT}/delaer_current_subscription`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json', 
            'Authorization': `Bearer ${accessToken}`, 
         }
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         console.log('current Packages ======',result);
         setCurrentpkg(result?.data)       
      })
   }

    const getRegistrationPackage = () => {
      fetch(`${API_ENDPOINT}/get_registration_packagelist`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json', 
         }
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         setRegistration_packages(result?.data?.packagelist);       
      });
   }

   const upgrade_package = async(packageinfo) =>{

     

      const res = await loadScript(
          "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
         //alert("Razorpay SDK failed to load. Are you online?");
         toast_anim_error('Razorpay failed to load. Are you online?')
         return false;
      }

   
     let invoice = `DS_${random_str(10)}`;
      fetch(`${API_ENDPOINT}/razor_payment_order`,{
         method: 'POST',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json',
         },
         body: JSON.stringify({
            amount:(packageinfo.package_amount)*100,
            invoice:invoice
         })
      }).then(function (response) {
         return response.json();
      }).then( (result)=> {
         console.log('invoice=====',result)
         var options = {
            "key": RAZOR_KEY, // Enter the Key ID generated from the Dashboard
            "amount": result.data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            "currency": "INR",
            "name": "CTMRI",
            "description": "Dealer Subscription",
            "image": "https://i.imgur.com/3g7nmJC.jpg",
            "order_id": result.data.order_id,
            "handler": function (response){
               console.log('rzp1 response', response)
               const payment = {
                  razorpay_payment_id: response.razorpay_payment_id,
                  razorpay_order_id: response.razorpay_order_id,
                  razorpay_signature: response.razorpay_signature,
                  amount: result.data.amount,
                  valid_upto:packageinfo?.valid_month,
                  packageinfo:packageinfo,
               };
              /* const resultcap =  axios.post(`${API_ENDPOINT}/razor_payment_capture`, payment);
               if(resultcap?.data?.msg){
                   console.log(resultcap?.data?.msg);
                }else{
                    console.log(resultcap?.message);
                }
               */
               //console.log('razorpay data', data)
               //const formData = {...values, payment}
               if(response.razorpay_payment_id){
                 onSubmit(payment)            
               }
            },
              "theme": {
               "color": "#009f7f"
            }
            /*"prefill": {
               "name": values.name,
               "email": values.email,
               "contact": values.phone_no
            },
            "theme": {
               "color": "#009f7f"
            }
            */
         };

         const rzp1 = new window.Razorpay(options);
          rzp1.on('payment.failed', function (response){
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.order_id);
            alert(response.error.metadata.payment_id);
         }); 
         rzp1.open();
      })
     

   }

   const onSubmit = (payment) =>{
      

      axios.post(`${API_ENDPOINT}/add_delaer_subscription`,payment,{
         headers: {
            'Content-Type': 'multipart/form-data',
             'Authorization': `Bearer ${accessToken}`, 
         }
      }).then( (result)=> {
         console.log('data=====',result.data)
         
         let data = result.data;
         if(result?.data?.status == 1){
            navigate(Links.MY_PRODUCTS);
            navigate(0);
         }
        
      })

   }
   return(
     <> {currentpkg && currentpkg?.package_name  ? 
         <><div class="flex items-start justify-start px-5 mx-auto gap-4">
               <div class="w-fit text-left text-xl font-semibold text-accent my-7">
                  Your current Package is {currentpkg.package_name}  
               </div>
               {registration_packages &&
               <><div className="my-7 flex flex-col" > 
               <Link to="#" onClick={()=>{upgrade_package(registration_packages[1]) }} data-variant="normal" className="w-fit inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-3 py-0 h-9 text-sm font-semibold">
               Upgrade to {registration_packages[1]?.package_name}
               </Link>
               {registration_packages[1]?.package_description}
               </div>
                <div className="my-7 flex flex-col" > 
               <Link to="#" onClick={()=>{upgrade_package(registration_packages[2]) }} data-variant="normal" className="w-fit inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-3 py-0 h-9 text-sm font-semibold">
               Upgrade to {registration_packages[2]?.package_name}
               </Link>
               {registration_packages[2]?.package_description}
               </div></>
               }
         </div>
         </>
         :
         <></>
      }
      </>
   )
}

export default PackageMessage