import React, {useState, useEffect} from "react";

import LeftMenuLayout from '../layouts/LeftMenuLayout';

import OrderCard from './order/OrderCard'
import OrderCardDetail from './order/OrderCardDetail'
import {API_ENDPOINT} from './config/Common'
import {useOrder} from './order/OrderProvider'

import Collapse from 'rc-collapse';
import 'rc-collapse/assets/index.css';
import ScrollPageTop from '../routes/ScrollPageTop'
import NoRecords from './NoRecords'
const MyOrders = (props) => {
   const [accessToken] = useState(localStorage.getItem('access_token'))
   const [myOrders, setMyOrders] = useState(null)
   const {getSelectedOrder} = useOrder()
   
   
   useEffect(() => {
      getMyOrders()
   },[])

   const getMyOrders = () => {
      fetch(`${API_ENDPOINT}/get_my_orders`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+accessToken, 
         }
      }).then(function (response) {
         return response.json();
      }).then( (result)=> {
         //console.log('data=====',result)
         if(result.status === 1){
            setMyOrders(result.data)
            getSelectedOrder(result.data[0])
         }
      })
   }

   const setSelectedOrder = (val) => {
      //console.log('hererer', val)
      getSelectedOrder(val)

      const filteredData = myOrders.filter((item, i) =>  {
         if(item.order_id === val.order_id){
             item.isActive = true;
         }else{
            item.isActive = false;
         }
         return item
     });
     setMyOrders(filteredData)
     
   }

   return (
      <ScrollPageTop>
         <LeftMenuLayout>
            <div className="hidden w-full overflow-hidden lg:flex">
               
                  {
                  myOrders
                  ?
                  <div className="h-[80vh] min-h-[670px] w-full ltr:pr-5 rtl:pl-5 md:w-1/3 md:shrink-0 ltr:lg:pr-8 rtl:lg:pl-8">
                  <div className="flex h-full flex-col bg-white pb-5 md:border md:border-border-200">
                     <h3 className="py-5 px-5 text-xl font-semibold text-heading">
                     My Orders
                     </h3>
                     <div className="px-5 overflow-y-auto overflow-x-hidden">
                        {
                        myOrders && myOrders.map((val, key) => (
                        <OrderCard
                           key={`order-card-${val.order_id}`}
                           onClick={() => setSelectedOrder(val)}
                           order={val} 
                        />
                        ))
                        }
                     </div>
                  </div>
                  </div>
                  :
                  <NoRecords message="You'r not order anything yet" />

                  }
               
               <OrderCardDetail />
            </div>

            <div className="flex w-full flex-col lg:hidden">
               <div className="flex h-full w-full flex-col px-0 pb-5">
                  <h3 className="pb-5 text-xl font-semibold text-heading">
                     My Orders
                  </h3>
                  <Collapse
                     accordion={true}
                     defaultActiveKey="active"
                     expandIcon={() => null}
                  >
                     {myOrders && myOrders.map((order, index: number) => (
                        <Collapse.Panel
                        header={
                           <OrderCard
                              key={`mobile_${index}`}
                              order={order}
                              onClick={() => setSelectedOrder(order)}
                           />
                        }
                        headerClass="accordion-title"
                        key={index}
                        className="mb-4"
                        >
                           <OrderCardDetail />
                        </Collapse.Panel>
                     ))}
                  </Collapse>
               </div>
            </div>
         </LeftMenuLayout>
      </ScrollPageTop>
   );
}

export default MyOrders;