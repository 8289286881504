import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";

import LeftMenuLayout from '../layouts/LeftMenuLayout';

import classNames from 'classnames';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {API_ENDPOINT, toast_anim , pdfdoconly , hospital_regOptions, dealer_regOptions} from './config/Common'
import {Loading} from "../custom-icons";
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";

import AddAddress from './AddAddress'
import ContactNumber from './ContactNumber'
import axios from 'axios';  

import {APP_URL, APP_NAME , IMG_URL} from './config/Common'
import FileInput from '../ui/file-input.jsx';

import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import { toast } from 'react-toastify';
import ScrollPageTop from '../routes/ScrollPageTop'

import { yupResolver } from '@hookform/resolvers/yup';
import Input from '../ui/input.jsx'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import SelectInput from '../ui/select-input.jsx'
import Label from '../ui/label.tsx'
import Button from '../ui/Button.tsx'
import TextArea from '../ui/text-area.tsx'

import {
	Control,
	FieldErrors,
	useForm,
	useFormState,
	useWatch,
	Controller
 } from 'react-hook-form';

const SignupSchema = Yup.object().shape({
	name: Yup.string()
		.required('You must need to provide name.')
		.min(2, 'Name is too short!')
		.max(50, 'Name is too long!'),
	email: Yup.string()
		.required('You must need to provide email address.')
		.email('Invalid email.'),		
	state_id:Yup.object().nullable().required('State is required'),
	city_id:Yup.object().nullable().required('City is required'),
});

const SocialSchema = Yup.object().shape({
	facebook: Yup.string()
		.url('Invalid url')
		//.matches('/facebook/', 'Provide valid facebook profile url')
		.nullable(),
	twitter: Yup.string()
		.url('Invalid url')
		.nullable(),
	instagram: Yup.string()
		.url('Invalid url')
		.nullable(),
});

let defaultValues = {  
	name: '',
	email:'',
	phone_no:'',
	password:'',
	hospital_reg:'',
	state_id:'',
	city_id:'',
	pcp_ndt_no:'',
	gst_no:'',
};

const Profile = () => {
	
	const [access_token, setAccess_token] = useState(localStorage.getItem('access_token'))
	const [user_type, setUser_type] = useState(localStorage.getItem('user_type'))
	const [user_me, setUser_me] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loading_social, setLoading_social] = useState(false);
	const [states, setStates] = useState(null);
   const [cities, setCities] = useState(false);

	const { register, handleSubmit , control, reset, setValue, setError, formState: { errors },} = useForm({
		defaultValues: defaultValues,
		resolver: yupResolver(SignupSchema),
  	});

	useEffect(() => {
		getMyProfile()
		getState()

		setValue()
	},[])
	

	useEffect(() => {
		console.log()
		setValue('registered_as',{'id':defaultValues?.registered_as ,
			'label':defaultValues?.registered_as
		})
	},[defaultValues])

	const getState = () => {
		fetch(`${API_ENDPOINT}/get_states`,{
			 method: 'GET',
			 headers : {        
				  'Content-Type': 'application/json',
				  'Accept': 'application/json', 
			 }
		}).then(function (response) {
				  return response.json();
		}).then( (result)=> {
			 //console.log('states======',result)
			 setStates(result.data)			
		})
  }
  const handleSelect = (val,name) => {  
		console.log('cat val======',val,name.name)
		setError(name.name, null)
		setValue(name.name, val, true);
		if(name.name === 'state_id'){
			//setModels(null)
			getCitiesByState(val.id)
			setValue('city_id', null, true);
		}
	}

	const getCitiesByState = (state_id) => {
		fetch(`${API_ENDPOINT}/get_cities_by_state/${state_id}`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log('cities======',result)
			setCities(result.data)			
		})
	}

	const getMyProfile = () => {
		//console.log(this.state.access_token)
		fetch(`${API_ENDPOINT}/user_me`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': 'Bearer '+access_token, 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log(result)
			setUser_me(result.data)
			let data = result.data
			defaultValues = {  
				name: data.name,
				email:data.email,
				bio:data.bio,
				hospital_reg:data.hospital_reg,
				pcp_ndt_no:data.pcp_ndt_no,
				gst_no:data.gst_no,
				gst_certificate:data.gst_certificate,
				state_id:data.state,
				city_id:data.city,
				registered_as:data?.registered_as,
			};
			reset(defaultValues)
			getCitiesByState(data.state_id)
		})
	}	

	const siteMetadata = {
		url: `${APP_URL}/profile`,
		title:  `Profile - ${APP_NAME}`,
		description: `Profile ctmri`,
	}

	const onSubmit = (values) => {
		console.log('value', values)
		setLoading(true)		

		let input = values;
	      input = {...input,
	            gst_certificate: user_type === 'dealer' ? values?.gst_certificate[0]?.newimageforserver : null,
	      }

      setLoading(true)
      axios.post(`${API_ENDPOINT}/update_user_profile`,input,{
         headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer '+access_token, 
         }
      }).then( (result)=> {
         console.log('data=====',result.data)
         setLoading(false)
         console.log('data=====',result)
			if(result.status === 1){
				//setSubmitting(false)
				localStorage.setItem('user_name',result.data.name);
				toast_anim(result.message)
			}
      })

		
	}

	return (
		<ScrollPageTop>
			<ReactSEOMetaTags
				render={(el: React.ReactNode) => <Helmet>{el}</Helmet>}
				website={{ ...siteMetadata }}
				breadcrumb={[
					{ title: 'Home', url: `${APP_URL}` },
					{ title: 'Profile', url: `${APP_URL}/profile` },
				]}
			/>
         <LeftMenuLayout>
				<div className="w-full">
					<div className={classNames('w-full p-5 md:p-8 bg-light dark:bg-gray-700 shadow rounded mb-8')}>
						<p className="text-lg lg:text-xl text-heading capitalize mb-5 dark:text-zinc-400">Profile</p>
						{
						user_me &&	
						<form onSubmit={handleSubmit(onSubmit)}>
						<Input                
                            label={user_type === 'user' ? `Hospital name` : user_type === 'dealer' ? `Company name` : `Name`}
                            {...register('name')}
                            variant="outline"
                            className="mb-5"
                            error={errors.name?.message}
                            required
                        />
						<Input                
                            label={`Email`}
                            {...register('email')}
                            variant="outline"
                            className="mb-5"
                            type="email"
                            error={errors.email?.message}
                            required
                        />
						{
								user_type === 'user' ?
								<>
								<Input                
                            label={`Hospital Register Number`}
                            {...register('hospital_reg')}
                            variant="outline"
                            className="mb-5"
                        />
                         <div className="mb-5">
                         <Label>Registered As</Label>
                         <SelectInput
                            name="registered_as"                            
                            control={control}
                            getOptionLabel={(option: any) => option.label}
                            getOptionValue={(option: any) => option.id}                        
                            options={hospital_regOptions}
                            onChange={handleSelect}
                         />
                         <p className="my-2 text-xs text-start text-red-500">{errors.registered_as?.message}</p>
                      </div>
                        </>
								:
								user_type === 'dealer' &&
								<>
								<Input                
									label={`Company GST Number`}
									{...register('gst_no')}
									variant="outline"
									className="mb-5"
									error={errors.gst_no?.message}
								/>
								<div className="mb-5">
				                     <label htmlFor="gst_certificate" className="block mb-3 text-sm font-semibold leading-none text-body-dark">GST Certificate </label>
				                     <FileInput 
				                        name="gst_certificate" 
				                        control={control} 
				                        multiple={false} 
				                        helperText='Upload GST Certificate'
				                        help_ext='PDF, DOC, DOCX'
				                        acceptFile={pdfdoconly}
				                     />
				                     <p className="my-2 text-xs text-start text-red-500">{errors.gst_certificate?.message}</p>
				                </div>
				                {defaultValues.gst_certificate && defaultValues.gst_certificate != null ?
			                        (<div
			                           className="relative mb-2 inline-flex flex-col overflow-hidden rounded me-2 border border-border-200" >
			                           <a href={`${IMG_URL}${defaultValues.gst_certificate}`} target="_blank">Download GST Certificate</a>
			                        </div>)
			                        :null
			                     }
								<Input                
									label={`PCPNDT Number`}
									{...register('pcp_ndt_no')}
									variant="outline"
									className="mb-5"
									
								/>
								 <div className="mb-5">
	                         <Label>Registered As</Label>
	                         <SelectInput
	                            name="registered_as"                            
	                            control={control}
	                            getOptionLabel={(option: any) => option.label}
	                            getOptionValue={(option: any) => option.id}                        
	                            options={dealer_regOptions}
	                            onChange={handleSelect}
	                         />
	                         <p className="my-2 text-xs text-start text-red-500">{errors.registered_as?.message}</p>
	                      </div>
								</>
								}

								<div className="mb-5">
                            <Label>State <span className="text-red-500"> *</span></Label>
                            <SelectInput
                                name="state_id"                            
                                control={control}
                                getOptionLabel={(option: any) => option.name}
                                getOptionValue={(option: any) => option.id}                        
                                options={states}
                                onChange={handleSelect}
                            />
                            <p className="my-2 text-xs text-start text-red-500">{errors.state_id?.message}</p>
                        </div>
                        <div className="mb-5">
                            <Label>City <span className="text-red-500"> *</span></Label>
                            <SelectInput
                                name="city_id"                            
                                control={control}
                                getOptionLabel={(option: any) => option.city}
                                getOptionValue={(option: any) => option.id}                        
                                options={cities}
                                onChange={handleSelect}
                            />
                            <p className="my-2 text-xs text-start text-red-500">{errors.city_id?.message}</p>
                        </div>
								
								<TextArea
									label={`Bio`}
                            {...register('bio')}
                            variant="outline"
                            className="mb-5"
								/>
								
								<div className="flex">
									{
									!loading
									?	
									<button data-variant="normal" className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-0 h-12 ltr:ml-auto rtl:mr-auto">Save</button>
									:
									<Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 ltr:ml-auto" />
									}
								</div>
							</form>
						}
					</div>
					
					{
						user_me &&	<ContactNumber contact={user_me.phone_no} />}

					<div className={classNames('w-full p-5 md:p-8 bg-light dark:bg-gray-700 shadow rounded')}>
						<p className="text-lg lg:text-xl text-heading capitalize mb-5 dark:text-zinc-400">Social links</p>
						{
						user_me &&	
						<Formik
							initialValues={{
									twitter:user_me.socials ? user_me.socials.twitter : '',
									facebook:user_me.socials ? user_me.socials.facebook : '',
									instagram:user_me.socials ? user_me.socials.instagram : '',
									linkedin:user_me.socials ? user_me.socials.linkedin : '',
							}}
							
							validationSchema={SocialSchema}
							onSubmit={(values, { setErrors, setSubmitting }) => {
								setLoading_social(true)
								fetch(`${API_ENDPOINT}/user_me/socials`,{
									method: 'POST',
									headers : {        
										'Content-Type': 'application/json',
										'Accept': 'application/json',
										'Authorization': 'Bearer '+access_token, 
									},
									body: JSON.stringify(values)
								}).then(function (response) {
									return response.json();
								}).then( (result)=> {
									console.log(result)
									setLoading_social(false)
									if(result.status === 1){
										setSubmitting(false)
										toast_anim(result.message)
									}
								})
							}}
						>
						{({ values, errors, touched, handleSubmit, handleChange, isSubmitting}) => (
							<form onSubmit={handleSubmit}>
								<div className="mb-6">
									<label htmlFor="facebook" className="relative block mb-3 text-sm font-semibold leading-none text-body-dark dark:text-zinc-400">Facebook</label>
									<span className='relative text-gray-400 focus-within:text-gray-500 block'>
									<FaFacebookF className="pointer-events-none w-6 h-6 absolute top-1/2 transform -translate-y-1/2 left-3"/>

									<input id="facebook" name="facebook" type="text" className="flex w-full appearance-none items-center px-4 text-sm text-heading transition duration-300 ease-in-out focus:outline-none focus:ring-0 border border-border-base rounded focus:border-accent h-12 pl-14 dark:bg-gray-600 dark:border-gray-700 dark:text-zinc-400" value={values.facebook} onChange={handleChange} placeholder='https://facebook.com' />
									</span>
									
									{
									errors.facebook && touched.facebook &&
									<p className="mt-2 text-xs text-red-500">{errors.facebook}</p>
									}
								</div>
								<div className="mb-6">
									<label htmlFor="twitter" className="block mb-3 text-sm font-semibold leading-none text-body-dark dark:text-zinc-400">Twitter</label>
									<span className='relative text-gray-400 focus-within:text-gray-500 block'>
									<FaTwitter className="pointer-events-none w-6 h-6 absolute top-1/2 transform -translate-y-1/2 left-3"/>
									<input id="twitter" name="twitter" type="text" className="flex w-full appearance-none items-center px-4 text-sm text-heading transition duration-300 ease-in-out focus:outline-none focus:ring-0 border border-border-base rounded focus:border-accent h-12 pl-14 dark:bg-gray-600 dark:border-gray-700 dark:text-zinc-400" value={values.twitter} onChange={handleChange} placeholder='https://twitter.com' />
									</span>
									{
									errors.twitter && touched.twitter &&
									<p className="mt-2 text-xs text-red-500">{errors.twitter}</p>
									}
								</div>
								<div className="mb-6">
									<label htmlFor="instagram" className="block mb-3 text-sm font-semibold leading-none text-body-dark dark:text-zinc-400">Instagram</label>
									<span className='relative text-gray-400 focus-within:text-gray-500 block'>
									<FaInstagram className="pointer-events-none w-6 h-6 absolute top-1/2 transform -translate-y-1/2 left-3"/>
									<input id="instagram" name="instagram" type="text" className="flex w-full appearance-none items-center px-4 text-sm text-heading transition duration-300 ease-in-out focus:outline-none focus:ring-0 border border-border-base rounded focus:border-accent h-12 pl-14 dark:bg-gray-600 dark:border-gray-700 dark:text-zinc-400" value={values.instagram} onChange={handleChange} placeholder='https://instagram.com' />
									</span>
									{
									errors.instagram && touched.instagram &&
									<p className="mt-2 text-xs text-red-500">{errors.instagram}</p>
									}

								</div>
								<div className="mb-6">
									<label htmlFor="linkedin" className="block mb-3 text-sm font-semibold leading-none text-body-dark dark:text-zinc-400">Linkedin</label>
									<span className='relative text-gray-400 focus-within:text-gray-500 block'>
									<FaLinkedin className="pointer-events-none w-6 h-6 absolute top-1/2 transform -translate-y-1/2 left-3"/>
									<input id="linkedin" name="linkedin" type="text" className="flex w-full appearance-none items-center px-4 text-sm text-heading transition duration-300 ease-in-out focus:outline-none focus:ring-0 border border-border-base rounded focus:border-accent h-12 pl-14 dark:bg-gray-600 dark:border-gray-700 dark:text-zinc-400" value={values.linkedin} onChange={handleChange} placeholder='https://linkedin.com' />
									</span>
									{
									errors.linkedin && touched.linkedin &&
									<p className="mt-2 text-xs text-red-500">{errors.linkedin}</p>
									}

								</div>
								<div className="flex">
									{
									!loading_social
									?	
									<button data-variant="normal" className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-0 h-12 ltr:ml-auto rtl:mr-auto">Save</button>
									:
									<Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 ltr:ml-auto" />
									}
								</div>
							</form>
						)}
						</Formik>
						}

					</div>
					{
						localStorage.getItem('user_type') === 'user' &&
					<AddAddress />
					}
				</div>
			</LeftMenuLayout>
      </ScrollPageTop>
   );
};

export default Profile