import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import Header from '../layouts/Header';
import Footer from '../layouts/Footer'
import { useNavigate, useLocation } from "react-router-dom";
import ScrollPageTop from '../routes/ScrollPageTop'

const Logout = (props) => {
   
   const navigate = useNavigate();
   const {state} = useLocation();
   
   useEffect(() => {
      //console.log(navigate)
      
      console.log('page state',state)
      const user_name = localStorage.getItem('user_name');
      if(user_name){
         navigate('/profile', { replace: true });
      }else{
         setTimeout(() => {
            navigate('/', { replace: true });
         }, 3000);
      }
   });
   return(
      <ScrollPageTop>
         <Header />
         <div className={'_dashboard mx-auto w-full flex max-w-1920 flex-col items-center justify-center bg-gray-100 px-5 py-10 lg:flex-col xl:py-14 xl:px-8 2xl:px-14'}>
         <h1 className="mb-4 text-4xl">{state?.user_name}</h1>
         <img alt="Contact" src="./img/contact.svg" decoding="async" data-nimg="intrinsic" className="h-250 w-250" />
         
         <h2 className="mb-4 text-3xl mt-4">You're now signed out. Come back soon! </h2>
         </div>
         <Footer />
      </ScrollPageTop>
   )
}

export default Logout;