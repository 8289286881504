import { useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import {CloseIcon} from '../custom-icons'
import {
   API_ENDPOINT, 
   toast_anim_error,
   toast_anim, 
   APP_NAME, 
   APP_URL,
   IMG_URL,
   amount_format,
   LIMIT,
   replaceWithBr,
   discount,
   machine_condition
} from '../components/config/Common'

const BroadcastMessage = () => {
   console.log('user Type===>',localStorage.getItem('user_type'))
   const [accessToken, setAccessToken] = useState(localStorage.getItem('access_token'))
   const [userType, setUserType] = useState(localStorage.getItem('user_type'))
   const [message, setMessage] = useState(null)

   useEffect(() => {
      getBroadcastMessage();
   },[])

   const getBroadcastMessage = () => {
      fetch(`${API_ENDPOINT}/get_broadcast_message`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json', 
            'Authorization': `Bearer ${accessToken}`, 
         }
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         console.log('broadcast_message ======',result);
         if(result.status === 1){
            setMessage(result?.data)
         }       
      })
   }

   return(
      <>
      {
      message &&
         <>
         <h1 className="text-2xl pt-10 px-5 text-red-500">Notification</h1>
         {
         message.map((val, key) => (            
            <>
            {
             val.send_to === 'Both' || val.send_to === userType
             ?
            <div className={'mx-auto w-full bg-light py-1 px-5 lg:bg-gray-100 xl:flex-row xl:py-1 dark:bg-gray-800'}>
               <div className="overflow-hidden rounded border border-border-200 bg-red-500 dark:bg-gray-700 dark:border-none py-3 px-5 text-white mb-3 whitespace-pre-wrap">{val.message}</div>
            </div>
            :
            null
            }
            </>
         ))
         }
      </>
      }
      </>
   )

}

export default BroadcastMessage