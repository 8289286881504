import {Fragment, useRef, useState, useEffect} from "react";
import { Link } from "react-router-dom";
import classNames from 'classnames';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
import {API_ENDPOINT, toast_anim} from '../config/Common'
import {Loading} from "../../custom-icons";
import { PlusIcon } from '@heroicons/react/24/solid'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { AiFillEdit } from "react-icons/ai";
import { IoTrashOutline } from "react-icons/io5";
import { IN } from 'country-flag-icons/react/3x2'

const ValidationSchema = Yup.object().shape({
	phone_no: Yup.string()      
      .min(10, 'Invalid phone number.')
		.required('Contact number is required.'),
});

const ContactNumber = (props) => {
   const [open, setOpen] = useState(false)
   const cancelButtonRef = useRef(null)
   const [loading, setLoading] = useState(false)
   const [accessToken, setAccessToken] = useState(localStorage.getItem('access_token'))
   const [phoneNo, setPhoneNo] = useState(null)
   const [invoice, setInvoice] = useState(localStorage.getItem('rand_num'))
   
   useEffect(() => {
      getBillingContact()
   },[])

   const getBillingContact = () => {
      fetch(`${API_ENDPOINT}/get_billing_contact/${invoice}`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${accessToken}`, 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			//console.log('contact=========',result)
         setPhoneNo(result.data)
		})
   }

   return (
   <>   
      <div className={classNames('w-full p-5 md:p-8 bg-light dark:bg-slate-800 shadow rounded mb-8 mt-8')}>
         <div className="flex items-center justify-between mb-5 md:mb-8">
            <div className="flex items-center space-x-3 md:space-x-4 rtl:space-x-reverse">
               <span className="flex items-center justify-center w-8 h-8 text-base rounded-full bg-accent text-light lg:text-xl">1</span>
               <p className="text-lg dark:text-white lg:text-xl text-heading capitalize">Contact Number</p>
            </div>
            <button className="flex items-center text-sm font-semibold text-accent transition-colors duration-200 focus:outline-none focus:text-accent-hover hover:text-accent-hover" onClick={() => setOpen(true)}>
               <PlusIcon className="w-4 h-4 stroke-2 ltr:mr-0.5 rtl:ml-0.5" />Update</button>
         </div>
         <div className="mb-6">
            <span className='relative text-gray-400 focus-within:text-gray-600 block'>
            <IN className="pointer-events-none w-6 h-6 absolute top-1/2 transform -translate-y-1/2 left-3"/>
            <input type="text" className="flex w-full appearance-none items-center px-4 text-sm text-heading transition duration-300 ease-in-out focus:outline-none focus:ring-0 border border-border-base rounded focus:border-accent h-12 pl-5 pl-12" readOnly value={phoneNo}  />
            
            </span>
         </div>
         
      </div>


      <Transition.Root show={open} as={Fragment} dir='ltr'>
      <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
               <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
               <div className="flex flex-col justify-center bg-light p-5 sm:p-8 md:min-h-0 md:rounded-xl">
                  <h1 className="mb-5 text-center text-sm font-semibold text-heading sm:mb-6">Update Contact Number</h1>
                  
                  <Formik
							initialValues={{
								phone_no: phoneNo,
							}}
                     validationSchema={ValidationSchema}
                     onSubmit={(values, { setErrors, setSubmitting, resetForm }) => {
                        
                        //console.log(accessToken)
                        //openSnackbar('This is the content of the Snackbar.')
                        setLoading(true)
                        fetch(`${API_ENDPOINT}/update_billing_contact/${invoice}`,{
                           method: 'POST',
                           headers : {        
                              'Content-Type': 'application/json',
                              'Accept': 'application/json',
                              'Authorization': 'Bearer '+accessToken, 
                           },
                           body: JSON.stringify(values)
                        }).then(function (response) {
                              return response.json();
                        }).then( (result)=> {
                           console.log('data=====',result)
                           setLoading(false)
                           if(result.status === 1){
                              setOpen(false)
                              toast_anim(result.message)
                              setPhoneNo(result.data.phone_no)
                           }

                           if(result.status === 0){
                              setErrors({phone_no:result.message})
                           }
                        })
                        
                     }}
                  >
                  {({ values, errors, touched, handleSubmit, handleChange, isSubmitting}) => (   
                  <Form className="w-full">                     

                     <div className="flex flex-col">
                        <div className="flex w-full items-center md:min-w-[360px]">
                           <div className="w-full react-tel-input ">
                           <span className='relative text-gray-400 focus-within:text-gray-600 block'>
                           <IN className="pointer-events-none w-6 h-6 absolute top-1/2 transform -translate-y-1/2 left-3"/>
                              <Field className="form-control pl-12 !flex !items-center !w-full !appearance-none !transition !duration-300 !ease-in-out !text-heading !text-sm focus:!outline-none focus:!ring-0 !border !border-border-base ltr:!border-r-0 rtl:!border-l-0 !rounded ltr:!rounded-r-none rtl:!rounded-l-none focus:!border-accent !h-12" name="phone_no" type="tel" />
                              </span>
                           </div>
                           <button data-variant="normal" className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-0 h-12 !text-sm ltr:!rounded-l-none rtl:!rounded-r-none" type='submit'>Update Contact</button>
                           
                        </div>
                        {errors.phone_no && touched.phone_no &&
                           <p className="mt-2 text-xs text-red-500">{errors.phone_no}</p>
                        }
                        {
                        loading &&
                        <Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600" />
                        }
                     </div>
                  </Form>
                  )}
                  </Formik>
               </div>
               </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
   </>   
   );
}

export default ContactNumber;