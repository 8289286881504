import React from "react";
import { Link } from "react-router-dom";
import {Bed, Table, Sofa, Chair, Bucket, XrayIcon, MriIcon, CtScanIcon} from "../custom-icons";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import {API_ENDPOINT, APP_URL, APP_NAME, IMG_URL, amount_format,discount, LIMIT} from './config/Common'
import AOS from 'aos';
import 'aos/dist/aos.css';
import ScrollPageTop from '../routes/ScrollPageTop'

import { BiRupee } from "react-icons/bi";
import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import {Links} from '../routes/PathLink'

// import Swiper core and required modules
import { Navigation, Pagination, Thumbs, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import {ChevronLeft, ChevronRight, Loading} from "../custom-icons";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';

import ProductCard from '../cards/ProductCard'

import InfiniteScroll from "react-infinite-scroll-component";
// product gallery breakpoints
const galleryCarouselBreakpoints = {
	320: {
	  slidesPerView: 1,
	},
	480: {
	  slidesPerView: 2,
	},
	640: {
	  slidesPerView: 3,
	},
	
  };

const upcomingBreakpoints = {
	320: {
	  slidesPerView: 1,
	},
	510: {
	  slidesPerView: 1,
	},
	600: {
		slidesPerView: 2,
	},
	950: {
		slidesPerView: 3,
	},
	1300: {
	  slidesPerView: 4,
	},
};
export default class Comingsoon extends React.Component {
	constructor(){
		super();
		
	}
	
	render(){
		
	return (
		<div className="w-screen h-screen flex justify-center items-center">
		<div className="bgimg justify-center items-center flex min-h-full">
		  <div class="topleft">
		    
		  </div>
		  <div >
		    <img src="http://ctmrihub.com/console/public/storage/setting_images/1683621354.png"  />
		    <h1>COMING SOON</h1>
		  </div>
		  <div class="bottomleft">
		  </div>
		</div>
		</div>
		);
	}
};
