import React, {useState, useEffect} from "react";
import ScrollPageTop from '../../routes/ScrollPageTop'
import LeftMenuLayout from '../../layouts/LeftMenuLayout';

import moment from 'moment';
import classNames from 'classnames';
import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import Table from 'rc-table';
import Pagination from '../../_utils/pagination.jsx'

import {
   API_ENDPOINT,
   APP_NAME, 
   APP_URL,
   date_format,
   amount_format,
   LIMIT,
   replaceWithBr 
} from '../config/Common'

import ModalView from '../../_utils/ModalView'
import {Links} from '../../routes/PathLink'
import ModalTicketView from './ModalTicketView'
import NoRecords from '../NoRecords'

const AmcTicket = () => {
   const [accessToken] = useState(localStorage.getItem('access_token'))
   const [tickets, setTickets] = useState(null)
   const [totalRec, setTotalRec] = useState(null)
   const [currentPage, setCurrentPage] = useState(1)
   const [isOpen, setIsOpen] = useState(false)
   const [ticket, setTicket] = useState(null)

   const [metaData, setMetaData] = useState({
      url: `${APP_URL}${Links.AMS_TICKETS}`,
      title:  `AMC Packages - ${APP_NAME}`,
      description:'AMC Packages'
   })

   useEffect(() => {
      getMyTickets(currentPage)
   },[])

   const getMyTickets = (page) => {
      let url = 'user_amc_tickets'
      if(localStorage.getItem('user_type') === 'dealer'){
         url = 'dealer_amc_tickets'
      }
      //console.log(url)
      fetch(`${API_ENDPOINT}/${url}?pg=${page}&limit=${LIMIT}`,{
         method: 'GET',
         headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+accessToken, 
         }
      }).then(function (response) {
         return response.json();
      }).then( (result)=> {
         console.log('ticket=====',result)
         setTickets(result.data)
         setTotalRec(result.totalRec)         
      })
   }

   const statusBadge = (record: any) => {
      let sts = true
      return(
         <>
         {
         record.status === 'open' ?
         <span className={classNames("bg-red-100 text-red-800 border-red-400 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border capitalize")}>{record.status}</span>
         :
         record.status === 'closed' ?
         <span className={classNames("bg-green-100 text-green-800 border-green-400 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border capitalize")}>{record.status}</span>
         :
         <span className={classNames("bg-yellow-100 text-yellow-800 border-yellow-400 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border capitalize")}>{record.status}</span>
         }
         </>
      )
   }

   const getMachineName = (record: any) => {
      return(
         <>
         {
         record.package &&
         <>
         <p className="text-sm">
            {
            record.package.machine.parent &&
            <span>{record.package.machine.parent.cat_name}, </span>
            }
            {record.package.machine.cat_name}
         </p>
         {
            record.model_name &&
         <p className="text-sm">{record.brand_name}, {record.model_name}</p>
         }
         </>
         }
         </>
      )
   }

   const columns = [
      {
         title: 'Ticket',
         dataIndex: '',
         key: 'ticket',
         width: 100,         
         align: 'left',
         render: (tk) => <button onClick={() => openModal(tk)} className="inline-flex items-center gap-2 text-sm text-accent hover:text-accent-hover hover:underline">{tk.ticket_no}</button>
      },
      {
         title: 'Machine',
         dataIndex: '',
         key: 'status',
         width: 100,         
         align: 'left',
         render: getMachineName
         
      },      
      {
         title: 'Condition',
         dataIndex: '',
         key: 'status',
         width: 100,         
         align: 'left',
         render: (tk) => <p className="text-sm capitalize">
            <div>{tk.conditions}</div>
            <div>YOM: {tk.yom}</div>
            </p>
      },
      {
         title: 'Amount/year',
         dataIndex: '',
         key: 'amount',
         width: 100,         
         align: 'right',
         render: (tk) => <p className="flex justify-end text-sm">{amount_format(tk.amount)}</p>
      },
      {
         title: 'Status',
         dataIndex: '',
         key: 'status',
         width: 100,         
         align: 'left',
         render: statusBadge
         
      },
      {
         title: 'Start/End',
         dataIndex: '',
         key: 'date',
         width: 100,         
         align: 'left',
         render: (tk) => <p className="text-sm">
            <div>{date_format(tk.created_at)}</div>
            <div>{tk.expireOn ? date_format(tk.expireOn):date_format(moment(tk.created_at).add(1, 'years'))}</div>
         </p>
      },
   ]

   const onPagination = (page, limit) => {
      //console.log('page===',page, limit) 
      
      getMyTickets(page)
      setCurrentPage(page)

      window.scrollTo({
         top: 0,
         left: 0,
         behavior: "smooth"
      });
   }

   const closeModal = () => {
      setIsOpen(false)
   }
   const openModal = (item) => {
      //console.log(item)
      setTicket(item)
      setIsOpen(true)
   }

   return(
      <ScrollPageTop>
         <ReactSEOMetaTags
				render={(el: React.ReactNode) => <Helmet>{el}</Helmet>}
				website={{ ...metaData }}
			/>
         <LeftMenuLayout>
            <div style={{overflow: 'hidden'}} className={classNames('w-full py-5 bg-light shadow rounded')}>
               <h1 className="ml-6 text-lg text-heading sm:text-xl">
					AMC Packages
					</h1>
               {
               totalRec > 0 ?   
               <div className="mt-6">
                  <Table 
                     columns={columns} 
                     data={tickets} 
                     className="orderDetailsTable w-full"
                     rowKey={(record:any) => record.cart_id}
                     scroll={{ x: 600 }}
                  />
                  <div className="mt-5 flex items-center justify-end">
                  {
                  totalRec > LIMIT &&
                  <Pagination
                     total={totalRec}
                     current={currentPage}
                     pageSize={LIMIT}
                     onChange={onPagination}
                  />
                  }
                  </div>
               </div>
               :
               <NoRecords message="AMC package are not available." />
               }
            </div>
         
            {
            ticket &&
            <ModalView open={isOpen} onClose={closeModal}>
               <ModalTicketView ticket={ticket} />
            </ModalView>
            }
         </LeftMenuLayout>
      </ScrollPageTop>
   ) 
}

export default AmcTicket