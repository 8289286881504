import React, {useState, useEffect,useRef , useCallback , Fragment} from "react";
import SearchBox from './search-box.tsx';
import { Dialog, Transition } from '@headlessui/react'
import {LoadingSearch} from "../../custom-icons";

import { 
  useParams, 
  useNavigate, 
  createSearchParams, 
  useLocation, 
} from "react-router-dom";
import {queryStringToJSON} from '../../components/config/Common.js'

import { useSearch } from './search.context.tsx';

interface Props {
  label: string;
  variant?: 'minimal' | 'normal' | 'with-shadow' | 'flat';
  [key: string]: unknown;
}

const Search: React.FC<Props> = ({ label, variant, ...props }) => {

  const { searchTerm, updateSearchTerm } = useSearch();
  const [ slugval, setSlugval ] = useState('');
  const submitRef = useRef();

  let [isOpen, setIsOpen] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  const testCallback = useCallback(() => {
    console.log("CALL BACK",searchTerm)
    setTimeout(() => { 
      submitRef.current.click();
      closeModal();
    }, 2000);
    //
  }, [searchTerm]);

  const location = useLocation()
  const navigate = useNavigate();

  const handleOnChange = (e: any) => {
    const {value} = e.target;
    updateSearchTerm(value);
  };

  const onSearch = (e: any) => {
    e.preventDefault();
    
    const { pathname, search } = location;
    //if (!searchTerm) return;
    updateSearchTerm(searchTerm)
    /* let query = queryStringToJSON(search)
    if(search){
      query = { ...query, search: searchTerm }
    }else{
      query = {search: searchTerm}
    }
     */
    let path = pathname;
    if(!path.includes('/shop')){
      //alert(slugval);
      if(slugval && slugval != ''){
        path = '/shop/'+slugval;
      }else{
         path = '/shop';
      }
    }

    if(path.includes('/shop')){
      //alert(slugval);
      if(slugval && slugval != ''){
        path = '/shop/'+slugval;
      }
    }
    
    //console.log(query)
    navigate(path);
    /* if (!searchTerm) return;
    const { pathname, query } = router;
    router.push(
      {
        pathname,
        query: { ...query, text: searchTerm },
      },
      undefined,
      {
        scroll: false,
      }
    ); */
  };

  
function onSetValue(serachtext,slugtext){
  console.log("AAA",serachtext,slugtext);
  openModal();
  if(slugtext && slugtext != ''){
    updateSearchTerm('');
  }else{
    updateSearchTerm(serachtext);
  }
  setSlugval(slugtext);
  testCallback();
  //submitRef.current.requestSubmit();
   //
  // submitRef?.current.dispatchEvent(new Event("submit"));

}
  function clearSearch() {
    updateSearchTerm('');
    /* const { pathname, query } = router;
    const { text, ...rest } = query;
    if (text) {
      router.push(
        {
          pathname,
          query: { ...rest },
        },
        undefined,
        {
          scroll: false,
        }
      );
    } */
  }

  return (
    <><SearchBox
      label={label}
      onSubmit={onSearch}
      onClearSearch={clearSearch}
      onChange={handleOnChange}
      value={searchTerm}
      name="search"
      placeholder={'Search product'}
      variant={'minimal'}
      onSetValue={onSetValue}
      {...props}
      submitRef={submitRef}

    />
    <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 opacity-70 bg-dark" />

          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl  p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg  font-medium leading-6 text-white text-center"
                  >
                    Loading ....
                  </Dialog.Title>
                  <div className="mt-2">
                  <LoadingSearch />
                  </div>

                  
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    
    </>
  );
};

export default Search;
