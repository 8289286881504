import React, {useState, useEffect, lazy} from "react";
import Logo from './logo';
import { Link } from "react-router-dom";
import AuthModalView from "../components/auth/AuthModalView"
import AuthorizedMenu from './AuthorizedMenu'
import MobileNav from './mobile-nav';
import { useNavigate } from "react-router-dom";

import { Drawer } from "flowbite";
import CartDrawer from './CartDrawer'
import {CartOutlinedIcon, SearchIcon} from "../custom-icons";

import { useCart } from '../cart/cart-utils'
//import {APP_URL} from '../config/Common'
import {useAuth} from '../provider/AuthProvider'
import { BsFillTelephoneFill } from "react-icons/bs";
import {admin_phone, APP_URL} from '../components/config/Common'
import {Links} from '../routes/PathLink'
import ModalView from '../_utils/ModalView'
import Search from '../ui/search/search.tsx'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import Banner from '../components/banner/banner';
const MegaMenuN = lazy(() => import("./MegaMenu"));


const Header = (props) => {
    const {hidesearch ,hidebanner } = props;
    const { logout, isUser } = useAuth();
    const [openLogin, setOpenLogin] = useState(false)
    const [isLogin, setIsLogin] = useState(false)
    const [drawerVisible, setDrawerVisible] = useState(false)
    //const [cartCount, setCartCount] = useState(0)
    const { cartCount } = useCart();
    const [openLoginNav, setOpenLoginNav] = useState(false)
    const [callNowModal, setCallNowModal] = useState(false)
    const [authModelView, setAuthModelView] = useState('LoginView')
    const [user_type, setUser_type] = useState('')
    const [mbSearch, setMbSearch] = useState(false)
    

    const navigate = useNavigate();

    useEffect(() => {
        //console.log('props===========',props)
        const access_token = localStorage.getItem('access_token');
         const user_type = localStorage.getItem('user_type');
         setUser_type(user_type);

        if(access_token){
            setIsLogin(true)
        }
    }, [])

    const openLoginModal = (loginView) => {        
        setOpenLogin(!openLogin)
        setAuthModelView(loginView)
        setOpenLoginNav(false)
    }
    const setLogin = () => {
        setIsLogin(true)
        openLoginModal(authModelView)
        //console.log('paren===========')
        if(props.rerenderParentCallback){
            props.rerenderParentCallback();
        }
    }
    const logoutUser = () => {
        
        let user_name = localStorage.getItem("user_name")

        localStorage.removeItem("access_token")
        localStorage.removeItem("user_type")
        localStorage.removeItem("user_id")
        localStorage.removeItem("user_name")
        logout()
        setIsLogin(false)
        navigate(
            Links.LOGOUT,
            {
               state: { user_name: user_name },
               replace: true 
            },
            
        );
    }
    const cart_count = (count) => {
        //setCartCount(count)
    }

    const openDrawer = () => {
        const options = {
            placement: 'right',
            backdrop: true,
            bodyScrolling: false,
            edge: false,
            edgeOffset: '',
            backdropClasses: 'cart-drawer bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-30',
            onHide: () => {
                //console.log('drawer is hidden');
                setDrawerVisible(false)
            },
            onShow: () => {
                //console.log('drawer is shown');
                setDrawerVisible(true)
            },
            onToggle: () => {
                //console.log('drawer has been toggled');
            }
          };
        const $targetEl = document.getElementById('drawer-right-example');
        const drawer: DrawerInterface = new Drawer($targetEl, options);
        drawer.show();
    }
    
    const closeDrawer = () => {
        //console.log('close drawer')
        const options1 = {
            placement: 'right',
            backdrop: false,
            bodyScrolling: true,
            edge: false,
            edgeOffset: '',
            backdropClasses: 'bg-gray-900 bg-opacity-0 dark:bg-opacity-0 fixed',
          };
        const $targetEl = document.getElementById('drawer-right-example');
        const drawer: DrawerInterface = new Drawer($targetEl, options1);
        drawer.hide();
        let cartDrawer = document.querySelector(".cart-drawer");
        let body = document.querySelector("body");
        cartDrawer.remove();
        body.classList.remove("overflow-hidden");
    }

    
    const closeLoginNav = () => {
        setOpenLoginNav(false)
        setCallNowModal(false)
    }

    const openLoginNavModal = () => {
        setOpenLoginNav(true)
    }
    const openCallNowModal = () => {
        setCallNowModal(true)
    }
    const openMobileSearch = () => {
        setMbSearch(!mbSearch)
    }
    
    
    return (
        <>
        <header className={'site-header-with-search h-14 md:h-16 lg:h-22'}>
            <div
                className={'fixed z-30 flex h-14 w-full transform-gpu items-center justify-between border-b border-border-200 bg-light px-4 py-5 shadow-sm transition-transform duration-300 md:h-16 lg:h-22 lg:px-3 dark:bg-gray-800 dark:border-gray-600'}
             >
                <div className="flex w-full items-center lg:w-auto">
                    <Logo className={'ltr:ml-0 rtl:mr-0'} />

                </div>
                <ul className="lg:hidden shrink-0 flex items-center justify-center space-x-5">
                    <li>
                    <button data-variant="normal" className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-3 py-0 h-9 text-sm font-semibold" onClick={openCallNowModal}><BsFillTelephoneFill className="mr-2" /> Call Now <ChevronRightIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" /></button>
                    </li>
                    <li>
                        <button className="pt-2" onClick={openMobileSearch}>
                            <SearchIcon className="h-5 w-5" />
                        </button>
                    </li>
                </ul>
                <ul className="hidden shrink-0 items-center space-x-4 rtl:space-x-reverse lg:flex 2xl:space-x-10">
                    <li>
                        <Link
                            to={Links.HOME}
                            className="flex items-center font-normal text-sm no-underline transition duration-200 text-accent hover:text-orange-500 focus:text-orange-500 dark:text-zinc-400"
                        >Home</Link>
                    </li>
                    <li className="hidden md:block">
                      <MegaMenuN/>
                    </li>
                    {!isLogin &&
                    <li>
                        <Link
                            to={Links.ABOUT}
                            className="flex items-center font-normal text-sm no-underline transition duration-200 text-accent hover:text-orange-500 focus:text-orange-500 dark:text-zinc-400"
                        >About</Link>
                    </li>
                    }
                    <li>
                        <Link
                            to={Links.CONTACT}
                            className="flex items-center font-normal text-sm no-underline transition duration-200 text-accent hover:text-orange-500 focus:text-orange-500 dark:text-zinc-400"
                        >Contact</Link>
                    </li>
                    <li>
                        <Link
                            to={Links.CATEGORY}
                            className="flex items-center font-normal text-sm no-underline transition duration-200 text-accent hover:text-orange-500 focus:text-orange-500 dark:text-zinc-400"
                        >Shop</Link>
                    </li>
                    <li>
                        <Link
                            to={`${APP_URL}${Links.BLOG}`} target='_blank'
                            className="flex items-center font-normal text-sm no-underline transition duration-200 text-accent hover:text-orange-500 focus:text-orange-500 dark:text-zinc-400"
                        >Blog</Link>
                    </li>
                    <li>
                        <Search variant={'minimal'} />
                    </li>
                    {user_type == 'user' || user_type == 'dealer' ?
                        (<li>
                    <Link to={Links.CREATE_AMS_TICKET} data-variant="normal" className="inline-flex items-center justify-center shrink-0 leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-2 py-0 h-9 text-sm">Buy AMC Package</Link>
                    </li>)
                        :
                        null
                    }
                    <li>
                    <button data-variant="normal" className="inline-flex items-center justify-center shrink-0 leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-2 py-0 h-9 text-sm" onClick={openCallNowModal}><BsFillTelephoneFill className="mr-2" /> Call Now</button>
                    </li>
                    
                    
                    <button className="hidden product-cart lg:flex relative" type="button" onClick={openDrawer}><CartOutlinedIcon className="w-5 h-5 dark:text-zinc-400" />
                    {
                    cartCount > 0 && 
                    <span className="min-w-[20px] h-5 flex items-center justify-center rounded-full bg-accent text-light text-[10px] absolute ltr:-right-1/2 rtl:-left-1/2 -top-1/2">{cartCount}</span>
                    }
                    </button>
                    

                    <div className="flex items-center space-x-4 rtl:space-x-reverse">
                        {/* <a href="#" target="_blank" rel="noreferrer" className="inline-flex h-9 shrink-0 items-center justify-center rounded border border-transparent bg-accent px-3 py-0 text-sm font-semibold leading-none text-light outline-none transition duration-300 ease-in-out hover:bg-accent-hover focus:shadow focus:outline-none focus:ring-1 focus:ring-accent-700">Become a Seller</a> */}
                        
                        <li>
                        {
                        !isUser ?
                            <button data-variant="normal" className="inline-flex items-center justify-center shrink-0 leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-3 py-0 h-9 text-sm" onClick={openLoginNavModal}>Login</button>
                        :
                        <AuthorizedMenu logout={logoutUser}  />
                        }
                        </li>
                    </div>
                </ul>
            </div>
        </header>
        
        {/*hidesearch ?
        (null)
        :(<Search
          variant={'minimal'}
          className=""  />)
        */}

        {/*<div className="w-full grid items-center content-center  ">
            <div className="w-full md:w-3/5 justify-self-center">
                <div class=" px-10 pt-5   ">
                  <div class="relative mb-4 flex w-full flex-wrap items-stretch">
                    <input
                      type="search"
                      class="bg-white relative m-0 -mr-0.5 block w-[1px] min-w-0 flex-auto rounded-l border border-solid border-neutral-300  bg-clip-padding px-3 py-[0.75rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-accent focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:focus:border-accent"
                      placeholder="Search Products"
                      aria-label="Search"
                      aria-describedby="button-addon1" />

                    <button
                      class="relative z-[2] flex items-center rounded-r bg-accent px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg"
                      type="button"
                      id="button-addon1"
                      data-te-ripple-init
                      data-te-ripple-color="light">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        class="h-5 w-5">
                        <path
                          fill-rule="evenodd"
                          d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                          clip-rule="evenodd" />
                      </svg>
                    </button>
                  </div>
               </div>
        </div>
        <ul className="list-none px-10  flex flex-wrap gap-5 items-center justify-self-center mb-3">
          <li>
          <Link
            to="#"
            className="text-sm flex items-center font-semibold   text-heading no-underline transition duration-200 text-accent hover:text-orange-500 focus:text-orange-500 dark:text-zinc-400"
            >
            Ct Scan</Link>
            </li>
          <li>
           <Link
            to="#"
            className=" text-sm  flex items-center font-semibold     text-heading no-underline transition duration-200 text-accent hover:text-orange-500 focus:text-orange-500 dark:text-zinc-400"
            >
          MRI
          </Link></li>
          <li>
           <Link
            to="#"
            className="text-sm flex items-center font-semibold   text-heading no-underline transition duration-200 text-accent hover:text-orange-500 focus:text-orange-500 dark:text-zinc-400"
            >
          X Ray
          </Link></li>
          <li><Link
            to="#"
            className="text-sm flex items-center font-semibold   text-heading no-underline transition duration-200 text-accent hover:text-orange-500 focus:text-orange-500 dark:text-zinc-400"
            >USG</Link></li>
            <li><Link
            to="#"
            className="text-sm flex items-center font-semibold   text-heading no-underline transition duration-200 text-accent hover:text-orange-500 focus:text-orange-500 dark:text-zinc-400"
            >Spare Parts</Link></li>
            <li><Link
            to="#"
            className="text-sm flex items-center font-semibold   text-heading no-underline transition duration-200 text-accent hover:text-orange-500 focus:text-orange-500 dark:text-zinc-400"
            >General Medical Equipment</Link></li>
             <li><Link
            to="#"
            className="text-sm flex items-center font-semibold   text-heading no-underline transition duration-200 text-accent hover:text-orange-500 focus:text-orange-500 dark:text-zinc-400"
            >Siemens</Link></li>
             <li><Link
            to="#"
            className="text-sm flex items-center font-semibold   text-heading no-underline transition duration-200 text-accent hover:text-orange-500 focus:text-orange-500 dark:text-zinc-400"
            >Hitachi</Link></li>
            
        </ul>
        </div>*/}
        {
         mbSearch &&    
        <div className="bg-light py-3">
            <Search variant={'minimal'} className="px-5"  />
        </div>
        }

         {hidebanner ?
         (null):
        (<Banner />)
        }
        <MobileNav openLogin={openLoginNavModal} drawerVisible={openDrawer} />

        {openLogin && 
            <AuthModalView 
                setLogin={setLogin} 
                openLoginModal={openLoginModal}
                authView={authModelView}
            />
        }

        <CartDrawer drawerVisible={drawerVisible} cart_count={cart_count} closeDrawer={closeDrawer} openLogin={openLoginNavModal} />

        <ModalView open={openLoginNav} onClose={closeLoginNav}>
            <div className="flex h-full min-h-screen w-screen flex-col justify-center bg-light py-6 px-5 sm:p-8 md:h-auto md:min-h-0 md:max-w-[500px] md:rounded-xl">
                <div className="flex items-center justify-around flex-wrap">
                    <div className="p-2 lg:p-0">
                        <button data-variant="normal" className="inline-flex items-center justify-center shrink-0 rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-5 text-xl " onClick={() => openLoginModal('LoginView')}>Hospital Login</button>
                    </div>
                    <div className="p-2 lg:p-0">
                        <Link to='/dealer-login' className="inline-flex items-center justify-center shrink-0 rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-5 text-xl ">Dealer Login</Link>
                    </div>
                    <div className="p-2 mt-8 lg:p-0">
                        <button data-variant="normal" className="inline-flex items-center justify-center shrink-0 rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-5 text-xl " onClick={() => openLoginModal('CustomerLogin')}>Customer Login</button>
                    </div>
                </div>
            </div>
        </ModalView>

        <ModalView open={callNowModal} onClose={closeLoginNav}>
            <div className="flex h-full min-h-screen w-screen flex-col justify-center bg-light py-6 px-5 sm:p-8 md:h-auto md:min-h-0 md:max-w-[500px] md:rounded-xl">
                <div className="flex items-center justify-center flex-wrap gap-3">
                    <div className="p-2 lg:p-0">
                        <a href={`tel:${admin_phone}`} data-variant="normal" className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-3 py-0 h-9 text-sm font-semibold"><BsFillTelephoneFill className="mr-2" /> Call Now</a>
                    </div>
                    <div className="p-2 lg:p-0">
                        <a href={`tel:${admin_phone}`} data-variant="normal" className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-3 py-0 h-9 text-sm font-semibold"><BsFillTelephoneFill className="mr-2" /> Call Service</a>
                    </div>
                    <div className="p-2 lg:p-0">
                        <a href={`tel:${admin_phone}`} data-variant="normal" className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-3 py-0 h-9 text-sm font-semibold"><BsFillTelephoneFill className="mr-2" /> Call for installation</a>
                    </div>
                </div>
            </div>
        </ModalView>

        </>
    )
}


export default Header;