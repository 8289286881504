import React, {useState, useEffect} from "react";

import {APP_URL, APP_NAME, API_ENDPOINT, addDays} from '../config/Common'
import Input from '../../ui/input.jsx'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import Button from '../../ui/Button.tsx'
import {useAuth} from '../../provider/AuthProvider'
import SelectInput from '../../ui/select-input.jsx'
import Label from '../../ui/label.tsx'
import Radio from '../../ui/radio/radio.tsx';
import Checkbox from '../../ui/checkbox/checkbox.tsx'
import TextArea from '../../ui/text-area.tsx'
import DatepickerInput from '../../ui/datepicker-input.jsx'

import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from 'yup';
import {
   Control,
   FieldErrors,
   useForm,
   useFormState,
   useWatch,
   Controller
 } from 'react-hook-form';
 
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import {PlainIcon} from '../../custom-icons'
import axios from 'axios';  

const FormSchema = Yup.object().shape({
   name: Yup.string()
      .required('You must need to provide your full name.')
      .min(2, 'Name is too short!')
      .max(50, 'Name is too long!'),
   phone_no: Yup.string()
      .required('You must need to provide your phone number.')
      .min(12, 'Invalid phone number.'),
   email: Yup.string()
      .required('You must need to provide your email address.')
      .email('Invalid email.'),
   enquiry_text: Yup.string()
      .required('Tell us about Issues'),
   cat_id:Yup.object()
      .nullable()
      .required('Category is required.'),
   sub_cat:Yup.object()
      .nullable()
      .required('Sub category is required.'),
   model_id:Yup.object()
      .nullable()
      .required('Model is required.'),
   brand_id:Yup.object()
      .nullable()
      .required('Brand is required.'),
   part_type: Yup.string()
      .required('Part Type is required.'),
   agree:Yup.bool()
      .oneOf([true], 'Field must be checked.'),
   
});

let defaultValues = {  
   name: '',
   email:'',
   phone_no:'',
   agree:true
};

const PartsRepairForm = () => {

   const { isUser, myInfo } = useAuth();
   const [states, setStates] = useState(null);
   const [cities, setCities] = useState(false);
   const [category, setCategory] = useState(null)
   const [subCategory, setSubCategory] = useState(null)
   const [brands, setBrands] = useState(null)
   const [models, setModels] = useState(null)
   const [startDate, setStartDate] = useState(null);
   const [loading, setLoading] = useState(false)
   const [success, setSuccess] = useState(false)

   const { register, handleSubmit , control, reset, setValue, setError, formState: { errors },} = useForm({
      defaultValues: defaultValues,
      resolver: yupResolver(FormSchema),
   });

   useEffect(() => {
      getParentCategory()
      getBrandsForSelect()
     
      defaultValues = {  
         name: myInfo ? myInfo.name : '',
         email:myInfo ? myInfo.email :'',
         phone_no:myInfo ? myInfo.phone_no.length < 12 ? `91${myInfo.phone_no}` : myInfo.phone_no : '',
         agree:true
      };

     
      reset(defaultValues)
   },[])

   useEffect(() => {
      console.log(myInfo)
      let date = addDays(new Date(), 1);
      setStartDate(date)
      setValue('appointment_date', moment(date).format(), true);

   },[])

   const getParentCategory = () => {
      fetch(`${API_ENDPOINT}/get_parent_category`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			//console.log('categories======',result)
			setCategory(result.data)			
		})
   }

   const getBrandsForSelect = () => {
      fetch(`${API_ENDPOINT}/get_brands_for_select`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			//console.log('brands======',result)
			setBrands(result.data)			
		})
   }

   
   const getModelsByBrands = (brand_id) => {
      fetch(`${API_ENDPOINT}/get_models_by_brands/${brand_id}`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			//console.log('models======',result)
			setModels(result.data)			
		})
   }

   const getSubCategoryByCat = (cat_id) => {
      fetch(`${API_ENDPOINT}/get_sub_category/${cat_id}`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			//console.log('sub cat======',result)
			setSubCategory(result.data)			
		})
   }

   const onSubmit = (values) => {
      //console.log('Enquiry form======',values)
      setLoading(true)
      let input = values
      let app_date = values.appointment_date;
      //'appointment_date', moment(date).format()
      input = {...input, 
         /* cat_id:values?.cat_id?.cat_id,
         sub_cat:values?.sub_cat?.cat_id,
         brand_id:values?.brand_id?.brand_id,
         model_id:values?.model_id?.model_id,
         state_id:values?.state_id?.id,
         city_id:values?.city_id?.id, */
         appointment_date:moment(app_date).format()
      }
      console.log('Enquiry form======',input)

      axios.post(`${API_ENDPOINT}/store_part_repiar_form`,input,{
         headers: {
            'Content-Type': 'application/json',
         }
      }).then( (result)=> {
         console.log('data=====',result.data)
         setLoading(false)
         if(result.data.status === 1){
            setSuccess(true)
            window.scrollTo({
               top: 0,
               left: 0,
               behavior: "smooth"
             });
         }
         
      })
   }

   const handleSelect = (val,name) => {  
      console.log('dropdown val======',val,name.name)
      setError(name.name, null)
      setValue(name.name, val, true);

      if(name.name === 'cat_id'){
         getSubCategoryByCat(val.cat_id)
         setValue('sub_cat', null, true);
      }

      if(name.name === 'brand_id'){         
         getModelsByBrands(val.brand_id)
         setValue('model_id', null, true);
      }

    
   }

   
   

   return(
      <div>
         <h1 className="mb-7 font-body text-xl font-bold text-heading md:text-2xl">
         Enquiry for Parts Repair
         </h1>
         {
         success ?
         <p className="my-2 text-xl text-center text-green-500">
            Your enquiry has been submitted successfully.<br/>We will be get back to you soon.
         </p>
         :
         <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 gap-2 sm:gap-6 sm:grid-cols-2">
               <Input                
                  label={`Name`}
                  {...register('name')}
                  variant="outline"
                  className="mb-5"
                  error={errors.name?.message}
                  required
               />
               <Input                
                  label={`Email`}
                  {...register('email')}
                  variant="outline"
                  className="mb-5"
                  type="email"
                  error={errors.email?.message}
                  required
               />
            </div>
            <div className="grid grid-cols-1 gap-2 sm:gap-6 sm:grid-cols-2">
               <div className="mb-5">
                  <label htmlFor="phone_no" className="block mb-3 text-sm font-semibold leading-none text-body-dark">Phone number <span className="text-red-500">*</span></label>
                  <Controller
                     name="phone_no"
                     control={control}
                     render={({ field }) => (
                        <PhoneInput
                           country="in"
                           disableDropdown
                           countryCodeEditable={false}
                           inputClass="!p-0 ltr:!pr-4 rtl:!pl-4 ltr:!pl-14 rtl:!pr-14 !flex !items-center !w-full !appearance-none !transition !duration-300 !ease-in-out !text-heading !text-sm focus:!outline-none focus:!ring-0 !border !border-border-base !rounded focus:!border-accent !h-12"
                           dropdownClass="focus:!ring-0 !border !border-border-base !shadow-350"
                           {...field}
                        />
                     )}
                  />
                  <p className="my-2 text-xs text-start text-red-500">{errors.phone_no?.message}</p>
               </div>
               <Input                
                  label={`Address`}
                  {...register('address')}
                  variant="outline"
                  className="mb-5"
                  error={errors.address?.message}
               />
               
            </div>   
            
            <h2 className="mb-7 font-body text-xl font-bold text-heading md:text-xl">
            About your machine parts
            </h2>
            <div className="grid grid-cols-1 gap-2 sm:gap-6 sm:grid-cols-2">
               {
               category &&
               <div className="mb-5">
                  <Label>Machine <span className="text-red-500"> *</span></Label>
                  <SelectInput
                     name="cat_id"                            
                     control={control}
                     getOptionLabel={(option: any) => option.cat_name}
                     getOptionValue={(option: any) => option.cat_id}                        
                     options={category}
                     onChange={handleSelect}                           
                  />
                  {errors.cat_id?.message && <p className="my-2 text-xs text-start text-red-500">{errors.cat_id?.message}</p>}
               </div>
               }
               {
               <div className="mb-5">
                  <Label>Machine type <span className="text-red-500"> *</span></Label>
                  <SelectInput
                     name="sub_cat" 
                     {...register('sub_cat')}
                     control={control}
                     getOptionLabel={(option: any) => option.cat_name}
                     getOptionValue={(option: any) => option.cat_id}                        
                     options={subCategory}
                     onChange={handleSelect}
                  />
                  {errors.sub_cat?.message && <p className="my-2 text-xs text-start text-red-500">{errors.sub_cat?.message}</p>}
               </div>                     
               }
            </div>
            <div className="grid grid-cols-1 gap-2 sm:gap-6 sm:grid-cols-2">
               <div className="mb-5">
                  <Label>Brand <span className="text-red-500"> *</span></Label>
                  <SelectInput
                     name="brand_id"                            
                     control={control}
                     getOptionLabel={(option: any) => option.name}
                     getOptionValue={(option: any) => option.brand_id}
                     options={brands}
                     onChange={handleSelect}
                  />
                  
                  {errors.brand_id?.message && <p className="my-2 text-xs text-start text-red-500">{errors.brand_id?.message}</p>}
               </div>
               <div className="mb-5">
                  <Label>Mode <span className="text-red-500"> *</span></Label>
                  <SelectInput
                     name="model_id"                            
                     control={control}
                     getOptionLabel={(option: any) => option.name}
                     getOptionValue={(option: any) => option.model_id}                        
                     options={models}
                     onChange={handleSelect}
                  />                        
                  {errors.model_id?.message && <p className="my-2 text-xs text-start text-red-500">{errors.model_id?.message}</p>}
               </div>
            </div>
            <div className="grid grid-cols-1 gap-2 sm:gap-6 sm:grid-cols-2">
               <Input
                  label='Part Type'
                  type="text"
                  {...register('part_type')}                        
                  variant="outline"
                  className="mb-5"
                  placeholder="" 
                  error={errors.part_type?.message}
                  required
               />
               <div className="mb-5">
                  <Input
                  label='Customer /Dealer'
                  type="text"
                  {...register('customer_dealer')}                        
                  variant="outline"
                  className="mb-5"
                  placeholder="" 
               />
               </div>
            </div>   
            <TextArea
               label='Issues'
               {...register('enquiry_text')}                        
               variant="outline"
               className="mb-5"
               error={errors.enquiry_text?.message}
               required
            />
            <div className="mb-5">
               <Checkbox 
                  name='agree'
                  {...register('agree')}
                  label={`Please agree, Charges will be vary according to your location.`}
                  theme='primary'
                  error={errors.agree?.message}
               />
            </div>

            <Button loading={loading}>
               Send <PlainIcon className="ml-2" />
            </Button>
         </form>
         }
      </div>
   )

}

export default PartsRepairForm