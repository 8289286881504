import React, { createContext, useContext, useState, useEffect } from "react";
import {API_ENDPOINT} from '../components/config/Common'

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [isUser, setIsUser] = useState(false)
    const [loginToken, setLoginToken] = useState(null)
    const [accessToken, setAccessToken] = useState(localStorage.getItem('access_token'))
    const [myInfo, setMyInfo] = useState(null)

    useEffect(() => {
        me(localStorage.getItem('access_token'))
        setLoginToken(localStorage.getItem('access_token'))
        //console.log(localStorage.getItem('access_token'))
     },[])

    const login = (res) => {
        setIsUser(true)
        setLoginToken(res.access_token)
        me(res.access_token)
    }

    const logout = () => {
        setIsUser(false)
        setLoginToken(null)
        setMyInfo(null)
    }

    const me = (loginToken) => {
        if(!loginToken){
            return false
        }

        fetch(`${API_ENDPOINT}/user_me`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${loginToken}`, 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			//console.log('my info========',result)
            setMyInfo(result.data)
            setIsUser(true)
		})
    }
    
    return <AuthContext.Provider value={{ isUser, login, logout, loginToken, me, myInfo }}>{children}</AuthContext.Provider>;
};
export const useAuth = () => {
    return useContext(AuthContext);
};