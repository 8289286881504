import React, {useState, useEffect} from "react";
import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';
import Radio from '../../ui/radio/radio.tsx';
import {
   API_ENDPOINT, 
   toast_anim_error,
   toast_anim, 
   RAZOR_KEY, 
   APP_NAME, 
   APP_URL,
   APP_LOGO, 
   loadScript, 
   RAZOR_JS,
   THEME,
   gateway_amount,
   amount_format,
   random_str,
   date_format,
   capitalize,
   useStateCallback
} from '../config/Common'
import { Link } from "react-router-dom";
import ScrollPageTop from '../../routes/ScrollPageTop'
import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import classNames from 'classnames';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
import {Loading } from "../../custom-icons";

import {useAuth} from '../../provider/AuthProvider'

import {Links as lk} from '../../routes/PathLink'
import AuthModalView from "../auth/AuthModalView"

import moment from 'moment';

const ValidationSchema = Yup.object().shape({
   category: Yup.string()
		.required('Machine is required.'),
});

const CreateAmcTicket = (props) => {
   const [loading, setLoading] = useState(false)
   const [cat, setCat] = useState(null)
   const [parentCat, setParentCat] = useState(null)
   const [accessToken, setAccessToken] = useState(localStorage.getItem('access_token'))
   const { myInfo, isUser, loginToken } = useAuth();
   const [subCat, setSubCat] = useState(null)
   const [subSubCat, setSubSubCat] = useState(null)
   const [success, setSuccess] = useState(false)
   const [ticketDetail, setTicketDetail] = useState(null)
   const [packageData, setPackageData] = useState(null)
   const [brands, setBrands] = useState(null)
   const [models, setModels] = useState(null)
   const [conditions, setConditions] = useState(null)

   const [subCatId, setSubCatId] = useState(null)
   const [subSubCatId, setSubSubCatId] = useState(null)
   const [brandId, setBrandId] = useState(null)
   const [modelId, setModelId] = useState(null)
   const [selCondition, setSelCondition] = useStateCallback(null)
   const [yom, setYom] = useState(null)

   const [openLogin, setOpenLogin] = useState(false)
   const [isLogin, setIsLogin] = useState(false)
   const [isPackage, setIsPackage] = useState(true)
   const [showPackageBtn, setShowPackageBtn] = useState(false)

   const [showbooking, setBooking] = useState(false);
   const [bookAmount, setBookAmount] = useState(0)

   const [metaData, setMetaData] = useState({
      url: `${APP_URL}${lk.CREATE_AMS_TICKET}`,
      title:  `Buy AMC Package - ${APP_NAME}`,
      description:'Buy AMC Package'
   })

   useEffect(() => {
      setAccessToken(localStorage.getItem('access_token'))
   },[accessToken])

   useEffect(() => {
      getCategories()
      getBrandBySubCat()
      //console.log('isUser',isUser)
   },[])

   const getCategories = () => {
      fetch(`${API_ENDPOINT}/get_service_cat`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json',            
         }
      }).then(function (response) {
         return response.json();
      }).then( (result)=> {
         console.log('data=====',result)
         setCat(result.data)
      })
   }

   const buyTicket = () => {
      payAndCreateTicket()
   }

    const handleBookMethod = (bookVal) => {
      //let bookVal = e.target.value;
      console.log('value ',bookVal);
      if(bookVal == 'proceed'){
         setBookAmount(100000)
      }
      if(bookVal == 'hold'){
         setBookAmount(50000)
      }
      
      
   }


   const payAndCreateTicket = async () => {
      //console.log(val)
      
      if(!packageData){
         toast_anim_error('Package not available')
         return false;
      }
      
      let ticket = `amc_${random_str(10)}`;

      const res = await loadScript(RAZOR_JS);

      if (!res) {         
         toast_anim_error('Razorpay failed to load. Are you online?')
         return false;
      } 
      
      /* if(!myInfo){
         await me(localStorage.getItem('access_token'))
      } */

      const requestOptions = {
         method: 'POST',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+loginToken, 
         },
         body: JSON.stringify({
            amount:gateway_amount(packageData.total_payamount),
            invoice:ticket,
            showbooking:showbooking,
            bookingamount:bookAmount*100,
         })
      };
      const response = await fetch(`${API_ENDPOINT}/razor_payment_order`, requestOptions);
      const result = await response.json();
      
      if(result.status === 1){
         var options = {
            "key": RAZOR_KEY,
            "amount": result.data.amount,
            "currency": "INR",
            "name": APP_NAME,
            "description": "Create AMC Token",
            "image": APP_LOGO,
            "order_id": result.data.order_id,
            "handler": function (response){
      
               /* const data = {
                  razorpay_payment_id: response.razorpay_payment_id,
                  razorpay_order_id: response.razorpay_order_id,
                  razorpay_signature: response.razorpay_signature,
               }; */
               //console.log('razorpay data', data)
               
               let pack = {
                  amount: packageData ? packageData.total_payamount : 0,
                  gstAmt: packageData ? packageData?.gstAmt :0,
                  sub_total:packageData ? packageData?.p_price :0,
                  package_id:packageData ? packageData.package_id : 0,
                  ticket:ticket,
                  payment_id:response.razorpay_payment_id,
                  brandId:brandId,
                  modelId:modelId,
                  conditions:selCondition,
                  yom:yom,
                  bookingamount:bookAmount,
               }
               
               
         
               generateAmcTicket(pack)
            },
            "prefill": {
               "name": myInfo ? myInfo.name : '',
               "email": myInfo ? myInfo.email : '',
               "contact": myInfo ? myInfo.phone_no : ''
            },         
            "theme": {
               "color": THEME
            }
         };

         const rzp1 = new window.Razorpay(options);
         rzp1.open();
      }else{
         toast_anim_error('Payment failed. Please try again?')
         return false;      
      }   

   }

   const generateAmcTicket = async (pack) => {
      //console.log('package data=====',localStorage.getItem('access_token'))
      let token = localStorage.getItem('access_token');
      setLoading(true)
      fetch(`${API_ENDPOINT}/create_amc_ticket`,{
         method: 'POST',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+token, 
         },
         body: JSON.stringify(pack)
      }).then(function (response) {
         return response.json();
      }).then( (result)=> {
         console.log('package data=====',result)
         setLoading(false)
         toast_anim(result.message)
         setSuccess(true)
         setTicketDetail(result.data)         
      })
   }

   const createNewTicket = () => {
      setSuccess(false)
      setPackageData(null)
   }

   const handleOnChange = (event: FormEvent) => {
      console.log("Form::onChange", event.target.name,event.target.value);

      let field = event.target.name

      if(field !== 'yom' ){
         if(field == 'booking_type'){

         }else{
             setIsPackage(true)
            setPackageData(null)
            setShowPackageBtn(false)
         }
        
      }
      
      if(field === 'category'){
         getSubCatByCat(event.target.value)
         if(event.target.value){
            setParentCat(event.target.value)
         }else{
            setParentCat(null)
         }
         setSubCat(null)
         setSubSubCat(null)
         //setBrands(null)
         //setModels(null)
         setConditions(null)

         setSelCondition(null)
         //setModelId(null)
         //setBrandId(null)
         setSubCatId(null)
         setSubSubCatId(null)
         //setShowPackageBtn(true)
      }
      //setSubSubCat
      if(field === 'subCategory'){
         getSecondLevelCatBySubCat(event.target.value)
         setSubCatId(event.target.value)
         
         setSubSubCat(null)
         setBrands(null)
         setModels(null)         
         setConditions(null)

         setSelCondition(null)
         setModelId(null)
         setBrandId('')
         setSubSubCatId(null)
         getBrandBySubCat()
      }         
      
      if(field === 'subSubCategory'){         
         //getBrandBySubCat(event.target.value)
         setSubSubCatId(event.target.value)
         setModels(null)
         setConditions(null)
         setSelCondition(null)
         setModelId(null)
         setBrandId(null)
      }

      if(field === 'brand'){         
         getModelByBrand(subCatId, event.target.value)
         setBrandId(event.target.value)
         setConditions(null)
         setSelCondition(null)
         setModelId(null)
      }
      if(field === 'model'){         
         setConditions(null)
         setModelId(event.target.value)
         getConditionByModel(subCatId, brandId, event.target.value)
         
         
         setSelCondition(null)
      }

      if(field === 'condition'){         
         setSelCondition(event.target.value)         
         setShowPackageBtn(true)
      }
      if(field === 'yom'){         
         setYom(event.target.value)
      }
      if(field === 'booking_type'){
         handleBookMethod(event.target.value);
      }
      


      /* if(field === 'category' || field === 'subCategory'){
         if(event.target.value){
            getPackageData(event.target.value)
         }else if(parentCat){
            //console.log('parentCat=====',parentCat)
            if(field === 'category' && event.target.value){
               getPackageData(parentCat)
            }else if(field === 'subCategory'){
               //console.log('subCategory=====',parentCat)
               getPackageData(parentCat)
            }else{
               setPackageData(null)
            }
         }
      } */
   };

   const getConditionByModel = (sub_cat, brand, model) => {

      let cond = [{
             'id':'1',
             'label':'New',
           },
           {
             'id':'2',
             'label':'Refurbished',
           },
           {
             'id':'3',
             'label':'Used',
           },
           {
             'id':'4',
             'label':'Imported Refurbished',
           }];

      setConditions(cond)
      setShowPackageBtn(true)

      /* fetch(`${API_ENDPOINT}/get_condition_by_model/${sub_cat}/${brand}/${model}`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json',            
         }
      }).then(function (response) {
         return response.json();
      }).then( (result)=> {
         console.log('condition=====',result)
         if(result.status === 1){
            setConditions(result.data)
            setShowPackageBtn(false)
         }else{
            setConditions(null)
            setShowPackageBtn(true)
            //getPackageData(parentCat)
         }
      }) */
   }

   const getModelByBrand = (sub_cat, brand) => {
      fetch(`${API_ENDPOINT}/get_models_by_brand/${sub_cat}/${brand}`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json',            
         }
      }).then(function (response) {
         return response.json();
      }).then( (result)=> {
         console.log('model=====',result)
         if(result.status === 1){
            setModels(result.data)
            //setShowPackageBtn(false)
         }else{
            //getPackageData(parentCat)
            //setShowPackageBtn(true)
            setModels(null)
         }
      })
   }

   const getBrandBySubCat = () => {
      fetch(`${API_ENDPOINT}/get_sub_cat_brands`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json',            
         }
      }).then(function (response) {
         return response.json();
      }).then( (result)=> {
         console.log('brand=====',result)
         if(result.status === 1){
            setBrands(result.data)
            //setShowPackageBtn(false)
         }else{
            //getPackageData(parentCat)
            //setShowPackageBtn(true)
            setBrands(null)
         }
      })
   }

   const getPackageData = (catId) => {
      //console.log('catId=====',catId)
      let query = '';
      
      if(subCatId){
         query = `?subCatId=${subCatId}`
      }
      if(brandId){
         query += `&brandId=${brandId}`
      }
      if(modelId){
         query += `&modelId=${modelId}`
      }
      if(selCondition){
         query += `&condition=${selCondition}`
      }
      
      console.log('query=====',query)

      fetch(`${API_ENDPOINT}/get_package_data/${catId}${query}`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json',            
         }
      }).then(function (response) {
         return response.json();
      }).then( (result)=> {
         console.log('package=====',result)
         setShowPackageBtn(false)
         if(result.status === 1){
            setPackageData(result.data);
            if(parseFloat(result?.data?.total_payamount) > 500000){
               setBooking(true);
            }
         }else{
            setIsPackage(false)
         }
      })
   }

   const getSubCatByCat = (catId) => {
      //console.log('cat id',catId)
      setSubCat(null)
      if(!catId){         
         return false
      }      

      fetch(`${API_ENDPOINT}/get_sub_cat/${catId}`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json',            
         }
      }).then(function (response) {
         return response.json();
      }).then( (result)=> {
         console.log('sub cat=====',result)
         if(result.status === 1){
            setSubCat(result.data)
            //setShowPackageBtn(false)
         }else{
            //getPackageData(parentCat)
            setSubCat(null)
            //setShowPackageBtn(true)
         }
      })
   }

   const getSecondLevelCatBySubCat = (subCat) => {
      //console.log('cat id',catId, subCat)
      setSubSubCat(null)
      if(!subCat){         
         return false
      }      

      fetch(`${API_ENDPOINT}/get_sub_cat/${subCat}`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json',            
         }
      }).then(function (response) {
         return response.json();
      }).then( (result)=> {
         console.log('sub sub cat=====',result)
         if(result.status === 1){
            setSubSubCat(result.data)
            //setShowPackageBtn(false)
         }else{
            //getPackageData(parentCat)
            setSubSubCat(null)
            //setShowPackageBtn(true)
         }
      })
   }

   

   const openLoginModal = () => {        
      setOpenLogin(!openLogin)
  }

   const setLogin = () => {
      setIsLogin(true)
      openLoginModal()
      //console.log('paren===========')
   }

   const showPackage = () => {
      getPackageData(parentCat)
   }
   

   return(
      <ScrollPageTop>
         <ReactSEOMetaTags
				render={(el: React.ReactNode) => <Helmet>{el}</Helmet>}
				website={{ ...metaData }}
			/>         
         <Header hidebanner={true} />
         <div className="px-4 py-8 bg-gray-100 dark:bg-dark lg:px-8 xl:px-16 2xl:px-20">
            
            <div className="flex flex-col items-center w-full max-w-5xl m-auto rtl:space-x-reverse lg:flex-row lg:items-start lg:space-x-8 justify-center">
               <div className="w-full space-y-6 lg:max-w-2xl">
                  <div className={classNames('w-full p-5 md:p-8 bg-light shadow rounded mb-8 mt-8')}>
                     <p className="text-lg lg:text-xl text-heading capitalize mb-5">AMC Package (Annual Maintenance Contract)</p>
                     {
                     success ?
                     <div className="my-5">
                        
                        <div className="flex items-center">
                           <div className="w-[100px] py-2">Package id</div>
                           <div className="px-2">:</div>
                           <div>{ticketDetail.ticket_no}</div>
                        </div>                        
                        <div className="flex items-center">
                           <div className="w-[100px] py-2">Amount</div>
                           <div className="px-2">:</div>
                           <div>{amount_format(ticketDetail.amount)}</div>
                        </div>
                        <div className="flex items-center">
                           <div className="w-[100px] py-2">GST </div>
                           <div className="px-2">:</div>
                           <div>{amount_format(ticketDetail.gst_tax)}</div>
                        </div>
                        <div className="flex items-center">
                           <div className="w-[100px] py-2">Machine</div>
                           <div className="px-2">:</div>                           
                           {
                           ticketDetail.package &&
                           <div>
                              {
                              ticketDetail.package.machine.parent &&
                              <span>{ticketDetail.package.machine.parent.cat_name}, </span>
                              }
                              {ticketDetail.package.machine.cat_name}
                           </div>
                           }
                        </div>
         
                        <div className="flex items-center">
                           <div className="w-[100px] py-2">Status</div>
                           <div className="px-2">:</div>
                           <div>Open</div>
                        </div>

                        <div className="flex items-center">
                           <div className="w-[100px] py-2">Start Date</div>
                           <div className="px-2">:</div>
                           <div>{date_format(ticketDetail.created_at)}</div>
                        </div>
                        <div className="flex items-center">
                           <div className="w-[100px] py-2">End Date</div>
                           <div className="px-2">:</div>
                           <div>{date_format(ticketDetail.expireOn)}</div>
                        </div>
                        <div className="my-5"><Link to={lk.AMS_TICKETS} className="inline-flex items-center gap-2 text-sm font-semibold text-accent hover:text-accent-hover" onClick={createNewTicket}>View Package</Link></div>
                     </div>
                     :
                     <Formik
                        initialValues={{                     
                           category:'',
                           subCategory:'',
                        }}
                        validationSchema={ValidationSchema}
                        onSubmit={(values, { setErrors, setSubmitting, resetForm }) => {
                           //setLoading(true)
                           //resetForm({values:''})
                           
                           //openLoginModal()
                           if(!isUser){
                              openLoginModal()                              
                           }else{
                              payAndCreateTicket()
                           }
                        }}
                        handleChange={(field) => {
                           console.log('on change', field)
                        }}
                        
                     >
                     {({ values, errors, touched, handleSubmit, handleChange, isSubmitting}) => (
                        <Form onChange={handleOnChange}>
                           <div className="mb-6">
                              <label htmlFor="category" className="block text-body-dark font-semibold text-sm leading-none mb-3">Machine</label>
                              <Field as="select" id="category" name="category" className="px-4 py-3 flex items-center w-full rounded appearance-none transition duration-300 ease-in-out text-heading text-sm focus:outline-none focus:ring-0 border border-border-base focus:border-accent">
                                 <option value="">Select machine</option>
                                 {
                                    cat && cat.map((val, key) => (
                                    <option value={val.cat_id}>{val.cat_name}</option>
                                    ))
                                 }
                              </Field>
                              {errors.category && touched.category &&
                              <p className="mt-2 text-xs text-red-500">{errors.category}</p>
                              }
                           </div>
                           {
                           subCat &&                           
                           <div className="mb-6">
                              <label htmlFor="subCategory" className="block text-body-dark font-semibold text-sm leading-none mb-3">Machine type</label>
                              <Field as="select" id="subCategory" name="subCategory" className="px-4 py-3 flex items-center w-full rounded appearance-none transition duration-300 ease-in-out text-heading text-sm focus:outline-none focus:ring-0 border border-border-base focus:border-accent">
                                 <option value="">Select machine type</option>
                                 {
                                    subCat && subCat.map((val, key) => (
                                    <option key={`${key}`} value={val.cat_id}>{val.cat_name} </option>
                                    ))
                                 }
                                 
                              </Field>
                              {errors.subCategory && touched.subCategory &&
                              <p className="mt-2 text-xs text-red-500">{errors.subCategory}</p>
                              }
                           </div>
                           }
                           {
                              subSubCat &&
                           <div className="mb-6">
                              <label htmlFor="subSubCategory" className="block text-body-dark font-semibold text-sm leading-none mb-3">Machine sub type</label>
                              <Field as="select" id="subSubCategory" name="subSubCategory" className="px-4 py-3 flex items-center w-full rounded appearance-none transition duration-300 ease-in-out text-heading text-sm focus:outline-none focus:ring-0 border border-border-base focus:border-accent">
                                 <option value="">Select machine sub type</option>
                                 {
                                    subSubCat && subSubCat.map((val, key) => (
                                    <option key={`${key}`} value={val.cat_id}>{val.cat_name} </option>
                                    ))
                                 }
                                 
                              </Field>
                              {errors.subSubCategory && touched.subSubCategory &&
                              <p className="mt-2 text-xs text-red-500">{errors.subSubCategory}</p>
                              }
                           </div>
                           }
                           {
                           subCat && 
                           <div className="mb-6">
                              <label htmlFor="brand" className="block text-body-dark font-semibold text-sm leading-none mb-3">Brand</label>
                              <Field as="select" id="brand" name="brand" className="px-4 py-3 flex items-center w-full rounded appearance-none transition duration-300 ease-in-out text-heading text-sm focus:outline-none focus:ring-0 border border-border-base focus:border-accent">
                                 <option value="">Select brand</option>
                                 {
                                    brands && brands.map((val, key) => (
                                    <option key={`${key}`} value={val.brand_id}>{val.name} </option>
                                    ))
                                 }
                                 
                              </Field>
                              {errors.brand && touched.brand &&
                              <p className="mt-2 text-xs text-red-500">{errors.brand}</p>
                              }
                           </div>
                           }
                           {
                              models &&
                           <div className="mb-6">
                              <label htmlFor="model" className="block text-body-dark font-semibold text-sm leading-none mb-3">Model</label>
                              <Field as="select" id="model" name="model" className="px-4 py-3 flex items-center w-full rounded appearance-none transition duration-300 ease-in-out text-heading text-sm focus:outline-none focus:ring-0 border border-border-base focus:border-accent">
                                 <option value="">Select model</option>
                                 {
                                    models && models.map((val, key) => (
                                       
                                    <option key={`${key}`} value={val.model_id}>{val.name} </option>
                                    ))
                                 }
                                 
                              </Field>
                              {errors.model && touched.model &&
                              <p className="mt-2 text-xs text-red-500">{errors.model}</p>
                              }
                           </div>
                           }
                           {
                              conditions && 
                           <div className="mb-6">
                              <label htmlFor="condition" className="block text-body-dark font-semibold text-sm leading-none mb-3">Condition</label>
                              <Field as="select" id="condition" name="condition" className="px-4 py-3 flex items-center w-full rounded appearance-none transition duration-300 ease-in-out text-heading text-sm focus:outline-none focus:ring-0 border border-border-base focus:border-accent capitalize">
                                 <option value="">Select condition</option>
                                 {
                                    conditions && conditions.map((val, key) => (
                                    <option key={`${val.id}`} value={val.id}>{capitalize(val.label)} </option>
                                    ))
                                 }
                                 
                              </Field>
                              {errors.condition && touched.condition &&
                              <p className="mt-2 text-xs text-red-500">{errors.condition}</p>
                              }
                           </div>
                           }
                           

                           {
                              models &&
                              <div className="mb-6">
                              <label htmlFor="yom" className="block text-body-dark font-semibold text-sm leading-none mb-3">YOM (Year of Manufacture) (CT-Scan/MRI/X-Ray/Cath Lab)</label>
                              <Field id="yom" name="yom" type="number" placeholder="YYYY (4-Digit year, Like 1985)" min="1950" max={moment().year()} className="flex w-full appearance-none items-center px-4 text-sm text-heading transition duration-300 ease-in-out focus:outline-none focus:ring-0 border border-border-base rounded focus:border-accent h-12" />
                              {errors.yom && touched.yom &&
                              <p className="mt-2 text-xs text-red-500">{errors.yom}</p>
                              }
                              </div>
                           }   
                           
                           {
                              
                           packageData ? 
                           <>
                              <div className="mb-2 flex items-center justify-start text-accent text-xl">
                                 <div>AMC package amount per year</div>
                                 <div className="px-2">:</div>
                                 <div>{amount_format(packageData.p_price)}</div>

                              </div>
                              <div className="mb-2 flex items-center justify-start text-accent text-xl">
                                 <div>GST ({packageData.gst}%)</div>
                                 <div className="px-2">:</div>
                                 <div>{amount_format(packageData.gstAmt)}</div>
                              </div>
                               <div className="border-t-4 border-double border-border-200 mb-6 flex items-center justify-start text-accent text-xl">
                                 <div>Total AMC package amount per year to pay </div>
                                 <div className="px-2">:</div>
                                 <div>{amount_format(packageData.total_payamount)}</div>
                              </div>

                              { showbooking ?
                                 <div className="p-5 mt-2 border border-gray-200 bg-light">
                                    <div id="headlessui-radiogroup-16" role="radiogroup" aria-labelledby="headlessui-label-17">
                                       <label className="mb-5 block text-base font-semibold text-heading" id="headlessui-label-17" role="none">Booking Amount</label>
                                       <div>
                                          <Radio
                                             name='booking_type'
                                             label={'Proceed with advance 1 lakh'}
                                             id={'proceed_with_advance'}
                                             className="mr-2 mb-2"
                                             value={'proceed'}
                                             
                                          />
                                          <Radio
                                             name='booking_type'
                                             label={`Hold with advance Rs.50,000.00`}
                                             id={'hold_with_advance'}
                                             className="mr-2 mb-2"
                                             value={'hold'}
                                            
                                          />
                                       </div>
                                       <p class="text-base font-light text-heading mt-2 text-red-600">Package amount is more than 500,000.00. You can book by paying the booking amount. The remaining amount can be paid by cheque.</p>
                                    </div>
                                 </div>
                                 :null
                                 }
                              
                              <div className="mb-6">
                              {
                                 !loading
                                 ?
                                 <button data-variant="normal" className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-0 h-12 col-span-2 w-full" type="submit">Buy AMC Package</button>
                                 :
                                 <Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 ltr:ml-auto" />
                              }
                              </div>
                           </>
                           :
                           null
                           
                           }
                           {
                              !isPackage &&
                              <div className="mb-6 flex items-center justify-start text-accent text-xl">
                                 <div>Package not available for this selection.</div>
                              </div>
                           }
                           {
                              showPackageBtn &&
                              <button data-variant="normal" className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-0 h-12 col-span-2 " type="button" onClick={showPackage}>Show Package</button>
                           }
                        </Form>
                     )}
                     </Formik>
                     }
                  </div>
               </div>
               {/* <div className="w-full mt-10 mb-10 sm:mb-12 lg:mb-0 lg:w-96">                  
                  <div className="w-full">
                     <div className="mb-4 flex flex-col items-center space-x-4 rtl:space-x-reverse">
                        
                     </div>
                  </div>
               </div> */}
            </div>
         </div>
         <Footer />
         {
            openLogin && 
            <AuthModalView 
               setLogin={setLogin} 
               openLoginModal={openLoginModal}
               buyTicket={buyTicket}
            />
         }
      </ScrollPageTop>
   )
}

export default CreateAmcTicket;