
import React, {useState, useEffect} from "react";
import {APP_URL,  API_ENDPOINT, LIMIT , showRating , date_format, IMG_URL} from './config/Common'
import InfiniteScroll from "react-infinite-scroll-component";
import {Loading} from "../custom-icons";
import { MdOutlineStarPurple500 } from "react-icons/md";


const ProductRatings = ({product, product_id, productRating, ref}) => {
    const [accessToken] = useState(localStorage.getItem('access_token'))
    const [productratings, setProductratings] = useState(null);
    const [productratingssummary, setProductratingssummary] = useState(null)

    const [totalRec, setTotalRec] = useState(0)
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const [ratingData, setRatingData] = useState(null)

     useEffect(() => {
        setPage(1)
          getProductRatings()
          getProductRatingsSummary()
          getProductJson()
       },[product]);

    
     
     const getProductRatings = () => {
        let paginate = `page=${1}&limit=${LIMIT}`;
        
          fetch(`${API_ENDPOINT}/get_product_ratings/${product_id}?${paginate}`,{
             method: 'GET',
             headers : {        
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer '+accessToken, 
             }
          }).then(function (response) {
             return response.json();
          }).then( (result)=> {
             console.log('paginate====', result)
             if(result.status === 1){
                setProductratings(result.data);
                let nextPage = page + 1
                setPage(nextPage)

                if(result.data.length < LIMIT){
                   setHasMore(false)
                }else{
                   setHasMore(true)
                }
                setTotalRec(result.total)
             }
          })
       }

    const fetchData = () => {
     
        //this.getProducts(this.state.page)
      
         getProductRatings(page);
    }


    const getProductRatingsSummary = () => {
        let paginate = `page=${page}&limit=${LIMIT}`;
          fetch(`${API_ENDPOINT}/get_product_ratings_summary/${product_id}?${paginate}`,{
             method: 'GET',
             headers : {        
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer '+accessToken, 
             }
          }).then(function (response) {
             return response.json();
          }).then( (result)=> {
             console.log("rating summery=====",result);
             if(result.status === 1){
               setProductratingssummary(result?.ratePercent);
             }
          })
    }

    const getProductJson = () => {
        
        
        fetch(`${API_ENDPOINT}/get_aggregate_rating/${product.p_id}`,{
            method: 'GET',
            headers : {        
               'Content-Type': 'application/json',
               'Accept': 'application/json',
            }
         }).then(function (response) {
            return response.json();
         }).then( (result)=> {
            //console.log('img==', result)
            if(result.status == 1){
                setRatingData(result.data)
            }else{
                setRatingData(null)
            }
         })
    }


      return(
          <>
          <div className="flex">
           {ratingData?.avgRating && ratingData?.avgRating > 0 
           && <div className="flex mt-6 flex-col w-full md:w-2/12 ">
           <div className="flex flex-col items-center mb-2">
            <p className="ml-2 text-5xl  font-lg text-gray-900 dark:text-white">{ratingData?.avgRating}</p>
            <p class="text-sm font-medium text-gray-500 dark:text-gray-400 py-1">{ratingData?.totalRating} ratings</p>
            <div className="flex">
                {showRating(ratingData?.avgRating, 18, '#ffb901')}
            </div> 
         </div>
        </div>}
        <div className="flex mt-6 flex-col w-full md:w-1/2">
          {productratingssummary && productratingssummary.map((item, key) => (
                <div class="flex items-center mt-1">
                     <a href="#" class="text-sm font-medium font-semibold flex items-center  hover:underline">{item?.rating} <MdOutlineStarPurple500 className="w-3 h-3 " /></a>
                     <div class="w-2/4 h-1 mx-4 bg-gray-200 rounded dark:bg-gray-700">
                         {item?.rating == 5 ?
                         <div class={`h-1 bg-green-500 rounded `} style={{width: `${item.percent}%`}}></div>
                         :
                         (null)
                        }
                         {item?.rating == 4 ?
                         <div class="h-1 bg-green-300 rounded w-[`${item.percent}%`]" style={{width: `${item.percent}%`}}></div>
                         :
                        (null)
                        }
                         {item?.rating == 3 ?
                         <div class="h-1 bg-yellow-300 rounded w-[{item.percent}%]" style={{width: `${item.percent}%`}}></div>
                         :
                        (null)
                        }
                         {item?.rating == 2 ?
                         <div class="h-1 bg-orange-300 rounded w-[{item.percent}%]" style={{width: `${item.percent}%`}}></div>
                         :
                        (null)
                        }
                         {item?.rating == 1 ?
                         <div class="h-1 bg-red-500 rounded w-[{item.percent}%]" style={{width: `${item.percent}%`}}></div>
                         :
                        (null)
                        }
                     </div>
                     <span class="text-sm font-medium text-gray-500 dark:text-gray-400">{item.percent}%</span>
                 </div>
            ))
        }
        </div>
         </div>

          {productratings && totalRec > 0 ?
         <div ref={ref} className="border-b border-border-200 border-opacity-70 px-5 py-4 lg:px-16 lg:py-14">

         <div className="flex w-full flex-col md:flex-row">
        
          <div className="w-full">

         <article>
            <div name="grid"  className="px-4 xl:px-0">
                 <div className="w-full pb-6">
                
                  <><h2 className="mb-4 text-lg font-semibold tracking-tight text-heading md:mb-6">Customer reviews</h2>
                  <InfiniteScroll
                            dataLength={productratings.length} //This is important field to render the next data
                            next={fetchData}
                            hasMore={hasMore}
                            loader={
                                <h4 className="h-20 mt-5"><Loading className="w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />Loading...</h4>
                            }
                     scrollThreshold={0.7}
                     style={{ overflowY: 'hidden' }}
                            >
                            <div layout className="grid grid-cols-1 gap-1">
                            {
                                productratings && productratings.map((item, key) => (
                            <>
                                 <div class="flex items-center mb-2 space-x-4">
                                     <img class="w-10 h-10 rounded-full" src={`https://ui-avatars.com/api/?name=${item.user_name}&background=random`} alt="" />
                                     <div class="space-y-1 font-medium dark:text-white ">
                                         <p className="capitalize">{item.user_name} 
                                            <div class="flex items-center mb-1">{showRating(item.rating, 18, '#ffb901')}</div>
                                             <p class="text-sm text-gray-500 dark:text-gray-400">{item?.reviewtitle}</p>

                                        </p>
                                     </div>
                                 </div>
                               
                                 <footer class="mb-1 text-sm text-gray-500 dark:text-gray-400"><p>Reviewed on <time datetime="2017-03-03 19:00">{date_format(item?.created_at)}</time></p></footer>
                                 <p class="mb-2 text-gray-500 dark:text-gray-400">{item?.rating_comment}</p>
                                 <div className="flex flex-row gap-5 pb-3">
                                    {
                                    item.review_image && item.review_image.map((rate,index) => (
                                    <a href={`${IMG_URL}${rate}`} target="_blank"><img  alt={`${item.user_name}-${index}`} src={`${IMG_URL}${rate}`} className="h-40"  /></a>
                                    ))
                                    }
                                </div>
        
                            </>
                                ))
                            }                     
                            </div>      
                  </InfiniteScroll>     
                  </>          
                  
                        </div>
                    </div>

         </article>


          </div>
         </div>
         </div>
         :
        <></>
      }
      </>
      )
  
}

export default ProductRatings