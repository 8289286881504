import React, {useState, useEffect} from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import * as yup from 'yup';
import {API_ENDPOINT, APP_URL, APP_NAME, admin_phone, toast_anim} from '../config/Common'
import ScrollPageTop from '../../routes/ScrollPageTop'
import {Links} from '../../routes/PathLink'
import Seo from '../seo/seo'

import TextArea from '../../ui/text-area.tsx'
import Button from '../../ui/Button.tsx'


import {
   Control,
   FieldErrors,
   useForm,
   useFormState,
   useWatch,
   Controller
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const formSchema = yup.object().shape({
   negomessage: yup.string().required('Negotiation Message is required'), 
});

let defaultValues = {  
   negomessage:'',
};

const NegotitateSend = (props) => {
   const [name, setName] = useState(localStorage.getItem('user_name'));
   const [email, setEmail] = useState(localStorage.getItem('email'));
   const [user_id, setUser_id] = useState(localStorage.getItem('user_id'));

   
   const [accessToken, setAccessToken] = useState(localStorage.getItem('access_token'))

   const [loading, setLoading] = useState(false);
   
   const { register, handleSubmit , control, reset, setValue, setError, formState: { errors },} = useForm({
      defaultValues: defaultValues,
      resolver: yupResolver(formSchema),
   });

  


   const {product} = props;
   const [pagecontent, setPagecontent] = useState(null);
   const [pagetitle, setPageTitle] = useState(null);
   
   const onSubmit = (values) => {
      
      let input = {...values, product:product , name:name , email:email, user_id:user_id };
      console.log('input', input);
      setLoading(true)
      fetch(`${API_ENDPOINT}/send_negotation_form`,{
         method: 'POST',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json', 
            'Authorization': `Bearer ${accessToken}`, 
         },
         body: JSON.stringify(input)
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         console.log('mail======',result);
         setLoading(false)
         if(result.status === 1){
            toast_anim(result?.message); 
             props.closeModal();
            /*defaultValues = {  
               name: '',
               email:'',
               subject:'',
               description:''
            };
            reset(defaultValues) 
            
            */   
         }
      })
   }

   return(<div className="py-2"><form onSubmit={handleSubmit(onSubmit)}>              
      <TextArea
         label='Message'
         {...register('negomessage')}                        
         variant="outline"
         className="mb-5"
         error={errors.negomessage?.message}
         required
      />
      <div>
      <Button loading={loading}>
         Send
      </Button>

       <Button type="button" className="ml-2 bg-black" onClick={()=>{ props.closeModal() }} >
         Cancel
      </Button>

      </div>
   </form></div>)
}

export default NegotitateSend;