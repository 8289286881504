import React, {useState, useEffect} from "react";
import { motion } from 'framer-motion';
import {EmptyCartIcon} from '../../custom-icons'
import {useAuth} from '../../provider/AuthProvider'
import { Drawer } from "flowbite";
import { useCart } from '../../cart/cart-utils'
import Radio from '../../ui/radio/radio.tsx';
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";

import {
   API_ENDPOINT, 
   toast_anim_error, 
   RAZOR_KEY, 
   APP_NAME, 
   APP_URL, 
   amount_format, 
   loadScript,
   bookingamount,
} from '../config/Common'

import AddBillingAddress from './AddBillingAddress'

import ContactNumber from './ContactNumber'
import { BiRupee } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Button from '../../ui/Button.tsx'
import classNames from 'classnames';
import ScrollPageTop from '../../routes/ScrollPageTop'
import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'

import {useGstRates} from '../../gst-rates/gst-rates'

const Checkout = (props) => {
   const { isUser } = useAuth();
   const { gstSaleRate } = useGstRates();
   const { cartItem, cartTotal, gstTax } = useCart();
   const [accessToken, setAccessToken] = useState(localStorage.getItem('access_token'))
   const [invoice, setInvoice] = useState(localStorage.getItem('rand_num'))
   const [user_me, setUser_me] = useState(null)
   
   const [shipping, setShipping] = useState(0)
   const [grantTotal, setGrantTotal] = useState(0)
   const [isBilling, setIsBilling] = useState(false)
   const [isShipping, setIsShipping] = useState(false)
   const [billingAddr, setBillingAddr] = useState(null)
   const [showbooking, setBooking] = useState(false);
   const [bookingMethod, setBookingMethod] = useState(null);
   const [bookAmount, setBookAmount] = useState(0)

   const [payMathode, setPayMathode] = useState('')

   const [metaData, setMetaData] = useState({
      url: `${APP_URL}/checkout`,
      title:  `Checkout - ${APP_NAME}`,
      description:'checkout'
   })
   const [loading, setLoading] = useState(false)
   

   const { getCartItems} = useCart();

   const navigate = useNavigate();

   useEffect(() => {
      if(!accessToken){
         return navigate("/", { replace: true });
      }
   },[accessToken])

   useEffect(() => {
      getMyProfile()
      //console.log('isUser=======', isUser)
   },[isUser])

   useEffect(() => {
      let total = cartTotal + gstTax + shipping
      setGrantTotal(total);
      //console.log("asdasd",total);
      if(parseFloat(total) > 500000){
         setBooking(true);
      }
      //setBooking()
   },[cartTotal, gstTax])

   const getMyProfile = () => {
      //console.log(this.state.access_token)
      fetch(`${API_ENDPOINT}/user_me`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${accessToken}`, 
         }
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         console.log('users========',result)
         setUser_me(result.data)
         
      })
   }

   

   async function displayRazorpay() {

      if(!isBilling || !isShipping){
         toast_anim_error('Billing or shipping in empty')
         return false;
      }

      if(!bookingMethod && showbooking){
         toast_anim_error('Please select Booking Amount')
         return false;
      }

      const res = await loadScript(
          "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
         //alert("Razorpay SDK failed to load. Are you online?");
         toast_anim_error('Razorpay failed to load. Are you online?')
         return false;
      }
      // New code if grand total is greater than 500000      
      
      fetch(`${API_ENDPOINT}/razor_payment_order`,{
         method: 'POST',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+accessToken, 
         },
         body: JSON.stringify({
            amount:grantTotal*100,
            invoice:invoice,
            showbooking:showbooking,
            bookingamount:bookAmount*100,

         })
      }).then(function (response) {
         return response.json();
      }).then( (result)=> {
         console.log('invoice=====',result)
         

         var options = {
            "key": RAZOR_KEY, // Enter the Key ID generated from the Dashboard
            "amount": result.data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            "currency": "INR",
            "name": "CTMRI",
            "description": "Purchese products",
            "image": "https://i.imgur.com/3g7nmJC.jpg",
            "order_id": result.data.order_id,
            "handler": function (response){
               console.log('rzp1 response', response)
               const data = {
                  razorpay_payment_id: response.razorpay_payment_id,
                  razorpay_order_id: response.razorpay_order_id,
                  razorpay_signature: response.razorpay_signature,
               };
               //console.log('razorpay data', data)
               if(response.razorpay_payment_id){
                  handlePlaceOrder()            
               }

            },
            "prefill": {
               "name": user_me.name,
               "email": user_me.email,
               "contact": user_me.phone_no
            },
            
            "theme": {
               "color": "#009f7f"
            }
         };

         const rzp1 = new window.Razorpay(options);
         /* rzp1.on('payment.failed', function (response){
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.order_id);
            alert(response.error.metadata.payment_id);
         }); */
         rzp1.open();
      })
   }

   const handlePlaceOrder = () => {
      //console.log('handlePlaceOrder', isBilling, isShipping)
      if(isBilling && isShipping){
         setLoading(true)
         fetch(`${API_ENDPOINT}/place_order/${invoice}`,{
            method: 'POST',
            headers : {        
               'Content-Type': 'application/json',
               'Accept': 'application/json',
               'Authorization': 'Bearer '+accessToken, 
            },
            body: JSON.stringify({
               sub_total:cartTotal,
               total_amount:grantTotal,
               tax:gstTax,
               shipping:shipping,
               payMethod:payMathode,
               showbooking:showbooking,
               bookingamount:bookAmount,
               bookingMethod:bookingMethod
            })
         }).then(function (response) {
            return response.json();
         }).then( (result)=> {
            console.log('data=====',result)
            setLoading(false)
            if(result.status === 1){
               getCartItems();
               return navigate(`/orders/${result.data.invoice}`, { replace: true });
            }
         })
      }else{
         toast_anim_error('Billing or shipping in empty')
         return false;
      }
   }

   const setRequiredField = (type) => {
      if(type === 'billing'){
         setIsBilling(true)
      }
      if(type === 'shipping'){
         setIsShipping(true)
      }
      
   }

   const getAddress = (addr) => {
      //console.log('address=====',addr)
      setBillingAddr(addr)
   }

   const handleChange = (e) => {
      //console.log('onchange', e.target.name, e.target.value)
      setPayMathode(e.target.value)
      
   }

   const handleBookMethod = (e) => {
      setBookingMethod(e.target.value)
      let bookVal = e.target.value
      if(bookVal == 'proceed'){
         setBookAmount(100000)
      }
      if(bookVal == 'hold'){
         setBookAmount(50000)
      }
      if(bookVal == 'contact_dealer'){
         setBookAmount(10000)
      }
      
   }


   return(
      <ScrollPageTop>
         <ReactSEOMetaTags
            render={(el: React.ReactNode) => <Helmet>{el}</Helmet>}
            website={{ ...metaData }}
         />
         <Header />
         <div className="px-4 py-8 bg-gray-100 dark:bg-dark lg:px-8 xl:px-16 2xl:px-20">
            {
            cartItem.length > 0 ?
            <div className="flex flex-col items-center w-full max-w-5xl m-auto rtl:space-x-reverse lg:flex-row lg:items-start lg:space-x-8">
               <div className="w-full space-y-6 lg:max-w-2xl">
                  <ContactNumber />                  
                  <AddBillingAddress type='billing' num={2} setRequiredField={setRequiredField} getAddress={getAddress} />
                  <AddBillingAddress type='shipping' num={3} setRequiredField={setRequiredField} />
               </div>
               <div className="w-full mt-10 mb-10 sm:mb-12 lg:mb-0 lg:w-96">
                  
                  <div className="w-full">
                     <div className="mb-4 flex flex-col items-center space-x-4 rtl:space-x-reverse">
                        <span className="text-base font-bold text-heading">Your Order</span>
                     </div>
                     <div className="flex flex-col border-b border-border-200 py-3">
                        {
                        cartItem.length > 0 && cartItem.map((val, key) => (
                        <div className="flex justify-between py-2" key={`checkout-${val.cart_id}`}>
                           <div className="flex items-center justify-between text-base">
                              <span className="text-sm text-body">
                                 <span className="text-sm font-bold text-heading">{val.quantity}</span>
                                 <span className="mx-2">x</span>
                                 <span>{val.product.product_name}</span>
                              </span>
                           </div>
                           <span className="text-sm text-body flex items-center">
                              {amount_format(val.item_total)}                              
                           </span>
                        </div>
                        ))
                        }                        
                     </div>
                     <div className="mt-4 mb-5 space-y-2">
                        <div className="flex justify-between">
                           <p className="text-sm text-body">Sub Total</p>
                           <span className="text-sm text-body flex items-center">
                              {amount_format(cartTotal)}
                           </span>
                        </div>
                        <div className="flex justify-between">
                           <p className="text-sm text-body">GST ({gstSaleRate}%)</p>
                           <span className="text-sm text-body flex items-center">
                              {amount_format(gstTax)}                              
                           </span>
                        </div>
                        {/*<div className="flex justify-between">
                           <p className="text-sm text-body">Shipping</p>
                           <span className="text-sm text-body flex items-center">
                              {amount_format(shipping)}                              
                           </span>
                        </div>
                        
                        */}
                        <div className="flex justify-between pt-3 border-t-4 border-double border-border-200">
                           <p className="text-sm text-accent">Shipping charges included in price</p>
                        </div>

                     </div>
                     <div className="flex justify-between pt-3 border-t-4 border-double border-border-200">
                        <p className="text-base font-semibold text-heading">Total</p>
                        <span className="text-base font-semibold text-heading flex items-center">
                           {amount_format(grantTotal)}                           
                        </span>
                     </div>
                     
                     <div className="p-5 mt-10 border border-gray-200 bg-light">
                        <div id="headlessui-radiogroup-16" role="radiogroup" aria-labelledby="headlessui-label-17">
                           <label className="mb-5 block text-base font-semibold text-heading" id="headlessui-label-17" role="none">Choose Payment Method</label>
                           <div className="flex items-center">
                              <Radio
                                 name='pay_methode'
                                 label={'Pay Online'}
                                 id={'pay_online'}
                                 className="mr-2"
                                 value={'Online'}
                                 onChange={handleChange}
                              />
                              <Radio
                                 name='pay_methode'
                                 label={'COD'}
                                 id={'cod'}
                                 value={'COD'}
                                 className="mr-2"
                                 onChange={handleChange}
                              />
                           </div>
                           
                        </div>
                     </div>
                     {payMathode === 'Online' && showbooking ?
                     <div className="p-5 mt-2 border border-gray-200 bg-light">
                        <div id="headlessui-radiogroup-16" role="radiogroup" aria-labelledby="headlessui-label-17">
                           <label className="mb-5 block text-base font-semibold text-heading" id="headlessui-label-17" role="none">Booking Amount</label>
                           <div>
                              <Radio
                                 name='booking_type'
                                 label={'Proceed with advance 1 lakh'}
                                 id={'proceed_with_advance'}
                                 className="mr-2 mb-2"
                                 value={'proceed'}
                                 onChange={handleBookMethod}
                              />
                              <Radio
                                 name='booking_type'
                                 label={`Hold with advance Rs.50,000.00`}
                                 id={'hold_with_advance'}
                                 className="mr-2 mb-2"
                                 value={'hold'}
                                 onChange={handleBookMethod}
                              />
                              <Radio
                                 name='booking_type'
                                 label={`Contact Dealer Rs.10,000.00`}
                                 id={'contact_dealer'}
                                 className="mr-2"
                                 value={'contact_dealer'}
                                 onChange={handleBookMethod}
                              />
                           </div>
                           <p class="text-base font-light text-heading mt-2 text-red-600">Cart Amount is more than 5,00,000.00, you can book by paying Booking Amount. Remaning amount you can pay by cheque.</p>
                        </div>
                     </div>
                     :null
                     }

                     {
                     payMathode == 'Online' &&
                     <Button
                     className={classNames('mt-5 w-full')}
                     onClick={displayRazorpay}
                     loading={loading}
                     {...props}
                     >
                     Place Order
                     </Button>
                     }

                     {
                     payMathode == 'COD' &&
                     <Button
                     className={classNames('mt-5 w-full')}
                     onClick={handlePlaceOrder}
                     loading={loading}
                     {...props}
                     >
                     Place Order
                     </Button>
                     }
                  </div>
               </div>
            </div>
            :
            <motion.div
                  layout
                  initial="from"
                  animate="to"
                  exit="from"
                  className="flex h-full flex-col items-center justify-center animate__animated animate__bounceIn"
               >
                  <EmptyCartIcon width={140} height={176} />
                  <h4 className="mt-6 text-base font-semibold">
                  Cart is empty
                  </h4>
               </motion.div>
            }
         </div>
         <Footer />
      </ScrollPageTop>
   );
}

export default Checkout;