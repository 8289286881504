import cn from 'classnames';
import moment from 'moment';
import { BiRupee } from "react-icons/bi";
import {
   date_format,
   amount_format,
} from '../config/Common'

const OrderCard = ({onClick, order}: props) => {

   return(
      <div                        
         role="button"
         className={cn(
         'mb-4 flex w-full shrink-0 cursor-pointer flex-col overflow-hidden rounded border-2 border-transparent bg-gray-100 last:mb-0 animate__animated',{
            '!border-accent animate__pulse':order.isActive
         }
         )}
         onClick={onClick}
      >
         <div className="flex items-center justify-between border-b border-border-200 py-3 px-5 md:px-3 lg:px-5 ">
            <span className="flex shrink-0 text-sm font-bold text-heading ltr:mr-4 rtl:ml-4 lg:text-base">
               Order
               <span className="font-normal">#{order.invoice}</span>
            </span>
            <span
               // className="max-w-full truncate whitespace-nowrap rounded bg-blue-100 px-3 py-2 text-sm text-blue-500"
               className={`max-w-full truncate capitalize whitespace-nowrap rounded bg-[#F59E0B] px-3 py-2 text-sm text-white`}
               title='pending'
            >
               {order.order_status}
            </span>
         </div>
         <div className="flex flex-col p-5 md:p-3 lg:px-4 lg:py-5">
            <p className="mb-4 flex w-full items-center justify-between text-sm text-heading last:mb-0">
               <span className="w-24 shrink-0 overflow-hidden">
               Order Date
               </span>
               <span className="ltr:mr-auto rtl:ml-auto">:</span>
               <span className="ltr:ml-1 rtl:mr-1">
               {date_format(order.created_at)}
               </span>
            </p>
            <p className="mb-4 flex w-full items-center justify-between text-sm font-bold text-heading last:mb-0">
               <span className="w-24 shrink-0 overflow-hidden">
               Amount
               </span>
               <span className="ltr:mr-auto rtl:ml-auto">:</span>
               <span className="ltr:ml-1 rtl:mr-1">
               {amount_format(order.sub_total)}
               </span>
            </p>
            <p className="mb-4 flex w-full items-center justify-between text-sm font-bold text-heading last:mb-0">
               <span className="w-24 flex-shrink-0 overflow-hidden">
               Total Price
               </span>
               <span className="ltr:mr-auto rtl:ml-auto">:</span>
               <span className="ltr:ml-1 rtl:mr-1 flex items-center">
               {amount_format(order.total_amount)}
               </span>
            </p>
            {
            order.bookingamount > 0 &&
            <p className="mb-4 flex w-full items-center justify-between text-sm font-bold text-heading last:mb-0">
               <span className="w-24 flex-shrink-0 overflow-hidden">
               Advanced
               </span>
               <span className="ltr:mr-auto rtl:ml-auto">:</span>
               <span className="ltr:ml-1 rtl:mr-1 flex items-center">
               {amount_format(order.bookingamount)}
               </span>
            </p>
            }
         </div>

      </div>
   )
}

export default OrderCard