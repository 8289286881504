import React, {useState, useEffect} from "react";
import cn from 'classnames';
import {Eye} from "../../custom-icons";
import { Link } from "react-router-dom";
import { BiRupee } from "react-icons/bi";
import {useOrder} from './OrderProvider'
import ProgressBox from './progress-box.tsx'
import {
   API_ENDPOINT, 
   amount_format,
} from '../config/Common'
import CartTable from './CartTable'

const OrderCardDetail = () => {
   const {selectedOrder} = useOrder()
   const [accessToken] = useState(localStorage.getItem('access_token'))
   const [cart, setCart] = useState(null)
   const [trackData, setTrackData] = useState(null)
   
   useEffect(() => {
      get_completed_cart()
      get_order_trackData()
   },[selectedOrder])

   const get_completed_cart = () => {
      if(selectedOrder){
         //console.log(selectedOrder.invoice)
         fetch(`${API_ENDPOINT}/get_completed_cart/${selectedOrder.invoice}`,{
            method: 'GET',
            headers : {        
               'Content-Type': 'application/json',
               'Accept': 'application/json',
               'Authorization': 'Bearer '+accessToken, 
            }
         }).then(function (response) {
            return response.json();
         }).then( (result)=> {
            //console.log('data=====',result)
            setCart(result.data)
            //getSelectedOrder(result.data[0])
         })
      }
   }
   const get_order_trackData = () => {
      if(selectedOrder){
         //console.log(selectedOrder.invoice)
         fetch(`${API_ENDPOINT}/get_order_track_data/${selectedOrder.invoice}`,{
            method: 'GET',
            headers : {        
               'Content-Type': 'application/json',
               'Accept': 'application/json',
               'Authorization': 'Bearer '+accessToken, 
            }
         }).then(function (response) {
            return response.json();
         }).then( (result)=> {
            //console.log('data=====',result)
            setTrackData(result.data)
            //getSelectedOrder(result.data[0])
         })
      }
   }
   return(
      <>      
      {
      selectedOrder &&
      <div className="flex w-full flex-col border border-border-200 bg-white lg:w-2/3 ">
         <div className="flex flex-col items-center p-5 md:flex-row md:justify-between">
            <h2 className="mb-2 flex text-sm font-semibold text-heading md:text-lg">
            Order Details <span className="px-2">-</span>{' '}
               {selectedOrder.invoice}
            </h2>
            <div className="flex items-center">
               <Link
                  to={`/orders/${selectedOrder.invoice}`}
                  className="flex items-center text-sm font-semibold text-accent no-underline transition duration-200 hover:text-accent-hover focus:text-accent-hover"
               >
                  <Eye width={20} className="ltr:mr-2 rtl:ml-2" />
                  Details
               </Link>
            </div>
         </div>
         <div className="relative mx-5 mb-6 overflow-hidden rounded">
            <div className={cn(`bg-[#F7F8FA] px-7 py-4`)}>
               <div className="mb-0 flex flex-col flex-wrap items-center justify-between gap-x-8 text-base font-bold text-heading sm:flex-row lg:flex-nowrap">
                  <div
                     className={`order-2 flex w-full gap-6 xs:flex-nowrap sm:order-1 max-w-full basis-full justify-between lg:ltr:mr-auto`}
                  >
                     <div className="flex flex-wrap items-center">
                        <span className="mb-2 block text-xs xs:text-base lg:mb-0 lg:inline-block lg:ltr:mr-4 lg:rtl:ml-4">
                        Order status :
                        </span>
                        <div className="w-full lg:w-auto">
                        <span className="px-3 py-1 rounded-full capitalize text-sm text-light bg-[#F59E0B] min-h-[2rem] items-center justify-center text-[9px] !leading-none xs:text-sm inline-flex">{selectedOrder.order_status}</span>
                        </div>
                     </div>
                     <div className="flex flex-wrap items-center">
                        <span className="mb-2 block text-xs xs:text-base lg:mb-0 lg:inline-block lg:ltr:mr-4 lg:rtl:ml-4">
                        Payment status :
                        </span>
                        <div className="w-full lg:w-auto">
                        <span className="px-3 py-1 rounded-full capitalize text-sm text-light bg-accent min-h-[2rem] items-center justify-center text-[9px] !leading-none xs:text-sm inline-flex">{selectedOrder.pay_status}</span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div className="flex flex-col border-b border-border-200 sm:flex-row">
            
            <div className="flex w-full flex-col border-b border-border-200 px-5 py-4 sm:border-b-0 ltr:sm:border-r rtl:sm:border-l md:w-3/5">
            {
            selectedOrder.billing.map((item, index) => (
               <React.Fragment key={`addr-${item.billing_id}`}>
               {
               item.type === 'shipping' &&
               <div>
                  <span className="mb-2 block text-sm font-bold text-heading">
                  Shipping address
                  </span>
                  <span className="text-sm text-body">
                  {item.street_address}, {item.city}, {item.state}, {item.zip}, {item.country}
                  </span>
               </div>
               }
               {
               item.type === 'billing' &&
               <div className="mb-4">
                  <span className="mb-2 block text-sm font-bold text-heading">
                  Billing Address
                  </span>

                  <span className="text-sm text-body">
                  {item.street_address}, {item.city}, {item.state}, {item.zip}, {item.country}
                  </span>
               </div>
               }
               </React.Fragment>
               ))
               
            }
            </div>
            
            <div className="flex w-full flex-col px-5 py-4 md:w-2/5">
               <div className="mb-3 flex justify-between">
                  <span className="text-sm text-body">Sub Total</span>
                  <span className="text-sm text-heading flex items-center">
                     {amount_format(selectedOrder.sub_total)}
                  </span>
               </div>
               {/* <div className="mb-3 flex justify-between">
                  <span className="text-sm text-body">Discount</span>
                  <span className="text-sm text-heading flex items-center">
                     {amount_format(0)}
                  </span>
               </div> */}
               <div className="mb-3 flex justify-between">
                  <span className="text-sm text-body">GST</span>
                  <span className="text-sm text-heading flex items-center">
                     {amount_format(selectedOrder.tax_gst)}
                  </span>
               </div>
               {selectedOrder.total_delivery_fee && selectedOrder.total_delivery_fee > 0 ?
                  <div className="mb-3 flex justify-between">
                     <span className="text-sm text-body">Delivery Fee</span>
                     <span className="text-sm text-heading flex items-center">
                        {amount_format(selectedOrder.total_delivery_fee)}
                     </span>
                  </div>
                  :
                  <div className="mb-3 flex justify-between border-t-4 border-double border-b-4 ">
                   <span className="text-sm text-accent py-2">Shipping charges included in price</span>
                  </div>
               }
              
               

               <div className="flex justify-between">
                  <span className="text-sm font-bold text-heading">
                  Total
                  </span>
                  <span className="text-sm text-heading flex items-center">
                     {amount_format(selectedOrder.total_amount)}
                  </span>
               </div>
            </div>
         </div>
         {
         trackData && trackData.length > 0 ?
         <>
         <h2 className="ml-5 mt-5 flex text-sm font-semibold text-heading md:text-lg">
            Track Order
            </h2>
         <ProgressBox
            data={trackData}
            filledIndex={trackData.length}
         />
         </>
         :null
         }
         {
            cart &&
            <CartTable cart={cart} />
         }
         
      </div>
      }
      </>
   )
}

export default OrderCardDetail