import {Fragment, useRef, useState, useEffect} from "react";
import Table from 'rc-table';
import {APP_URL, IMG_URL, amount_format,} from '../config/Common'
import { Link  } from "react-router-dom";
import { BiRupee } from "react-icons/bi";
import WriteReview from './WriteReview.jsx'
import Button from '../../ui/Button.tsx'
import { Dialog, Transition } from '@headlessui/react'
import {Loading,CloseIcon} from "../../custom-icons";

const CartTable = (props) => {
   const {cart} = props
   const [open, setOpen] = useState(false)
   const [reviewProduct, setReviewProduct] = useState(null)
   const cancelButtonRef = useRef(null)

   const writeReview = (item) => {
      console.log('product id====', item)
      setOpen(true)
      setReviewProduct(item)
   }

   const closeModal = () => {
      setOpen(false)
   }

   const OrderItemList = (record: any) => {
      return (
         <div className="flex flex-col items-start">
            <div className="relative flex h-16 w-16 shrink-0 overflow-hidden rounded">
               {
               record.product.product_image
               ?
               <img
                  src={`${IMG_URL}${record.product.product_image}`}
                  className="h-full w-full object-contain"
                  alt={record.product.product_name}
               />
               :
               <img
                  src={`${APP_URL}/img/no-image-available.png`}
                  className="h-full w-full object-contain"
                  alt={record.product.product_name}
               />
               }
            </div>
            <div className="flex flex-col overflow-hidden ltr:ml-4 rtl:mr-4">
               <div className="mb-1 flex space-x-1 rtl:space-x-reverse">
                  <Link to={`/product/${record.product.product_slug}`} className=" w-[60%] inline-block overflow-hidden truncate text-sm text-body transition-colors hover:text-accent hover:underline">
                     {record.product.product_name}
                  </Link>
                  <span className="inline-block overflow-hidden truncate text-sm text-body">
                     x
                  </span>
                  <span className="inline-block overflow-hidden truncate text-sm font-semibold text-heading">
                     {record.quantity} item
                  </span>
               </div>
               <span className="mb-1 inline-block overflow-hidden truncate text-sm font-semibold text-accent flex items-center">
                  {amount_format(record.amount)}
               </span>
            </div>
            <div>
               <Button
                  className="mt-2"
                  onClick={() => writeReview(record.product)}
                  loading={false}
                  size='smaller'
                  {...props}
                  >
                  Write a Review
                  </Button>
            </div>
         </div>
      )
   }   

   const columns = [
      {
         title: <span className="ltr:pl-20 rtl:pr-20">Item</span>,
         dataIndex: '',
         key: 'items',
         width: 200,
         ellipsis: true,
         align: 'left',
         render: OrderItemList,
       },
       {
         title: 'Quantity',
         dataIndex: '',
         key: 'pivot',
         align: 'center',
         width: 100,
         render: (qty) => <p className="text-base">{qty.quantity}</p>,
       },
       {
         title: 'Price',
         dataIndex: '',
         key: 'price',
         align: 'right',
         width: 100,
         render: (pivot) => <p className="flex items-center justify-end">{amount_format(pivot.amount)}</p>,
       },       
    ];

   return (
      <>
      <Table 
         columns={columns} 
         data={cart} 
         className="orderDetailsTable w-full"
         rowKey={(record:any) => record.cart_id}
         scroll={{ x: 600, y: 500 }}
      />

      <Transition.Root show={open} as={Fragment} dir='ltr'>
      <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
               <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
               <div className="min-h-screen bg-light p-4 md:min-h-0 md:rounded-xl">
                  <button aria-label="Close panel" className="absolute top-4 z-[60] inline-block outline-none focus:outline-none ltr:right-4 rtl:left-4" onClick={closeModal}>
                     <CloseIcon className="h-4 w-4" />
                  </button>
                  {reviewProduct && <WriteReview reviewProduct={reviewProduct} closeModal={closeModal} />}
               </div>
               </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>

      </>
   )

}

export default CartTable;