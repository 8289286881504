import React, {useState} from "react";
import {APP_URL, remove_overflow} from '../components/config/Common'
import Logo from './logo';
import { useNavigate } from "react-router-dom";
import {useAuth} from '../provider/AuthProvider'
import {SideMenu} from './SideMenu'
import { Link } from "react-router-dom";
import {CloseIcon} from '../custom-icons'
import {Links} from '../routes/PathLink'

const MobileMenuDrawer = (props) => {
   const [sideMenu, SetSideMenu] = useState(SideMenu)

   const navigate = useNavigate();
   const { logout } = useAuth();

   const logoutUser = () => {
      let user_name = localStorage.getItem("user_name")
      localStorage.removeItem("access_token")
      localStorage.removeItem("user_type")
      localStorage.removeItem("user_id")
      localStorage.removeItem("user_name")
      logout()
      remove_overflow()
      //setIsLogin(false)
      navigate(
         Links.LOGOUT,
         {
            state: { user_name: user_name },
            replace: true 
         },
      );
   }

   const go_to_link = (link) => {
      //console.log(link)
      remove_overflow()
      navigate(link);
   }

   const closeDrawer = () => {
      props.closeRightMenuDrawer()
   }

   return (

      <div id="mobile-menu-drawer" className="fixed top-0 right-0 z-50 h-screen overflow-y-auto transition-transform translate-x-full bg-white w-80 dark:bg-gray-800 " tabIndex="-1" aria-labelledby="drawer-right-label">
         
         <div className="fixed top-0 z-20 mb-4 flex w-80 max-w-md items-center justify-between border-b border-border-200 border-opacity-75 bg-white p-5 md:mb-6">
            <a className="inline-flex w-24 md:w-auto" href="/">
               <span className="relative h-10 w-32 md:w-40">
                  <Logo />
               </span>
            </a>
            <button className="flex h-7 w-7 items-center justify-center rounded-full bg-gray-100 text-muted transition-all duration-200 hover:bg-accent hover:text-light focus:bg-accent focus:text-light focus:outline-none ltr:ml-3 ltr:-mr-2 rtl:mr-3 rtl:-ml-2" onClick={closeDrawer}>
               <span className="sr-only"></span>
               <CloseIcon className="h-3 w-3" />
            </button>
         </div>
         
         <div className="pt-20">
            <ul className="flex-grow">
               {
                  sideMenu && sideMenu.map((item, index) => (
                     <li key={index}>
                        <button onClick={() => go_to_link(item.link)} className="block cursor-pointer px-5 py-3 text-sm font-semibold capitalize text-heading transition duration-200 hover:text-accent md:px-8">{item.label}</button>
                     </li>      
                  ))
               }
               <li>
                  <span onClick={logoutUser} className="block cursor-pointer px-5 py-3 text-sm font-semibold capitalize text-heading transition duration-200 hover:text-accent md:px-8">Logout</span>
               </li>
            </ul>
         </div>
      </div>
   )
}

export default MobileMenuDrawer;