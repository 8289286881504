import React, {useState, useEffect} from "react";
import LeftMenuLayout from '../../layouts/LeftMenuLayout';
import ScrollPageTop from '../../routes/ScrollPageTop'


import classNames from 'classnames';
import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import Table from 'rc-table';
import Pagination from '../../_utils/pagination.jsx'

import {
   API_ENDPOINT,
   APP_NAME, 
   APP_URL,
   date_format,
   LIMIT,
} from '../config/Common'

import ModalView from '../../_utils/ModalView'
import {Links} from '../../routes/PathLink'
import ModalTicketView from './ModalTicketView'
import NoRecords from '../NoRecords'

const CmcTicket = () => {
   const [accessToken] = useState(localStorage.getItem('access_token'))
   const [tickets, setTickets] = useState(null)
   const [totalRec, setTotalRec] = useState(null)
   const [currentPage, setCurrentPage] = useState(1)
   const [isOpen, setIsOpen] = useState(false)
   const [ticket, setTicket] = useState(null)

   const [metaData, setMetaData] = useState({
      url: `${APP_URL}${Links.CMS_TICKETS}`,
      title:  `My Service Tickets - ${APP_NAME}`,
      description:'My Service Tickets'
   })

   useEffect(() => {
      getMyTickets(currentPage)
   },[])

   const getMyTickets = (page) => {
      let url = 'user_cmc_tickets'
      if(localStorage.getItem('user_type') === 'dealer'){
         url = 'dealer_cmc_ticket'
      }
      //console.log(url)
      fetch(`${API_ENDPOINT}/${url}?pg=${page}&limit=${LIMIT}`,{
         method: 'GET',
         headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+accessToken, 
         }
      }).then(function (response) {
         return response.json();
      }).then( (result)=> {
         console.log('cmc ticket=====',result)
         setTickets(result.data)
         setTotalRec(result.totalRec)         
      })
   }

   const statusBadge = (record: any) => {
      let sts = true
      return(
         <>
         {
         record.status === 'open' ?
         <span className={classNames("bg-red-100 text-red-800 border-red-400 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border capitalize")}>{record.status}</span>
         :
         record.status === 'closed' ?
         <span className={classNames("bg-green-100 text-green-800 border-green-400 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border capitalize")}>{record.status}</span>
         :
         <span className={classNames("bg-yellow-100 text-yellow-800 border-yellow-400 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border capitalize")}>{record.status}</span>
         }
         </>
      )
   }

   
   const columns = [
      {
         title: '#',
         dataIndex: '',
         key: 'items',
         width: 60,
         align: 'left',
         render: (tk,row,k) => <p className="text-sm">{k+1}</p>         
      },
      {
         title: 'Ticket',
         dataIndex: '',
         key: 'ticket',
         width: 150,         
         align: 'left',
         render: (tk) => <button onClick={() => openModal(tk)} className="inline-flex items-center gap-2 text-sm text-accent hover:text-accent-hover hover:underline">{tk.ticket_no}</button>
      },
      {
         title: 'Title',
         dataIndex: '',
         key: 'status',
         width: 300,         
         align: 'left',
         render: (tk) => <p className="text-sm">{tk.title}</p>         
      },
      {
         title: 'Status',
         dataIndex: '',
         key: 'status',
         width: 100,         
         align: 'left',
         render: statusBadge
         
      },
      {
         title: 'Start Date',
         dataIndex: '',
         key: 'date',
         width: 150,         
         align: 'left',
         render: (tk) => <p className="text-sm">{date_format(tk.created_at)}</p>
      },
   ]

   const onPagination = (page, limit) => {
      //console.log('page===',page, limit) 
      
      getMyTickets(page)
      setCurrentPage(page)

      window.scrollTo({
         top: 0,
         left: 0,
         behavior: "smooth"
      });
   }

   const closeModal = () => {
      setIsOpen(false)
   }
   const openModal = (item) => {
      //console.log(item)
      setTicket(item)
      setIsOpen(true)
   }

   return(
      <ScrollPageTop>
         <ReactSEOMetaTags
				render={(el: React.ReactNode) => <Helmet>{el}</Helmet>}
				website={{ ...metaData }}
			/>
         <LeftMenuLayout>
            <div style={{overflow: 'hidden'}} className={classNames('w-full py-5 bg-light shadow rounded')}>
					<h1 className="ml-6 text-lg text-heading sm:text-xl">
					My Service Tickets
					</h1>
               {
               totalRec > 0 ?   
               <div className="mt-6">
                  <Table 
                     columns={columns} 
                     data={tickets} 
                     className="orderDetailsTable w-full"
                     rowKey={(record:any) => record.cart_id}
                     scroll={{ x: 800 }}
                  />
                  <div className="mt-5 flex items-center justify-end">
                  {
                  totalRec > LIMIT &&
                  <Pagination
                     total={totalRec}
                     current={currentPage}
                     pageSize={LIMIT}
                     onChange={onPagination}
                  />
                  }
                  </div>
               </div>
               :
               <NoRecords message="CMC package not found :(" />
               }
            </div>
         </LeftMenuLayout>
         {
         ticket &&
         <ModalView open={isOpen} onClose={closeModal}>
            <ModalTicketView ticket={ticket} />
         </ModalView>
         }
         
      </ScrollPageTop>
   ) 
}

export default CmcTicket