import React, {useState, useEffect} from "react";
import {APP_URL, IMG_URL, amount_format,discount, showRating, rating_format} from '../components/config/Common'

import { BiRupee } from "react-icons/bi";
import { motion as ms } from 'framer-motion';
import {Links} from '../routes/PathLink'
import { Link } from "react-router-dom";
import SavePriceInd from '../ui/SavePriceInd'
import { BsCart4 } from "react-icons/bs";

const ProductCard = ({
   item,
   upcoming
}:props) => {

   const [user_type, setUser_type] = useState('')

    useEffect(() => {
      
         const user_type = localStorage.getItem('user_type');
         setUser_type(user_type);
       
    }, [])

   return(
      <Link to={`${Links.PRODUCT}/${item.product_slug}`}>
         <ms.article 
         layout
         initial={{ y: 10, opacity: 0 }}
         animate={{ y: 0, opacity: 1 }}
         exit={{ y: -10, opacity: 0 }}
         transition={{ duration: 0.5 }}
         className="group product-card cart-type-krypton h-full cursor-pointer overflow-hidden rounded border border-border-200 bg-light dark:bg-gray-700 dark:border-none transition-shadow duration-200 hover:shadow-sm">
            <div className="relative flex h-48 w-auto items-center justify-center sm:h-64">      
               <div className="absolute top-3 flex justify-start gap-2">

                  {
               item.is_featured == 1 && 
               <div class=" rounded bg-yellow-500 px-1.5 text-xs font-semibold leading-6 text-light ltr:left-3 rtl:right-3 sm:px-2 md:top-4 md:px-2.5 ltr:md:left-4 rtl:md:right-4">Featured</div>
               }
                {
               item.is_popular == 1 && 
               <div class=" rounded bg-[#7D87D4] px-1.5 text-xs font-semibold leading-6 text-light ltr:left-3 rtl:right-3 sm:px-2 md:top-4 md:px-2.5 ltr:md:left-4 rtl:md:right-4">Popular</div>
               }

               {
               item.is_verified == 1 && 
               <div class=" rounded bg-[#8D9557] px-1.5 text-xs font-semibold leading-6 text-light ltr:left-3 rtl:right-3 sm:px-2 md:top-4 md:px-2.5 ltr:md:left-4 rtl:md:right-4">Verified</div>
               }
               {
               item.is_upcoming == 1 && 
               <div class=" rounded bg-[#00A09D] px-1.5 text-xs font-semibold leading-6 text-light ltr:left-3 rtl:right-3 sm:px-2 md:top-4 md:px-2.5 ltr:md:left-4 rtl:md:right-4">Upcoming</div>
               }

               {
               item.in_stock == 0 && 
               <div class=" rounded bg-[#CB797B] px-1.5 text-xs font-semibold leading-6 text-light ltr:left-3 rtl:right-3 sm:px-2 md:top-4 md:px-2.5 ltr:md:left-4 rtl:md:right-4">Out Of Stock</div>
               }
               </div>
             
               
               {
               !upcoming &&
               <>
               {
               item.availability_status && item.availability_status !== 'available' ?
               <div class="absolute bg-dark text-center text-light w-full bg-opacity-50 text-md py-3 capitalize h-full flex items-center justify-center">
                  <span className={"bg-red-100 text-red-800 border-red-400 text-md font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border capitalize"}>{item.availability_status}</span>
               </div>
               :null
               }
               </>
               }
               
               {
                  item.product_image
                  ?
                  <img src={`${IMG_URL}${item.product_image}`} className="h-48 w-full sm:h-64 object-cover" alt={item.product_name} sizes="100vw" />
                  :
                  <img src={`${APP_URL}/img/no-image-available.png`} alt={item.product_name} sizes="100vw" />
               }
         
            </div>
            <header className="relative p-3 md:p-2 md:pb-3">
               <h3 role="button" className="mb-1 truncate text-base text-heading dark:text-zinc-400">{item.product_name}</h3>
               {/* <p className="text-xs text-muted">1pc(s)</p> */}
               
               <div className="relative flex items-center justify-between">
                  {
                     //let item.price.toLocaleString()
                  <div className="relative">
                     {user_type == 'dealer' && item.dealer_price &&  item.dealer_price > 0 ?
                        (<span className="text-sm text-accent md:text-base flex items-center">
                        {amount_format(item.dealer_price)}
                        </span>)
                        :
                        ( 
                        <>
                        {item.warranty_price && item.warranty_price > 0 ?
                        <><span className="text-sm text-accent md:text-base flex items-center">
                        {amount_format(item.warranty_price)}
                        </span>
                        {user_type != 'dealer' &&
                         <SavePriceInd max={item.sale_price} min={item.warranty_price} />
                        }
                        </>
                           :
                        <>
                        {item.dealer_price && item.dealer_price > 0 ?
                           <><span className="text-sm text-accent md:text-base flex items-center">
                           {amount_format(item.dealer_price)}
                           </span>
                           {user_type != 'dealer' &&
                            <SavePriceInd max={item.sale_price} min={item.dealer_price} />
                           }
                           </>
                           :
                            <><span className="text-sm text-accent md:text-base flex items-center">
                           {amount_format(item.price)}
                           </span>
                           {user_type != 'dealer' &&
                            <SavePriceInd max={item.sale_price} min={item.price} />
                           }
                           </>
                        }
                        </>
                        }
                        </>

                        )
                     }
                     
                  </div>
                  }
                  
               </div>
               
               
               {item.ratings ? <div className="flex text-xs items-center justify-start gap-1">
                     <div className="text-body">{rating_format(item?.ratings)}</div>
                     <div className="flex">{showRating(item.ratings, 18, '#ffb901')}</div> 
                     <div className="text-body">({item.total_reviews})</div>
                 </div>
                 :null
               }
               <div className="flex bg-light text-accent group-hover:bg-accent group-hover:text-light ease-in duration-300 rounded-full w-[43%] items-center justify-between mt-1">
                  <div className="uppercase text-xs ease-in duration-300 pl-1 group-hover:pl-2">Add to Cart</div>
                  <div className="relative -right-[1px] h-7 w-7 bg-accent text-light group-hover:bg-light group-hover:text-accent rounded-full flex items-center justify-center">
                     <BsCart4  />
                  </div>
                 </div>
               
            </header>
         </ms.article>
      </Link>
   )
}

export default ProductCard;