import React from "react";
import { Link } from "react-router-dom";

import LeftMenuLayout from '../layouts/LeftMenuLayout';

import classNames from 'classnames';
import {HeartOutlineIcon, EyeOff, Eye, Loading} from "../custom-icons";
import { Formik } from 'formik';
import * as Yup from 'yup';
import {API_ENDPOINT, toast_anim, toast_anim_error} from './config/Common'

import {APP_URL, APP_NAME} from './config/Common'
import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import ScrollPageTop from '../routes/ScrollPageTop'

const SignupSchema = Yup.object().shape({
	old_password:Yup.string()
		.required('You must need to provide your current password.'),
	password: Yup.string()
		.min(8, 'The password must be at least 8 characters.')
		.required('You must need to provide your new password.'),
	password_confirmation: Yup.string()
		.required('You must need to provide your confirm password.')
		.oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export default class ChangePassword extends React.Component {
	constructor(){
		super();
		this.state ={
			width:window.innerWidth,
			productsImg:[],
			openModal:false,
			thumbsSwiper:null,
			show:false,
			access_token:localStorage.getItem('access_token'),
			success:false,
			message:'',
			loading:false
		}
	}

	componentDidMount = () => {
		const access_token = localStorage.getItem('access_token');
		this.setState({
			access_token: access_token
		})
	}
	
	render(){
		const {show} = this.state
		const siteMetadata = {
			url: `${APP_URL}/change-password`,
			title:  `Change Password - ${APP_NAME}`,
			description: `Change Password ctmri`,
		}
      return (
         <ScrollPageTop>
			<ReactSEOMetaTags
				render={(el: React.ReactNode) => <Helmet>{el}</Helmet>}
				website={{ ...siteMetadata }}
				breadcrumb={[
					{ title: 'Home', url: `${APP_URL}` },
					{ title: 'Change Password', url: `${APP_URL}/change-password` },
				]}
			/>
         <LeftMenuLayout>

            <div className={classNames('w-full p-5 md:p-8 bg-light shadow rounded')}>
					<h1 className="mb-5 text-lg font-semibold text-heading sm:mb-8 sm:text-xl">
					Change Password
					</h1>
					<Formik
					initialValues={{
							old_password:'',
							password_confirmation: '',
							password: '',
					}}
					validationSchema={SignupSchema}
					onSubmit={(values, { setErrors, setSubmitting, resetForm }) => {
						console.log(values)
						this.setState({
							loading:true,
							success: false,
							message:'',
						})
						fetch(`${API_ENDPOINT}/change_password`,{
							method: 'POST',
							headers : {        
								'Content-Type': 'application/json',
								'Accept': 'application/json',
								'Authorization': 'Bearer '+this.state.access_token, 
							},
							body: JSON.stringify(values)
						}).then(function (response) {
								return response.json();
						}).then( (result)=> {
							//console.log(result)
							this.setState({
								loading:false
							})
							if(result.status === 1){
								//console.log(result)
								setSubmitting(false)
								resetForm({values:''})
								
								toast_anim(result.message)
							}
							if(result.status === 0){
								if(result.error === 'not_same'){
									setErrors({password_confirmation:result.message})
								}
								if(result.error === 'old_pass'){
									setErrors({old_password:result.message})
								}
								
								//toast_anim_error(result.message)
							}
							//setErrors({phone_no:result.message})
						})
					}}
					>
					{({ values, errors, touched, handleSubmit, handleChange, isSubmitting }) => (
						<form onSubmit={handleSubmit}>
							<div className="mb-5">
								<div className="flex items-center justify-between mb-2">
									<label htmlFor="old_password" className="font-semibold text-sm text-body">Current Password</label>
								</div>
								<div className="relative">
									<input id="old_password" name="old_password" type={show ? 'text' : 'password'} className="py-3 ltr:pl-4 rtl:pr-4 ltr:pr-11 rtl:pl-11 w-full rounded appearance-none transition duration-300 ease-in-out text-heading text-sm focus:outline-none focus:ring-0 border border-border-base focus:border-accent" autoComplete="off" autoCorrect="off" autoCapitalize="none" spellCheck="false" aria-invalid="false" value={values.old_password} onChange={handleChange}/>
									<label htmlFor="old_password" className="absolute ltr:right-4 rtl:left-4 top-5 -mt-2 text-body cursor-pointer" onClick={() => this.setState({show:!show})}>
										{show ? (
											<EyeOff className="w-6 h-6" />
										) : (
											<Eye className="w-6 h-6" />
										)}
									</label>
									{
									errors.old_password && touched.old_password &&
									<p className="mt-2 text-xs text-red-500">{errors.old_password}</p>
									}
								</div>
							</div>
							<div className="mb-5">
								<div className="flex items-center justify-between mb-2">
									<label htmlFor="password" className="font-semibold text-sm text-body">Password</label>
								</div>
								<div className="relative">
									<input id="password" name="password" type={show ? 'text' : 'password'} className="py-3 ltr:pl-4 rtl:pr-4 ltr:pr-11 rtl:pl-11 w-full rounded appearance-none transition duration-300 ease-in-out text-heading text-sm focus:outline-none focus:ring-0 border border-border-base focus:border-accent" autoComplete="off" autoCorrect="off" autoCapitalize="none" spellCheck="false" aria-invalid="false" value={values.password} onChange={handleChange}/>
									<label htmlFor="password" className="absolute ltr:right-4 rtl:left-4 top-5 -mt-2 text-body cursor-pointer" onClick={() => this.setState({show:!show})}>
										{show ? (
											<EyeOff className="w-6 h-6" />
										) : (
											<Eye className="w-6 h-6" />
										)}
									</label>
									{
									errors.password && touched.password &&
									<p className="mt-2 text-xs text-red-500">{errors.password}</p>
									}
								</div>
							</div>
							<div className="mb-5">
								<div className="flex items-center justify-between mb-2">
									<label htmlFor="password_confirmation" className="font-semibold text-sm text-body">Confirm Password</label>
								</div>
								<div className="relative">
									<input id="password_confirmation" name="password_confirmation" type={show ? 'text' : 'password'} className="py-3 ltr:pl-4 rtl:pr-4 ltr:pr-11 rtl:pl-11 w-full rounded appearance-none transition duration-300 ease-in-out text-heading text-sm focus:outline-none focus:ring-0 border border-border-base focus:border-accent" autoComplete="off" autoCorrect="off" autoCapitalize="none" spellCheck="false" aria-invalid="false" value={values.password_confirmation} onChange={handleChange}/>
									<label htmlFor="password_confirmation" className="absolute ltr:right-4 rtl:left-4 top-5 -mt-2 text-body cursor-pointer" onClick={() => this.setState({show:!show})}>
										{show ? (
											<EyeOff className="w-6 h-6" />
										) : (
											<Eye className="w-6 h-6" />
										)}
									</label>
									{
									errors.password_confirmation && touched.password_confirmation &&
									<p className="mt-2 text-xs text-red-500">{errors.password_confirmation}</p>
									}
									
								</div>
							</div>
							<div className="flex">
							{
							!this.state.loading ?
							<button data-variant="normal" className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-0 h-12 ltr:ml-auto rtl:mr-auto" type='submit'>Submit</button>
							:
							<Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 ltr:ml-auto" />
							}
							</div>
						</form>
					)}
					</Formik>

				</div>
			</LeftMenuLayout>
         </ScrollPageTop>
      );
	}
};