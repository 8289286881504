import {
   date_format,
   amount_format,
   replaceWithBr,
} from '../config/Common'
import moment from 'moment';
import classNames from 'classnames';

const ModalTicketView = ({ticket, desc}:props) => {

   const statusBadge = (record: any) => {
      
      return(
         <>
         {
         record.status === 'open' ?
         <span className={classNames("bg-red-100 text-red-800 border-red-400 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border capitalize")}>{record.status}</span>
         :
         record.status === 'closed' ?
         <span className={classNames("bg-green-100 text-green-800 border-green-400 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border capitalize")}>{record.status}</span>
         :
         <span className={classNames("bg-yellow-100 text-yellow-800 border-yellow-400 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border capitalize")}>{record.status}</span>
         }
         </>
      )
   }

   const getMachineName = (record: any) => {
      return(
         <>
         {
         record.package &&
         <p className="text-sm">
            {
            record.package.machine.parent &&
            <span>{record.package.machine.parent.cat_name}, </span>
            }
            {record.package.machine.cat_name}
         </p>
         }
         </>
      )
   }

   return(
      <div className="flex h-full min-h-screen w-screen flex-col justify-center bg-light py-6 px-5 sm:p-8 md:h-auto md:min-h-0 md:max-w-[500px] md:rounded-xl">
         <div className="flex justify-center mb-4">
            <h1 className="text-2xl font-black text-accent">{ticket.ticket_no}</h1>
         </div>
         {
         ticket.amount > 0 && 
         <div class="mb-2">
            <span class="mb-1 block text-xl font-bold text-heading">Amount</span>
            <span class="text-xl text-body">{amount_format(ticket.amount)}</span>
         </div>
         }
         {
         ticket.package &&
         <div class="mb-2">
            <span class="mb-1 block text-sm font-bold text-heading">Machine</span>
            <span class="text-sm text-body">{getMachineName(ticket)}</span>
         </div>
         }         
         {
         ticket.brand_name &&
         <div class="mb-2">
            <span class="mb-1 block text-sm font-bold text-heading">Brand</span>
            <span class="text-sm text-body">{ticket.brand_name}</span>
         </div>
         }

         {
         ticket.model_name &&
         <div class="mb-2">
            <span class="mb-1 block text-sm font-bold text-heading">Model</span>
            <span class="text-sm text-body">{ticket.model_name}</span>
         </div>
         }

         {
         ticket.title && 
         <div class="mb-2">
            <span class="mb-1 block text-sm font-bold text-heading">Title</span>
            <span class="text-sm text-body">{ticket.title}</span>
         </div>
         }
         {
            ticket.description &&
            <div class="mb-2">
               <span class="mb-1 block text-sm font-bold text-heading">Description</span>
               <span class="text-sm text-body" dangerouslySetInnerHTML={{ __html: replaceWithBr(ticket.description) }} />
            </div>
         }
         <div class="mb-2">
            <span class="mb-1 block text-sm font-bold text-heading">Status</span>
            <span class="text-sm text-body">{statusBadge(ticket)}</span>
         </div>
         <div class="mb-2">
            <span class="mb-1 block text-sm font-bold text-heading">Start date</span>
            <span class="text-sm text-body">{date_format(ticket.created_at)}</span>
         </div>
         <div class="mb-2">
            <span class="mb-1 block text-sm font-bold text-heading">End date</span>
            <span class="text-sm text-body">{ticket.expireOn ? date_format(ticket.expireOn):date_format(moment(ticket.created_at).add(1, 'years'))}</span>
         </div>
         {
            desc &&
            <div class="mb-2">
               <span class="mb-1 block text-sm font-bold text-heading">Proposal Description</span>
               <span class="text-sm text-body" dangerouslySetInnerHTML={{ __html: replaceWithBr(desc) }} />
               
            </div>
         }
      </div>
   )
}

export default ModalTicketView