import React, {useState, useEffect} from "react";

import { Link } from "react-router-dom";
import {
   API_ENDPOINT, 
   toast_anim_error,
   toast_anim, 
   APP_NAME, 
   APP_URL,
   IMG_URL,
   amount_format,
   LIMIT,
   replaceWithBr,
   discount,
   machine_condition
} from '../components/config/Common'
import ScrollPageTop from '../routes/ScrollPageTop'
import LeftMenuLayout from '../layouts/LeftMenuLayout';
import {Links} from '../routes/PathLink'
import classNames from 'classnames';
import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import Card from '../ui/card.tsx'
import Table from 'rc-table';
import { BsPencilSquare, BsFillTrash3Fill, BsEyeFill } from "react-icons/bs";
import Pagination from '../_utils/pagination.jsx'
import ModalView from '../_utils/ModalView'
import NoRecords from '../components/NoRecords'
import {Loading} from '../custom-icons'
import PackageMessage from '../components/packages/PackageMessage';
const usertype = localStorage.getItem('user_type');


const ProductList = () => {

   const [metaData, setMetaData] = useState({
      url: `${APP_URL}${Links.MY_PRODUCTS}`,
      title:  `My products - ${APP_NAME}`,
      description:'My products'
   })


   const [accessToken, setAccessToken] = useState(localStorage.getItem('access_token'))
   const [product, setProduct] = useState(null)
   const [status, setStatus] = useState('all')
   const [totalRec, setTotalRec] = useState(null)
   const [currentPage, setCurrentPage] = useState(1)
   const [isOpen, setIsOpen] = useState(false)
   const [mProduct, setMProduct] = useState(null)
   const [process, setProcess] = useState(false)
   const [currentpkg, setCurrentpkg] = useState(null);
   useEffect(() => {
      getSellerProducts(currentPage)
      getCurrentPackage();
   },[currentpkg])

   const getCurrentPackage = () => {
      fetch(`${API_ENDPOINT}/delaer_current_subscription`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json', 
            'Authorization': `Bearer ${accessToken}`, 
         }
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         console.log('current Packages ======',result);
         setCurrentpkg(result?.data)       
      })
   }


   const getSellerProducts = (page) => {
      fetch(`${API_ENDPOINT}/get_seller_product?pg=${page}&limit=${LIMIT}`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json',
            'Authorization': `Bearer ${accessToken}`, 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log('product details======',result)
			setProduct(result.data)
         setTotalRec(result.totalRec)
         setProcess(true)         			
		})
   }

   const onPagination = (page, limit) => {
      //console.log('page===',page, limit) 
      
      getSellerProducts(page)
      setCurrentPage(page)

      window.scrollTo({
         top: 0,
         left: 0,
         behavior: "smooth"
      });
   }

   const OrderItemList = (record: any) => {
      return (
         <div className="flex items-center">
            <div className="flex flex-col overflow-hidden ltr:ml-4 rtl:mr-4">
               <div className="relative h-16 w-16 shrink-0 overflow-hidden rounded">
               {
               record.product_image
               ?
               <img
                  src={`${IMG_URL}${record.product_image}`}
                  className="h-full w-full object-contain"
                  alt={record.product_name}
               />
               :
               <img
                  src={`${APP_URL}/img/no-image-available.png`}
                  className="h-full w-full object-contain"
                  alt={record.product_name}
               />
               }
               </div>
               <div className="mb-1 flex space-x-1 rtl:space-x-reverse">
                  <Link to={`/product/${record.product_slug}`} className="inline-block overflow-hidden truncate text-sm text-body transition-colors hover:text-accent hover:underline">
                     {record.product_name}
                  </Link>
                  
               </div>
               {usertype == 'user' && 
                <span className="mb-1 inline-block overflow-hidden truncate text-sm text-accent">
                  <div>Price</div>
                  <div>{amount_format(record.price)}</div>
               </span>
               }
               {
               record.negotiable_price && record.negotiable_price > 0 &&  usertype == 'user' && 
               <span className="mb-1 inline-block overflow-hidden truncate text-sm text-yellow-600">
                  <div>Negotiable Price</div>
                  <div>{amount_format(record.negotiable_price)}</div>
               </span>
               }
               {usertype == 'dealer' && 
                <span className="mb-1 inline-block overflow-hidden truncate text-sm text-accent">
                  <div>Price without warranty</div>
                  <div>{amount_format(record.price)}</div>
               </span>
               }
               {
               record.warranty_price &&  usertype == 'dealer' && 
               <span className="mb-1 inline-block overflow-hidden truncate text-sm text-yellow-800">
                  <div>Price with warranty</div>
                  <div>{amount_format(record.warranty_price)}</div>
               </span>
               }
               {
               record.dealer_price && record.dealer_price > 0 && 
               <span className="mb-1 inline-block overflow-hidden truncate text-sm text-yellow-600">
                  <div>Dealer Price</div>
                  <div>{amount_format(record.dealer_price)}</div>
               </span>
               }
               <div className="text-center rounded bg-yellow-500 px-1.5 text-xs font-semibold leading-6 text-light sm:px-2 md:px-2.5 capitalize">{record.availability_status}</div>
               
            </div>
         </div>
      )
   }

   const statusBadge = (record: any) => {
      let sts = true
      return(
         <>
         <div>
         {
         record.status === 'inactive' ?
         <span className={classNames("bg-red-100 text-red-800 border-red-400 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border capitalize")}>{record.status}</span>
         :
         record.status === 'active' ?
         <span className={classNames("bg-green-100 text-green-800 border-green-400 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border capitalize")}>{record.status}</span>
         :
         <span className={classNames("bg-yellow-100 text-yellow-800 border-yellow-400 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border capitalize")}>{record.status}</span>
         }
         </div>
         <div>
         {
         record.isCOD === 1 ?
         <span className={classNames("bg-green-100 text-green-800 border-green-400 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border capitalize")}>COD</span>
         :
         <span className={classNames("bg-red-100 text-red-800 border-red-400 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border capitalize")}>No COD</span>
         }
         </div>
         </>
      )
   }

   const changeItemCod = (item) => {
      //console.log('item', item)
      fetch(`${API_ENDPOINT}/update_seller_product_COD/${item}`,{
			method: 'POST',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json',
            'Authorization': `Bearer ${accessToken}`, 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			//console.log('product cod details======',result)
			getSellerProducts(currentPage)
      
		})
   }

   const hideItemBookNow = (item) => {
      //console.log('item', item)
      fetch(`${API_ENDPOINT}/mark_out_of_stock/${item}`,{
			method: 'POST',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json',
            'Authorization': `Bearer ${accessToken}`, 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			//console.log('product cod details======',result)
			getSellerProducts(currentPage)
      
		})
   }

   const renderReview = (record: any) => {
      return(
         <>
         <div className="flex items-center justify-end">
            <div className="mx-2">
               <Link to={`${Links.MY_PRODUCTS}/edit/${record.p_id}`} className="cursor-pointer text-lg text-body transition-colors hover:text-accent" title="Edit">
                  <BsPencilSquare />
               </Link>
            </div>
            <div className="flex items-center mx-2">
               <button className="cursor-pointer text-lg text-body transition-colors hover:text-accent" title="View Details" onClick={() => openModal(record)}>
                  <BsEyeFill /> 
               </button>
               <span className="ml-2 text-body text-sm">{record.view_count}</span>
            </div>
         </div>
         </>   
      )
   }

   const showMachine = (record: any) => {
      return(
         <p className="text-sm">
            {
            record.categories.map((val, key) => (
               <div>{val.cat_name}</div>
            ))
            }
         </p>
      )
   }

   const showManufacture = (record: any) => {
      return(
         <>
         {record.brand_name && <p className="text-sm">Brand: {record.brand_name}</p>}
         {record.model_name && <p className="text-sm">Model: {record.model_name}</p>}
         {record.yom && <p className="text-sm">YOM: {record.yom}</p>}
         </>
      )
   }

   const columns = [
      {
         title: <span className="ltr:pl-20 rtl:pr-20">Product</span>,
         dataIndex: '',
         key: 'items',
         width: 150,
         ellipsis: true,
         align: 'left',
         render: OrderItemList,
       },
       {
         title: 'Category',
         dataIndex: '',
         key: 'pivot',
         align: 'left',
         width: 100,
         render: showMachine,
       },
       {
         title: 'Manufacture',
         dataIndex: '',
         key: 'pivot',
         align: 'left',
         width: 100,
         render: showManufacture,
       },
       {
         title: 'Condition',
         dataIndex: '',
         key: 'pivot',
         align: 'center',
         width: 100,
         render: (qty) => <p className="text-sm text-body capitalize">{machine_condition[qty.machine_condition]}</p>,
       },
       {
         title: 'Status',
         dataIndex: '',
         key: 'price',
         align: 'right',
         width: 100,
         render: statusBadge,
       },
       {
         title: 'Action',
         dataIndex: '',
         align: 'right',
         fixed: 'right',
         className:'bg-gray-50',
         width: 150,
         render:renderReview
       }
    ];

    const closeModal = () => {
      setIsOpen(false)
      
   }
   const openModal = (item) => {
      //console.log(item)
      setIsOpen(true)
      setMProduct(item)
   }

   return(
      <ScrollPageTop>
         <ReactSEOMetaTags
				render={(el: React.ReactNode) => <Helmet>{el}</Helmet>}
				website={{ ...metaData }}
			/>
         <LeftMenuLayout>
            {
            !process ?
            <div className="w-full h-96 flex items-center justify-center">
               <Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />
            </div>
            :
            <div style={{overflow: 'hidden'}} className={classNames('w-full py-5 bg-light shadow rounded dark:bg-gray-700')}>
               {usertype == 'dealer' &&  <PackageMessage /> } 
               <div className="flex items-center justify-between px-5">
                  <h1 className="text-lg text-heading sm:text-xl dark:text-body">
                  Products
                  </h1>
                  {currentpkg && currentpkg?.package_name != '' && totalRec >= 2 && usertype == 'dealer' ?
                     <></>
                     :
                    <Link to={Links.ADD_PRODUCT} data-variant="normal" className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-3 py-0 h-9 text-sm font-semibold">Add Product</Link>
                  }
               </div>
               {
               totalRec > 0 ?
               <div className="mt-6">
                  <Table 
                     columns={columns} 
                     data={product} 
                     className="orderDetailsTable w-full"
                     rowKey={(record:any) => record.p_id}
                     scroll={{ x: 650 }}
                  />
                  <div className="mt-5 flex items-center justify-end">
                  {
                  totalRec > LIMIT &&
                  <Pagination
                     total={totalRec}
                     current={currentPage}
                     pageSize={LIMIT}
                     onChange={onPagination}
                  />
                  }
                  </div>
               </div>
               :
               <NoRecords message={'You are not added any products yet!'} />
               }
            </div>
            }
         </LeftMenuLayout>
         {
         mProduct &&
         <ModalView open={isOpen} onClose={closeModal}>
            <div className="flex h-full min-h-screen w-screen flex-col justify-center bg-light py-6 px-5 sm:p-8 md:h-auto md:min-h-0 md:max-w-[500px] md:rounded-xl">
               <div className="flex justify-center mb-4">
                  <h1 className="text-2xl font-black text-accent">Product Details</h1>
               </div>
               <div className="mb-2 flex flex-wrap items-center">
                  <div className="w-32 p-2">
                  {
                     mProduct.product_image
                     ?
                     <img
                        src={`${IMG_URL}${mProduct.product_image}`}
                        className="h-full w-full object-contain"
                        alt={mProduct.product_name}
                     />
                     :
                     <img
                        src={`${APP_URL}/img/no-image-available.png`}
                        className="h-full w-full object-contain"
                        alt={mProduct.product_name}
                     />
                  }
                  </div>
                  {
                     mProduct.more_images.length > 0 && mProduct.more_images.map((item, index) => (
                        <div className="w-32 p-2" key={`img-${item.pimg_id}`}>
                        <img
                           src={`${IMG_URL}${item.image}`}
                           className="h-full w-full object-contain"
                           alt={mProduct.product_name}
                        />   
                        </div>
                     ))
                  }
                  
               </div>
               <div class="mb-2">
                  <span class="mb-1 block text-sm font-bold text-heading">Pricing</span>
                  <span class="text-sm text-body capitalize  flex items-center">Price: {amount_format(mProduct.price)}</span>
                  <div class="text-sm text-body capitalize flex items-center">Sale price: {amount_format(mProduct.sale_price)}</div>
                  {discount(mProduct.price, mProduct.sale_price) > 0 ?<div class="text-sm text-body capitalize flex items-center">Discount: {discount(mProduct.price, mProduct.sale_price)}%</div> : null}
               </div>
               <div class="mb-2">
                  <span class="mb-1 block text-sm font-bold text-heading">Product name</span>
                  <span class="text-sm text-body">{mProduct.product_name}</span>
               </div>
               <div class="mb-2">
                  <span class="mb-1 block text-sm font-bold text-heading">SKU</span>
                  <span class="text-sm text-body">{mProduct.sku}</span>
               </div>
               {
               mProduct.description &&
               <div class="mb-2">
                  <span class="mb-1 block text-sm font-bold text-heading">Description</span>
                  <span class="text-sm text-body" dangerouslySetInnerHTML={{ __html: replaceWithBr(mProduct.description) }} />
               </div>
               }
               <div class="mb-2">
                  <span class="mb-1 block text-sm font-bold text-heading">Category</span>
                  <span class="text-sm text-body">{showMachine(mProduct)}</span>
               </div>
               <div class="mb-2">
                  <span class="mb-1 block text-sm font-bold text-heading">Manufacturer</span>
                  <span class="text-sm text-body">{showManufacture(mProduct)}</span>
               </div>
               <div class="mb-2">
                  <span class="mb-1 block text-sm font-bold text-heading">Condition</span>
                  <span class="text-sm text-body capitalize">{mProduct.machine_condition}</span>
               </div>
               <div class="mb-2">
                  <span class="mb-1 block text-sm font-bold text-heading">Availability</span>
                  <span class="text-sm text-body capitalize">Quantity: {mProduct.quantity}</span>
                  <div class="text-sm text-body capitalize">In Stock: {mProduct.quantity > 0 ? <>Yes</> : <>No</>}</div>
               </div>
               
            </div>
         </ModalView>
         }
      </ScrollPageTop>
   )
}

export default ProductList;