import React, {useState, useEffect, useRef , Fragment} from "react";

import { Link , useParams, useNavigate } from "react-router-dom";
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';


import {
	API_ENDPOINT, 
	replaceWithBr, 
	toast_anim, 
	toast_anim_error, 
	APP_URL, 
	APP_NAME,
	IMG_URL, 
	amount_format,
	discount,
	machine_condition,
	showRating,
	rating_format
} from './config/Common'
import classNames from 'classnames';
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Counter from '../layouts/Counter';
import {Dialog, Popover , Transition} from '@headlessui/react'


// import Swiper core and required modules
import { Navigation, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import InnerImageZoom from 'react-inner-image-zoom';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';

import {HeartOutlineIcon, HeartFillIcon, ChevronLeft, ChevronRight, Loading} from "../custom-icons";

import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'

import ScrollPageTop from '../routes/ScrollPageTop'
import RelatedProducts from './RelatedProducts'

import {Links} from '../routes/PathLink'
import SavePrice from '../ui/SavePrice'

import {useAuth} from '../provider/AuthProvider'
import Button from '../ui/Button.tsx'
import {useCart} from '../cart/cart-utils'
import Seo from './seo/seo'
import NegotitateSend from './products/NegotitateSend';
import ProductRatings from './ProductRatings';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

// product gallery breakpoints
const galleryCarouselBreakpoints = {
	320: {
	  slidesPerView: 2,
	},
	480: {
	  slidesPerView: 3,
	},
	640: {
	  slidesPerView: 3,
	},
	800: {
	  slidesPerView: 4,
	},
  };


const ProductDetails = () => {	
	const navigate = useNavigate();
	const [productsImg, setProductsImg] = useState([])	
	const [thumbsSwiper, setThumbsSwiper] = useState(null);
	const [openrefund , setOpenrefund] = useState(false);
	const [product, setProduct] = useState(null)	
	const [cat_arr, setCatArr] = useState([])	
	const [allowqtychnage, setAllowqtychnage] = useState(false);	
	const [descExpand, setDescExpand] = useState(true)	
	const [price_type , setPriceType] = useState('default');
	const [inWishlist, setInWishlist] = useState(false);
	const [isOpen , setIsOpen] = useState(false);	
	
	const [quantity, setQuantity] = useState(0)
	const [invoice] = useState(localStorage.getItem('rand_num'))
	const prevRef = useRef(null);
	const nextRef = useRef(null);
	const myRef = useRef(null);
	const {slug} = useParams()
	const { isUser, loginToken } = useAuth();
	const { addCartItem, cartItem, updateItemQty, deleteCartItem, checkItemInCart, itemCartQty } = useCart();
	
	const [metaTitle, setMetaTitle] = useState('')
	const [metaDesc, setMetaDesc] = useState('')
	const [user_type, setUser_type] = useState('')

	const outOfStock = false

	useEffect(() => {
		getProductDetail(slug)
		getProductsImg(slug)
		setTimeout(() => {
			window.scrollTo({
			  top: 0,
			  left: 0,
			  behavior: "smooth"
			});
		 }, 10);
	},[slug, loginToken])

	useEffect(() => {
		const user_type = localStorage.getItem('user_type');
      setUser_type(user_type);
		updateViewCount(slug)

	},[])

	

	const updateViewCount = (slug) => {
		fetch(`${API_ENDPOINT}/update_view_count/${slug}`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json',
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log('view count======',result.data)
		})
	}

	const bookNowProduct = (item) => {
		addCartItem(item,price_type)
		navigate(Links.CHECKOUT);
	}

	const getProductDetail = (slug) => {
		//console.log(user_id)

		fetch(`${API_ENDPOINT}/get_products_slug_front/${slug}`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${loginToken}`, 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log('products rating======',result.data)
			if(result.status === 1){
				setProduct(result.data)
				if(result?.data?.cat_id && result?.data?.cat_id != ''){
					let data_cat = result?.data?.cat_id.split();
					let allowquntychange = data_cat.find(obj => {
						//console.log("ALLOW chnage",obj);
				      if(obj == 22){
				      	return true;
				      }
				  });
					//console.log("ALLOW chnage ",allowquntychange);
					if(allowquntychange ){
						setAllowqtychnage(allowquntychange);
					}else{
						setAllowqtychnage(false);
					}
					
					
					//setCatArr(data_cat);
				}else{
					//setCatArr([]);
				}
				
				setInWishlist(result.inWishlist === 0 ? false : true)
				itemInCart(result.data.p_id)
				checkItemInCart(result.data.p_id)

				setMetaTitle(result.data.meta_title ? result.data.meta_title : result.data.product_name)
				setMetaDesc(result.data.meta_description ? result.data.meta_description : result.data.description)
			}else{
				navigate(Links.HOME);
			}
		})
	}

	const getProductsImg = (slug) => {

		fetch(`${API_ENDPOINT}/get_products_slug_front/image/${slug}`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			//console.log('products img======',result)
			setProductsImg(result.data)
		})
	
	}

	const itemInCart = (id) => {
		
      if(invoice){
         fetch(`${API_ENDPOINT}/is_product_in_cart/${id}?invoice=${invoice}`,{
            method: 'GET',
            headers : {
               'Content-Type': 'application/json',
               'Accept': 'application/json',
            }
         }).then(function (response) {
               return response.json();
         }).then( (result)=> {
            //console.log('insert data qty=====',result)
            if(result.status === 1){
               /* this.setState({
						quantity:result.data.quantity
					}) */
					setQuantity(result.data.quantity)
            }
         })
      }
   }

	const setThumbSwiper = (swiper) => {
		//console.log(swiper)
		setThumbsSwiper(swiper)
		
	}

	const addToWishlist = (pId) => {
		
		fetch(`${API_ENDPOINT}/add_product_to_wishlist`,{
			method: 'POST',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${loginToken}`, 
			},
			body: JSON.stringify({
				product_id:pId
			})
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			//console.log('data=====',result)
			//toast_anim(result.message)
			if(result.status === 1){
				setInWishlist(true)				
				toast_anim(result.message)
			}else{
				setInWishlist(false)				
				toast_anim_error(result.message)
			}
		})
	}

	/* 
	* call this fun from Header.js
	* when user login show wishlist icon
	*/
	const rerenderParentCallback = () => {		
		getProductDetail(slug)		
	}

	/* const descExpand = () => {
		setDescExpand(!descExpand)
	} */

	const addToCart = (item) => {
      const qty = Number(quantity) + 1
      setQuantity(qty)
      addCartItem(item,price_type)
	}

   const handleRemoveClick = async (item) => {
      console.log('itemCartQty', itemCartQty)
      let q = itemCartQty - 1;
      if(q <= 0){
         deleteCartItem(item.p_id)
      }else{
         updateItemQty(item.p_id, q)   
      }
   }
   const handleIncrement = (item) => {
      //console.log('Increment')
      updateItemQty(item.p_id, Number(itemCartQty)+1)
   }

   const closeModal = () =>{
   	setIsOpen(false);
   }

	return (
		<>
		<Seo 
         title={`${metaTitle} - ${APP_NAME}`}
         desc={metaDesc && metaDesc.substring(0, 200)}
         url={`${APP_URL}${Links.PRODUCT}/${slug}`}
         image={productsImg && `${IMG_URL}${productsImg[0]}`}
      />
		<Header rerenderParentCallback={rerenderParentCallback} />
		{
			product ?
			<div className="min-h-screen bg-light">
					<article className="rounded-lg bg-light">
				<div className="flex flex-col border-b border-border-200 border-opacity-70 md:flex-row">
					<div className="p-6 pt-10 md:w-1/2 lg:p-14 xl:p-16">
						<div className="mb-8 flex items-center justify-between lg:mb-10">
							{/* <button className="inline-flex items-center justify-center text-accent font-semibold transition-colors hover:text-accent-hover focus:text-accent-hover focus:outline-none">
								<BackArrowIcon className="w-5 h-5 ltr:mr-2 rtl:ml-2" />
								Back
							</button> */}
							{/*
							discount(product.price, product.sale_price) > 0 ?	
							<div className="rounded-full bg-accent px-3 text-xs font-semibold leading-6 text-light ltr:ml-auto rtl:mr-auto">
								{discount(product.price, product.sale_price)}%
							</div>
							:null
							*/}
						</div>
						<div className="product-gallery h-full">
							<div className="relative">
								<Swiper
								// install Swiper modules
								id="productGallery"
								modules={[Navigation, Thumbs]}
								spaceBetween={0}
								slidesPerView={1}
								thumbs={{swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null}}
								navigation={{
									prevEl: prevRef.current, // Assert non-null
									nextEl: nextRef.current, // Assert non-null
								}}
								>
								{
									productsImg ? productsImg.map((item, index) => (
									<SwiperSlide key={`normal-${index}`} className="flex items-center justify-center selection:bg-transparent">
										{/* <Zoom>
											<img src={`${IMG_URL}${item}`} width={450} height={450} alt={index} className="cursor-zoom-in" />
										</Zoom> */}
										{/*<Zoom
											img={`${IMG_URL}${item}`}
											zoomScale={3}
											width={450}
											height={350}
											/>
											*/}
											<InnerImageZoom src={`${IMG_URL}${item}`} zoomSrc={`${IMG_URL}${item}`} zoomType="hover" width={450}
											height={350} />
 					
										
									</SwiperSlide>
									))
									:
									<SwiperSlide className="flex items-center justify-center selection:bg-transparent">
										<img src={`${APP_URL}/img/no-image-available.png`} width={200} height={200} alt="" />
									</SwiperSlide>
								}								
								</Swiper>
								<div
								ref={prevRef}
								className="product-gallery-prev absolute top-2/4 z-10 -mt-4 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border border-border-200 border-opacity-70 bg-light text-heading shadow-xl transition-all duration-200 hover:bg-gray-100 ltr:-left-4 rtl:-right-4 md:-mt-5 md:h-9 md:w-9 ltr:md:-left-5 rtl:md:-right-5"
								>
									<ChevronLeft className="h-4 w-4" />
								</div>
								<div
								ref={nextRef}
								className="product-gallery-next absolute top-2/4 z-10 -mt-4 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border border-border-200 border-opacity-70 bg-light text-heading shadow-xl transition-all duration-200 hover:bg-gray-100 ltr:-right-4 rtl:-left-4 md:-mt-5 md:h-9 md:w-9 ltr:md:-right-5 rtl:md:-left-5"
								>
									<ChevronRight className="h-4 w-4" />
								</div>
							</div>
							<div className='relative mx-auto mt-5 max-w-md lg:mt-8 lg:pb-2'>
								<Swiper
									id="productGalleryThumbs"
									spaceBetween={20}
									watchSlidesProgress={true}
									freeMode={true}
									observer={true}
									observeParents={true}
									breakpoints={galleryCarouselBreakpoints}
									onSwiper={setThumbSwiper}
								>
								{
									productsImg && productsImg.map((item, index) => (
										<SwiperSlide key={`thumb-${index}`} className="flex cursor-pointer items-center justify-center overflow-hidden rounded border border-border-200 border-opacity-75 hover:opacity-75">
											<img src={`${IMG_URL}${item}`} width={80} height={80} alt={index}  />
										</SwiperSlide>
									))
								}
								</Swiper>
							</div>
						</div>

					</div>

					<div className="flex flex-col items-start p-5 pt-10 md:w-1/2 lg:p-14 xl:p-16">
						<div className="w-full">
							<div className="flex w-full items-start justify-between space-x-8 rtl:space-x-reverse">
								<h1 className="text-lg font-semibold tracking-tight text-heading md:text-xl xl:text-2xl capitalize">{product.product_name}</h1>
								{
								isUser &&
								<span>
									<button type="button" className={classNames(
										'mt-0.5 flex h-10 w-10 shrink-0 items-center justify-center rounded-full border border-gray-300 transition-colors ',
										{
											'!border-accent': inWishlist,
										},        
										)} onClick={() => addToWishlist(product.p_id)}>
										{
										inWishlist
										?
										<HeartFillIcon className="h-5 w-5 text-accent animate__animated animate__bounceIn" />
										:
										<HeartOutlineIcon className="h-5 w-5 text-accent animate__animated animate__bounceIn" />
										}
										
									</button>
								</span>
								}
							</div>
							{product.ratings ? 
							<div className="flex cursor-pointer justify-start items-center"
							onClick={()=>{
								setTimeout(() => { 
									myRef?.current.scrollIntoView( { behavior: 'smooth',block: "start",inline: "nearest",} )
       						}, 10);
   						}}
							>
								<span className="mr-1 text-body">{rating_format(product?.ratings)}</span> {showRating(product?.ratings, 18, '#ffb901')} <span className="ml-2 pointer-cursor text-accent text-sm">{product?.total_reviews} ratings</span>
							</div>
							:null
							}
							{
							product.sku && 							
							<div class="flex items-center justify-between mt-2"><span class="block text-sm font-normal text-body">SKU: {product.sku}</span></div>
							}
							{
							product.description && 
							<div className="mt-4 text-sm leading-7 text-body md:mt-4">
								
								
								{descExpand ? 
									product.description.substring(0, 200) 
								: 
									
									<span dangerouslySetInnerHTML={{__html:replaceWithBr(product.description)}} />
								} 

								{
									product.description.length >= 200 && 
									<span className="cursor-pointer text-orange-500 hover:text-accent" onClick={() => setDescExpand(!descExpand)}> [...{descExpand ? `more` : 'less'}] </span>
								}
							</div>
												
							}
							
							<div className="my-5 flex flex-col items-start">
							{ user_type == 'dealer' ?
								(<>
								  {product.dealer_price && product.dealer_price > 0 ?
									<><ins className="text-2xl font-semibold text-accent no-underline md:text-3xl flex items-center">
									{amount_format(product.dealer_price)}
									</ins>
									</>
									:
									<ins className="text-2xl font-semibold text-accent no-underline md:text-3xl flex items-center">
										{amount_format(product.price)}
									</ins>
									}
									{
										product.price_diffrence == 'different' && product.price && 
										<div className="mt-1">
										<span className="text-xl font-semibold text-accent no-underline flex items-center">
														                        <input id="default-checkbox" type="radio" value="without warranty" name="price_type" className="w-4 h-4 text-accent-600 bg-gray-100 border-gray-300 rounded focus:ring-accent-500 dark:focus:ring-accent-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" onChange={e => setPriceType('without warranty')} checked={price_type == 'without warranty' ? true : false}  />
 {amount_format(product.price)} <span className="text-sm ml-2 text-body font-normal">(Price without warranty)</span>
										</span>
										</div>
									} 
									{product.warranty_price && 
										<div className="mt-1">
										<span className="text-xl font-semibold text-accent no-underline flex items-center">
														                        <input id="default-checkbox" type="radio" value="with warranty" name="price_type" className="w-4 h-4 text-accent-600 bg-gray-100 border-gray-300 rounded focus:ring-accent-500 dark:focus:ring-accent-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" onChange={e => setPriceType('with warranty')} checked={price_type == 'with warranty' ? true : false}  />
 {amount_format(product.warranty_price)} <span className="text-sm ml-2 text-body font-normal">(Price with warranty {product?.warranty_for && product?.warranty_for > 0  && <span className="text-accent">for {product.warranty_for} Year</span>  })</span>
										</span>
										</div>
									} 
								</>
								)
								:
								<>

								{product.warranty_price && product.warranty_price > 0 ?
								<div className="flex"><ins className="text-2xl font-semibold text-accent no-underline md:text-3xl flex items-center">
									{amount_format(product.warranty_price)}
								</ins>
								{
								product.sale_price > 0 && product.warranty_price > 0 ? 
								<SavePrice max={product.sale_price} min={product.warranty_price} />
								:null
								}
								</div>
								:
								<>
								{product.dealer_price && product.dealer_price > 0 ?
									<div className="flex"><ins className="text-2xl font-semibold text-accent no-underline md:text-3xl flex items-center">
										{amount_format(product.dealer_price)}
									</ins>
									{
									product.sale_price > 0 && product.dealer_price > 0 ? 
									<SavePrice max={product.sale_price} min={product.dealer_price} />
									:null
									}
									</div>
									:
									<div className="flex"><ins className="text-2xl font-semibold text-accent no-underline md:text-3xl flex items-center">
										{amount_format(product.price)}
									</ins>
									{
									product.sale_price > 0 && product.price > 0 ? 
									<SavePrice max={product.sale_price} min={product.price} />
									:null
									}
									</div>
								}
								</>
								}

									{/*product.warranty_price && 
										<div className="mt-1">
										<span className="text-xl font-semibold text-accent no-underline flex items-center">
											<input id="default-checkbox" type="radio" value="with warranty" name="price_type" className="w-4 h-4 text-accent-600 bg-gray-100 border-gray-300 rounded focus:ring-accent-500 dark:focus:ring-accent-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" onChange={e => setPriceType('with warranty')} checked={price_type == 'with warranty' ? true : false}  />
 											{amount_format(product.warranty_price)} <span className="text-sm ml-2 text-body font-normal">(Price with warranty {product?.warranty_for && product?.warranty_for > 0  && <span className="text-accent">for {product.warranty_for} Year</span>  })</span>
										</span>
										</div>
									*/} 
								</>
								}

								{/*{ user_type == 'dealer' && 
								product.dealer_price && product.dealer_price > 0 ?
								<><ins className="text-2xl font-semibold text-accent no-underline md:text-3xl flex items-center">
									{amount_format(product.dealer_price)}
								</ins>
								
								</>
								:
								<ins className="text-2xl font-semibold text-accent no-underline md:text-3xl flex items-center">
									{amount_format(product.sale_price)}
								</ins>
								}*/}
								
								{/*
								product.sale_price && Number(product.sale_price) < Number(product.price) ?
								<del className="text-sm font-normal text-muted ltr:ml-2 rtl:mr-2 md:text-base flex items-center">
									{amount_format(product.price)}										
								</del>
								:null
								*/}
							</div>
							

							{/* {
							user_type == 'dealer' && product.availability_status === 'available' ?
								(<div className="mt-6 flex flex-col items-center md:mt-6 lg:flex-row">
									<div className="mb-3 w-full lg:mb-0 lg:max-w-[400px]">
										<div className="w-full col-span-2 flex items-center mb-4">
			                        <input id="default-checkbox" type="radio" value="default" name="price_type" className="w-4 h-4 text-accent-600 bg-gray-100 border-gray-300 rounded focus:ring-accent-500 dark:focus:ring-accent-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"  onChange={e => setPriceType('default')} checked={price_type == 'default' ? true : false} />
			                        <label for="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Default Price</label>
			                     </div> 
			                     {
										 product.price && 
			                     <div className="w-full col-span-2 flex items-center mb-4">
			                        <input id="default-checkbox" type="radio" value="without warranty" name="price_type" className="w-4 h-4 text-accent-600 bg-gray-100 border-gray-300 rounded focus:ring-accent-500 dark:focus:ring-accent-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" onChange={e => setPriceType('without warranty')} checked={price_type == 'without warranty' ? true : false}  />
			                        <label for="default-checkbox" className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Price without warranty</label>
			                     </div> 
			                  	}
			                     {product.warranty_price && 
			                     <div className="mt-2 w-full col-span-2 flex items-center mb-4">
			                        <input id="default-checkbox" type="radio" value="with warranty" name="price_type" className="w-4 h-4 text-accent-600 bg-gray-100 border-gray-300 rounded focus:ring-accent-500 dark:focus:ring-accent-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" onChange={e => setPriceType('with warranty')} checked={price_type == 'with warranty' ? true : false}  />
			                        <label for="default-checkbox" className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Price with warranty </label>
			                     </div> 
			                  	}
			                  </div>
		                 </div>)
		                 :
		                 null
							}
							*/}
							{
							product.availability_status === 'available' ?
							<div className="mt-6 flex flex-col items-center md:mt-6 lg:flex-row">
							 
								<div className="mb-3 w-full lg:mb-0 lg:max-w-[400px] ">
									{/* <AddToCart item={product} quantity={quantity} /> */}
									{
										itemCartQty <= 0
										?										
										<button className={classNames("flex w-full items-center justify-center rounded bg-accent py-4 px-5 text-sm font-semibold text-light transition-colors duration-300 hover:bg-accent-hover focus:bg-accent-hover focus:outline-none lg:text-base animate__animated",{'animate__flipOutX':itemCartQty >= 1 ? true : false, 'animate__flipInX':itemCartQty <= 0 ? true : false})} onClick={() => addToCart(product)}>
											<span>Add To Shopping Cart</span>
										</button>										
										:
										<>			
										{ allowqtychnage ?
										 (<Counter
											value={itemCartQty}
											onDecrement={() => handleRemoveClick(product)}
											onIncrement={() => handleIncrement(product)}
											variant="big"
											disabled={outOfStock}
											className={classNames("animate__animated",{'animate__flipInX':itemCartQty >= 1 ? true : false, 'animate__flipOutX': itemCartQty <= 0 ? true : false})}
										/>)
										:
										(<><p>Product Already in cart !</p></>)
										}							
										</>
									}

									
								{isUser && <button className={classNames(" mt-2 flex w-full items-center justify-center rounded bg-accent py-4 px-5 text-sm font-semibold text-light transition-colors duration-300 hover:bg-accent-hover focus:bg-accent-hover focus:outline-none lg:text-base animate__animated",{'animate__flipOutX':itemCartQty >= 1 ? true : false, 'animate__flipInX':itemCartQty <= 0 ? true : false})} onClick={() => setIsOpen(true)}>
										<span>Negotiate</span>
									</button>
								}
								</div>
								{
								isUser && itemCartQty <= 0  && 
								<div className="mb-3 w-full lg:mb-0 lg:max-w-[400px] lg:ml-5">
									<button className={classNames("flex w-full items-center justify-center rounded bg-accent font-semibold py-4 px-5 text-sm text-light transition-colors duration-300 hover:bg-accent-hover focus:bg-accent-hover focus:outline-none lg:text-base animate__animated")} onClick={() => bookNowProduct(product)}>
										<span>Book now</span>
									</button>
								</div>
								}
							</div>
							:
							product.availability_status ?
							<div className="text-xl text-red-500 mt-5">Product is {product.availability_status}</div>
							:
							<div className="text-xl text-red-500 mt-5">Upcoming</div>
							}
						</div>

						<Popover>
					      
					        <>
					        	<div className="mt-2">
					          <img className="border-0 outline-0 cursor-pointer" alt="Refund policy" onMouseEnter={() => setOpenrefund(true)}
onMouseLeave={() => setOpenrefund(false)} src={`${APP_URL}/img/refund_2366081.png`} title="Refund policy" />

					          {openrefund && (
					          <Transition
					            show={openrefund}
					            enter="transition duration-100 ease-out"
					            enterFrom="transform scale-95 opacity-0"
					            enterTo="transform scale-100 opacity-100"
					            leave="transition duration-75 ease-out"
					            leaveFrom="transform scale-100 opacity-100"
					            leaveTo="transform scale-95 opacity-0"
					          >
					            <Popover.Panel><div>
					            
					              <div><h3 className="text-2xl mb-2">Refund policy</h3>
<p>The product amount shall be refunded within 7-10 days, money will refunded for small equipment.</p>

<p>In case of big equipment no refund policy.</p></div>
					            </div>
					             </Popover.Panel>
					             </Transition>
					          )}
					          </div>
					        </>
					     
					    </Popover>
						{
						product.categories &&
						<div className="w-full mt-4 md:mt-6 pt-4 md:pt-6 flex flex-row items-start border-t border-border-200 border-opacity-60">
							<span className="text-sm font-semibold text-heading capitalize ltr:mr-6 rtl:ml-6 py-1">Categories</span>
							<div className="flex flex-row flex-wrap">
								{
								product.categories.map((val, key) => (
								<Link key={key} to={!val.parent ? `${Links.CATEGORY}/${val.slug}` : `${Links.CATEGORY}/${val.parent.slug}/${val.slug}`} className="text-sm text-heading tracking-wider whitespace-nowrap py-1 px-2.5 bg-transparent border border-border-200 rounded ltr:mr-2 rtl:ml-2 mb-2 transition-colors hover:border-accent hover:text-accent focus:outline-none focus:bg-opacity-100">{val.cat_name}</Link>
								))
								}
							</div>
							
						</div>
						}
						
					</div>
				</div>
				<div name="details" className="border-b border-border-200 border-opacity-70 px-5 py-4 lg:px-16 lg:py-14"><h2 className="mb-4 text-lg font-semibold tracking-tight text-heading md:mb-6">Details</h2>
					
					{product.description && <p className="text-sm text-body" dangerouslySetInnerHTML={{__html:replaceWithBr(product.description)}} />}
					
				</div>

				<div name="details" className="border-b border-border-200 border-opacity-70 px-5 py-4 lg:px-16 lg:py-14"><h2 className="mb-4 text-lg font-semibold tracking-tight text-heading md:mb-6">Specification</h2>
					<div className="font-normal text-body capitalize">
					{
					product.sku && 
						<div className="flex items-center">
							<div className="w-52 py-2">SKU</div>
							<div className="px-2">:</div>
							<div>{product.sku}</div>
						</div> 
					}
					{
					product.brand && 
						<div className="flex items-center">
							<div className="w-52 py-2">Manufacturer</div>
							<div className="px-2">:</div>
							<div>{product.brand.name}</div>
						</div> 
					}
					{
					product.model && 
						<div className="flex items-center">
							<div className="w-52 py-2">model</div>
							<div className="px-2">:</div>
							<div>{product.model.name}</div>
						</div> 
					}
					{
					product.tube_magnet_year && 							
						<div className="flex items-center">
							<div className="w-52 py-2">{product?.tube_magnet_year_label}</div>
							<div className="px-2">:</div>
							<div>{product.tube_magnet_year}</div>
						</div> 
					}
					{
					product.tube_count && 							
					
						<div className="flex items-center">
							<div className="w-52 py-2">{product?.tube_count_label}</div>
							<div className="px-2">:</div>
							<div>{product.tube_count}</div>
						</div> 
					}
					{
					product.yom && 					
						<div className="flex items-center">
							<div className="w-52 py-2">YOM (Year of Manufacture)</div>
							<div className="px-2">:</div>
							<div>{product.yom}</div>
						</div> 
					}
					{
					product.delivery_period && 							
						<div className="flex items-center">
							<div className="w-52 py-2">Availability period</div>
							<div className="px-2">:</div>
							<div>{product.delivery_period}</div>
						</div> 
					}
					{
					product.machine_condition && 
						<div className="flex items-center">
							<div className="w-52 py-2">Condition</div>
							<div className="px-2">:</div>
							<div>{machine_condition[product.machine_condition]}</div>
						</div> 
					}

					{
					product.accessories && 
						<div className="flex items-center">
							<div className="w-52 py-2">Accessories</div>
							<div className="px-2">:</div>
							<div>{product.accessories}</div>
						</div> 
					}

					{
					product.withwarrant == 'yes' && 
						<div className="flex items-center">
							<div className="w-52 py-2">With Warranty</div>
							<div className="px-2">:</div>
							<div>{product.withwarrant}</div>
						</div> 
					}

					{
					product.withinstalation == 'yes' && 
						<div className="flex items-center">
							<div className="w-52 py-2">With Instalation</div>
							<div className="px-2">:</div>
							<div>{product.withinstalation}</div>
						</div> 
					}
					
					
				</div>
				</div>
			</article>
			<div ref={myRef} className={`scroll-mt-32 border-t border-gray-100`}>
				<LazyLoadComponent>
				<ProductRatings product={product} product_id={product.p_id} />
				</LazyLoadComponent>
			</div>
				{product.p_id > 0 && 
					<RelatedProducts 
						currentProduct={product.p_id} 
						category={product.cat_id}	
					/>
				}
			
			</div>
			:
			<div className="w-full h-96 flex items-center justify-center">
				<Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />
			</div>
			
		}
		<Footer />

		<Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Negotiate
                  </Dialog.Title>
                  <NegotitateSend product={product} closeModal={closeModal} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

		</>
		);
	
};

export default ProductDetails