// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import {getAuth} from "firebase/auth"

// Your web app's Firebase configuration
const firebaseConfig = {
   apiKey: "AIzaSyDwS0BJh_ELbcyS0I8gzB2Qq250jgSnM5w",
   authDomain: "ctmri-e4f48.firebaseapp.com",
   projectId: "ctmri-e4f48",
   storageBucket: "ctmri-e4f48.appspot.com",
   messagingSenderId: "849434002078",
   appId: "1:849434002078:web:c0aa7a39c6c859af99718f"
 };
 

// Initialize Firebase
//const app = initializeApp(firebaseConfig);

//export const authentication = getAuth(app)
// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
let auth = firebase.auth();

const authentication = getAuth(app)
export {firebase , authentication, auth};