import React, {useState, useEffect} from "react";
import Header from '../../layouts/Header'
import Footer from '../../layouts/Footer'
import ScrollPageTop from '../../routes/ScrollPageTop'
import PasswordInput from '../../ui/password-input.tsx'
import Button from '../../ui/Button.tsx'
import {Links} from '../../routes/PathLink'

import * as Yup from 'yup';

import {
   Control,
   FieldErrors,
   useForm,
   useFormState,
   useWatch,
   Controller
} from 'react-hook-form';
 
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';  
import { Loading } from "../../custom-icons";
import { useParams, useNavigate } from "react-router-dom";
import {
   API_ENDPOINT,    
   toast_anim_error,
   toast_anim 
} from '../config/Common'
import {useAuth} from '../../provider/AuthProvider'

const SignupSchema = Yup.object().shape({
	password: Yup.string()
		.min(8, 'The password must be at least 8 characters.')
		.required('You must need to provide your new password.'),
	password_confirmation: Yup.string()
		.required('You must need to provide your confirm password.')
		.oneOf([Yup.ref("password"), null], "Passwords must match"),
});

const defaultValues = {  
   password: '',
   password_confirmation:'',
}

const ResetPassword = () => {
   const navigate = useNavigate();
   const [loading, setLoading] = useState(false)
   const [success, setSuccess] = useState(false)
   const [pageLoading, setPageLoading] = useState(true)
   const [tokenData, setTokenData] = useState(null)
   const { isUser } = useAuth();
   const { register, handleSubmit , control, reset, setValue, setError, formState: { errors },} = useForm({
      defaultValues: defaultValues,
      resolver: yupResolver(SignupSchema),
   });
   
   const {token} = useParams()

   useEffect(() => {
      if(isUser){
         return navigate(Links.HOME, { replace: true });
      }
   },[isUser])

   useEffect(() => {
      checkResetPasswordLink(token)
   },[])   

   const checkResetPasswordLink = (token) => {
      //console.log('token', token)
      
      axios.get(`${API_ENDPOINT}/check_password_token/${token}`,{
         headers: {
             'Content-Type': 'application/json',
         }
     }).then( (result)=> {
         console.log('result', result.data)
         setPageLoading(false)
         if(result.data.status === 1){
            setTokenData(result.data.data)
         }
     })
   }

   const onSubmit = (values) => {
      let input = values;
      input = {...input, token:token}
      setSuccess(false)
      setLoading(true)
      axios.post(`${API_ENDPOINT}/reset_password`,input,{
         headers: {
            'Content-Type': 'application/json',
         }
      }).then( (result)=> {
         setLoading(false)
         
         let data = result.data
         if(data.status === 1){
            toast_anim('Success: Password updated')
            setSuccess(true)
         }
         if(data.status === 2){
            toast_anim_error('Error: Link expired')
         }
      })
   }
   
   return(
      <ScrollPageTop>
         <Header />
         {
         !pageLoading 
         ?
         <div className="flex justify-center">
            <div className="flex h-full min-h-screen w-screen flex-col justify-center bg-light py-6 px-5 sm:p-8 md:h-auto md:min-h-0 md:max-w-[480px] md:rounded-xl my-5">
               <div className="flex justify-center">
                  <h1 className="text-xl font-black text-accent mb-5">Reset Password</h1>
               </div>
               {
               tokenData ?
               <form onSubmit={handleSubmit(onSubmit)}>
                  <PasswordInput                
                     label={`Password`}
                     {...register('password')}
                     variant="outline"
                     className="mb-5"                        
                     type='password'
                     error={errors.password?.message}
                     required
                  />
                  <PasswordInput                
                     label={`Confirm Password`}
                     {...register('password_confirmation')}
                     variant="outline"
                     className="mb-5"                        
                     type='password'
                     error={errors.password_confirmation?.message}
                     required
                  />
                  <Button 
                     className="w-full"
                     loading={loading}
                  >
                     Reset Password
                  </Button>
                  {success && <p className="my-5 text-sm text-start text-green-500">New password is set with <span className="text-body text-heading">{tokenData.email}</span>. Now you can login with new password.</p>}
               </form>
               :
               <p className="mt-4 mb-7 px-2 text-center text-sm leading-relaxed text-body sm:mt-5 sm:mb-10 sm:px-0 md:text-base">Link has expired</p>
               }
            </div>
         </div>
         :
         <div className="w-full h-96 flex items-center justify-center">
				<Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />
			</div>
         }
         <Footer />
      </ScrollPageTop>
   )
}

export default ResetPassword