import React, {useState} from "react";
import Button from '../../ui/Button.tsx'
import { FaFacebookF, FaTwitter, FaGoogle } from 'react-icons/fa';
import { signInWithPopup, FacebookAuthProvider, TwitterAuthProvider, GoogleAuthProvider } from "firebase/auth";
import {authentication} from '../../FirebaseConfig'
import ModalView from '../../_utils/ModalView'
import { Link, useNavigate } from "react-router-dom";
import {Links} from '../../routes/PathLink'
import {
   API_ENDPOINT, 
   toast_anim_error, 
} from '../config/Common'
import {deviceDetect} from "react-device-detect"
import {useAuth} from '../../provider/AuthProvider'

import DealerSocialSignup from './DealerSocialSignup'
import HospitalSocialLogin from './HospitalSocialLogin'

const SocialLogin = (props) => {
   const navigate = useNavigate();
   const [isOpen, setIsOpen] = useState(false)
   const [socialData, setSocialData] = useState(null)
   const [loading, setLoading] = useState(false);
   const { login } = useAuth();

   const closeModal = () => {
      setIsOpen(false)
   }

   const signupWithSocial = (values) => {
      
      setLoading(true)

      let input = values;
      input = {...input, device:deviceDetect()}

      //console.log(input)
      //return
      
      fetch(`${API_ENDPOINT}/login_social_user`,{
         method: 'POST',
         headers : {        
            'Content-Type': 'application/json',         
         },
         body: JSON.stringify(input)
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         //console.log(result)
         setLoading(false)
         if(result.status === 1){
            localStorage.setItem('access_token',result.access_token);
            localStorage.setItem('user_type',result.user_type);
            localStorage.setItem('user_id',result.user_id);
            localStorage.setItem('user_name',result.user_name);
            
            login({access_token:result.access_token, user_name:result.user_name})

            if(result.user_type === 'dealer'){
               return navigate(Links.AMC_BID, { replace: true });
            }else{
               props.setLogin()
               if(props.closeModal){
                  props.closeModal()
               }
            }
         }
         if(result.status === 0){
            if(props.handleView){
               if(props.user_type === 'customer'){
                  props.handleView('customerSocial')
               }else{
                  props.handleView('socialLogin')
               }
            }
            if(props.getSocialData){
               props.getSocialData(values)
            }
            setSocialData(values)
            setIsOpen(true)
            
         }
         
      })
   }

   const loginWithGoogle = () => {
      const provider = new GoogleAuthProvider();
		signInWithPopup(authentication, provider)
		.then((result) => {
			console.log('google result',result)
			let data = {
				name:result.user.displayName,
				email:result.user.email,
				provider:'google'
			}
         signupWithSocial(data)
		})
		.catch((error) => {
			//console.log(error.message)			
			showFirebaseError(error.message)
			//console.log('NewText====',firebase_error)
		})
   }

   const loginWithFacebook = () => {
		/* let data = {
			name:'Facebook k',
			email:'krunal.facebookhos@gmail.com',
			provider:'facebook'
		}
		signupWithSocial(data) */

		const provider = new FacebookAuthProvider();
		signInWithPopup(authentication, provider)
		.then((result) => {
			//console.log('facebook result',result)
			let data = {
				name:result.user.displayName,
				email:result.user.email,
				provider:'facebook'
			}
         signupWithSocial(data)
		})
		.catch((error) => {
			//console.log(error.message)			
			showFirebaseError(error.message)
			//console.log('NewText====',firebase_error)
		})
	}

   const loginWithTwitter = () => {
		/* let data = {
			name:'Twitter Hos',
			email:'krunal.twitterhos@gmail.com',
			provider:'twitter'
		}
		signupWithSocial(data) */
		
		const provider = new TwitterAuthProvider();
		signInWithPopup(authentication, provider)
		.then((result) => {
			console.log('twitter data',result)
			let data = {
				name:result.user.displayName,
				email:result._tokenResponse.email,
				provider:'twitter'
			}
			//console.log(data)

			signupWithSocial(data)
		})
		.catch((error) => {
			//console.log(error)
			showFirebaseError(error.message)
		})
	}

   const showFirebaseError = (err) => {
		let firebase_error = err.replaceAll(/Firebase|Error|:|auth|\/| /g, "");
		firebase_error = firebase_error.replace('(','');
		firebase_error = firebase_error.replace(')','');
		firebase_error = firebase_error.replaceAll('-',' ');		
		firebase_error =  firebase_error[0].toUpperCase() + firebase_error.slice(1)
      //console.log('firebase_error====',firebase_error)
      toast_anim_error(firebase_error)
	}

   return(
      <>
      <div className="relative mt-8 mb-6 flex flex-col items-center justify-center text-sm text-heading sm:mt-11 sm:mb-8">
         <hr className="w-full" />
         <span className="absolute -top-2.5 bg-light px-2 ltr:-ml-4">Sign in With Socials</span>
      </div>
      <div className="mt-2 grid grid-cols-2 gap-4">
         <Button
            className="!bg-social-google !text-light hover:!bg-social-google-hover"
            onClick={loginWithGoogle}
         >
           <FaGoogle className="mr-3" />
         </Button>
        {/* <Button
            className="!bg-social-facebook !text-light hover:!bg-social-facebook-hover"
            onClick={loginWithFacebook}
         >
           <FaFacebookF className="mr-3" />
         </Button>
         */}
         <Button
            className="!bg-social-twitter !text-light hover:!bg-social-twitter-hover"
            onClick={loginWithTwitter}
         >
           <FaTwitter className="mr-3" />
         </Button>
         
      </div>
      {props.user_type === 'dealer' &&
         <ModalView open={isOpen} onClose={closeModal}>
               <DealerSocialSignup social_data={socialData} />
         </ModalView>
      }
      
      </>
   )
}

export default SocialLogin