import React from "react";
import { Link } from "react-router-dom";

import LeftMenuLayout from '../layouts/LeftMenuLayout';

import classNames from 'classnames';
import {Loading, EmptyCartIcon} from "../custom-icons";
import { motion } from 'framer-motion';
import {FadeInOut} from './FadeInOut'
import {API_ENDPOINT, toast_anim, toast_anim_error, APP_URL, APP_NAME, IMG_URL, amount_format} from './config/Common'
import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import { BiRupee } from "react-icons/bi";
import ScrollPageTop from '../routes/ScrollPageTop'

export default class MyWishlist extends React.Component {
	constructor(){
		super();
		this.state ={
			width:window.innerWidth,
			access_token:localStorage.getItem('access_token'),
         wishlist:[],
         remove:false
		}
	}

	componentDidMount = () => {
		const access_token = localStorage.getItem('access_token');
		this.setState({
			access_token: access_token
		},() => {
         this.getMyWhishList()
      })
	}

   getMyWhishList = () => {
      fetch(`${API_ENDPOINT}/get_my_wishlist`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': 'Bearer '+this.state.access_token, 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log('wishlist======',result)
         if(result.status === 1){
            this.setState({
               wishlist:result.data
            })
         }
		})
   }
   addToWishlist = (pId) => {
		//alert(pId)
		fetch(`${API_ENDPOINT}/add_product_to_wishlist`,{
			method: 'POST',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': 'Bearer '+this.state.access_token, 
			},
			body: JSON.stringify({
				product_id:pId
			})
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			//console.log('data=====',result)
			//toast_anim(result.message)
			if(result.status === 0){
            setTimeout(() => {
               const filteredData = this.state.wishlist.filter((item) =>
                  item.product_id !== pId
               );
               this.setState({ wishlist: filteredData });
            }, 500);

				toast_anim(result.message)
            const filteredData = this.state.wishlist.filter((item, i) =>  {
               if(item.product_id === pId){
                   item.removed = true;
               }
               return item
           });
           this.setState({ wishlist: filteredData });

			}
		})
	}
	
	render(){
		const {show} = this.state
      const siteMetadata = {
			url: `${APP_URL}/my-wishlist`,
			title:  `My Wishlist - ${APP_NAME}`,
			description: `My Wishlist ctmri`,
		}
      return (
         <ScrollPageTop>
         <ReactSEOMetaTags
				render={(el: React.ReactNode) => <Helmet>{el}</Helmet>}
				website={{ ...siteMetadata }}
				breadcrumb={[
					{ title: 'Home', url: `${APP_URL}` },
					{ title: 'My Wishlist', url: `${APP_URL}/my-wishlist` },
				]}
			/>
         <LeftMenuLayout>
            <motion.div layout className={classNames('w-full p-5 md:p-8 bg-light shadow rounded')}>
					<h1 className="text-center text-lg font-semibold text-heading sm:text-xl">
					My Wishlist
					</h1>
               {
               this.state.wishlist.length > 0 ? this.state.wishlist.map((item, index) => (
                  <motion.div
                     layout
                     initial="from"
                     animate="to"
                     exit="from"
                     variants={FadeInOut(0.25)} 
                     key={`wishlist-${item.p_id}`} 
                     className={classNames('flex w-full items-start space-x-4 border-b border-gray-200 py-5 first:pt-0 last:border-0 last:pb-0 rtl:space-x-reverse sm:space-x-5 xl:items-center animate__animated ',{'animate__bounceOut':item.removed})}>
               
                  <div className="relative flex h-16 w-16 shrink-0 items-center justify-center border border-gray-200 sm:h-[74px] sm:w-[74px]">
                     {
                     item.product_image
                     ?
                     <img src={`${IMG_URL}${item.product_image}`} alt={item.product_name} />
                     :
                     <img src={`${APP_URL}/img/no-image-available.png`} alt={item.product_name} />
                     }
                  </div>

                  <div className="flex w-full flex-col items-start sm:flex-row sm:justify-between sm:space-x-4 rtl:sm:space-x-reverse xl:items-center">
                     <div className="flex w-full flex-col sm:items-start">
                        <Link
                           to={`/product/${item.product_slug}`}
                           className="text-lg font-semibold text-heading transition-colors hover:text-accent"
                        >
                           {item.product_name}
                        </Link>
                     </div>
                     <div className="mt-4 flex w-full flex-col justify-between space-y-3 xs:flex-row xs:space-y-0 sm:flex-col sm:justify-end sm:space-y-3 md:mt-0">
                        <span className="flex items-center sm:justify-end">
                           {
									item.sale_price && item.sale_price > 0 ?
                           <ins className="text-xl font-semibold text-heading no-underline flex items-center">
                           {amount_format(item.sale_price)}                           
                           </ins>
                           :
                           <ins className="text-xl font-semibold text-heading no-underline flex items-center">
                           {amount_format(item.price)}                           
                           </ins>
                           }
                           {
                           item.sale_price && Number(item.sale_price) < Number(item.price) ?
                           <del className="text-base font-normal text-muted ltr:ml-3 rtl:mr-3 flex items-center">
                              {amount_format(item.price)}
                           </del>
                           :null
                           }
                        </span>

                        <div className="flex items-center space-x-6 rtl:space-x-reverse sm:justify-end">
                           {/* <button className="whitespace-nowrap text-sm font-semibold text-accent hover:text-accent-hover hover:underline"><span>Add to Cart</span></button> */}
                           {/* <span className="flex h-7 w-px border-r border-dashed border-gray-300" /> */}
                           <button className="whitespace-nowrap text-sm font-semibold text-red-500 hover:underline sm:mt-0" onClick={() => this.addToWishlist(item.p_id)}>Remove</button>
                        </div>
                     </div>
                  </div>
                  </motion.div>
               ))
               :
               <motion.div
                     layout
                     initial="from"
                     animate="to"
                     exit="from"
                     className="flex h-full flex-col items-center justify-center pt-5 animate__animated animate__bounceIn"
                  >
                     <EmptyCartIcon width={140} height={176} />
                     <h4 className="mt-6 text-base font-semibold">
                     No products found
                     </h4>
               </motion.div>
               }
            </motion.div>
         </LeftMenuLayout>
         </ScrollPageTop>
      );
	}
};