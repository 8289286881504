import { CheckMark } from '../../custom-icons/checkmark.tsx'
import cn from 'classnames';
import Scrollbar from './scrollbar.tsx';
import styles from './progress-box.module.css';
import {
   date_format,
} from '../config/Common'

type ProgressProps = {
  data: any[] | undefined;
  status: string;
  filledIndex: number;
};

const ProgressBox: React.FC<ProgressProps> = ({
  status,
  data,
  filledIndex,
}) => {
  return (
    <Scrollbar
      className="h-full w-full"
      options={{
        scrollbars: {
          autoHide: 'never',
        },
      }}
    >
      <div
        className={`${
          // @ts-ignore
          data?.length <= 3 ? 'lg:justify-center' : ''
        } flex w-full flex-col py-7 md:flex-row md:items-start`}
      >
        {data?.map((item: any, index) => (
          <div
            className={styles.progress_container}
            key={`order-status-${index}`}
          >
            <div className="flex flex-col items-start ml-5 md:items-center mr-5 mb:mr-0 lg:mb-5">
               <span className="text-base capitalize text-body-dark ltr:text-left rtl:text-right md:px-2 md:!text-center">
               {date_format(item.trk_date)}
               </span>
            </div>
            <div
              className={cn(
                styles.progress_wrapper,
                index <= filledIndex ? styles.checked : ''
              )}
            >
              <div className={styles.status_wrapper}>
                {index <= filledIndex ? (
                  <div className="h-4 w-3">
                    <CheckMark className="w-full" />
                  </div>
                ) : (
                  <>{index+1}</>
                )}
              </div>
              <div className={styles.bar} />
            </div>

            <div className="flex flex-col items-start ltr:ml-5 rtl:mr-5 md:items-center ltr:md:ml-0 rtl:md:mr-0">
              {item && (
                <span className="text-base capitalize text-body-dark ltr:text-left rtl:text-right md:px-2 md:!text-center">
                  {item?.message}
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
    </Scrollbar>
  );
};

export default ProgressBox;
