
export const Links = {
   HOME:'/',
   LOGOUT:'/logout',
   CONTACT:'/contact',
   ABOUT:'/about',
   HELP:'/help',
   TERMS:'/terms-and-conditions',
   PRIVACY:'/privacy-policy',
   CHANGE_PASSWORD:'/change-password',
   PROFILE:'/profile',
   MY_WISHLIST:'/my-wishlist',
   ORDERS:'/orders',
   CHECKOUT:'/checkout',
   PRODUCT:'/product',
   CATEGORY:'/shop',
   AMS_TICKETS:'/amc-packages',
   DEALER_LOGIN:'/dealer-login',
   DEALER_SIGNUP:'/dealer-register',
   CREATE_AMS_TICKET:'/buy-amc-package',
   AMC_BID:'/amc-for-bidding',
   MY_PROPOSALS:'/my-proposals',
   CMS_TICKETS:'/cmc-tickets',
   ADD_PRODUCT:'/add-product',
   MY_PRODUCTS:'/my-products',
   RESET_PASSWORD:'/reset-password',
   TESTIMONIALS:'/testimonials',
   CARRIER:'/careers',
   UPCOMING_PRODUCTS:'/upcoming-products',
   FREE_CONSULTATION:'/free-consultation',
   WHYCTMRIHUB:'/why-ctmri-hub',
   AERBLICENSING:'/aerb-licensing',
   PARTSREPAIR:'/parts-repair',
   BLOG:'/blog',
   SHIPPINGPOLICY:'/shipping-policy',
   HIRECHAINPULLI:'/hire-chain-pulli-labourers',
   FEATURED_PRODUCTS:'/featured-products',
   POPULAR_PRODUCTS:'/popular-products',
   DEALER_SUBSCRIPTION_PLANS:'/dealer-subscriptions-plans'

}