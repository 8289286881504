
import {forwardRef} from "react";

const DatepickerInput = forwardRef(({ value, onClick }, ref) => {
  return(
    <div 
      className="px-4 h-12 flex items-center w-full rounded appearance-none transition duration-300 ease-in-out text-heading text-sm focus:outline-none focus:ring-0 dark:bg-gray-600 dark:border-gray-700 dark:text-zinc-400 border border-border-base focus:shadow focus:bg-light focus:border-accent dark:bg-gray-600" 
      onClick={onClick} 
      ref={ref}
    >
        {value}
    </div>
  )
});

export default DatepickerInput;