import React, {useState} from "react";
import {EyeOff, Eye, Loading} from "../../custom-icons";
//import { useAuth } from "../../provider/AuthProvider";
import { Formik } from 'formik';
import * as Yup from 'yup';
import {API_ENDPOINT} from '../config/Common'
import {useAuth} from '../../provider/AuthProvider'
import { Link } from "react-router-dom";
import {deviceDetect} from "react-device-detect"
const SignupSchema = Yup.object().shape({
	email: Yup.string()      
		.required('You must need to provide your email.'),
	password: Yup.string()
		.required('You must need to provide your password.'),
});

const LoginView = (props) => { 

	const [show, setShow] = useState(false);
	const [loading, setLoading] = useState(false);
	const { login } = useAuth();

	const handleView = () => {
		props.handleView('ForgotPassword')
	}
		
	return(
		<>		
		<div className="flex items-center justify-center mt-4 mb-5 sm:mt-5 sm:mb-5 md:text-base">
			<div className="text-sm text-body md:text-base mr-2">Login with your email & password</div>
		</div>
		<Formik
				initialValues={{
						email: '',
						password: '',
				}}
				validationSchema={SignupSchema}
				onSubmit={(values, { setErrors }) => {
					//login(values.email);
					let input = values;
        			input = {...input, device:deviceDetect(), user_type:props.user_type}

					setLoading(true)
					fetch(`${API_ENDPOINT}/customer_login`,{
								method: 'POST',
								headers : {        
										'Content-Type': 'application/json'
								},
								body: JSON.stringify(input)
						}).then(function (response) {
								return response.json();
						}).then( (result)=> {
							//console.log(result)
							setLoading(false)
							if(result.status === 1){
									localStorage.setItem('access_token',result.access_token);
									localStorage.setItem('user_type',result.user_type);
									localStorage.setItem('user_id',result.user_id);
									localStorage.setItem('user_name',result.user_name);
									localStorage.setItem('email',result.email);
									props.setLogin()
									login({access_token:result.access_token, user_name:result.user_name})
									
									//call from AMC ticket
									if(props.buyTicket){
										props.buyTicket();
									}
							}
							if(result.status === 0){
								setErrors({password:result.message})
							}
							//setErrors({phone_no:result.message})
						})
				}}
			>
			{({ values, errors, touched, handleSubmit, handleChange }) => (
			<form onSubmit={handleSubmit}>
				<div className="mb-5">
					<label htmlFor="email" className="block mb-3 text-sm font-semibold leading-none text-body-dark">Email</label>
					<input id="email" name="email" type="text" className="flex w-full appearance-none items-center px-4 text-sm text-heading transition duration-300 ease-in-out focus:outline-none focus:ring-0 border border-border-base rounded focus:border-accent h-12" autoComplete="off" autoCorrect="off" autoCapitalize="none" spellCheck="false" aria-invalid="false" value={values.email} onChange={handleChange} />
					{
							errors.email && touched.email &&
							<p className="mt-2 text-xs text-red-500">{errors.email}</p>
					}
				</div>

				<div className="mb-5">
					<div className="flex items-center justify-between mb-2">
						<label htmlFor="password" className="font-semibold text-sm text-body">Password</label>
						<button type="button" className="text-xs text-accent transition-colors duration-200 focus:outline-none focus:text-accent-700 focus:font-semibold hover:text-accent-hover" onClick={handleView}>Forgot password?</button>
					</div>
					<div className="relative">
						<input id="password" name="password" type={show ? 'text' : 'password'} className="py-3 ltr:pl-4 rtl:pr-4 ltr:pr-11 rtl:pl-11 w-full rounded appearance-none transition duration-300 ease-in-out text-heading text-sm focus:outline-none focus:ring-0 border border-border-base focus:border-accent" autoComplete="off" autoCorrect="off" autoCapitalize="none" spellCheck="false" aria-invalid="false" value={values.password} onChange={handleChange}/>
						<label htmlFor="password" className="absolute ltr:right-4 rtl:left-4 top-5 -mt-2 text-body cursor-pointer" onClick={() => setShow((prev) => !prev)}>

							{show ? (
								<EyeOff className="w-6 h-6" />
							) : (
								<Eye className="w-6 h-6" />
							)}
						</label>
						{
								errors.password && touched.password &&
								<p className="mt-2 text-xs text-red-500">{errors.password}</p>
								}
					</div>
				</div>

				<div className="mt-8 flex justify-center">
					{
					!loading
					?
					<button data-variant="normal" className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-0 h-12 h-11 w-full sm:h-12" >Login</button>
					:
					<Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600" />
					}
				</div>
			</form>
			)}
		</Formik>
		</>
	)
}

export default LoginView;