import React , {useState, useEffect}  from "react";
import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import {Links} from '../../routes/PathLink'
import {APP_URL, APP_NAME, API_ENDPOINT, IMG_URL} from '../config/Common'
import { Navigation, Pagination, Thumbs, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from "react-router-dom";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';
const testimonialBreakpoints = {
	320: {
		slidesPerView: 1,
	},
}

const bannerTemp = [
   {image:'slider_images/1681817173.jpg'},
   {image:'slider_images/1682598070.jpg'},
   {image:'slider_images/1681817173.png'},
]

const Banner = (props) => {

     const [bannerloading, setBannerloading] = useState(false)
      const [banner, setBanner] = useState(null)   
         useEffect(() => {
      get_page_banner()
   },[]);
   const get_page_banner = () => {
      setBannerloading(true);

      fetch(`${API_ENDPOINT}/get_page_banner`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json', 
         }
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         //console.log('slider======',result)
        setBannerloading(false);
         if(result.status === 1){
           setBanner(result.data);    
           //setBanner(bannerTemp)
         }
      })
     setBannerloading(false);
   }

   return(
      <>
      {
      banner &&
      <section class="relative ">
         <div className="h-[27.069vw] overflow-hidden">
         <Swiper
            // install Swiper modules
            modules={[Navigation, Thumbs, Pagination, Autoplay]}
            id="productGallery-122"
            watchSlidesProgress={true}
            freeMode={true}
            observer={true}
            observeParents={true}
            breakpoints={testimonialBreakpoints}
            pagination={{ clickable: true }}
            loop={true}
            autoplay={{
               delay: 5000,
               disableOnInteraction: false,
            }}
         >
         {
         banner.map((val, key) => (
            val?.image &&
           
            <SwiperSlide>
              <><Link {...(val?.link && val?.link != '' ? {to: val?.link} : {to: '#'})} aria-label=" Slider"  >
               <LazyLoadImage src={`${IMG_URL}/${val?.image}`} class="h-[27.069vw] w-full object-fit-cover " />
            </Link></></SwiperSlide>
         ))
         }
         </Swiper>
         </div>
         </section>
      }
      </>
   )
}

export default Banner