import React, {useState, useEffect ,useRef} from "react";
import Header from '../../layouts/Header'
import Footer from '../../layouts/Footer'
import ScrollPageTop from '../../routes/ScrollPageTop'
import {EyeOff, Eye, Loading} from "../../custom-icons";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import {
   Control,
   FieldErrors,
   useForm,
   useFormState,
   useWatch,
   Controller
 } from 'react-hook-form';
 
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';  
import {Links} from '../../routes/PathLink'
import {
   API_ENDPOINT, 
   random_str, 
   toast_anim_error, 
   RAZOR_KEY, 
   loadScript,
   register_valid,
   register_fee,
   renew_per,
   pdfdoconly,
   dealer_regOptions,
} from '../config/Common'
import Input from '../../ui/input.jsx'
import FileInput from '../../ui/file-input.jsx';

import PasswordInput from '../../ui/password-input.tsx'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import Button from '../../ui/Button.tsx'
import {useAuth} from '../../provider/AuthProvider'
import SelectInput from '../../ui/select-input.jsx'
import Label from '../../ui/label.tsx'
import Radio from '../../ui/radio/radio.tsx';
import Checkbox from '../../ui/checkbox/checkbox.tsx'
import {deviceDetect} from "react-device-detect"
import SocialLogin from './SocialLogin'
import { FaInfoCircle } from "react-icons/fa";


const SignupSchema = Yup.object().shape({
   name: Yup.string()
      .required('You must need to provide your company name.')
      .min(2, 'Name is too short!')
      .max(50, 'Name is too long!'),
   phone_no: Yup.string()
      .required('You must need to provide your phone number.')
      .min(12, 'Invalid phone number.'),
   email: Yup.string()
      .required('You must need to provide your email address.')
      .email('Invalid email.'),
   password: Yup.string()
      .required('You must need to provide your password.')
      .min(8, 'The password must be at least 8 characters.'),
   /* gst_no: Yup.string()
      .required('You must need to provide GST number.'),
   pcp_ndt_no: Yup.string()
      .required('You must need to provide PCPNDT number.'), */
   state_id:Yup.object().nullable().required('State is required.'),
   city_id:Yup.object().nullable().required('City is required.'),
   agree_to_pay:Yup.string().required("Package is required"),
   //agree_to_pay:Yup.bool().oneOf([true], 'Field must be checked.'),
   /*gst_certificate: Yup.array()
      .min(1, "You must need to upload GST Certificate")
      .required("You must need to upload GST Certificate"),
      */
});

const defaultValues = {  
   name: '',
   email:'',
   phone_no:'',
   password:'',
   gst_no:'',
   pcp_ndt_no:'',
   state_id:'',
   city_id:'',
   freelancer:'no',
   agree_to_pay:'1',
 };

const DealerRegister = () => {
   const navigate = useNavigate();
   const [loading, setLoading] = useState(false);
   const [states, setStates] = useState(null);
   const [registration_packages, setRegistration_packages] = useState(null);
   const [selectedplan, setSelectedplan] = useState(null);

   const [cities, setCities] = useState(false);
   const [isFreelancer, setIsFreelancer] = useState(false);
	const { login } = useAuth();
   const refphone = useRef(null);
   const { register, handleSubmit , control, reset, setValue, setError, formState: { errors },} = useForm({
      defaultValues: defaultValues,
      resolver: yupResolver(SignupSchema),
      shouldFocusError:false,
   });
   

   useEffect(() => {
      if(localStorage.getItem('access_token')){
         return navigate(Links.HOME, { replace: true });
      }
   })

   useEffect(() => {
      getState()
      getRegistrationPackage()
      reset(defaultValues)
   },[])

   const getState = () => {
      fetch(`${API_ENDPOINT}/get_states`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			//console.log('states======',result)
			setStates(result?.data)			
		})
   }


    const getRegistrationPackage = () => {
      fetch(`${API_ENDPOINT}/get_registration_packagelist`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json', 
         }
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         setRegistration_packages(result?.data?.packagelist);       
      });
   }

   const dealerSubscription = async (values) => {
      console.log("register click ",values)
      //return 

      const res = await loadScript(
          "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
         //alert("Razorpay SDK failed to load. Are you online?");
         toast_anim_error('Razorpay failed to load. Are you online?')
         return false;
      }

      if(values.freelancer === 'no'){
         if(!values.gst_no){
            setError('gst_no', { type: 'custom', message: 'You must need to provide GST number.' });
            
         }
         if(!values.pcp_ndt_no){
            setError('pcp_ndt_no', { type: 'custom', message: 'You must need to provide PCPNDT number.' });
         }

         if(!values.gst_no || !values.pcp_ndt_no){
            return false
         }
      }

      const validate =  await axios.post(`${API_ENDPOINT}/validate_dealer`,values)

      if(validate.data.status === 0){
         Object.entries(validate.data.message).forEach((item,ky) =>{
            if(item[0] === 'email'){                    
               setError('email', { type: 'custom', message: item[1] });
            }
            if(item[0] === 'phone_no'){
               setError('phone_no', { type: 'custom', message: item[1] });
            }
            if(item[0] === 'gst_no'){
               setError('gst_no', { type: 'custom', message: 'You must need to provide GST number.' });
            }
            if(item[0] === 'pcp_ndt_no'){
               setError('pcp_ndt_no', { type: 'custom', message: 'You must need to provide PCPNDT number.' });
            }
         })
         return false
      }

      //console.log(validate.data)

      

      let invoice = `DS_${random_str(10)}`;
      if(values?.agree_to_pay == 1){
          onSubmit(values); 
      }else{
      fetch(`${API_ENDPOINT}/razor_payment_order`,{
         method: 'POST',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json',
         },
         body: JSON.stringify({
            amount:(selectedplan?.package_amount)*100,
            invoice:invoice
         })
      }).then(function (response) {
         return response.json();
      }).then( (result)=> {
         console.log('invoice=====',result)
         var options = {
            "key": RAZOR_KEY, // Enter the Key ID generated from the Dashboard
            "amount": result.data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            "currency": "INR",
            "name": "CTMRI",
            "description": "Dealer Subscription",
            "image": "https://i.imgur.com/3g7nmJC.jpg",
            "order_id": result.data.order_id,
            "handler": function (response){
               console.log('rzp1 response', response)
               const payment = {
                  razorpay_payment_id: response.razorpay_payment_id,
                  razorpay_order_id: response.razorpay_order_id,
                  razorpay_signature: response.razorpay_signature,
                  amount: result.data.amount,
                  valid_upto:selectedplan?.valid_month,
               };
              /* const resultcap =  axios.post(`${API_ENDPOINT}/razor_payment_capture`, payment);
               if(resultcap?.data?.msg){
                   console.log(resultcap?.data?.msg);
                }else{
                    console.log(resultcap?.message);
                }
               */
               //console.log('razorpay data', data)
               const formData = {...values, payment}
               if(response.razorpay_payment_id){
                  onSubmit(formData)            
               }
            },
            "prefill": {
               "name": values.name,
               "email": values.email,
               "contact": values.phone_no
            },
            "theme": {
               "color": "#009f7f"
            }
         };

         const rzp1 = new window.Razorpay(options);
          rzp1.on('payment.failed', function (response){
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.order_id);
            alert(response.error.metadata.payment_id);
         }); 
         rzp1.open();
      })
      }
   }


   const onSubmit = async (values) => {
      console.log('on submit======',values)
      let input = values;
      input = {...input, device:deviceDetect(), provider:'manual',
            gst_certificate: values?.gst_certificate[0]?.newimageforserver,
      }

      setLoading(true)
      axios.post(`${API_ENDPOINT}/dealer_register`,input,{
         headers: {
            'Content-Type': 'multipart/form-data',
         }
      }).then( (result)=> {
         console.log('data=====',result.data)
         setLoading(false)
         let data = result.data;
         if(data.status === 1){
            localStorage.setItem('access_token',data.access_token);
            localStorage.setItem('user_type',data.user_type);
            localStorage.setItem('user_id',data.user_id);
            localStorage.setItem('user_name',data.user_name);
            localStorage.setItem('email',data.email);
            login({access_token:data.access_token, user_name:data.user_name})
            return navigate(Links.PROFILE, { replace: true });
         }
         if(data.status === 0){
            Object.entries(data.message).forEach((item,ky) =>{
               if(item[0] === 'email'){                    
                  setError('email', { type: 'custom', message: item[1] });
               }
               if(item[0] === 'phone_no'){
                  setError('phone_no', { type: 'custom', message: item[1] });
               }
               if(item[0] === 'gst_no'){
                  setError('gst_no', { type: 'custom', message: 'You must need to provide GST number.' });
               }
               if(item[0] === 'pcp_ndt_no'){
                  setError('pcp_ndt_no', { type: 'custom', message: 'You must need to provide PCPNDT number.' });
               }
            })
        }
      })
   }

   const handleSelect = (val,name) => {  
      console.log('cat val======',val,name.name)
      setError(name.name, null)
      setValue(name.name, val, true);
      if(name.name === 'state_id'){
         //setModels(null)
         getCitiesByState(val.id)
         setValue('city_id', null, true);
      }
   }

   const getCitiesByState = (state_id) => {
      fetch(`${API_ENDPOINT}/get_cities_by_state/${state_id}`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log('cities======',result)
			setCities(result.data)			
		})
   }

   const handleFreelancer = (e) => {
      //console.log('onchange', e.target.name, e.target.value)
      setValue('freelancer', e.target.value, true);
      if(e.target.value === 'yes'){
         setIsFreelancer(true)
      }else{
         setIsFreelancer(false)
      }
   }

    const handleRegPackage = (e) => {
      console.log('onchange', e.target.name, e.target.value)
      setValue('agree_to_pay', e.target.value, true);
      let currval = e.target.value;
        if (registration_packages) {
         registration_packages?.map((item, key) => {
               if(item?.id == currval){
                  setSelectedplan(item);
               }
         })
      }
     /* if(e.target.value === 'yes'){
         setIsFreelancer(true)
      }else{
         setIsFreelancer(false)
      }
      */
   }

   const handleChange = (e) => {
      console.log('onchange', e.target.name, e.target.checked)
   }

   return(
      <ScrollPageTop>
         <Header hidesearch={true}  hidebanner={true} />
         <div className="flex justify-center">
            <div className="flex h-full min-h-screen w-screen flex-col justify-center bg-light py-6 px-5 sm:p-8 md:h-auto md:min-h-0 md:max-w-[580px] md:rounded-xl my-5">
               <div className="flex justify-center">
               <h1 className="text-xl font-black text-accent">Dealer/Service Engineer/Bio Medical Register</h1>
               </div>         
               <p className="mt-4 mb-7 px-2 text-center text-sm leading-relaxed text-body sm:mt-5 sm:mb-10 sm:px-0 md:text-base">By signing up, you agree to our<Link to={Links.TERMS} className="mx-1 cursor-pointer text-accent underline hover:no-underline">terms</Link>&amp;<Link to={Links.PRIVACY} className="cursor-pointer text-accent underline hover:no-underline ltr:ml-1 rtl:mr-1">policy</Link></p>
               <form onSubmit={handleSubmit(dealerSubscription)}>
                  <Input                
                     label={`Company name`}
                     {...register('name')}
                     variant="outline"
                     className="mb-5"
                     error={errors.name?.message}
                     required
                  />                  
                  <div className="mb-5">
                     <label htmlFor="phone_no" className="block mb-3 text-sm font-semibold leading-none text-body-dark">Phone number <span className="text-red-500">*</span></label>
                     <Controller
                        name="phone_no"
                        control={control}
                        ref={refphone}
                        render={({ field }) => (
                           <PhoneInput
                              country="in"
                              disableDropdown
                              countryCodeEditable={false}
                              inputClass="!p-0 ltr:!pr-4 rtl:!pl-4 ltr:!pl-14 rtl:!pr-14 !flex !items-center !w-full !appearance-none !transition !duration-300 !ease-in-out !text-heading !text-sm focus:!outline-none focus:!ring-0 !border !border-border-base !rounded focus:!border-accent !h-12"
                              dropdownClass="focus:!ring-0 !border !border-border-base !shadow-350"
                              {...field}
                           />
                        )}
                     />
                     <p className="my-2 text-xs text-start text-red-500">{errors.phone_no?.message}</p>
                  </div>
                  <Input                
                     label={`Email`}
                     {...register('email')}
                     variant="outline"
                     className="mb-5"
                     type="email"
                     error={errors.email?.message}
                     required
                  />
                  <PasswordInput                
                     label={`Password`}
                     {...register('password')}
                     variant="outline"
                     className="mb-5"                        
                     type='password'
                     error={errors.password?.message}
                     required
                  />
                  <div className="mb-5">
                     <Label>Are you freelancer</Label>
                     <div className="flex items-center">
                     <Radio
                        {...register('freelancer')}
                        label="Yes"
                        id="yes"
                        value={'yes'}
                        className="mr-2"
                        onChange={handleFreelancer}
                     />
                     <Radio
                        {...register('freelancer')}
                        id="no"
                        label="No"
                        value={'no'}
                        onChange={handleFreelancer}
                     />
                     </div>
                  </div>
                  {
                  !isFreelancer &&
                  <>
                  <Input                
                     label={`GST Number`}
                     {...register('gst_no')}
                     variant="outline"
                     className="mb-5"
                     error={errors.gst_no?.message}
                     required
                  />
                  <div className="mb-5">
                     <label htmlFor="phone_no" className="block mb-3 text-sm font-semibold leading-none text-body-dark">GST Certificate <span className="text-red-500">*</span></label>
                     <FileInput 
                        name="gst_certificate" 
                        control={control} 
                        multiple={false} 
                        helperText='Upload GST Certificate'
                        help_ext='PDF, DOC, DOCX'
                        acceptFile={pdfdoconly}
                     />
                     <p className="my-2 text-xs text-start text-red-500">{errors.gst_certificate?.message}</p>
                  </div>
                  
                  <Input                
                     label={`PCPNDT/Drug license/Surgical license Number/ISO`}
                     {...register('pcp_ndt_no')}
                     variant="outline"
                     className="mb-5"
                     error={errors.pcp_ndt_no?.message}
                     required
                  />
                  </>
                  }

                  <div className="mb-5">
                     <Label>Registered As<span className="text-red-500"> *</span></Label>
                     <SelectInput
                        name="registered_as"                            
                        control={control}
                        getOptionLabel={(option: any) => option.label}
                        getOptionValue={(option: any) => option.id}                        
                        options={dealer_regOptions}
                        onChange={handleSelect}
                     />
                     <p className="my-2 text-xs text-start text-red-500">{errors.state_id?.message}</p>
                  </div>

                  <div className="mb-5">
                     <Label>State <span className="text-red-500"> *</span></Label>
                     <SelectInput
                        name="state_id"                            
                        control={control}
                        getOptionLabel={(option: any) => option.name}
                        getOptionValue={(option: any) => option.id}                        
                        options={states}
                        onChange={handleSelect}
                     />
                     <p className="my-2 text-xs text-start text-red-500">{errors.state_id?.message}</p>
                  </div>
                  <div className="mb-5">
                     <Label>City <span className="text-red-500"> *</span></Label>
                     <SelectInput
                        name="city_id"                            
                        control={control}
                        getOptionLabel={(option: any) => option.city}
                        getOptionValue={(option: any) => option.id}                        
                        options={cities}
                        onChange={handleSelect}
                     />
                     <p className="my-2 text-xs text-start text-red-500">{errors.city_id?.message}</p>
                  </div>
                  {/*<div className="mb-5">
                     <Checkbox 
                        name='agree_to_pay'
                        {...register('agree_to_pay')}
                        label={`Rs.${register_fee}/- Registration fee with extra 18% gst, Charges will be applicable for ${register_valid} months from the date of registration. Fee will increase by ${renew_per}% when renew.`}
                        theme='primary'
                        error={errors.agree_to_pay?.message}
                     />
                  </div>*/}
                  <div className="flex mb-5">
                  Know about plans <Link to={`${Links.DEALER_SUBSCRIPTION_PLANS}`} target="_blank" className="font-semibold text-accent underline transition-colors duration-200 hover:text-accent-hover hover:no-underline focus:text-accent-hover focus:no-underline focus:outline-none ltr:ml-1 rtl:mr-1"><FaInfoCircle /></Link>
                  </div>
                   <div className="grid auto-rows-max  grid-cols-2 items-start mb-5  w-full gap-2">
                      {
                        registration_packages && registration_packages?.map((item, key) => (
                           <div className="flex flex-col border-accent border-2 px-2 py-2 h-full "><Radio
                              {...register('agree_to_pay')}
                              label={item?.package_name}
                              id={item?.package_name}
                              value={item?.id}
                              className="mr-2"
                              onChange={handleRegPackage}
                           />
                           <div className="text-sm ml-4 pt-2">{item?.package_description}</div>
                           </div>
                        ))
                     }   
                    <p className="my-2 text-xs text-start text-red-500">{errors.agree_to_pay?.message}</p>
                  </div>
                  
                  <div>
                     <Button 
                        className="w-full"
                        loading={loading}
                     >
                     Register
                     </Button>
                  </div>
               </form>
               <SocialLogin mode='Register' user_type='dealer' />
               <div className="relative mt-8 mb-6 flex flex-col items-center justify-center text-sm text-heading sm:mb-8"><hr className="w-full" /><span className="absolute -top-2.5 bg-light px-2 ltr:left-2/4 ltr:-ml-4 rtl:right-2/4 rtl:-mr-4">Or</span></div>
               <div className="text-center text-sm text-body sm:text-base">Already have an account? <Link to={`${Links.DEALER_LOGIN}`} className="font-semibold text-accent underline transition-colors duration-200 hover:text-accent-hover hover:no-underline focus:text-accent-hover focus:no-underline focus:outline-none ltr:ml-1 rtl:mr-1">Login</Link></div>
               
            </div>
         </div>
         <Footer />
      </ScrollPageTop>
   )
}

export default DealerRegister