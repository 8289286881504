import React, {useState, useEffect, useRef , Fragment} from "react";
import { Link } from "react-router-dom";
import {Bed, Table, Sofa, Chair, Bucket, XrayIcon, MriIcon, CtScanIcon} from "../custom-icons";
import {API_ENDPOINT, APP_URL, APP_NAME, IMG_URL, amount_format,discount, LIMIT, replaceWithBr, showRating} from './config/Common'
import AOS from 'aos';
import 'aos/dist/aos.css';
import ScrollPageTop from '../routes/ScrollPageTop'
import {Links} from '../routes/PathLink'
import Card from '../ui/card.tsx'
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component';
import SavePriceInd from '../ui/SavePriceInd'
import { BsCart4 } from "react-icons/bs";

const HomeCategoryProduct = (props) => {
	const [products, setProducts] = useState(null)	
	const [user_type, setUser_type] = useState(localStorage.getItem('user_type'))	
	const [catInfo, setCatInfo] = useState(null)	
	//const user_type = localStorage.getItem('user_type');
	const {cat_id} = props
	useEffect(() => {
		//console.log('catid==', cat_id)
		getCateProductForHome(cat_id)
	},[])

	const getCateProductForHome = (cat_id) => {
		fetch(`${API_ENDPOINT}/get_cat_product_home/${cat_id}`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log('products length======',result.data)
			setProducts(result.data)
			setCatInfo(result.cat_info)
			//
		})
	}

	return (
		<>
			<div className="md:flex flex-1 bg-white-100 dark:bg-gray-900 py-10 px-5 items-center gap-5 border-b-2 border-accent">
				<div className="w-full md:w-1/4">
					<h1 className="text-accent text-4xl leading-[40px]">Explore our <span className="font-semibold">{catInfo?.cat_name}</span> Range</h1>
					<div className="text-body py-5 line-clamp-3">{catInfo?.description}</div>
					<Link to={`${Links.CATEGORY}/${catInfo?.slug}`} className="order-5 items-center justify-center rounded-full border-2 border-accent bg-accent px-3 py-2 text-sm font-semibold text-white transition-colors duration-300 hover:border-accent hover:bg-accent hover:text-light focus:border-accent focus:bg-accent focus:text-light focus:outline-none sm:order-4 sm:justify-start sm:px-5">
						<span>Know More</span>
					</Link>
				</div>
				<div className="w-full md:w-3/4 grid grid-cols-2 md:grid-cols-5 gap-3 pt-10 md:pt-0">
					{
						products && products.slice(0, 5).map((item, key) => (

								<Link to={`${Links.PRODUCT}/${item.product_slug}`} key={`product-${item.p_id}`}>
									<article className="group product-card cart-type-krypton h-full cursor-pointer overflow-hidden rounded border border-border-200 bg-light transition-shadow duration-200 hover:shadow-sm dark:bg-gray-700 dark:border-none">
										<div className="relative flex h-44 w-auto items-center justify-center sm:h-44">
											 <div className="absolute top-3 flex gap-3">
											 	{item.is_featured == 1 && 
													<div class=" rounded bg-yellow-500 px-1.5 text-xs leading-6 text-light ltr:left-3 rtl:right-3 sm:px-2 md:top-4 md:px-2.5 ltr:md:left-4 rtl:md:right-4">Featured</div>
												}
												{item.is_upcoming == 1 && 
													<div class="rounded bg-[#00A09D] px-1.5 text-xs leading-6 text-light ltr:left-3 rtl:right-3 sm:px-2 md:top-4 md:px-2.5 ltr:md:left-4 rtl:md:right-4">Upcoming</div>
												}
												{
							               item.is_popular == 1 && 
							               <div class=" rounded bg-[#7D87D4] px-1.5 text-xs leading-6 text-light ltr:left-3 rtl:right-3 sm:px-2 md:top-4 md:px-2.5 ltr:md:left-4 rtl:md:right-4">Popular</div>
							               }
							               {
								            item.is_verified == 1 && 
								            <div class="rounded bg-[#8D9557] px-1.5 text-xs leading-6 text-light ltr:left-3 rtl:right-3 sm:px-2 md:top-4 md:px-2.5 ltr:md:left-4 rtl:md:right-4">Verified</div>
								            }

               						 </div>
											{/*
											item.is_featured == 1 && 
											<div class="absolute top-3 rounded bg-yellow-500 px-1.5 text-xs font-semibold leading-6 text-light ltr:left-3 rtl:right-3 sm:px-2 md:top-4 md:px-2.5 ltr:md:left-4 rtl:md:right-4">Featured</div>
											*/}
											{/*
											item.is_upcoming == 1 && 
											<div class="absolute top-3 rounded bg-[#00A09D] px-1.5 text-xs font-semibold leading-6 text-light ltr:left-3 rtl:right-3 sm:px-2 md:top-4 md:px-2.5 ltr:md:left-4 rtl:md:right-4">Upcoming</div>
											*/}

											{
											item.availability_status && item.availability_status !== 'available' ?
											<div class="absolute bg-dark text-center text-light w-full bg-opacity-50 text-sm py-3 capitalize h-full flex items-center justify-center">
											<span className={"bg-red-100 text-red-800 border-red-400 text-sm mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border capitalize"}>{item.availability_status}</span>
											</div>
											:null
											}
											
											{
												item.product_image
												?
												<LazyLoadImage src={`${IMG_URL}${item.product_image}`} className="h-44 w-full sm:h-44 object-cover" alt={item.product_name} sizes="100vw" />
												:
												<LazyLoadImage src={`${APP_URL}/img/no-image-available.png`} alt={item.product_name} sizes="100vw" />
											}											
											

											
										</div>

										<header className="relative px-1 py-2 flex flex-col items-start justify-between gap-1">
											<h3 role="button" className="w-full truncate text-sm text-heading capitalize">{item.product_name}</h3>
											
											<div className="relative flex items-center justify-between">
												{
													//let item.price.toLocaleString()
												<div className="relative">
													
													{user_type == 'dealer' && item.dealer_price &&  item.dealer_price > 0 ?
						                        (<><span className="text-sm text-accent md:text-base flex items-center">
						                        {amount_format(item.dealer_price)}
						                        </span>
						                        </>
						                         )
						                        :
						                        (<>
						                        {item.warranty_price && item.warranty_price > 0 ?
						                         <><span className="text-sm text-accent md:text-base flex items-center">
						                        {amount_format(item.warranty_price)}
							                     </span>
							                     
							                     </>
							                     :
							                     <>
							                     {item.dealer_price && item.dealer_price > 0 ?
							                     <><span className="text-sm text-accent md:text-base flex items-center">
							                        {amount_format(item.dealer_price)}
							                     </span>
							                     
							                     </>
							                     :
							                     <>
							                     <span className="text-sm text-accent md:text-base flex items-center">
							                        {amount_format(item.price)}
							                     </span>
							                     
							                     </>
							                  	}
							                     </>
						                  		}
						                  	</>
						                     )
						                     
						                     }
						                     	
											</div>
												}
												
											</div>
											{item.ratings ? <div className="flex text-xs items-center justify-start gap-1">
						                     	<div className="flex">{showRating(item.ratings, 18, '#ffb901')}</div> 
						                     	<div>({item.total_reviews})</div>
						                    </div>
						                    :null
						                	}
						                    
											
						                    <div className="flex bg-light text-accent group-hover:bg-accent group-hover:text-light ease-in duration-300 rounded-full w-[70%] items-center justify-between">
						                    	<div className="uppercase text-xs ease-in duration-300 pl-1 group-hover:pl-2">Add to Cart</div>
						                    	<div className="relative -right-[1px] h-7 w-7 bg-accent text-light group-hover:bg-light group-hover:text-accent rounded-full flex items-center justify-center">
						                    		<BsCart4  />
						                    	</div>
						                    </div>
										</header>
									</article>
								</Link>
								))

							}
							</div>
			</div>

			
		</>
	)
}

export default HomeCategoryProduct;