
import {APP_URL, IMG_URL, amount_format,discount , truncate} from '../components/config/Common'

import { motion as ms } from 'framer-motion';
import {Links} from '../routes/PathLink'
import { Link } from "react-router-dom";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
const BlogPostCard = ({
   item,
   upcoming
}:props) => {

   return(
      <div className="bg-light drop-shadow-lg rounded-lg overflow-hidden border-2 border-accent hover:bg-accent hover:ease-in hover:duration-300">
        <Link to={item.link} target="_blank" className="group text-heading hover:text-white">
          <div>
            <div className="flex w-full">
              <div className="justify-center align-center w-full overflow-hidden">
              {item.img
                  ?
                  <img src={item.img} className="h-auto w-full md:h-[169px] object-cover ease-in duration-300 group-hover:scale-110" alt={item.title}  />
                  :
                  <img src={`${APP_URL}/img/no-image-available.png`} alt={item.title}   />
               }
              </div>
            </div>
          </div>
          <div className="mb-0 flex flex-row flex-wrap items-center justify-between gap-x-8 sm:flex-col lg:flex-nowrap">
            <div className="w-full px-2 py-2 h-14 overflow-hidden ">
              <span className="mb-2 block lg:mb-0 lg:inline-block lg:ltr:mr-4 lg:rtl:ml-4 font-bold">
               {item.title}
              </span>
           </div>
           <div className="px-2 py-2 w-full">{truncate(item.content,100,100)}</div>
           <div className="px-2 pb-5 w-full flex gap-1 items-center text-accent group-hover:text-white">Countinue Reading <MdKeyboardDoubleArrowRight size={20} /></div>
          <div>
        </div>
      </div>
      </Link>
    </div>
   )
}

export default BlogPostCard;