import React, {useState, useEffect} from "react";
import LeftMenuLayout from '../../layouts/LeftMenuLayout';
import ScrollPageTop from '../../routes/ScrollPageTop'
import Table from 'rc-table';
import Pagination from '../../_utils/pagination.jsx'
import moment from 'moment';
import classNames from 'classnames';
import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import Button from '../../ui/Button.tsx'
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
import { motion } from 'framer-motion';
import {
   API_ENDPOINT,
   APP_NAME, 
   APP_URL,
   date_format,
   amount_format,
   LIMIT,
   replaceWithBr 
} from '../config/Common'

import ModalView from '../../_utils/ModalView'
import {Links} from '../../routes/PathLink'
import {PlainIcon} from '../../custom-icons'
import NoRecords from '../NoRecords'

const AmcBid = (props) => {
   const [accessToken] = useState(localStorage.getItem('access_token'))
   const [tickets, setTickets] = useState(null)
   const [totalRec, setTotalRec] = useState(null)
   const [currentPage, setCurrentPage] = useState(1)
   const [isOpen, setIsOpen] = useState(false)
   const [ticket, setTicket] = useState(null)
   const [success, setSuccess] = useState(false)
   const [loading, setLoading] = useState(false)
   const [error, setError] = useState(false)   
   
   const [metaData, setMetaData] = useState({
      url: `${APP_URL}${Links.AMC_BID}`,
      title:  `AMC's for bidding - ${APP_NAME}`,
      description:`AMC's for bidding`
   })

   useEffect(() => {
      getBiddingAmc(currentPage)
   },[])

   const getBiddingAmc = (page) => {
      fetch(`${API_ENDPOINT}/get_bidding_amc?pg=${page}&limit=${LIMIT}`,{
         method: 'GET',
         headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+accessToken, 
         }
      }).then(function (response) {
         return response.json();
      }).then( (result)=> {
         console.log('ticket=====',result)
         setTickets(result.data)
         setTotalRec(result.totalRec)         
      })
   }

   const getMachineName = (record: any) => {
      return(
         <>
         {
         record.package &&
         <p className="text-sm">
            {
            record.package.machine.parent &&
            <span>{record.package.machine.parent.cat_name}, </span>
            }
            {record.package.machine.cat_name}
         </p>         
         }
         {
            record.model_name &&
            <p className="text-sm">{record.brand_name}, {record.model_name}</p>
         }
         </>
      )
   }

   const columns = [      
      {
         title: ' ',
         dataIndex: '',
         key: 'pivot',
         width: 120,         
         align: 'right',
         fixed: 'left',
         className:'bg-light',
         render: (tk) => {
            if(!tk.bid_id)
               return <Button onClick={() => openModal(tk)} size='smaller'>Bid Now</Button>
            else
               return <Button onClick={() => openModal(tk)} size='smaller'>Edit Bid</Button>
         }         
      },
      {
         title: 'Ticket',
         dataIndex: '',
         key: 'ticket',
         width: 150,         
         align: 'left',
         render: (tk) => <div className="text-sm">{tk.ticket_no}</div>
      },
      {
         title: 'Machine',
         dataIndex: '',
         key: 'status',
         width: 150,         
         align: 'left',
         render: getMachineName
         
      },
      {
         title: 'Condition',
         dataIndex: '',
         key: 'status',
         width: 100,         
         align: 'left',
         render: (tk) => <p className="text-sm capitalize">
            <div>{tk.conditions}</div>
            <div>YOM: {tk.yom}</div>
            </p>
      },      
      {
         title: 'Amount/year',
         dataIndex: '',
         key: 'amount',
         width: 150,         
         align: 'right',
         render: (tk) => <p className="flex justify-end text-sm">{amount_format(tk.amount)}</p>
      },
      {
         title: 'Bidding End',
         dataIndex: '',
         key: 'enddate',
         width: 150,         
         align: 'left',         
         render: (tk) => <p className="text-sm">{tk.expireOn ? date_format(tk.expireOn):moment(tk.created_at).add(1, 'years').format('MMMM DD, YYYY')}</p>,
         
      },
      
   ]

   const onPagination = (page, limit) => { 
      
      getBiddingAmc(page)
      setCurrentPage(page)

      window.scrollTo({
         top: 0,
         left: 0,
         behavior: "smooth"
      });
   }

   const closeModal = () => {
      setIsOpen(false)
      
   }
   const openModal = (item) => {
      //console.log(item)
      setTicket(item)
      setIsOpen(true)
      setSuccess(false)
      setError(false)
   }

   const ValidationSchema = Yup.object().shape({
      amount: Yup.number()
         .required('Bid amount is required.'),
   });

   const add_bid_proposal = (values) => {
      setLoading(true)
      setSuccess(false)
      setError(false)
      fetch(`${API_ENDPOINT}/add_bid_proposal`,{
         method: 'POST',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+accessToken, 
         },
         body: JSON.stringify(values)
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         //console.log(result)
         setLoading(false)
         if(result.status === 1){
            setSuccess(result.message)
            
            const filteredData = tickets.filter((item, i) =>  {
               if(item.ticket_id === values.ticket_id){
                  item.bid_amount = values.amount;
                  item.bid_description = values.description;
                  item.bid_id = result.data.bid_id
                  item.bid_date = date_format(new Date())
               }
               return item
            });
            setTickets(filteredData)
            setTimeout(() => {
               closeModal()
            },2000)
           
         }else{
            setError(result.message)
         }
      })
   }

   const CustomExpandIcon = (props) => {
      let text;
      if (props.expanded) {
         text = '&#8679; Hide bid';
      } else {
         text = '&#8681; Show bid';
      }
      //console.log('record', props.record);
      return (
         <>
         {
            props.record.bid_id &&
         <a
            className="inline-flex items-center gap-2 text-sm text-accent hover:text-accent-hover"
            onClick={e => {
            props.onExpand(props.record, e);
            }}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: text }}
         />
         }
         </>
      );
   }


const onExpand = (expanded, record) => {
  // eslint-disable-next-line no-console
  console.log('onExpand', expanded, record);
};

const rowRender = (record, index, indent, expanded) => {
   //expanded ? <p>extra: {record.bid_amount}</p> : null
   return(
      <>
         {
            expanded &&
            <div 
            className="mx-10 my-2 text-sm">
               <div className="flex items-start my-2">
                  <div className="w-[100px]">Bid amount</div>
                  <div className="px-2">:</div>
                  <div>{amount_format(record.bid_amount)}</div>
               </div>
               <div className="flex items-start my-2">
                  <div className="w-[100px]">Description</div>
                  <div className="px-2">:</div>
                  <div className="w-[72%] md:w-4/5" dangerouslySetInnerHTML={{ __html: replaceWithBr(record.bid_description) }} />
               </div>
               <div className="flex items-start my-2">
                  <div className="w-[100px]">Bid Date</div>
                  <div className="px-2">:</div>
                  <div>{date_format(record.bid_date)}</div>
               </div>
            </div>
         }
      </>
   )
}

   return (
      <ScrollPageTop>
         <ReactSEOMetaTags
				render={(el: React.ReactNode) => <Helmet>{el}</Helmet>}
				website={{ ...metaData }}
			/>
         <LeftMenuLayout>
            <div style={{overflow: 'hidden'}} className={classNames('w-full py-5 bg-light shadow rounded')}>
					<h1 className="ml-6 text-lg text-heading sm:text-xl">
					AMC Packages for bidding
					</h1>
            
            {
            totalRec > 0 ?
               <div className="mt-6">
                  <Table 
                     columns={columns} 
                     data={tickets} 
                     className="orderDetailsTable w-full"
                     rowKey={(record:any) => record.ticket_id}
                     scroll={{ x: 800 }}
                     expandable={{
                        columnTitle: ' ',
                        onExpand,
                        expandIcon: CustomExpandIcon,
                        expandedRowRender: rowRender,
                        columnWidth:120,
                        expandIconColumnIndex:7,
                     }}
                  />
                  <div className="mt-5 flex items-center justify-end">
                  {
                  totalRec > LIMIT &&
                  <Pagination
                     total={totalRec}
                     current={currentPage}
                     pageSize={LIMIT}
                     onChange={onPagination}
                  />
                  }
                  </div>
               </div>
            :
            <NoRecords message="AMC package are not available for bidding." />
            }
            </div>
         
         {
         ticket &&
         <ModalView open={isOpen} onClose={closeModal}>
            <div className="flex h-full min-h-screen w-screen flex-col justify-center bg-light py-6 px-5 sm:p-8 md:h-auto md:min-h-0 md:max-w-[500px] md:rounded-xl">
               <div className="flex justify-center mb-4">
                  <h1 className="text-2xl font-black text-accent">{ticket.ticket_no}</h1>
               </div>
               <div class="mb-2">
                  <span class="mb-1 block text-xl font-bold text-heading">Amount</span>
                  <span class="text-xl text-body">{amount_format(ticket.amount)}</span>
               </div>
               <div class="mb-2">
                  <span class="mb-1 block text-sm font-bold text-heading">Machine</span>
                  <span class="text-sm text-body">{getMachineName(ticket)}</span>
               </div>
               {
               ticket.brand_name &&
               <div class="mb-2">
                  <span class="mb-1 block text-sm font-bold text-heading">Brand</span>
                  <span class="text-sm text-body">{ticket.brand_name}</span>
               </div>
               }

               {
               ticket.model_name &&
               <div class="mb-2">
                  <span class="mb-1 block text-sm font-bold text-heading">Model</span>
                  <span class="text-sm text-body">{ticket.model_name}</span>
               </div>
               }
               
               <div>
               <Formik
                  initialValues={{                     
                     amount:ticket.bid_amount ? ticket.bid_amount : '',
                     description:ticket.bid_description ? ticket.bid_description : '',
                     ticket_id:ticket.ticket_id
                  }}
                  validationSchema={ValidationSchema}
                  onSubmit={(values, { setErrors, setSubmitting, resetForm }) => {
                     ///console.log(values)
                     add_bid_proposal(values)
                     //resetForm({values:''})
                  }}
               >
               {({ values, errors, touched, handleSubmit, handleChange, isSubmitting}) => (
                  <Form>
                     <div className='my-5'>
                        <label htmlFor="amount" className="block mb-3 text-sm font-semibold leading-none text-body-dark">Bid Amount</label>
                        <Field id="amount" name="amount" type="text" className="flex w-full appearance-none items-center px-4 text-sm text-heading transition duration-300 ease-in-out focus:outline-none focus:ring-0 border border-border-base rounded focus:border-accent h-12" />
                        {errors.amount && touched.amount &&
                        <p className="mt-2 text-xs text-red-500">{errors.amount}</p>
                        }
                     </div>
                     <div className='mb-5'>
                        <label htmlFor="description" className="block text-body-dark font-semibold text-sm leading-none mb-3">Description</label>
                        <Field as="textarea" id="description" name="description" className="px-4 py-3 flex items-center w-full rounded appearance-none transition duration-300 ease-in-out text-heading text-sm focus:outline-none focus:ring-0 border border-border-base focus:border-accent" rows="5" ></Field>
                        {errors.description && touched.description &&
                        <p className="mt-2 text-xs text-red-500">{errors.description}</p>
                        }
                     </div>
                     <div className="flex items-center">
                     
                        <Button 
                           size='small'
                           type="submit"
                           loading={loading}
                        ><PlainIcon className="mr-2" />{ticket.bid_id ? <>Update Proposal</> :<>Send Proposal</>}</Button>
                        
                        <div className="ml-5">
                        {success && <p className="text-xs text-green-500">{success}</p>}
                        {error && <p className="text-xs text-red-500">{error}</p>}
                        </div>
                     </div>
                  </Form>
               )}
               </Formik>
               </div>               
            </div>
               
         </ModalView>
         }
         </LeftMenuLayout>
      </ScrollPageTop>
   )
}

export default AmcBid;