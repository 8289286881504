import React, { createContext, useContext, useState, useEffect } from "react";
import {API_ENDPOINT} from '../components/config/Common'

const GSTRatesContext = createContext('CartContext');

export const GSTRatesProvider = ({children}) => {

   const [gstSaleRate, setGstSaleRate] = useState(0)
   const [gstServiceRate, setGstServiceRate] = useState(0)

   useEffect(() => {
      gst_rates()
   },[])

   const gst_rates = async () => {
   
      const response = await fetch(`${API_ENDPOINT}/get_gst_rates`)
      const result = await response.json();
      //console.log('gst rates',result.data);
      setGstSaleRate(result.data.gst_sales);
      setGstServiceRate(result.data.gst_services);
   }

   return <GSTRatesContext.Provider value={{ gstSaleRate, gstServiceRate }} >{children}</GSTRatesContext.Provider>

}

export const useGstRates = () => {
   const context = React.useContext(GSTRatesContext);
   if (context === undefined) {
      throw new Error(`useGstRates must be used within a GSTRatesProvider`);
   }
   return React.useMemo(() => context, [context]);
   //return context;
};