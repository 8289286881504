import { useEffect, useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import { AiFillCloseCircle } from "react-icons/ai";

const getPreviewImage = (value: any) => {
	let images: any[] = [];
	if (value) {
		images = Array.isArray(value) ? value : [{ ...value }];
	}
	return images;
};
const ACCEPTED_FILE_TYPES = "image/*,application/pdf,application/zip,application/vnd.rar,application/epub+zip,.psd"

export default function Uploader({
	onChange,
	value,
	multiple,
	acceptFile,
	helperText,
	help_ext,
}) {

	const [files, setFiles] = useState(getPreviewImage(value));
	const [error, setError] = useState(null);
	const [acceptFileList, setAcceptFileList] = useState(acceptFile);
		//console.log("ACC FILE",acceptFile);
	const { getRootProps, getInputProps } = useDropzone({
		...(acceptFile ? { accept: acceptFile } : { accept: ACCEPTED_FILE_TYPES }),
		multiple,
		onDrop: async (acceptedFiles) => {
			//console.log(acceptedFiles)
			if (acceptedFiles.length) {
				let data = [];
				let newdatafile = [];
				let newdatafile1 = [];
				acceptedFiles.map((file,idx) => {
					
					 let newimage =URL.createObjectURL(file);
							newdatafile1 = {
							'name':file.name,
							'newimageforserver':[
								file
							],
							'preview':URL.createObjectURL(file),
						};
					
					newdatafile=[newdatafile1,...newdatafile];
					
					let mergedData;
					if (multiple) {															 
						mergedData = files.concat(newdatafile);
						setFiles(mergedData);
					}else{
						mergedData = newdatafile;
						setFiles(newdatafile);
					}
					onChange(mergedData);
				})
			}
		},
		onDropRejected: (fileRejections) => {
			console.log("REJECT",fileRejections);
	      fileRejections.forEach((file) => {
	        file?.errors?.forEach((error) => {
	          if (error?.code === 'file-too-large') {
	            setError("File to large");
	          } else if (error?.code === 'file-invalid-type') {
	            setError("INVALID FILE TYEP");
	          }
	        });
	      });
	    },
	})

	const  extension = (fname) => {
		var pos = fname.lastIndexOf(".");
		var strlen = fname.length;
		if (pos != -1 && strlen != pos + 1) {
		  var ext = fname.split(".");
		  var len = ext.length;
		  var extension = ext[len - 1].toLowerCase();
		} else {
		  extension = "No extension found";
		}
		return extension;
	}

	const handleDelete = (image: string) => {
		const images = files.filter((file) => file.thumbnail !== image);
		setFiles(images);
		if (onChange) {
		  onChange(images);
		}
	};

	const thumbs = files.map((file,idx) => {

		//console.log("files",file);
		//console.log("files",idx);
		//return true;
		const imgTypes = [
			 'tif',
			 'tiff',
			 'bmp',
			 'jpg',
			 'jpeg',
			 'gif',
			 'png',
			 'eps',
			 'raw',
			 'svg',
			 'webp',
		  ];
		//console.log("FIEL RESPONSE ",file?.name);
		//return true;
		if(file?.name){
		let  ext = extension(file?.name);
		 const isImage = file?.name && imgTypes.includes(ext);
		return (
				<div
				  className={`relative mt-2 inline-flex flex-col overflow-hidden rounded me-2 ${
					 isImage ? 'border border-border-200' : ''
				  }`}
				  key={idx}
				>
				  {/* {file?.thumbnail && isImage ? ( */}
				  {isImage ? (					 
					 <figure className="relative h-16 w-28">
						<img
						  src={file?.preview}
						  alt={file?.name}
						  layout="fill"
						  objectfit="contain"
						  className="h-16 w-28"
						/>
					 </figure>
				  ) : (
					 <div className="flex flex-col items-center">
						<div className="flex h-14 w-14 min-w-0 items-center justify-center overflow-hidden">						  
						</div>
						<p className="flex cursor-default items-baseline p-1 text-xs text-body">
						  <span
							 className="inline-block max-w-[64px] overflow-hidden overflow-ellipsis whitespace-nowrap"
							 title={`${file.name}.${ext}`}
						  >
							 {file.name}
						  </span>
						  .{ext}
						</p>
					 </div>
				  	)}
					{multiple ? (
						<button
						className="absolute top-1 right-0 flex h-6 w-6 items-center justify-center rounded-full bg-red-600 text-xs text-light shadow-xl outline-none end-1"
						onClick={() => handleDelete(file.thumbnail)}
						>
						<AiFillCloseCircle color='red' size={35} />
						</button>
					) : null}
					
				</div>
			 );
		}
	 });

	useEffect(
		() => () => {
		  // Reset error after upload new file
		  setError(null);
  
		  // Make sure to revoke the data uris to avoid memory leaks
		  files.forEach((file: any) => URL.revokeObjectURL(file.thumbnail));
		},
		[files]
	 );

	return (

		<section className="upload">
			<div {...getRootProps({
					className:
						'border-dashed border-2 border-border-base h-36 rounded flex flex-col justify-center items-center cursor-pointer focus:border-accent-400 focus:outline-none',
				})}
			>
				<input {...getInputProps()} />
				<p className="mt-4 text-center text-sm text-body pb-5">
					{helperText ?
					<span className="font-semibold text-accent">
					{helperText}
					</span>
					:
					<span className="font-semibold text-accent">
					Upload an image
					</span>
					}
					{' '}
					or drag and drop <br />
					{
					help_ext	?
					<span className="text-xs text-body">{help_ext}</span>
					:
					<span className="text-xs text-body">PNG, JPG</span>
					}
				</p>
			</div>
			{thumbs}
			 {error && (
		          <p className="mt-4 text-center text-sm text-body text-red-600">
		            {error}
		          </p>
		        )}
		</section>
	)
}