import {Fragment, useRef, useState, useEffect} from "react";
import { Link } from "react-router-dom";
import classNames from 'classnames';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
import {API_ENDPOINT, toast_anim, toast_anim_error} from '../config/Common'
import {Loading, CloseIcon} from "../../custom-icons";
import { PlusIcon } from '@heroicons/react/24/solid'


import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { AiFillEdit } from "react-icons/ai";
import { IoTrashOutline } from "react-icons/io5";


import { toast } from 'react-toastify';

const ValidationSchema = Yup.object().shape({
	address_type: Yup.string()      
		.required('Type is required.'),
   
   country: Yup.string()
		.required('Country is required.'),
   city: Yup.string()
      .min(2, 'Too short')
		.required('City is required.'),
   state: Yup.string()      
		.required('State is required.'),
   zip: Yup.string()
      .min(4, 'Too short')
		.required('Zip is required.'),
   street_address: Yup.string()
      .min(2, 'Too short')
		.required('Street address is required.'),
});



const AddBillingAddress = (props) => {
   const [open, setOpen] = useState(false)
   const cancelButtonRef = useRef(null)
   const [success, setSuccess] = useState(false)
   const [loading, setLoading] = useState(false)   
   const [accessToken, setAccessToken] = useState(localStorage.getItem('access_token'))
   const [billingAddress, setBillingAddress] = useState(null);
   const [invoice, setInvoice] = useState(localStorage.getItem('rand_num'))
   const [sameAsBilling, setSameAsBilling] = useState(false)
   const [sameAsError, setSameAsError] = useState(false)

   useEffect(() => {
      getBillingAddress()
   },[])

   const getBillingAddress = () => {
      fetch(`${API_ENDPOINT}/get_billing_address/${invoice}?type=${props.type}`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${accessToken}`, 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			//console.log('billing address=======',result)
         setSameAsError(false)
         if(result.status === 1){
            setBillingAddress(result.data)
            if(props.type === 'billing'){
               props.setRequiredField('billing')
               props.getAddress(result.data)
            }
            if(props.type === 'shipping'){
               props.setRequiredField('shipping')
            }
         }

		})
   }

   const setShippingSame = () => {
      //console.log('billing address=======',sameAsBilling)
      fetch(`${API_ENDPOINT}/get_billing_address/${invoice}?type=billing`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${accessToken}`, 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			//console.log('billing address=======',result)
         if(result.status === 1){
            updateSameShipping()
         }else{
            setSameAsError('Billing is empty! Add billing address first.')
         }
      })
   }

   const updateSameShipping = () => {
      fetch(`${API_ENDPOINT}/update_sameas_billing/${invoice}`,{
			method: 'POST',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${accessToken}`, 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log('billing address=======',sameAsBilling)
         setOpen(false)
         if(result.status === 1){
            toast_anim(result.message)
            props.setRequiredField('shipping')
         }else{
            toast_anim_error(result.message)
         }
      })
   }

   const closeModal = () => {
      setOpen(false)
      //props.openLoginModal()
    }

   return (
   <>   
      <div className={classNames('w-full p-5 md:p-8 bg-light shadow rounded mb-8 mt-8')}>
         <div className="flex items-center justify-between mb-5 md:mb-8">
            <div className="flex items-center space-x-3 md:space-x-4 rtl:space-x-reverse">
               <span className="flex items-center justify-center w-8 h-8 text-base rounded-full bg-accent text-light lg:text-xl">{props.num}</span>
               <p className="text-lg lg:text-xl text-heading capitalize">{props.type} Address</p>
            </div>
            <button className="flex items-center text-sm font-semibold text-accent transition-colors duration-200 focus:outline-none focus:text-accent-hover hover:text-accent-hover" onClick={() => setOpen(true)}>
               <PlusIcon className="w-4 h-4 stroke-2 ltr:mr-0.5 rtl:ml-0.5" />
               {billingAddress ? <span>Update</span> : <span>Add</span>}
            </button>
         </div>

         <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2">
            {
            billingAddress &&
            <div className="group relative cursor-pointer rounded border p-4 hover:border-accent border-transparent bg-gray-100 mb-4">
               
               <p className="mb-3 text-sm font-semibold capitalize text-heading">{props.type}</p>
               <p className="text-sm text-sub-heading">{billingAddress.street_address}, {billingAddress.city}, {billingAddress.state}, {billingAddress.zip}, {billingAddress.country}</p>
            </div>
            }
         </div>
      </div>

      <Transition.Root show={open} as={Fragment} dir='ltr'>
      <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
               <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
               <div className="min-h-screen bg-light p-5 sm:p-8 md:min-h-0 md:rounded-xl">
                  <button aria-label="Close panel" className="absolute top-4 z-[60] inline-block outline-none focus:outline-none ltr:right-4 rtl:left-4 lg:hidden" onClick={closeModal}>
                     <CloseIcon className="h-4 w-4" />
                  </button>
                  <h1 className="mb-4 text-center text-lg font-semibold capitalize text-heading sm:mb-6">Add {props.type} Address {sameAsBilling}</h1>
                  
                  {
                     props.type == 'shipping'
                     &&
                     <>
                     
                      <div className="w-full col-span-2 flex items-center mb-4">
                        <input id="default-checkbox" type="checkbox" value={sameAsBilling} className="w-4 h-4 text-accent-600 bg-gray-100 border-gray-300 rounded focus:ring-accent-500 dark:focus:ring-accent-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" onChange={e => setSameAsBilling(!sameAsBilling)} checked={sameAsBilling ? true : false} />
                        <label for="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Same as billing</label>
                     </div> 

                     {
                     sameAsBilling &&
                        <>
                        {
                        !loading
                        ?
                        <button data-variant="normal" className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-0 h-12 col-span-2 w-full" type="submit" onClick={setShippingSame}>Submit</button>
                        :
                        <Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 ltr:ml-auto" />
                        }
                        {sameAsError &&
                        <p className="mt-2 text-xs text-red-500">{sameAsError}</p>
                        }
                        </>
                     }
                     </>
                  }
                  {
                  !sameAsBilling &&
                  <Formik
							initialValues={{
								address_type: props.type,
								country:billingAddress  ? billingAddress.country : '',
                        city:billingAddress ? billingAddress.city : '',
                        state:billingAddress ? billingAddress.state : '',
                        zip:billingAddress ? billingAddress.zip : '',
                        street_address:billingAddress ? billingAddress.street_address : ''
							}}
                     validationSchema={ValidationSchema}
                     onSubmit={(values, { setErrors, setSubmitting, resetForm }) => {
                        //resetForm({values:''})
                        //setSuccess(true)
                        //console.log(accessToken)
                        //openSnackbar('This is the content of the Snackbar.')
                        setLoading(true)
                        fetch(`${API_ENDPOINT}/update_order_address/${invoice}`,{
                           method: 'POST',
                           headers : {        
                              'Content-Type': 'application/json',
                              'Accept': 'application/json',
                              'Authorization': 'Bearer '+accessToken, 
                           },
                           body: JSON.stringify(values)
                        }).then(function (response) {
                              return response.json();
                        }).then( (result)=> {
                           //console.log('data=====',result)
                           setLoading(false)
                           if(result.status === 1){
                              setOpen(false)
                              toast_anim(result.message)
                              setBillingAddress(result.data)
                              if(props.type === 'billing'){
                                 props.setRequiredField('billing')
                                 props.getAddress(result.data)
                              }
                              if(props.type === 'shipping'){
                                 props.setRequiredField('shipping')
                              }
                           }
                        })
                     }}
                  >
                  {({ values, errors, touched, handleSubmit, handleChange, isSubmitting}) => (   
                  <Form className="grid h-full grid-cols-2 gap-5">
                     
                     <div className="w-full col-span-2">
                        <label className="block text-body-dark font-semibold text-sm leading-none mb-3">Type</label>
                        <div className="flex items-center space-x-4 rtl:space-x-reverse">
                           {
                           props.type == 'billing'
                           ?
                           <div>
                              <div className="flex items-center mb-4">
                                 <Field id="billing" name="address_type" type="radio" className="w-4 h-4 text-accent-600 bg-gray-100 border-gray-300 focus:ring-accent-500 dark:focus:ring-accent-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" value="billing"/>
                                 <label for="billing" className="ml-2 text-body text-sm">Billing</label>
                              </div>
                           </div>
                           :
                           <div>
                              <div className="flex items-center mb-4">
                                 <Field id="shipping" name="address_type" type="radio" className="w-4 h-4 text-accent-600 bg-gray-100 border-gray-300 focus:ring-accent-500 dark:focus:ring-accent-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" value="shipping" />
                                 <label for="shipping" className="ml-2 text-body text-sm">Shipping</label>
                              </div>
                           </div>
                           }
                        </div>
                        {errors.address_type && touched.address_type &&
                           <p className="mt-2 text-xs text-red-500">{errors.address_type}</p>
                        }
                     </div>
                     
                     
                     <div>
                        <label htmlFor="country" className="block mb-3 text-sm font-semibold leading-none text-body-dark">Country</label>
                        <Field id="country" name="country" type="text" className="flex w-full appearance-none items-center px-4 text-sm text-heading transition duration-300 ease-in-out focus:outline-none focus:ring-0 border border-border-base rounded focus:border-accent h-12" />
                        {errors.country && touched.country &&
                        <p className="mt-2 text-xs text-red-500">{errors.country}</p>
                        }
                     </div>

                     <div>
                        <label htmlFor="city" className="block mb-3 text-sm font-semibold leading-none text-body-dark">City</label>
                        <Field id="city" name="city" type="text" className="flex w-full appearance-none items-center px-4 text-sm text-heading transition duration-300 ease-in-out focus:outline-none focus:ring-0 border border-border-base rounded focus:border-accent h-12" />
                        {errors.city && touched.city &&
                        <p className="mt-2 text-xs text-red-500">{errors.city}</p>
                        }
                     </div>

                     <div>
                        <label htmlFor="state" className="block mb-3 text-sm font-semibold leading-none text-body-dark">State</label>
                        <Field id="state" name="state" type="text" className="flex w-full appearance-none items-center px-4 text-sm text-heading transition duration-300 ease-in-out focus:outline-none focus:ring-0 border border-border-base rounded focus:border-accent h-12" />
                        {errors.state && touched.state &&
                        <p className="mt-2 text-xs text-red-500">{errors.state}</p>
                        }
                     </div>

                     <div>
                        <label htmlFor="zip" className="block mb-3 text-sm font-semibold leading-none text-body-dark">ZIP</label>
                        <Field id="zip" name="zip" type="text" className="flex w-full appearance-none items-center px-4 text-sm text-heading transition duration-300 ease-in-out focus:outline-none focus:ring-0 border border-border-base rounded focus:border-accent h-12" />
                        {errors.zip && touched.zip &&
                        <p className="mt-2 text-xs text-red-500">{errors.zip}</p>
                        }
                     </div>

                     <div className="col-span-2">
                        <label htmlFor="street_address" className="block text-body-dark font-semibold text-sm leading-none mb-3">Street Address</label>
                        <Field as="textarea" id="street_address" name="street_address" className="px-4 py-3 flex items-center w-full rounded appearance-none transition duration-300 ease-in-out text-heading text-sm focus:outline-none focus:ring-0 border border-border-base focus:border-accent" rows="2" ></Field>
                        {errors.street_address && touched.street_address &&
                        <p className="mt-2 text-xs text-red-500">{errors.street_address}</p>
                        }
                     </div>
                     {
                     !loading
                     ?
                     <button data-variant="normal" className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-0 h-12 col-span-2 w-full" type="submit">Add Address</button>
                     :
                     <Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 ltr:ml-auto" />
                     }
                  </Form>
                  )}
                  </Formik>
                  }
               </div>
               </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
   </>   
   );
}

export default AddBillingAddress;