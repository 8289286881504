import { useState ,InputHTMLAttributes ,useRef } from 'react';

import cn from 'classnames';
import { SearchIcon, CloseIcon } from '../../custom-icons'

import { Link } from "react-router-dom";
import {APP_URL} from '../../components/config/Common'


export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  label: string;
  variant?: 'minimal' | 'normal' | 'with-shadow' | 'flat';
  onSubmit: (e: any) => void;
  onClearSearch: (e: any) => void;
  onSetValue:()=>void;
}

const classes = {
  normal:
    'bg-light ltr:pl-6 rtl:pr-6 ltr:pr-14 rtl:pl-14 ltr:rounded-tr-none rtl:rounded-tl-none ltr:rounded-br-none rtl:rounded-bl-none  border ltr:border-r-0 rtl:border-l-0 border-transparent focus:border-accent',
  minimal:
    'search-minimal border-accent bg-gray-100 ltr:pl-10 rtl:pr-10 ltr:pr-4 rtl:pl-4 ltr:md:pl-14 rtl:md:pr-14 border border-transparent focus:border-accent focus:bg-light',
  flat: 'bg-white ltr:pl-10 rtl:pr-10 ltr:pr-4 rtl:pl-4 ltr:md:pl-14 rtl:md:pr-14 border-0',
  'with-shadow':
    'search-with-shadow bg-light ltr:pl-10 rtl:pr-10 ltr:pr-12 rtl:pl-12 ltr:md:pl-14 rtl:md:pr-14 focus:bg-light border-0',
};

const SearchBox: React.FC<Props> = ({
  className,
  label,
  onSubmit,
  onClearSearch,
  variant = 'normal',
  value,
  onSetValue,
  submitRef,
  ...rest
}) => {
   
   //let [value,Setvalue] = useState(value);
   const handletextClick = (textsearch,slugtext) => {
    console.log("LINK CLICK",textsearch);
    onSetValue(textsearch,slugtext);
   }

  return (
    <form  onSubmit={onSubmit} className={cn('w-full', className)}>
     <div
        className={cn('relative flex rounded md:rounded-lg', {
          'h-14 shadow-900': variant === 'normal',
          'h-10 md:h-10': variant === 'minimal',
          'h-auto !rounded-none': variant === 'flat',
          'h-16 shadow-downfall': variant === 'with-shadow',
        })}
      >
        <label htmlFor={label} className="sr-only">
          {label}
        </label>

        <input
          id={label}
          type="text"
          value={value}
          autoComplete="off"
          className={cn(
            'search item-center flex h-full w-full  appearance-none overflow-hidden truncate rounded-lg text-sm text-heading placeholder-gray-500 transition duration-300 ease-in-out focus:outline-none focus:ring-0 pl-10',
            {
              'placeholder:text-slate-400': variant === 'flat',
            },
            classes[variant]
          )}
          {...rest}
          data-variant={variant}
          autoFocus
        />
        {value && (
          <button
            type="button"
            onClick={onClearSearch}
            className={cn(
              'absolute flex h-full w-10 cursor-pointer items-center justify-center text-body transition-colors duration-200 hover:text-accent-hover focus:text-accent-hover focus:outline-none md:w-14',
              {
                'ltr:right-36 rtl:left-36': variant === 'normal',
                'ltr:right-0 rtl:left-0': variant !== 'normal',
              }
            )}
          >
            <span className="sr-only">close</span>
            <CloseIcon className="h-3.5 w-3.5 md:h-3 md:w-3" />
          </button>
        )}

        {variant === 'normal' ? (
          <button className="flex h-full min-w-[143px] items-center justify-center rounded-lg bg-accent px-8 font-semibold text-light transition-colors duration-200 hover:bg-accent-hover focus:bg-accent-hover focus:outline-none ltr:rounded-tl-none ltr:rounded-bl-none rtl:rounded-tr-none rtl:rounded-br-none">
            <SearchIcon className="h-4 w-4 ltr:mr-2.5 rtl:ml-2.5" />
          </button>
        ) : (
          <button className="absolute flex h-full w-10 items-center justify-center text-body transition-colors duration-200 hover:text-accent-hover focus:text-accent-hover focus:outline-none ltr:left-0 rtl:right-0 md:w-14">
            <span className="sr-only"></span>
            <SearchIcon className="h-4 w-4" />
          </button>
        )}
      </div> 

        {/*<div className="w-full grid items-center content-center bg-no-repeat bg-cover bg-center py-10"  style={{backgroundImage:`url(${APP_URL}/img/pexels-david-bartus-963278.jpg)`,position:'relative'}}>
           <div id="color-overlay"></div>
            <div className="w-full md:w-3/5 justify-self-center ">
          

                <div class=" px-10 pt-5   ">
                  <div class="relative mb-4 flex w-full flex-wrap items-stretch">
                    <input
                      type="search"
                      class="bg-white relative m-0 -mr-0.5 block w-[1px] min-w-0 flex-auto rounded-l border border-solid border-neutral-300  bg-clip-padding px-3 py-[0.75rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-accent focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:focus:border-accent"
                      placeholder="Search Products"
                      aria-label="Search"
                       value={value}
                        {...rest}
                       data-variant={variant}
                      aria-describedby="button-addon1" />

                    <button
                      class="relative z-[2] flex items-center rounded-r bg-accent px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg"
                      type="submit"
                      id="button-addon1"
                      data-te-ripple-init
                      data-te-ripple-color="light" 
                      ref={submitRef}
                      >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        class="h-5 w-5">
                        <path
                          fill-rule="evenodd"
                          d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                          clip-rule="evenodd" />
                      </svg>
                    </button>
                  </div>
               </div>
        </div>
        <ul className="list-none px-10  flex flex-wrap gap-5 items-center justify-self-center mb-3 z-10 h-5 md:h-full overflow-hidden md:overflow-auto">
          <li>
          <Link
            to="#"
            onClick={()=>handletextClick('Ct Scan','ct-scan')}
            className="text-sm flex items-center font-semibold   text-heading no-underline transition duration-200 text-accent hover:text-orange-500 focus:text-orange-500 dark:text-zinc-400"
            >
            Ct Scan</Link>
            </li>
          <li>
           <Link
            to="#"
             onClick={()=>handletextClick('MRI','mri')}
            className=" text-sm  flex items-center font-semibold     text-heading no-underline transition duration-200 text-accent hover:text-orange-500 focus:text-orange-500 dark:text-zinc-400"
            >
          MRI
          </Link></li>
          <li>
           <Link
            to="#"
             onClick={()=>handletextClick('X Ray','x-ray')}
            className="text-sm flex items-center font-semibold   text-heading no-underline transition duration-200 text-accent hover:text-orange-500 focus:text-orange-500 dark:text-zinc-400"
            >
          X Ray
          </Link></li>
          <li><Link
            to="#"
            
             onClick={()=>handletextClick('Spare Parts','spare-parts')}
            className="text-sm flex items-center font-semibold   text-heading no-underline transition duration-200 text-accent hover:text-orange-500 focus:text-orange-500 dark:text-zinc-400"
            >Spare Parts</Link></li>
            <li><Link
            to="#"
            onClick={()=>handletextClick('USG','usg')}
            className="text-sm flex items-center font-semibold   text-heading no-underline transition duration-200 text-accent hover:text-orange-500 focus:text-orange-500 dark:text-zinc-400"
            >USG</Link></li>
            <li><Link
            to="#"
            onClick={()=>handletextClick('General Medical Equipment','general-medical-equipments')}
            className="text-sm flex items-center font-semibold   text-heading no-underline transition duration-200 text-accent hover:text-orange-500 focus:text-orange-500 dark:text-zinc-400"
            >General Medical Equipment</Link></li>
             <li><Link
            to="#"
            onClick={()=>handletextClick('Cath Lab','cath-lab')}
            className="text-sm flex items-center font-semibold   text-heading no-underline transition duration-200 text-accent hover:text-orange-500 focus:text-orange-500 dark:text-zinc-400"
            >Cath Lab</Link></li>
             <li><Link
            to="#"
             onClick={()=>handletextClick('Mammography','mammography')}
            className="text-sm flex items-center font-semibold   text-heading no-underline transition duration-200 text-accent hover:text-orange-500 focus:text-orange-500 dark:text-zinc-400"
            >Mammography</Link></li>
            
        </ul>
        
        </div>*/}
    </form>
  );
};

export default SearchBox;
