import React, { createContext, useContext, useState } from "react";
import {API_ENDPOINT, toast_anim, toast_anim_error} from '../components/config/Common'

const CartContext = createContext('CartContext');


export const CartProvider = ({children}) => {
   const [randNum] = useState(localStorage.getItem('rand_num'));
   const [cartItem, setCartItem] = useState(false)
   const [cartTotal, setCartTotal] = useState(0)
   const [cartCount, setCartCount] = useState(0)
   const [itemCartQty, setItemCartQty] = useState(0)
   const [gstTax, setGstTax] = useState(0)

   const getCartItems = () => {
      
      fetch(`${API_ENDPOINT}/get_cart_list?code=${randNum}`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json',
         }
      }).then(function (response) {
         return response.json();
      }).then( (result)=> {
         console.log('cart list======',result)
         if(result.status === 1){
            setCartItem(result.data)
            setCartTotal(result.cart_total)
            setCartCount(result.data.length)
            setGstTax(result.gst_total)
            //tax_gst(result.cart_total)
            //console.log('tax======',tax)
         }
      })
   }

   const tax_gst = async (amt) => {

      let amount = Number(amt)
   
      const response = await fetch(`${API_ENDPOINT}/get_cart_gst/${amount}`)
      const data = await response.json();
      console.log('gst',data.data);
      setGstTax(data.data)
      //return data.data;
   }

   const addCartItem = (item,price_type) => {
      //const rand_num = localStorage.getItem('rand_num')
      //console.log('utils rand=====',randNum)
      console.log("price type ",price_type);
      const user_type = localStorage.getItem('user_type');
      let price = 0;
      if(user_type == 'dealer'){
         if(price_type == 'with warranty'){
           price= item.warranty_price ? item.warranty_price : item.price;
         }else{
             if(item.dealer_price && item.dealer_price > 0){
            price = item.dealer_price;
            }else{
               price = item.price;
            }
         }
      }else{
         if(item.warranty_price && item.warranty_price > 0){
            price = item.warranty_price;
         }else{
            if(item.dealer_price && item.dealer_price > 0){
            price = item.dealer_price;
            }else{
               price = item.price;
            }
         }
         
      }
      if(randNum){
         fetch(`${API_ENDPOINT}/add_to_cart`,{
            method: 'POST',
            headers : {        
               'Content-Type': 'application/json',
               'Accept': 'application/json',
            },
            body: JSON.stringify({
               product_id:item.p_id,
               amount:price,
               invoice:randNum,
               seller_id:item.user_id,
               price_type:price_type,
            })
         }).then(function (response) {
               return response.json();
         }).then( (result)=> {
            //console.log('insert data=====',result)
            //toast_anim(result.message)
            if(result.status === 1){
               getCartItems()
               checkItemInCart(item.p_id)
               toast_anim(result.message)
            }
         })
      }
   }

   const deleteCartItem = (id) => {
      //const randNum = localStorage.getItem('rand_num')
      if(randNum){
         fetch(`${API_ENDPOINT}/delete_cart_item/${id}?num=${randNum}`,{
            method: 'DELETE',
            headers : {        
               'Content-Type': 'application/json',
               'Accept': 'application/json',
            }
         }).then(function (response) {
            return response.json();
         }).then( (result)=> {
            //console.log('delete item======',result)
            if(result.status === 1){
               getCartItems()
               checkItemInCart(id)
               toast_anim_error(result.message)
            }
         })
      }
   }

   const updateItemQty = (id,qty) => {
      if(randNum){
         fetch(`${API_ENDPOINT}/update_item_qty`,{
            method: 'POST',
            headers : {        
               'Content-Type': 'application/json',
               'Accept': 'application/json',
            },
            body: JSON.stringify({
               product_id:id,
               quantity:qty,
               invoice:randNum
            })
         }).then(function (response) {
            return response.json();
         }).then( (result)=> {
            //console.log('quantity updated======',result)
            if(result.status === 1){
               getCartItems()
               checkItemInCart(id)
            }
         })
      }
   }

   const checkItemInCart = (id) => {
		
      if(randNum){
         fetch(`${API_ENDPOINT}/is_product_in_cart/${id}?invoice=${randNum}`,{
            method: 'GET',
            headers : {
               'Content-Type': 'application/json',
               'Accept': 'application/json',
            }
         }).then(function (response) {
               return response.json();
         }).then( (result)=> {
            //console.log('checkItemInCart=====',result)
            if(result.status === 1){
               setItemCartQty(result.data.quantity)
            }else{
               setItemCartQty(0)
            }
         })
      }
   }

   
   return <CartContext.Provider value={{ getCartItems, cartItem, setCartItem, cartTotal, cartCount, addCartItem, deleteCartItem, updateItemQty, checkItemInCart, itemCartQty, gstTax }} >{children}</CartContext.Provider>
}

export const useCart = () => {
   const context = React.useContext(CartContext);
   if (context === undefined) {
      throw new Error(`useCart must be used within a CartProvider`);
   }
   return React.useMemo(() => context, [context]);
   //return context;
};