import React, {useState, useEffect} from "react";
import { Link , useParams, useNavigate } from "react-router-dom";

import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import {
   API_ENDPOINT,
   APP_URL, 
   IMG_URL,
   date_format,
   amount_format,
} from '../config/Common'
import {HomeIconNew} from '../../custom-icons'
import Table from 'rc-table';
import { BiRupee } from "react-icons/bi"; 
import moment from 'moment';
import CartTable from '../order/CartTable'
import ScrollPageTop from '../../routes/ScrollPageTop'
import {Links} from '../../routes/PathLink'
import ProgressBox from '../order/progress-box.tsx'

const OrderDetail = (props) => {
   const navigate = useNavigate();
   const [accessToken] = useState(localStorage.getItem('access_token'))
   const { invoice } = useParams();

   const [cart, setCart] = useState(null)
   const [order, setOrder] = useState(null)
   const [orderAddress, setOrderAddress] = useState(null)
   const [trackData, setTrackData] = useState(null)

   const user_name = localStorage.getItem('user_name');

   useEffect(() => {
      if(!accessToken){
         return navigate("/", { replace: true });
      }      
   },[accessToken])

   useEffect(() => {
      getOrderDetailByInvoice()
      get_order_trackData()
   },[])

   const getOrderDetailByInvoice = () => {
      fetch(`${API_ENDPOINT}/get_order_by_invoice/${invoice}`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+accessToken, 
         }
      }).then(function (response) {
         return response.json();
      }).then( (result)=> {
         //console.log('data=====',result)
         setCart(result.cart)
         setOrder(result.order)
         setOrderAddress(result.billing)
      })
   }

   const get_order_trackData = () => {
      
      //console.log(selectedOrder.invoice)
      fetch(`${API_ENDPOINT}/get_order_track_data/${invoice}`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+accessToken, 
         }
      }).then(function (response) {
         return response.json();
      }).then( (result)=> {
         //console.log('data=====',result)
         setTrackData(result.data)
         //getSelectedOrder(result.data[0])
      })
      
   }

   return(
      <ScrollPageTop>
         <Header />
            <div className="p-4 sm:p-8">
               <div className="mx-auto w-full max-w-screen-lg">
                  <div className="mb-5"><Link className="inline-flex items-center gap-2 text-sm font-semibold text-accent hover:text-accent-hover" to={Links.ORDERS}><HomeIconNew />My Orders</Link></div>

                  <div className="relative overflow-hidden rounded border shadow-sm">
                     <div className="bg-[#F7F8FA] lg:px-11 lg:py-5 p-6">
                        <div className="mb-0 flex flex-col flex-wrap items-center justify-between gap-x-8 text-base font-bold text-heading sm:flex-row lg:flex-nowrap">
                           {
                           order &&
                           <div className="order-2 flex w-full gap-6 xs:flex-nowrap sm:order-1 max-w-full basis-full justify-between lg:ltr:mr-auto">
                              <div className="flex flex-wrap items-center">
                                 <span className="mb-2 block text-xs xs:text-base lg:mb-0 lg:inline-block lg:ltr:mr-4 lg:rtl:ml-4">Order Status :</span>
                                 {
                                 order.order_status === 'pending'
                                 ?
                                 <div className="w-full lg:w-auto">
                                    <span className="px-3 py-1 rounded-full text-sm text-light bg-[#EAB308] min-h-[2rem] items-center justify-center text-[9px] !leading-none xs:text-sm inline-flex">Order Pending</span>
                                 </div>
                                 :
                                 order.order_status === 'completed'
                                 ?
                                 <div className="w-full lg:w-auto">
                                    <span className="px-3 py-1 rounded-full text-sm text-light bg-accent min-h-[2rem] items-center justify-center text-[9px] !leading-none xs:text-sm inline-flex">Order Completed</span>
                                 </div>
                                 :
                                 <div className="w-full lg:w-auto">
                                    <span className="px-3 py-1 rounded-full text-sm text-light bg-[#EAB308] min-h-[2rem] items-center justify-center text-[9px] !leading-none xs:text-sm inline-flex">Order Processing</span>
                                 </div>
                                 }
                              </div>
                              <div className="flex flex-wrap items-center">
                                 <span className="mb-2 block text-xs xs:text-base lg:mb-0 lg:inline-block lg:ltr:mr-4 lg:rtl:ml-4">Payment Status :</span>
                                 {
                                 order.pay_status === 'pending'
                                 ?
                                 <div className="w-full lg:w-auto">
                                    <span className="px-3 py-1 rounded-full text-sm text-light bg-[#EAB308] min-h-[2rem] items-center justify-center text-[9px] !leading-none xs:text-sm inline-flex">Payment Pending</span>
                                 </div>
                                 :
                                 <div className="w-full lg:w-auto">
                                    <span className="px-3 py-1 rounded-full text-sm text-light bg-accent min-h-[2rem] items-center justify-center text-[9px] !leading-none xs:text-sm inline-flex capitalize">{order.pay_status}</span>
                                 </div>
                                 }
                              </div>
                           </div>
                           }
                        </div>
                     </div>
                     
                     <div className="bg-light p-6 sm:p-8 lg:p-12">
                        {
                           order &&
                        <div className="mb-6 grid gap-4 sm:grid-cols-2 md:mb-12 lg:grid-cols-4">
                           <div className="rounded border border-border-200 py-4 px-5 shadow-sm">
                              <h3 className="mb-2 text-sm font-semibold text-heading">Order Number</h3>
                              <p className="text-sm text-body-dark">{order.invoice}</p>
                           </div>
                           <div className="rounded border border-border-200 py-4 px-5 shadow-sm">
                              <h3 className="mb-2 text-sm font-semibold text-heading">Date</h3>
                              <p className="text-sm text-body-dark">
                                 {date_format(order.created_at)}
                              </p>
                           </div>
                           <div className="rounded border border-border-200 py-4 px-5 shadow-sm">
                              <h3 className="mb-2 text-sm font-semibold text-heading">Total</h3>
                              <p className="text-sm text-body-dark flex items-center">
                                 {amount_format(order.total_amount)}
                              </p>
                           </div>
                           <div className="rounded border border-border-200 py-4 px-5 shadow-sm">
                              <h3 className="mb-2 text-sm font-semibold text-heading">Payment Method</h3>
                              <p className="text-sm text-body-dark">{order.payment_mode}</p>
                           </div>
                        </div>
                        }

                        
                        <div className="flex flex-col lg:flex-row">
                        
                           <div className="mb-12 w-full lg:mb-0 lg:w-1/2 ltr:lg:pr-3 rtl:lg:pl-3">
                              <h2 className="mb-6 text-xl font-bold text-heading">Total Amount</h2>
                              {
                                 order &&
                              <div>
                                 <p className="mt-5 flex text-body-dark">
                                    <strong className="w-5/12 text-sm font-semibold text-heading sm:w-4/12">Sub Total</strong>:<span className="w-7/12 text-sm ltr:pl-4 rtl:pr-4 sm:w-8/12 flex items-center">{amount_format(order.sub_total)}</span>
                                 </p>
                                 <p className="mt-5 flex text-body-dark">
                                    <strong className="w-5/12 text-sm font-semibold text-heading sm:w-4/12">GST</strong>:<span className="w-7/12 text-sm ltr:pl-4 rtl:pr-4 sm:w-8/12 flex items-center">{amount_format(order.tax_gst)}</span>
                                 </p>
                                 {order.total_delivery_fee && order.total_delivery_fee > 0?
                                    <p className="mt-5 flex text-body-dark">
                                    <strong className="w-5/12 text-sm font-semibold text-heading sm:w-4/12">Shipping Charge</strong>:<span className="w-7/12 text-sm ltr:pl-4 rtl:pr-4 sm:w-8/12 flex items-center">{amount_format(order.total_delivery_fee)}</span>
                                 </p>
                                 :
                                 <p className="mt-5 py-2 flex text-sm text-accent border-t-4 border-double border-border-20 border-b-4">
                                    Shipping charges included in price
                                  </p>
                                 }
                              
                                 {/* <p className="mt-5 flex text-body-dark">
                                    <strong className="w-5/12 text-sm font-semibold text-heading sm:w-4/12">Discount</strong>:<span className="w-7/12 text-sm ltr:pl-4 rtl:pr-4 sm:w-8/12 flex items-center">{amount_format(0)}</span>
                                 </p> */}
                                 <p className="mt-5 flex text-body-dark">
                                    <strong className="w-5/12 text-sm font-semibold text-heading sm:w-4/12">Total</strong>:<span className="w-7/12 text-sm ltr:pl-4 rtl:pr-4 sm:w-8/12 flex items-center">{amount_format(order.total_amount)}</span>
                                 </p>
                                 {
                                 order.bookingamount > 0 ? 
                                 <p className="mt-5 flex text-body-dark">
                                    <strong className="w-5/12 text-sm font-semibold text-heading sm:w-4/12">Advanced</strong>:<span className="w-7/12 text-sm ltr:pl-4 rtl:pr-4 sm:w-8/12 flex items-center">{amount_format(order.bookingamount)}</span>
                                 </p>
                                 :null
                                 }
                                 
                              </div>
                              }
                           </div>
                        
                           <div className="w-full lg:w-1/2 ltr:lg:pl-3 rtl:lg:pr-3">
                              <h2 className="mb-6 text-xl font-bold text-heading">Order Details</h2>
                              {
                              order &&
                              <div>
                                 <p className="mt-5 flex text-body-dark">
                                    <strong className="w-4/12 text-sm font-semibold text-heading">Name</strong>:<span className="w-8/12 text-sm ltr:pl-4 rtl:pr-4 ">{user_name}</span>
                                 </p>
                                 <p className="mt-5 flex text-body-dark">
                                    <strong className="w-4/12 text-sm font-semibold text-heading">Total Item</strong>:<span className="w-8/12 text-sm ltr:pl-4 rtl:pr-4 ">{cart.length} Item</span>
                                 </p>
                                 {
                                 orderAddress && orderAddress.map((val, key) => (
                                 <React.Fragment key={`addr-${val.billing_id}`}>
                                 {
                                 val.type === 'shipping' &&
                                 <p className="mt-5 flex text-body-dark">
                                    <strong className="w-4/12 text-sm font-semibold text-heading">Shipping Address</strong>:<span className="w-8/12 text-sm ltr:pl-4 rtl:pr-4 ">{val.street_address}, {val.city}, {val.state}, {val.zip}, {val.country}</span>
                                 </p>
                                 }
                                 
                                 {
                                 val.type === 'billing' &&
                                 <p className="mt-5 flex text-body-dark">
                                    <strong className="w-4/12 text-sm font-semibold text-heading">Billing Address</strong>:<span className="w-8/12 text-sm ltr:pl-4 rtl:pr-4">{val.street_address}, {val.city}, {val.state}, {val.zip}, {val.country}</span>
                                 </p>
                                 }
                                 </React.Fragment>
                                 ))
                                 }
                              </div>
                              }
                           </div>
                        </div>
                        {
                        trackData && trackData.length > 0 ?
                        <>
                           <h2 className="mt-5 flex text-sm font-semibold text-heading md:text-lg">Track Order
                           </h2>
                           <ProgressBox
                           data={trackData}
                           filledIndex={trackData.length}
                        />
                        </>
                        :null
                        }

                        <div className="mt-12">
                           <div className="rc-table orderDetailsTable w-full rc-table-fixed-header rc-table-scroll-horizontal">
                              <div className="rc-table-container">
                                 <div style={{overflow: 'hidden'}} className="rc-table-header">
                                    {
                                    cart &&
                                    <CartTable cart={cart} />
                                    }
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         <Footer />
      </ScrollPageTop>
   );

}

export default OrderDetail;