import {Fragment, useRef, useState, useEffect} from "react";
import { Link } from "react-router-dom";
import classNames from 'classnames';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
import {API_ENDPOINT, toast_anim} from './config/Common'
import {Loading,CloseIcon} from "../custom-icons";
import { PlusIcon } from '@heroicons/react/24/solid'


import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { AiFillEdit } from "react-icons/ai";
import { IoTrashOutline } from "react-icons/io5";

import { toast } from 'react-toastify';

const ValidationSchema = Yup.object().shape({
	address_type: Yup.string()      
		.required('Type is required.'),
   title: Yup.string()
      .min(2, 'Too short')
      .max(30, 'Too long')
		.required('Title is required.'),
   country: Yup.string()
		.required('Country is required.'),
   city: Yup.string()
      .min(2, 'Too short')
		.required('City is required.'),
   state: Yup.string()      
		.required('State is required.'),
   zip: Yup.string()
      .min(4, 'Too short')
		.required('Zip is required.'),
   street_address: Yup.string()
      .min(2, 'Too short')
		.required('Street address is required.'),
});



const AddAddress = (props) => {
   const [open, setOpen] = useState(false)
   const cancelButtonRef = useRef(null)
   const [success, setSuccess] = useState(false)
   const [loading, setLoading] = useState(false)
   
   const [accessToken, setAccessToken] = useState(localStorage.getItem('access_token'))
   const [user_address, setUser_address] = useState(null);

   useEffect(() => {
      getUserAddress()
   },[])

   const getUserAddress = () => {
      fetch(`${API_ENDPOINT}/get_user_address`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${accessToken}`, 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log(result)
         setUser_address(result.data)
		})
   }

   const deleteAddres = (id) => {
      fetch(`${API_ENDPOINT}/delete_user_address/${id}`,{
			method: 'DELETE',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${accessToken}`, 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
         getUserAddress()
			console.log('delete=====',result)
         //setUser_address(result.data)
         toast_anim(result.message)
		})
   }

   const closeModal = () => {
      setOpen(false)
      //props.openLoginModal()
    }

   return (
   <>   
      <div className={classNames('w-full p-5 md:p-8 bg-light shadow rounded mb-8 mt-8')}>
         <div className="flex items-center justify-between mb-5 md:mb-8">
            <div className="flex items-center space-x-3 md:space-x-4 rtl:space-x-reverse">
               <p className="text-lg lg:text-xl text-heading capitalize">Addresses</p>
            </div>
            <button className="flex items-center text-sm font-semibold text-accent transition-colors duration-200 focus:outline-none focus:text-accent-hover hover:text-accent-hover" onClick={() => setOpen(true)}>
               <PlusIcon className="w-4 h-4 stroke-2 ltr:mr-0.5 rtl:ml-0.5" />Add</button>
         </div>
         <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
            {
            user_address && user_address.map((item, index) => (
            <div className="group relative cursor-pointer rounded border p-4 hover:border-accent border-transparent bg-gray-100 mb-4">
               <div className="absolute text-sm -top-3 bg-accent text-light rounded pl-2 pr-2 flex item-center h-6">{item.title}</div>
               <p className="mb-3 text-sm font-semibold capitalize text-heading">{item.type}</p>
               <p className="text-sm text-sub-heading">{item.street_address}, {item.city}, {item.state}, {item.zip}, {item.country}</p>
               <div className="absolute top-4 flex space-x-2 opacity-0 group-hover:opacity-100 ltr:right-4 rtl:left-4 rtl:space-x-reverse">
                  {/* <button className="flex items-center text-sm font-semibold text-accent transition-colors duration-200 focus:outline-none focus:text-accent-hover hover:text-accent-hover">
                     <AiFillEdit className="w-4 h-4 stroke-2 ltr:mr-0.5 rtl:ml-0.5" />
                  </button> */}
                  <button className="flex items-center text-sm font-semibold text-accent transition-colors duration-200 focus:outline-none focus:text-accent-hover hover:text-accent-hover" onClick={() => deleteAddres(item.address_id)}>
                     <IoTrashOutline className="w-5 h-5 stroke-2 ltr:mr-0.5 rtl:ml-0.5" />
                  </button>
               </div>
            </div>
            ))
            }
            
         </div>
      </div>


      <Transition.Root show={open} as={Fragment} dir='ltr'>
      <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
               <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
               <div className="min-h-screen bg-light p-5 sm:p-8 md:min-h-0 md:rounded-xl">
                  <button aria-label="Close panel" className="absolute top-4 z-[60] inline-block outline-none focus:outline-none ltr:right-4 rtl:left-4 lg:hidden" onClick={closeModal}>
                     <CloseIcon className="h-4 w-4" />
                  </button>
                  <h1 className="mb-4 text-center text-lg font-semibold text-heading sm:mb-6">Add New Address</h1>
                  
                  <Formik
							initialValues={{
								address_type: 'billing',
								title: '',
								country:'',
                        city:'',
                        state:'',
                        zip:'',
                        street_address:''
							}}
                     validationSchema={ValidationSchema}
                     onSubmit={(values, { setErrors, setSubmitting, resetForm }) => {
                        //resetForm({values:''})
                        //setSuccess(true)
                        //console.log(accessToken)
                        //openSnackbar('This is the content of the Snackbar.')
                        setLoading(true)
                        fetch(`${API_ENDPOINT}/add_user_address`,{
                           method: 'POST',
                           headers : {        
                              'Content-Type': 'application/json',
                              'Accept': 'application/json',
                              'Authorization': 'Bearer '+accessToken, 
                           },
                           body: JSON.stringify(values)
                        }).then(function (response) {
                              return response.json();
                        }).then( (result)=> {
                           //console.log('data=====',result)
                           setLoading(false)
                           if(result.status === 1){
                              //resetForm({values:''})
                              setOpen(false)
                              toast_anim(result.message)
                              getUserAddress()
                           }
                        })
                     }}
                  >
                  {({ values, errors, touched, handleSubmit, handleChange, isSubmitting}) => (   
                  <Form className="grid h-full grid-cols-2 gap-5">
                     <div className="w-full">
                        <label className="block text-body-dark font-semibold text-sm leading-none mb-3">Type</label>
                        <div className="flex items-center space-x-4 rtl:space-x-reverse">
                           <div>
                              <div className="flex items-center mb-4">
                                 <Field id="billing" name="address_type" type="radio" className="w-4 h-4 text-accent-600 bg-gray-100 border-gray-300 focus:ring-accent-500 dark:focus:ring-accent-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" value="billing"/>
                                 <label for="billing" className="ml-2 text-body text-sm">Billing</label>
                              </div>
                           </div>
                           <div>
                              <div className="flex items-center mb-4">
                                 <Field id="shipping" name="address_type" type="radio" className="w-4 h-4 text-accent-600 bg-gray-100 border-gray-300 focus:ring-accent-500 dark:focus:ring-accent-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" value="shipping" />
                                 <label for="shipping" className="ml-2 text-body text-sm">Shipping</label>
                              </div>
                           </div>
                        </div>
                        {errors.address_type && touched.address_type &&
                           <p className="mt-2 text-xs text-red-500">{errors.address_type}</p>
                        }
                     </div>

                     <div className="col-span-2">
                        <label htmlFor="title" className="block mb-3 text-sm font-semibold leading-none text-body-dark">Title</label>
                        <Field id="title" name="title" type="text" className="flex w-full appearance-none items-center px-4 text-sm text-heading transition duration-300 ease-in-out focus:outline-none focus:ring-0 border border-border-base rounded focus:border-accent h-12" />
                        {errors.title && touched.title &&
                        <p className="mt-2 text-xs text-red-500">{errors.title}</p>
                        }
                     </div>
                     <div>
                        <label htmlFor="country" className="block mb-3 text-sm font-semibold leading-none text-body-dark">Country</label>
                        <Field id="country" name="country" type="text" className="flex w-full appearance-none items-center px-4 text-sm text-heading transition duration-300 ease-in-out focus:outline-none focus:ring-0 border border-border-base rounded focus:border-accent h-12" />
                        {errors.country && touched.country &&
                        <p className="mt-2 text-xs text-red-500">{errors.country}</p>
                        }
                     </div>

                     <div>
                        <label htmlFor="city" className="block mb-3 text-sm font-semibold leading-none text-body-dark">City</label>
                        <Field id="city" name="city" type="text" className="flex w-full appearance-none items-center px-4 text-sm text-heading transition duration-300 ease-in-out focus:outline-none focus:ring-0 border border-border-base rounded focus:border-accent h-12" />
                        {errors.city && touched.city &&
                        <p className="mt-2 text-xs text-red-500">{errors.city}</p>
                        }
                     </div>

                     <div>
                        <label htmlFor="state" className="block mb-3 text-sm font-semibold leading-none text-body-dark">State</label>
                        <Field id="state" name="state" type="text" className="flex w-full appearance-none items-center px-4 text-sm text-heading transition duration-300 ease-in-out focus:outline-none focus:ring-0 border border-border-base rounded focus:border-accent h-12" />
                        {errors.state && touched.state &&
                        <p className="mt-2 text-xs text-red-500">{errors.state}</p>
                        }
                     </div>

                     <div>
                        <label htmlFor="zip" className="block mb-3 text-sm font-semibold leading-none text-body-dark">ZIP</label>
                        <Field id="zip" name="zip" type="text" className="flex w-full appearance-none items-center px-4 text-sm text-heading transition duration-300 ease-in-out focus:outline-none focus:ring-0 border border-border-base rounded focus:border-accent h-12" />
                        {errors.zip && touched.zip &&
                        <p className="mt-2 text-xs text-red-500">{errors.zip}</p>
                        }
                     </div>

                     <div className="col-span-2">
                        <label htmlFor="street_address" className="block text-body-dark font-semibold text-sm leading-none mb-3">Street Address</label>
                        <Field as="textarea" id="street_address" name="street_address" className="px-4 py-3 flex items-center w-full rounded appearance-none transition duration-300 ease-in-out text-heading text-sm focus:outline-none focus:ring-0 border border-border-base focus:border-accent" rows="2" ></Field>
                        {errors.street_address && touched.street_address &&
                        <p className="mt-2 text-xs text-red-500">{errors.street_address}</p>
                        }
                     </div>
                     {
                     !loading
                     ?
                     <button data-variant="normal" className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-0 h-12 col-span-2 w-full" type="submit">Add Address</button>
                     :
                     <Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 ltr:ml-auto" />
                     }
                  </Form>
                  )}
                  </Formik>
               </div>
               </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
   </>   
   );
}

export default AddAddress;