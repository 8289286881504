import React,{useState} from "react";
import Logo from './logo';
import { Link } from "react-router-dom";
import {SendIcon} from "../custom-icons/SendIcon";
import {APP_URL,API_ENDPOINT, toast_anim , pdfdoconly} from '../components/config/Common'
import {Links} from '../routes/PathLink'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';  
import { LazyLoadImage,LazyLoadComponent } from 'react-lazy-load-image-component';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import { useSiteSettings } from '../site-settings/site-settings';
import { FaSquareXTwitter } from "react-icons/fa6";
import {
    Control,
    FieldErrors,
    useForm,
    useFormState,
    useWatch,
    Controller
 } from 'react-hook-form';
import PopupBanner from '../components/banner/PopupBanner';

 

import { AiFillTwitterCircle, AiFillInstagram, AiFillFacebook, AiFillLinkedin } from 'react-icons/ai';

import { useLocation } from 'react-router-dom'

const newletterSchema = Yup.object().shape({
    email: Yup.string()
        .required('You must need to provide email address.')
        .email('Invalid email.'),       
});

let defaultValues = {  
    email:'',
};



const Footer = () => {
      const location = useLocation();
    const [loading, setLoading] = useState(false);
     const { sitelogo } = useSiteSettings();
    const logos = [
        {logo:'/img/make-in-india.png'},
        {logo:'/img/digitalIndia-logo.png'},
        {logo:'/img/Azadi_Ka_Amrit_Mahotsav.png'},
        {logo:'/img/vision-india.jpg'},
        {logo:'/img/aatmanirbhar-bharat-logo.png'},
    ]
      //console.log('location===',location.pathname);

    const { register, handleSubmit , control, reset, setValue, setError, formState: { errors },} = useForm({
        defaultValues: defaultValues,
        resolver: yupResolver(newletterSchema),
    });


    const onSubmitNews = (values) => {
        console.log('value', values)
        setLoading(true)        

        let input = values;
      input = {...input,
      }

      setLoading(true)
      axios.post(`${API_ENDPOINT}/add_newletter_subscription`,input,{
         headers: {
            'Content-Type': 'multipart/form-data'
         }
      }).then( (result)=> {
         console.log('data=====',result.data)
         setLoading(false)
         console.log('data=====',result)
            if(result.data.status === 1){
                //setSubmitting(false)
                //localStorage.setItem('user_name',result.data.name);
                toast_anim(result.data.message)
            }
      })

        
    }

    return (
        <div className="flex w-full flex-col border-gray-800 bg-white lg:border-b-8  dark:bg-gray-700">
            {/* Top */}
            <LazyLoadComponent>
            <div className="flex items-center justify-center flex-wrap w-full gap-6 py-5 px-5 md:px-5">
                {
                logos.map((val,key) => (
                <div key={`logo-${key}`} className="flex items-center justify-center w-[40%] lg:w-56 bg-light rounded">
                    <LazyLoadImage src={`${APP_URL}${val.logo}`} className="h-28 object-contain" alt="" />
                </div>
                ))
                }
            </div>
             </LazyLoadComponent>

            <div className="bg-gray-100 py-10 px-5 md:px-5">
                <h3 className="text-center text-2xl text-body mb-10">We are currently working and dealing with Indian Dealers and Refurbishers only!</h3>
                <div className="flex flex-col md:flex-row items-center justify-around">
                    <div>
                        <h3 className="text-center text-4xl text-accent mt-4 mb-3 uppercase">Our <span className="bg-accent text-white px-2">Delivery</span> Partner</h3>
                        <div className="flex justify-center flex-col items-center mt-2 text-body"><img src={`${APP_URL}/img/dtdc.png`} className="h-28 object-contain" alt="DTDC delivery partner" /></div>
                        </div>
                    <div>
                        <h3 className="text-center text-4xl text-accent mt-4 mb-3 uppercase">Our <span className="bg-accent text-white px-2">Finance</span> Partner</h3>
                        <div className="flex justify-center items-center mt-2 text-body gap-3">
                            <img src={`${APP_URL}/img/hdfc-bank.png`} className="h-28 object-contain" alt="HDFC Finance partner" />
                            <img src={`${APP_URL}/img/axis-bank.png`} className="h-28 object-contain" alt="AXIS Finance partner" />
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="bg-accent-300 grid w-full grid-cols-[repeat(auto-fill,minmax(260px,1fr))] gap-6 py-10 md:grid-cols-3 xl:grid-cols-[repeat(auto-fit,minmax(220px,1fr))] xl:gap-8 2xl:gap-2 2xl:grid-cols-4 px-5 md:px-10 lg:px-[50px] xl:px-16 justify-center m-auto border-t-2 border-accent">
                <div className="flex flex-col">
                    <div className="mb-[2px] flex h-16 items-start">
                        <Logo />
                    </div>
                    <address className="mb-7 text-sm not-italic text-heading dark:text-zinc-300 capitalize mt-5">
                    Block no.5 Ashwamegh apt <br /> lendra park ramdaspeth, <br />Nagpur-440012.
                    </address>
                    <span className="mb-1 text-sm text-heading dark:text-zinc-300">
                    <a href="mailto:support@ctmrihub.com">support@ctmrihub.com</a>
                    </span>
                    <span className="text-sm text-heading dark:text-zinc-300">
                    <a href={`tel:+917387869666`}>+917387869666</a><br/><a href={`tel:+918421008839`}>+918421008839</a>
                    </span>
                </div>

                <div className="flex flex-col">
                <h3 className="mt-3 mb-7 text-xl font-semibold text-accent dark:text-zinc-300">
                    Services
                    </h3>
                    <ul className="space-y-3">
                        <li>
                            <Link to={Links.CONTACT} className="text-sm text-heading transition-colors hover:text-orange-500 dark:text-zinc-300 dark:hover:text-orange-500">Contact</Link>
                        </li>
                        <li>
                            <Link to={Links.HELP} className="text-sm text-heading transition-colors hover:text-orange-500 dark:text-zinc-300 dark:hover:text-orange-500">Help</Link>
                        </li>
                        <li>
                            <Link to={Links.FREE_CONSULTATION} className="text-sm text-heading transition-colors hover:text-orange-500 dark:text-zinc-300 dark:hover:text-orange-500">Ask for consultation</Link>
                        </li>
                        <li>
                            <Link to={Links.PARTSREPAIR} className="text-sm text-heading transition-colors hover:text-orange-500 dark:text-zinc-300 dark:hover:text-orange-500">PARTS REPAIR</Link>
                        </li>
                         <li>
                            <Link to={Links.AERBLICENSING} className="text-sm text-heading transition-colors hover:text-orange-500 dark:text-zinc-300 dark:hover:text-orange-500">AERB Licensing</Link>
                        </li>
                        
                        <li>
                            <Link to={Links.CARRIER} className="text-sm text-heading transition-colors hover:text-orange-500 dark:text-zinc-300 dark:hover:text-orange-500">Careers</Link>
                        </li>
                         <li>
                            <Link to={Links.HIRECHAINPULLI} className="text-sm text-heading transition-colors hover:text-orange-500 dark:text-zinc-300 dark:hover:text-orange-500">Hire Chain Pulli Labourers</Link>
                        </li>
                         <li>
                            <Link to={Links.DEALER_SUBSCRIPTION_PLANS} className="text-sm text-heading transition-colors hover:text-orange-500 dark:text-zinc-300 dark:hover:text-orange-500">Dealer Subscription Plans</Link>
                        </li>
                    </ul>
                </div>
                <div className="flex flex-col">
                <h3 className="mt-3 mb-7 text-xl font-semibold text-accent dark:text-zinc-300">
                    More
                    </h3>
                    <ul className="space-y-3">
                        <li>
                            <Link to={Links.ABOUT} className="text-sm text-heading transition-colors hover:text-orange-500 dark:text-zinc-300 dark:hover:text-orange-500">About</Link>
                        </li>
                        <li>
                            <Link to={Links.TERMS} className="text-sm text-heading transition-colors hover:text-orange-500 dark:text-zinc-300 dark:hover:text-orange-500">Terms and conditions</Link>
                        </li>
                        <li>
                            <Link to={Links.PRIVACY} className="text-sm text-heading transition-colors hover:text-orange-500 dark:text-zinc-300 dark:hover:text-orange-500">Privacy Policy</Link>
                        </li>
                        
                          <li>
                            <Link to={Links.POPULAR_PRODUCTS} className="text-sm text-heading transition-colors hover:text-orange-500 dark:text-zinc-300 dark:hover:text-orange-500">Popular Products</Link>
                        </li>
                        <li>
                            <Link to={Links.TESTIMONIALS} className="text-sm text-heading transition-colors hover:text-orange-500 dark:text-zinc-300 dark:hover:text-orange-500">Testimonials</Link>
                        </li>
                        <li>
                            <Link to={Links.WHYCTMRIHUB} className="text-sm text-heading transition-colors hover:text-orange-500 dark:text-zinc-300 dark:hover:text-orange-500">Why CTMRI HUB?</Link>
                        </li>
                        <li>
                            <Link to={Links.SHIPPINGPOLICY} className="text-sm text-heading transition-colors hover:text-orange-500 dark:text-zinc-300 dark:hover:text-orange-500">Shipping Policy</Link>
                        </li>
                        
                         <li>
                            <Link to={Links.BLOG} target="_blank" className="text-sm text-heading transition-colors hover:text-orange-500 dark:text-zinc-300 dark:hover:text-orange-500">Blog</Link>
                        </li> 
                        
                    </ul>
                </div>

                <div className="flex flex-col">
                    <h3 className="mt-3 mb-7 text-xl font-semibold text-accent dark:text-zinc-300">
                    Subscribe Now
                    </h3>
                    <p className="mb-7 text-sm text-heading dark:text-zinc-300">Subscribe your email for newsletter and featured news based on your interest</p>
                     <form onSubmit={handleSubmit(onSubmitNews)}>
                    <div className="flex flex-col">
                        <div className="relative w-full rounded border border-gray-200 bg-gray-50 ltr:pr-11 rtl:pl-11">
                            <input
                                type="email"
                                {...register('email')}
                                id="email_subscribe"                                
                                placeholder={'Write your email here'}
                                className="h-12 w-full border-0 bg-transparent text-sm text-body outline-none focus:outline-none ltr:pl-5 rtl:pr-5"
                            />
                            <button className="absolute top-1/2 -mt-2 ltr:right-3 rtl:left-3"><SendIcon className="text-gray-500 transition-colors hover:text-accent" /></button>
                        </div>
                        <div className="text-red-500 text-sm">{errors.email?.message}</div>
                    </div>
                    </form>
                     
                    <h3 className="mt-5 mb-3 text-xl font-semibold text-heading dark:text-zinc-300">
                    Follow us on
                    </h3>
                    <div className="flex items-center">
                        <a href="https://www.facebook.com/profile.php?id=100094913228352" rel="noreferrer" target="_blank" className="mr-2 text-3xl text-accent hover:text-[#1877f2]"><AiFillFacebook /></a>
                        <a href="https://x.com/ctmrihub123" rel="noreferrer" target="_blank" className="mr-2 text-3xl text-accent hover:text-[#000]"><FaSquareXTwitter size={28} /></a>
                        <a href="https://www.instagram.com/ctmrihub/" rel="noreferrer" target="_blank" className="mr-2 text-3xl text-accent hover:text-[#c13584]"><AiFillInstagram /></a>
                        <a href="https://in.linkedin.com/company/ctmrihub-com" rel="noreferrer" target="_blank" className="mr-2 text-3xl text-accent hover:text-[#0A66C2]"><AiFillLinkedin /></a>
                        
                    </div>
                </div>
            </div>
            <div className="flex w-full flex-col gap-3 items-center lg:flex-row lg:justify-between px-5 md:px-10 py-5 bg-accent text-white mb-10 md:mb-0">
                <span className="order-2 text-sm lg:order-1 dark:text-zinc-300">
                &copy; Copyright {new Date().getFullYear()}{' '}
                <Link
                    className="underline transition-colors hover:text-heading hover:no-underline"
                    to='/'
                >
                  ctmrihub.com
                </Link>{' '}
                All rights reserved
                </span>
                <div className="order-2 text-sm lg:order-1 dark:text-zinc-300">
                    Designed and Developed by 
                    {location.pathname === '/' ?
                    <a href="https://sisnolabs.com/" target="_blank" className="text-white hover:text-heading underline hover:no-underline"> SISNOLABS</a>
                    :
                    ' SISNOLABS'
                    }
                </div>
            </div>
            <FloatingWhatsApp
                        phoneNumber="8421008839"
                        accountName="CTMRIHUB"
                        allowEsc
                        allowClickAway
                        notification
                        notificationSound
                        avatar={`${process.env.REACT_APP_IMG_URL+sitelogo}`}
                      />
            <PopupBanner />
        </div>
    )

}

export default Footer;