import React, {useState, useEffect, useRef} from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import Collapse from 'rc-collapse';
import 'rc-collapse/assets/index.css';
import ScrollPageTop from '../../routes/ScrollPageTop'

import { Link , useParams, useNavigate, createSearchParams } from "react-router-dom";
import {API_ENDPOINT, APP_URL, APP_NAME, IMG_URL, LIMIT} from '../config/Common'
import {Bed, Table, Sofa, Chair, Bucket, XrayIcon, MriIcon, CtScanIcon, Loading} from "../../custom-icons";
import { BiRupee } from "react-icons/bi";
import { motion as ms } from 'framer-motion';
import motion from '../../_utils/motionUtil.ts'
import { BiDownArrow } from "react-icons/bi";
import classNames from 'classnames';

import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import ProductCard from '../../cards/ProductCard'
import {Links} from '../../routes/PathLink'
import BrandFilter from './fliter/BrandFilter'
import { useSearch } from '../../ui/search/search.context.tsx';
import InfiniteScroll from "react-infinite-scroll-component";
import NoRecords from '../NoRecords'
import Seo from '../seo/seo'
import {isMobile} from "react-device-detect"
import { Range, getTrackBackground } from 'react-range';
import {CloseIcon} from '../../custom-icons'
import Button from '../../ui/Button.tsx'

const STEP = 100;
const MIN = 1000;
const MAX = 500000;
const rtl = false

const Category = () => {
   const [process, setProcess] = useState(false)
   const [products, setProducts] = useState(null)
   const [totalRec, setTotalRec] = useState(0)
   const [page, setPage] = useState(1)
   const [category, setCategory] = useState(null)
   const [activeKey, setActiveKey] = useState(['ct-scan']);
   const navigate = useNavigate();
   const { searchTerm , updateSearchTerm } = useSearch();
   const myRef = useRef(null)
   const [values, setValues] =  useState([MIN, MAX])

   const [priceRange, setPriceRange] =  useState([0, 0])

   const [metaData, setMetaData] = useState({
      url: `${APP_URL}${Links.CATEGORY}`,
      title:  `Category - ${APP_NAME}`,
      description:'Category'
   })

   const [brand, setBrand] = useState(null)
   const [hasMore, setHasMore] = useState(true)
   
   const {slug, sub_slug} = useParams()
   //console.log(useParams())

   useEffect(() => {
      //setPage(1)
      if(slug && !sub_slug){
         getProductByCatSlug(1, slug)
         
      }else if(sub_slug){
         //console.log(sub_slug)
         getProductByCatSlug(1, sub_slug)
      }else{
         getProducts(1)
      }
   },[slug, sub_slug, brand, searchTerm, priceRange])

   useEffect(() => {      
      getAllCategory()
   },[])

   const getProductByCatSlug = (pg, slug) => {
      console.log('filter brand slug',brand, priceRange)
      let querySlug = `slug=${slug}`
      let paginate = `&page=${pg}&limit=${LIMIT}`;
      let search = ''
      if(searchTerm){
         search = `&search=${searchTerm}`
      }
      let brand_id = brand > 0 ? `&brand_id=${brand}` : '';
      let price = priceRange[0] > 0 ? `&price=${priceRange}` : '';

      fetch(`${API_ENDPOINT}/get_products_front?${querySlug}${paginate}${search}${brand_id}${price}`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log('slug products======',result)
         if(result.status === 1){
            if(pg === 1){
               setProducts(result.data)
            }else{
               setProducts([...products, ...result.data])
            }
            let nextPage = page + 1
            setPage(nextPage)

            if(result.data.length < LIMIT){
               setHasMore(false)
            }else{
               setHasMore(true)
            }
            setTotalRec(result.total)
            const siteMetadata = {
               url: `${APP_URL}${Links.CATEGORY}/${slug}`,
               title:  result.cat.meta_title ? result.cat.meta_title : result.cat.cat_name + ` - ${APP_NAME}`,
               description: result.cat.meta_description ? result.cat.meta_description.substring(0, 200) : result.cat.description?.substring(0, 200),
            }
            setMetaData(siteMetadata)
            console.log('isMobile', isMobile)
            if(isMobile){
               myRef?.current.scrollIntoView( { behavior: 'smooth', block: 'start' } )
            }
            setProcess(true)
         }
		})
   }

   const getProducts = (pg) => {
      console.log('filter brand',brand, priceRange)

      let paginate = `page=${pg}&limit=${LIMIT}`;
      let search = ''
      if(searchTerm){
         search = `&search=${searchTerm}`
      }
      let brand_id = brand > 0 ? `&brand_id=${brand}` : '';
      let price = priceRange[0] > 0 ? `&price=${priceRange}` : '';

		fetch(`${API_ENDPOINT}/get_products_front?${paginate}${search}${brand_id}${price}`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log('cat products======',result)
			//setProducts(result.data)
         if(pg === 1){
            setProducts(result.data)
         }else{
            setProducts([...products, ...result.data])
         }
         let nextPage = page + 1;
         setPage(nextPage)
         setTotalRec(result.total)

         if(result.data.length < LIMIT){
            setHasMore(false)
         }else{
            setHasMore(true)
         }

			setMetaData({
            url: `${APP_URL}${Links.CATEGORY}`,
            title:  `Category - ${APP_NAME}`,
            description:'Category'
         })
         
         if(isMobile){
            myRef?.current?.scrollIntoView( { behavior: 'smooth', block: 'start' } )
         }
         setProcess(true)
         
         /* let p = document.getElementById('product-list')
         p.scrollIntoView( { behavior: 'smooth', block: 'start' } ); */
		})
	}

   const fetchData = () => {
      console.log('page==',page)
		//this.getProducts(this.state.page)
      if(slug && !sub_slug){
         getProductByCatSlug(page, slug)
         
      }else if(sub_slug){
         //console.log(sub_slug)
         getProductByCatSlug(page, sub_slug)
      }else{
         getProducts(page)
      }
	}

   const getAllCategory = () => {
      fetch(`${API_ENDPOINT}/get_all_category`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log('categories======',result)
			setCategory(result.data)
			
		})
   }

   const getActiveKey = (key) => {
      console.log('getActiveKey======',key[0])
      setPage(1)
      if(key[0]){
         navigate(`${Links.CATEGORY}/${key[0]}`);
      }else{
         navigate(`${Links.CATEGORY}`);
      }
      updateSearchTerm('');
      const filteredData = category.filter((item, i) =>  {
         if(item.slug == key[0]){
            item.isActive = true;
         }else{
            item.isActive = false;
         }
         return item
     });
     //console.log('getActiveKey======',filteredData)
     setCategory(filteredData)

     
   }
   
   const handleBrandFilter = (brand) => {
      console.log('brand_id======',brand)
      //history.push('/dresses?color=blue')
      setBrand(brand.brand_id)
      /* const params = { brand:  brand.brand_name};
      let path = '/category';
      if(slug && !sub_slug){
         path += `/${slug}`
      }else if(sub_slug){
         path += `/${sub_slug}`
      }
      navigate({
         pathname: path,
         search: `?${createSearchParams(params)}`,
      }); */
   }

   const onFinalChange = (value) => {
      console.log(value)
      setPriceRange(value)
   }
   const clearPriceFilter = () => {
      setPriceRange([0,0])
      setValues([MIN, MAX])
   }

   return(
      <ScrollPageTop>
      {/* <ReactSEOMetaTags
         render={(el: React.ReactNode) => <Helmet>{el}</Helmet>}
         website={{ ...metaData }}
		/> */}
      <Seo 
         title={metaData.title}
         desc={metaData.description}
         url={metaData.url}
         image={`./img/home-bg.jpg`}
      />
      <Header />
      
      
      <div className="flex flex-col lg:flex-row bg-gray-100 dark:bg-gray-800  py-6">
				<div className="h-full bg-gray-100 dark:bg-gray-800 lg:w-[380px] xl:block">
					<aside className="h-full w-full lg:w-[380px] lg:bg-gray-100 lg:dark:bg-gray-800 xl:block undefined">
						<div className="os-host os-host-foreign os-theme-thin-dark os-host-resize-disabled os-host-scrollbar-horizontal-hidden os-host-transition os-host-overflow os-host-overflow-y">
							<div className="os-padding">
								<div className="os-viewport os-viewport-native-scrollbars-invisible">
									<div className="os-content" style={{padding: 0, height: 'auto', width: '100%'}}>
										<div className="p-6 ">
                                 {category &&
                                 <Collapse
                                    accordion={true}
                                    defaultActiveKey={slug ? slug : null}
                                    expandIcon={() => null}
                                    className="p-5 bg-light"
                                    openMotion={motion}
                                    onChange={getActiveKey}
                                 >
                                 {
                                    category.map((val, key) => (
                                    <Collapse.Panel
                                       key={`${val.slug}`}
                                       header={
                                          <button class="flex w-full items-center py-2 font-semibold text-body-dark outline-none transition-all ease-in-expo  focus:text-accent focus:outline-none focus:ring-0 ltr:text-left rtl:text-right text-accent text-sm">
                                             <span class="flex h-5 w-5 items-center justify-center ltr:mr-4 rtl:ml-4">
                                                {val.cat_image &&
                                                <img src={`${IMG_URL}${val.cat_image}`} alt={val.cat_name} />
                                                }
                                             </span>
                                             <span>{val.cat_name}</span>
                                             <span className={classNames("ltr:ml-auto ltr:mr-4 rtl:ml-4 rtl:mr-auto transition-all",{
                                                'rotate-180':val.isActive
                                             })}>
                                                <BiDownArrow />
                                             </span>
                                          </button>
                                       }
                                       headerClass="accordion-title"
                                       >
                                          <ul class="text-xs ltr:ml-4 rtl:mr-4" >
                                             {
                                             val.sub_cat && val.sub_cat.map((item, index) => (
                                             <React.Fragment key={`sub-cat-${item.cat_id}`}>
                                             <li class="rounded-md py-1" >
                                                <Link to={`/shop/${val.slug}/${item.slug}`} class="flex w-full items-center py-2 font-semibold text-body-dark outline-none transition-all ease-in-expo  focus:text-accent focus:outline-none focus:ring-0 ltr:text-left rtl:text-right text-body-dark text-sm text-body ltr:ml-5 rtl:mr-5 dark:text-zinc-400">
                                                   <span>{item.cat_name}</span>
                                                   <span class="ltr:ml-auto ltr:mr-4 rtl:ml-4 rtl:mr-auto"></span>
                                                </Link>
                                             </li>
                                             </React.Fragment>
                                             ))
                                             }
                                          </ul>
                                    </Collapse.Panel>
                                    ))
                                 }   
                                 </Collapse>
                                 }
										</div>
            
                              {
                              process && 
                              <>
                              <div className="px-6 pb-6">
                                 <BrandFilter handleBrandFilter={handleBrandFilter} />
                              </div>

                              <div className="px-6 pb-6">
                                 <div className="h-full w-full bg-light dark:bg-gray-700 p-5">
                                    <div className="flex items-center justify-between">
                                       <div className="text-heading dark:text-zinc-400">Price range</div>
                                       <Button 
                                          size='smaller'
                                          variant='normal'
                                          onClick={clearPriceFilter}
                                       >
                                       <CloseIcon className="w-4 h-4 pt-0.5 mr-0.5" /> Reset
                                       </Button>
                                    </div>
                                    <div className="h-10"></div>
                                    <Range
                                       step={STEP}
                                       min={MIN}
                                       max={MAX}
                                       values={values}
                                       onChange={(values) => setValues(values)}
                                       onFinalChange={onFinalChange}
                                       renderTrack={({ props, children }) => (
                                          <div
                                             onMouseDown={props.onMouseDown}
                                             onTouchStart={props.onTouchStart}
                                             style={{
                                             ...props.style,
                                             height: '36px',
                                             display: 'flex',
                                             width: '100%',
                                             }}
                                          >
                                             <div
                                             ref={props.ref}
                                             style={{
                                                height: '5px',
                                                width: '100%',
                                                borderRadius: '4px',
                                                background: getTrackBackground({
                                                   values,
                                                   colors: ['#ccc', '#009f7f', '#ccc'],
                                                   min: MIN,
                                                   max: MAX,
                                                   rtl
                                                }),
                                                alignSelf: 'center'
                                             }}
                                             >
                                             {children}
                                             </div>
                                          </div>
                                       )}
                                       renderThumb={({ index, props, isDragged }) => (
                                          <div
                                             {...props}
                                             style={{
                                             ...props.style,
                                             height: '30px',
                                             width: '30px',
                                             borderRadius: '20px',
                                             backgroundColor: '#FFF',
                                             display: 'flex',
                                             justifyContent: 'center',
                                             alignItems: 'center',
                                             boxShadow: '0px 2px 6px #AAA',
                                             outline:'none'
                                             }}
                                          >
                                             <div
                                             style={{
                                                position: 'absolute',
                                                top: '-28px',
                                                color: '#fff',
                                                fontWeight: 'bold',
                                                fontSize: '14px',
                                                fontFamily: 'Arial,Helvetica Neue,Helvetica,sans-serif',
                                                padding: '4px',
                                                borderRadius: '4px',
                                                backgroundColor: '#009f7f'
                                             }}
                                             >
                                             {values[index].toLocaleString(undefined, { minimumFractionDigits: 2 })}
                                             </div>
                                             <div
                                             style={{
                                                height: '10px',
                                                width: '10px',
                                                borderRadius: '20px',
                                                backgroundColor: isDragged ? '#009f7f' : '#CCC'
                                             }}
                                             />
                                          </div>
                                       )}
                                       />
                                    </div>
                              </div>
                              </>
                              }
									</div>
                           
								</div>
                        
							</div>                     
						</div>
                  
					</aside>
               
				</div>
            
				<main ref={myRef} id="product-list" className="block w-full lg:mt-6 xl:overflow-hidden ltr:xl:pl-0 ltr:xl:pr-5 rtl:xl:pr-0 rtl:xl:pl-5">
            {
            process
            ?
					<div name="grid"  className="px-4 xl:px-0">
						<div className="w-full pb-6">
                  {
                  products && totalRec > 0 ?
                  <InfiniteScroll
							dataLength={products.length} //This is important field to render the next data
							next={fetchData}
							hasMore={hasMore}
							loader={
								<h4 className="h-20 mt-5"><Loading className="w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />Loading...</h4>
							}
                     scrollThreshold={0.7}
							>
							<div layout className="grid grid-cols-[repeat(auto-fill,minmax(250px,1fr))] gap-3">
                     {
								products && products.map((item, key) => (
                           <ProductCard item={item} key={`product-${item.p_id}`} />
								))
							}                     
							</div>      
                  </InfiniteScroll>               
                  :
                     <NoRecords message="Sorry, No Product Found :(" />
                  }
							{/* <div className="mt-8 flex justify-center lg:mt-12"><button data-variant="normal" className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-0 h-12 h-11 text-sm font-semibold md:text-base">Load More</button></div> */}
						</div>
					</div>
               :
                  <div className="w-full h-96 flex items-center justify-center">
                     <Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />
                  </div>
               }
				</main>
			</div>
         
      <Footer />
      </ScrollPageTop>
   )
}

export default Category