import {Fragment, useRef, useState, useEffect} from "react";
import { Dialog, Transition } from '@headlessui/react'
import {APP_URL, APP_NAME, API_ENDPOINT, IMG_URL} from '../config/Common'
import { Link } from "react-router-dom";
import {Loading,CloseIcon} from "../../custom-icons";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import moment from 'moment';

const PopupBanner = (props) => {

   const [bannerloading, setBannerloading] = useState(false)
   const [banner, setBanner] = useState(null)   
   const [open, setOpen] = useState(false)
   const cancelButtonRef = useRef(null)

   useEffect(() => {
      
      /*let newdate = moment().add(1, 'days').format('YYYY-MM-DD');
      let now = moment().format('YYYY-MM-DD');
      let popupAd = localStorage.getItem('popupAd')
      if(popupAd){
         get_home_slider()
         localStorage.setItem('popupAd',newdate);         
      }else if(now > popupAd){
         get_home_slider()
         localStorage.setItem('popupAd',newdate);
      }else{
         setOpen(false)
      }*/
      get_home_slider()
      
      let popupAd = localStorage.getItem('popupAd')
      if(!popupAd){
         get_home_slider()
         let newdate = moment().add(1, 'days').format('YYYY-MM-DD');
         localStorage.setItem('popupAd',newdate);         
      }

      const interval = setInterval(() => {
          console.log('This will run every second!');
          get_home_slider()
      }, (1000*60*5));

      return () => clearInterval(interval);

      //console.log('popupBanner',localStorage.getItem('popupAd'))

      
   },[]);

   const get_home_slider = () => {
      setBannerloading(true);

      fetch(`${API_ENDPOINT}/get_home_slider`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json', 
         }
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         console.log('Popup======',result)
        setBannerloading(false);
         if(result.status === 1){
           setBanner(result.data);    
           setOpen(true)
           //setBanner(bannerTemp)
         }else{
            setOpen(false)

         }
      })
     setBannerloading(false);
   }

   const closeModal = () => {
      setOpen(false)
   }

   return(
      <>
      {
      banner &&
      <Transition.Root show={open} as={Fragment} dir='ltr'>
      <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-center justify-center p-5 pt-44 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
               <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
               <div className="bg-light p-2 md:min-h-0 md:rounded-xl">
                  <div className="text-xs text-gray-500 -mt-2">Sponsored</div>
                  <button aria-label="Close panel" className="absolute -top-3 z-[60] bg-gray-900 p-1 text-light rounded-full inline-block outline-none focus:outline-none ltr:-right-2" onClick={closeModal}>
                     <CloseIcon className="h-4 w-4" />
                  </button>
                  {
                     banner.slice(0, 1).map((item, key) => (
                     <Link to={item.link}><LazyLoadImage src={`${IMG_URL}/${item?.image}`} class="w-full object-fit-cover " /></Link>
                     ))
                  }
                  
               </div>
               </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
      }
      </>
   )
}

export default PopupBanner