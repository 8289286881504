import {Fragment, useRef, useState, useEffect} from "react";
import Table from 'rc-table';
import {APP_URL, IMG_URL, amount_format,API_ENDPOINT, toast_anim} from '../config/Common'
import { Link  } from "react-router-dom";
import { BiRupee } from "react-icons/bi";
import {Links} from '../../routes/PathLink'
import Button from '../../ui/Button.tsx'
import { Dialog, Transition } from '@headlessui/react'
import { toast } from 'react-toastify';
import {Loading,CloseIcon} from "../../custom-icons";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Formik, Field, Form } from 'formik';
import {
  Control,
  FieldErrors,
  useForm,
  useFormState,
  useWatch,
} from 'react-hook-form';
import FileInput from '../../ui/file-input.jsx';
import SelectInput from '../../ui/select-input';
import Input from '../../ui/input';
import TextArea from '../../ui/text-area.tsx';
import axios from 'axios';
const ratingoptions =[{
    'id':1,
    'label':'1',
  },
  {
    'id':2,
    'label':'2',
  },
  {
    'id':3,
    'label':'3',
  },
   {
    'id':4,
    'label':'4',
  },
   {
    'id':5,
    'label':'5',
  }
];

const ValidationSchema = Yup.object().shape({
   rate: Yup.string()      
      .required('Rating is required.'),
   name: Yup.string()      
      .required('Name is required.'),
   review: Yup.string()
      .min(5, 'Too short')
      .required('Review is required.'),
});

const WriteReview = (props) => {
   const {reviewProduct, closeModal} = props
   //const [product_url, setProduct_url] = useState('')
   const [open, setOpen] = useState(false)
   const [loading, setLoading] = useState(false)
   const [rate, setRate] = useState(5)
   
   const cancelButtonRef = useRef(null)
   const [accessToken, setAccessToken] = useState(localStorage.getItem('access_token'))
   const fileRef = useRef();

   const { register, handleSubmit , control , formState: { errors },} = useForm({
     resolver: yupResolver(ValidationSchema),
  });


   const onSubmit = (values) =>{
      //console.log(values)
      /*let img = []
      if(values?.review_images.length > 0){
         values?.review_images.map((vl, ind) => {
            img[ind] = vl.newimageforserver[0] 
         })
      }*/

      const input = {
         rate: values?.rate,
         name: values?.name,
         review:values?.review,
         title:values?.title,
         review_images: values?.review_images,
      };

      //console.log(input, img)
      /*let formData = new FormData;
      formData.append('rate', values?.rate);
      formData.append('name', values?.name);
      formData.append('review', values?.review);
      formData.append('title', values?.title);
      formData.append('review_images', values?.review_images);

      console.log(formData.FormData, reviewProduct.p_id)*/
      //
      /*axios({
           method: "POST",
           url: `${API_ENDPOINT}/write_user_review/${reviewProduct.p_id}`,
           headers: {              
              'Content-Type': 'application/x-www-form-urlencoded',
              'Authorization': 'Bearer '+accessToken, 
            },
           data: input,
         })
         .then(res => {
           console.log("res===", res.data);
         })
         .catch(err => {
           console.log("error in request", err);
         });*/
      setLoading(true)
      fetch(`${API_ENDPOINT}/write_user_review/${reviewProduct.p_id}`,{
         method: 'POST',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+accessToken,

         },
         body: JSON.stringify(input)
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         console.log('review result===',result)   
         setLoading(false)
         if(result.status === 1){
            //resetForm({values:''})
            toast_anim(result.message)
            closeModal()            
         }
      })
   }
   return (
      <>
      
      <div>
         <h1 className="mb-4 text-center text-lg font-semibold text-heading sm:mb-6">Write a Review</h1>
         {
            reviewProduct && 
         <div className="flex items-center gap-2">
            <div className="relative flex h-20 w-20 shrink-0 overflow-hidden rounded ">
               
               <img
                  src={`${IMG_URL}${reviewProduct.product_image}`}
                  className="h-full w-full object-contain"
                  alt={reviewProduct.product_name}
               />
            </div>
            <div>{reviewProduct.product_name}</div>   
         </div>
         }
         <div className="py-5">
         
         <form onSubmit={handleSubmit(onSubmit)} className="grid h-full grid-cols-2 gap-5" enctype="multipart/form-data">

            <div className="col-span-1">
               <label htmlFor="rate" className="block mb-3 text-sm font-semibold leading-none text-body-dark">Rate<span class="text-red-500"> *</span></label>                        
               
               {/*<SelectInput
                  name='rate'
                  control={control}
                  options={ratingoptions}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.id}
                  {...register('rate')}
                  />*/}
               <select defaultValue={rate} {...register('rate')} className="flex w-full appearance-none items-center px-4 text-sm text-heading transition duration-300 ease-in-out focus:outline-none focus:ring-0 border border-border-base rounded focus:border-accent h-12">
                    <option value="5">5</option>
                    <option value="4">4</option>
                    <option value="3">3</option>
                    <option value="2">2</option>
                    <option value="1">1</option>
                </select>   
               {errors.rate?.message &&
               <p className="mt-2 text-xs text-red-500">{errors.rate?.message}</p>
               }
            </div>
            <div className="col-span-1">
               
               <Input
                label={'Name'}
                {...register('name')}
                error={errors.name?.message}
                variant="outline"
                className="mb-5"
                required
              />
            </div>
            <div className="col-span-2">
               
               <Input
                label={'Review Heading'}
                {...register('title')}
                error={errors.title?.message}
                variant="outline"
                className="mb-5"
              />
            </div>
            <div className="col-span-2">
       
                  <TextArea
                label={'Review Body'}
                {...register('review')}
                error={errors.review?.message}
                variant="outline"
                className="mb-5"
                required
              />
               </div>
             {/*<div className="col-span-2">
                  <label htmlFor="review" className="block text-body-dark font-semibold text-sm leading-none mb-3">Review Image</label>
                  <FileInput name="review_images" control={control} />
               </div>*/}
               
               {
               !loading
               ?
               <button data-variant="normal" className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-0 h-12 col-span-2 w-full" type="submit">Submit</button>
               :
               <Loading className="animate-spin h-10 w-10 mb-3 text-gray-300 fill-accent" />
               }
         </form>
         </div>
      </div>
      
      </>
   )

}

export default WriteReview;