import React, {useState, useEffect} from "react";
import { Drawer } from "flowbite";
import {CartCheckBagIcon, CloseIcon, EmptyCartIcon} from "../custom-icons";
import { motion } from 'framer-motion';
import Counter from './Counter';
import cn from 'classnames';
import { BiRupee } from "react-icons/bi";
import {API_ENDPOINT, APP_URL, IMG_URL, amount_format} from '../components/config/Common'
import { useCart } from '../cart/cart-utils'
import AddToCart from '../cart/add-to-cart'
import { useNavigate, useLocation } from "react-router-dom";
import {useAuth} from '../provider/AuthProvider'

const CartDrawer = (props) => {
   //const [cartItem, setCartItem] = useState(false)
   //const [cartTotal, setCartTotal] = useState(0)
   const [access_token, setAccess_token] = useState(localStorage.getItem('access_token'))
   const { token, getCartItems, cartItem, cartTotal, deleteCartItem, updateItemQty, setCartItem } = useCart();
   const navigate = useNavigate();
   const location = useLocation();
   const { isUser } = useAuth();

   useEffect(() => {
      setAccess_token(localStorage.getItem('access_token'))
      getCartItems();
      //getToken();
      //console.log('======', cartItem)
   },[access_token]);

   const handleRemoveClick = (p_id, qty) => {
      //console.log('remove', p_id,qty)
      let q = qty - 1;
      if(q <= 0){
         deleteCartItem(p_id)
      }else{
         updateItemQty(p_id, q)   
      }
   }
   const handleIncrement = (p_id, qty , item) => {
      //console.log('Increment',item)
      let data_cat = item?.product?.cat_id.split();
      const allowquntychange = data_cat.find(obj => {
                 //console.log("ALLOW chnage",obj);
                  if(obj == 22){
                     return true;
                  }
              });
      //console.log("INCREs",allowquntychange);
      if(allowquntychange){
         updateItemQty(p_id, Number(qty)+1);
      }else{
         
      }
      
   }
   
   const removeFromCart = (id) => {
      //console.log('remove from cart', id)
      setTimeout(() => {
         deleteCartItem(id)
      }, 500);

      const filteredData = cartItem.filter((item, i) =>  {
         if(item.product_id === id){
             item.removed = true;
         }
         return item
     });
     setCartItem(filteredData)
     //this.setState({ wishlist: filteredData });
   }

   const go_to_checkout = () => {
      //console.log('isUser========', isUser)
      
      if(access_token){
         if(location.pathname !== '/checkout'){
            let cartDrawer = document.querySelector(".cart-drawer");
            let body = document.querySelector("body");
            cartDrawer.remove();
            body.classList.remove("overflow-hidden");
            return navigate("/checkout");
            
         }
      }else{
         if(!isUser){
            props.openLogin()
         }else{
            let cartDrawer = document.querySelector(".cart-drawer");
            let body = document.querySelector("body");
            cartDrawer.remove();
            body.classList.remove("overflow-hidden");
            return navigate("/checkout");
         }
      }
   }

   const closeDrawer = () => {
      props.closeDrawer()
   }

   const disableQty = (item) =>{
      let data_cat = item?.cat_id.split();
         const allowquntychange = data_cat.find(obj => {
                 return obj.cat_id == 9;
              });
         return !allowquntychange;
      //return true;
   }


   const outOfStock = false
   return (
      
      <div id="drawer-right-example" className="fixed top-0 right-0 z-50 h-screen overflow-y-auto transition-transform translate-x-full bg-white w-4/5 md:2/5 max-w-md dark:bg-gray-800 " tabIndex="-1" aria-labelledby="drawer-right-label">
             <section className="relative flex h-full flex-col">
               
                <header className="fixed top-0 z-10 w-4/5 md:2/5 max-w-md flex items-center justify-between border-b border-border-200 border-opacity-75 bg-light py-4 px-6">
                   <div className="flex font-semibold text-accent">
                      <CartCheckBagIcon className="shrink-0" width={24} height={22} />
                      <span className="flex ltr:ml-2 rtl:mr-2">
                         {cartItem.length} Items
                      </span>
                   </div>
                   <button className="flex h-7 w-7 items-center justify-center rounded-full bg-gray-100 text-muted transition-all duration-200 hover:bg-accent hover:text-light focus:bg-accent focus:text-light focus:outline-none ltr:ml-3 ltr:-mr-2 rtl:mr-3 rtl:-ml-2" onClick={closeDrawer}
                   >
                      <span className="sr-only"></span>
                      <CloseIcon className="h-3 w-3" />
                   </button>
               </header>
               {
               cartItem.length > 0 ?
               <>
               <motion.div layout className="flex-grow pt-16 pb-20">
                  {
                  cartItem.length > 0 && cartItem.map((item, index) => (
                  <motion.div
                     layout
                     initial="from"
                     animate="to"
                     exit="from"
                     key={`cart-${item.cart_id}`} 
                     className={cn('flex items-center border-b border-solid border-border-200 border-opacity-75 py-4 px-4 text-sm sm:px-6 animate__animated',{'animate__bounceOutUp':item.removed})}>
                     <div className="flex-shrink-0">
                        <Counter
                           value={item.quantity}
                           onDecrement={() => handleRemoveClick(item.product_id, item.quantity)}
                           onIncrement={() => handleIncrement(item.product_id, item.quantity , item)}
                           variant="pillVertical"
                           disabled={outOfStock}
                        />
                     </div>
                     <div className="relative mx-4 flex h-10 w-10 shrink-0 items-center justify-center overflow-hidden bg-gray-100 sm:h-16 sm:w-16">
                        {
                        item.product.product_image
                        ?
                        <img src={`${IMG_URL}${item.product.product_image}`} alt={item.product.product_name} />
                        :
                        <img src={`${APP_URL}/img/no-image-available.png`} alt={item.product.product_name} />
                        }
                     </div>
                     <div>
                        {/* <h3 className="font-bold text-heading">{item.name}</h3> */}
                        <h3 className="font-bold text-heading">{item.product.product_name} </h3>
                        <p className="my-2.5 font-semibold text-accent flex items-center">
                           {amount_format(item.amount)}
                           
                        </p>
                        <span className="text-xs text-body flex items-center">
                           {item.quantity} X {amount_format(item.amount)}
                        </span>
                     </div>
                     <div className="font-bold text-heading ltr:ml-auto rtl:mr-auto flex items-center">
                        {amount_format(item.item_total)}
                     </div>
                     <button
                        className="flex h-7 w-7 shrink-0 items-center justify-center rounded-full text-muted transition-all duration-200 hover:bg-gray-100 hover:text-red-600 focus:bg-gray-100 focus:text-red-600 focus:outline-none ltr:ml-3 ltr:-mr-2 rtl:mr-3 rtl:-ml-2" onClick={() =>removeFromCart(item.product_id)}
                        >
                        <span className="sr-only"></span>
                        <CloseIcon className="h-3 w-3" />
                     </button>
                  </motion.div>
                  ))
                  }
               </motion.div>
               {
               cartTotal > 0 &&
               <footer className={cn("fixed bottom-0 z-10 w-4/5 md:2/5 max-w-md py-5 px-6 animate__animated",{'animate__bounceIn':props.drawerVisible})}>
                  <button
                     className="flex h-12 w-full justify-between rounded-full bg-accent p-1 text-sm font-bold shadow-700 transition-colors hover:bg-accent-hover focus:bg-accent-hover focus:outline-none md:h-14" onClick={go_to_checkout}>
                     <span className="flex h-full flex-1 items-center px-5 text-light">
                        Checkout
                     </span>
                     <span className="flex h-full shrink-0 items-center rounded-full bg-light px-5 text-accent">
                     {amount_format(cartTotal)}
                     
                     </span>
                  </button>
               </footer>
               }
               </>
               :
                  <motion.div
                     layout
                     initial="from"
                     animate="to"
                     exit="from"
                     className="flex h-full flex-col items-center justify-center animate__animated animate__bounceIn"
                  >
                     <EmptyCartIcon width={140} height={176} />
                     <h4 className="mt-6 text-base font-semibold">
                     No products found
                     </h4>
                  </motion.div>
               }
             </section>
         </div>
         
   );

}
export default CartDrawer;