import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import Logo from '../../layouts/logo';
import LoginView from './LoginView';
import RegisterView from './RegisterView';
import CustomerRegister from './CustomerRegister';
import ForgotPassword from './ForgotPassword';

import {CloseIcon} from '../../custom-icons'
import { Link } from "react-router-dom";
import SocialLogin from './SocialLogin'
import HospitalSocialLogin from './HospitalSocialLogin'
import CustomerSocialLogin from './CustomerSocialLogin'
import LoginWithMobileOTP from './LoginWithMobileOTP';
import ModalView from '../../_utils/ModalView'

export default function AuthModalView(props) {
  const [open, setOpen] = useState(true)
  
  const [openForm, setOpenForm] = useState(props.authView)
  
  const [socialData, setSocialData] = useState(null)
  const [isOpenotplogin, setIOpenotplogi] = useState(false)
  const [selected_utype, setSelected_utype] = useState('');

  const cancelButtonRef = useRef(null)

  const handleView = (view) => {
    setOpenForm(view)
  }
  const closeModal = () => {
    setOpen()
    props.openLoginModal()
  }

  const getSocialData = (data) => {
    //console.log(data)
    setSocialData(data)
  }

  const closeOtpModal = () =>{
    setIOpenotplogi(false);
  }

  
  return (
    <>
    <Transition.Root show={open} as={Fragment} dir='ltr'>
      <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <button aria-label="Close panel" className="absolute top-4 z-[60] inline-block outline-none focus:outline-none ltr:right-4 rtl:left-4 lg:hidden" onClick={closeModal}>
                  <CloseIcon className="h-4 w-4" />
                </button>
                <div className="flex h-full min-h-screen w-screen flex-col justify-center bg-light py-6 px-5 sm:p-8 md:h-auto md:min-h-0 md:max-w-[480px] md:rounded-xl">
                                  
                  {
                    openForm === 'LoginView' 
                    ?
                    <>
                      <div className="flex justify-center">
                        <h1 className="text-2xl font-black text-accent">Hospital/Diagnostic Login</h1>
                      </div>                      
                      <LoginView handleView={handleView} user_type='user' {...props} />
                      <div className="relative mt-8 mb-6 flex flex-col items-center justify-center text-sm text-heading sm:mt-11 sm:mb-8"><hr className="w-full" /><span className="absolute -top-2.5 bg-light px-2 ltr:left-2/4 ltr:-ml-4 rtl:right-2/4 rtl:-mr-4">Or </span></div>
                       <button data-variant="normal" onClick={()=>{setIOpenotplogi(true); setSelected_utype('user')}} className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-0 h-12 h-11 w-full sm:h-12" >Login With Mobile Number/ OTP</button>
 
                      <SocialLogin 
                        mode='Login' 
                        user_type='hospital' 
                        handleView={handleView} 
                        getSocialData={getSocialData}
                        closeModal={closeModal}
                        {...props}
                      />

                      <div className="relative mt-8 mb-6 flex flex-col items-center justify-center text-sm text-heading sm:mt-11 sm:mb-8"><hr className="w-full" /><span className="absolute -top-2.5 bg-light px-2 ltr:left-2/4 ltr:-ml-4 rtl:right-2/4 rtl:-mr-4">Or</span></div>

                      <div className="text-center text-sm text-body sm:text-base">Don't have any account? <button className="font-semibold text-accent underline transition-colors duration-200 hover:text-accent-hover hover:no-underline focus:text-accent-hover focus:no-underline focus:outline-none ltr:ml-1 rtl:mr-1" onClick={() => handleView('RegisterView')}>Register</button></div>
                    </>  
                    :
                    openForm === 'RegisterView'
                    ?
                    <>
                      <div className="flex justify-center">
                        <h1 className="text-2xl font-black text-accent">Hospital/Diagnostic Register</h1>
                      </div>  
                      <RegisterView user_type='user' {...props} />

                      <SocialLogin 
                        mode='Register' 
                        user_type='hospital' 
                        handleView={handleView} 
                        getSocialData={getSocialData}
                        closeModal={closeModal}
                        {...props}
                      />

                      <div className="relative mt-8 mb-6 flex flex-col items-center justify-center text-sm text-heading sm:mt-11 sm:mb-8"><hr className="w-full" /><span className="absolute -top-2.5 bg-light px-2 ltr:left-2/4 ltr:-ml-4 rtl:right-2/4 rtl:-mr-4">Or</span></div>

                      <div className="text-center text-sm text-body sm:text-base">Already have an account? <button className="font-semibold text-accent underline transition-colors duration-200 hover:text-accent-hover hover:no-underline focus:text-accent-hover focus:no-underline focus:outline-none ltr:ml-1 rtl:mr-1" onClick={() => handleView('LoginView')}>Login</button></div>
                    </>
                    :
                    openForm === 'socialLogin' && socialData 
                    ?
                    <HospitalSocialLogin social_data={socialData} {...props} />
                    : 
                    openForm === 'ForgotPassword' 
                    ?
                    <>  
                      <ForgotPassword />
                      <div className="relative mt-8 mb-6 flex flex-col items-center justify-center text-sm text-heading sm:mt-11 sm:mb-8"><hr className="w-full" /><span className="absolute -top-2.5 bg-light px-2 ltr:left-2/4 ltr:-ml-4 rtl:right-2/4 rtl:-mr-4">Or</span></div>

                      <div className="text-center text-sm text-body sm:text-base">Back to <button className="font-semibold text-accent underline transition-colors duration-200 hover:text-accent-hover hover:no-underline focus:text-accent-hover focus:no-underline focus:outline-none ltr:ml-1 rtl:mr-1" onClick={() => handleView('LoginView')}>Login</button></div>
                    </>
                    :
                    openForm === 'CustomerLogin' 
                    ?
                    <>
                      <div className="flex justify-center">
                        <h1 className="text-2xl font-black text-accent">Customer Login</h1>
                      </div>                      
                      <LoginView handleView={handleView} user_type='customer' {...props} />
                       <div className="relative mt-8 mb-6 flex flex-col items-center justify-center text-sm text-heading sm:mt-11 sm:mb-8"><hr className="w-full" /><span className="absolute -top-2.5 bg-light px-2 ltr:left-2/4 ltr:-ml-4 rtl:right-2/4 rtl:-mr-4">Or </span></div>
                       <button data-variant="normal" onClick={()=>{setIOpenotplogi(true); setSelected_utype('customer')}} className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-0 h-12 h-11 w-full sm:h-12" >Login With Mobile Number/ OTP</button>
 
                      <SocialLogin 
                        mode='Login' 
                        user_type='customer' 
                        handleView={handleView} 
                        getSocialData={getSocialData}
                        closeModal={closeModal}
                        {...props}
                      />

                      <div className="relative mt-8 mb-6 flex flex-col items-center justify-center text-sm text-heading sm:mt-11 sm:mb-8"><hr className="w-full" /><span className="absolute -top-2.5 bg-light px-2 ltr:left-2/4 ltr:-ml-4 rtl:right-2/4 rtl:-mr-4">Or</span></div>

                      <div className="text-center text-sm text-body sm:text-base">Don't have any account? <button className="font-semibold text-accent underline transition-colors duration-200 hover:text-accent-hover hover:no-underline focus:text-accent-hover focus:no-underline focus:outline-none ltr:ml-1 rtl:mr-1" onClick={() => handleView('CustomerRegister')}>Register</button></div>
                    </>
                    :
                    openForm === 'CustomerRegister'
                    ?
                    <>
                      <div className="flex justify-center">
                        <h1 className="text-2xl font-black text-accent">Customer Register</h1>
                      </div>  
                      <CustomerRegister user_type='customer' {...props} />

                      <SocialLogin 
                        mode='Register' 
                        user_type='customer' 
                        handleView={handleView} 
                        getSocialData={getSocialData}
                        closeModal={closeModal}
                        {...props}
                      />

                      <div className="relative mt-8 mb-6 flex flex-col items-center justify-center text-sm text-heading sm:mt-11 sm:mb-8"><hr className="w-full" /><span className="absolute -top-2.5 bg-light px-2 ltr:left-2/4 ltr:-ml-4 rtl:right-2/4 rtl:-mr-4">Or</span></div>

                      <div className="text-center text-sm text-body sm:text-base">Already have an account? <button className="font-semibold text-accent underline transition-colors duration-200 hover:text-accent-hover hover:no-underline focus:text-accent-hover focus:no-underline focus:outline-none ltr:ml-1 rtl:mr-1" onClick={() => handleView('CustomerLogin')}>Login</button></div>
                    </>
                    :
                    openForm === 'customerSocial' && socialData 
                    &&
                    <CustomerSocialLogin social_data={socialData} {...props} />
                  }

                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
     <ModalView open={isOpenotplogin} onClose={closeOtpModal}>
            <div className="flex h-full min-h-screen w-screen flex-col justify-center bg-light py-6 px-5 sm:p-8 md:h-auto md:min-h-0 md:max-w-[480px] md:rounded-xl">                        
            <LoginWithMobileOTP user_type={selected_utype} closeOtpModal={closeOtpModal} closeModal={closeModal} />
            </div>
            
         </ModalView>
    </>
  )
}