import React, {useState, useEffect} from "react";
import Header from '../../layouts/Header'
import Footer from '../../layouts/Footer'
import Logo from '../../layouts/logo';
import ScrollPageTop from '../../routes/ScrollPageTop'
import {EyeOff, Eye, Loading} from "../../custom-icons";

import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import {API_ENDPOINT} from '../config/Common'
import {useAuth} from '../../provider/AuthProvider'
import { useNavigate } from "react-router-dom";

import { Link } from "react-router-dom";
import {Links} from '../../routes/PathLink'
import ForgotPassword from './ForgotPassword';
import ModalView from '../../_utils/ModalView'
import {deviceDetect} from "react-device-detect"

import SocialLogin from './SocialLogin'
import LoginWithMobileOTP from './LoginWithMobileOTP';

const SignupSchema = Yup.object().shape({
	email: Yup.string()      
		.required('You must need to provide your email.'),
	password: Yup.string()
		.required('You must need to provide your password.'),
});
const DealerLogin = (props) => {
   const [show, setShow] = useState(false);
   const [loading, setLoading] = useState(false);
   const { login } = useAuth();
   let navigate = useNavigate();
   const [isOpen, setIsOpen] = useState(false)
   const [isOpenotplogin, setIOpenotplogi] = useState(false)
   useEffect(() => {
      //console.log("devicedata deviceDetect", deviceDetect())
      //console.log("devicedata browserName", mobileModel)
      if(localStorage.getItem('access_token')){
         return navigate("/", { replace: true });
      }
   })

   const handleView = () => {
		setIsOpen(true)
	}

   const closeModal = () => {
      setIsOpen(false)      
   }

   const closeOtpModal = () =>{
      setIOpenotplogi(false);
   }

   return(
      <ScrollPageTop>
         <Header  hidesearch={true}  hidebanner={true} />
         <div className="flex justify-center">
            <div className="flex h-full min-h-screen w-screen flex-col justify-center bg-light py-6 px-5 sm:p-8 md:h-auto md:min-h-0 md:max-w-[480px] md:rounded-xl my-5">
               <div className="flex justify-center">
               <h1 className="text-xl font-black text-accent">Dealer/Service Engineer/Bio Medical Login</h1>
               </div>         
               <p className="mt-4 mb-8 text-center text-sm text-body sm:mt-5 sm:mb-10 md:text-base">
                  Login with your email & password
               </p>
               <Formik
                  initialValues={{
                     email: '',
                     password: '',
                  }}
                  validationSchema={SignupSchema}
                  onSubmit={(values, { setErrors }) => {
                     //login(values.email);
					      setLoading(true)

                     let input = values;
                     input = {...input, device:deviceDetect()}

                     //console.log(input)
                     //return
                     
                     fetch(`${API_ENDPOINT}/dealer_login`,{
								method: 'POST',
								headers : {        
									'Content-Type': 'application/json',         
								},
								body: JSON.stringify(input)
                     }).then(function (response) {
                           return response.json();
                     }).then( (result)=> {
                        //console.log(result)
                        setLoading(false)
                        if(result.status === 1){
                           localStorage.setItem('access_token',result.access_token);
                           localStorage.setItem('user_type',result.user_type);
                           localStorage.setItem('user_id',result.user_id);
                           localStorage.setItem('user_name',result.user_name);
                           //props.setLogin()
                           login({access_token:result.access_token, user_name:result.user_name})
                           return navigate(Links.AMC_BID, { replace: true });
                        }
                        if(result.status === 0){
                           setErrors({password:result.message})
                        }
                        //setErrors({phone_no:result.message})
                     })
                  }}
               >
               {({ values, errors, touched, handleSubmit, handleChange }) => (
                  <Form>
                     <div className="mb-5">
                        <label for="email" className="block mb-3 text-sm font-semibold leading-none text-body-dark">Email</label>
                        <Field id="email" name="email" type="email" className="flex w-full appearance-none items-center px-4 text-sm text-heading transition duration-300 ease-in-out focus:outline-none focus:ring-0 border border-border-base rounded focus:border-accent h-12" />
                        {
                           errors.email && touched.email &&
                           <p className="mt-2 text-xs text-red-500">{errors.email}</p>
                        }
                     </div>
                     <div className="mb-5">
                        <div className="flex items-center justify-between mb-2">
                           <label htmlFor="password" className="font-semibold text-sm text-body">Password</label>
                           <button type="button" className="text-xs text-accent transition-colors duration-200 focus:outline-none focus:text-accent-700 focus:font-semibold hover:text-accent-hover" onClick={handleView}>Forgot password?</button>
                        </div>
                        <div className="relative">
                           <Field id="password" name="password" type={show ? 'text' : 'password'} className="py-3 ltr:pl-4 rtl:pr-4 ltr:pr-11 rtl:pl-11 w-full rounded appearance-none transition duration-300 ease-in-out text-heading text-sm focus:outline-none focus:ring-0 border border-border-base focus:border-accent" />
                           <label htmlFor="password" className="absolute ltr:right-4 rtl:left-4 top-5 -mt-2 text-body cursor-pointer" onClick={() => setShow((prev) => !prev)}>

                              {show ? (
                                 <EyeOff className="w-6 h-6" />
                              ) : (
                                 <Eye className="w-6 h-6" />
                              )}
                           </label>
                           {
                           errors.password && touched.password &&
                           <p className="mt-2 text-xs text-red-500">{errors.password}</p>
                           }
                        </div>
                     </div>
                     <div className="mt-8 flex justify-center">
                        {
                        !loading
                        ?
                        <button data-variant="normal" className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-0 h-12 h-11 w-full sm:h-12" >Login</button>
                        :
                        <Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600" />
                        }
                     </div>
                  </Form>
               )}
               </Formik>
               <div className="relative mt-8 mb-6 flex flex-col items-center justify-center text-sm text-heading sm:mt-11 sm:mb-8"><hr className="w-full" /><span className="absolute -top-2.5 bg-light px-2 ltr:left-2/4 ltr:-ml-4 rtl:right-2/4 rtl:-mr-4">Or </span></div>
                 <button data-variant="normal" onClick={()=>{setIOpenotplogi(true)}} className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-0 h-12 h-11 w-full sm:h-12" >Login With Mobile Number/ OTP</button>
 
               <SocialLogin mode='Login' user_type='dealer' />

               <div className="relative mt-8 mb-6 flex flex-col items-center justify-center text-sm text-heading sm:mt-11 sm:mb-8"><hr className="w-full" /><span className="absolute -top-2.5 bg-light px-2 ltr:left-2/4 ltr:-ml-4 rtl:right-2/4 rtl:-mr-4">Or</span></div>
               
               <div className="text-center text-sm text-body sm:text-base">Don't have account? <Link to={`${Links.DEALER_SIGNUP}`} className="font-semibold text-accent underline transition-colors duration-200 hover:text-accent-hover hover:no-underline focus:text-accent-hover focus:no-underline focus:outline-none ltr:ml-1 rtl:mr-1" >Register</Link></div>
            </div>
         </div>

         <Footer />
         <ModalView open={isOpenotplogin} onClose={closeOtpModal}>
            <div className="flex h-full min-h-screen w-screen flex-col justify-center bg-light py-6 px-5 sm:p-8 md:h-auto md:min-h-0 md:max-w-[480px] md:rounded-xl">                        
            <LoginWithMobileOTP user_type="dealer" closeOtpModal={closeOtpModal} />
            </div>
            
         </ModalView>

         <ModalView open={isOpen} onClose={closeModal}>
            <div className="flex h-full min-h-screen w-screen flex-col justify-center bg-light py-6 px-5 sm:p-8 md:h-auto md:min-h-0 md:max-w-[480px] md:rounded-xl">                        
            <ForgotPassword />
            </div>
            
         </ModalView>
      </ScrollPageTop>
   )
}

export default DealerLogin