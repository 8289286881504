import React, {useState, useEffect} from "react";

import {API_ENDPOINT} from '../../config/Common'
import Checkbox from '../../../ui/checkbox/checkbox.tsx'
import Radio from '../../../ui/radio/radio.tsx';
import Button from '../../../ui/Button.tsx'
import {CloseIcon} from '../../../custom-icons'
import {isBrowser} from "react-device-detect"



const BrandFilter = (props) => {

   const [brands, setBrands] = useState(null)
   const [brand_id, setBrand_id] =  useState(null)
   
   useEffect(() => {
      getFilterBrands()
   },[])

   const getFilterBrands = () => {
      fetch(`${API_ENDPOINT}/get_brands_for_select`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
         //console.log('brands', result)
         setBrands(result.data)
      })
   }

   const handleChange = (e) => {
      //console.log('onchange', e.target.name, e.target.value)
      setBrand_id(e.target.value)
      //setValue([...value, e.target.value])
      //console.log('onchange', value)
      brands.filter((item, i) =>  {
         if(item.brand_id == e.target.value){
            props.handleBrandFilter({
               brand_name:item.name,
               brand_id:item.brand_id
            })    
         }
      });
      //props.handleBrandFilter(e.target.value)
      if(isBrowser){
         setTimeout(() => {
            window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
            });
         }, 10);
      }
   }

   const clearBrandFilter = () => {
      let radio = document.getElementById(brand_id);
      radio.checked = false;
      props.handleBrandFilter({
         brand_name:null,
         brand_id:null
      }) 
   }

   

   return(
      <div className="h-full w-full bg-light dark:bg-gray-700 p-5">
         <div className="flex items-center justify-between">
            <div className="text-heading dark:text-zinc-400">Brands</div>
            <Button 
               size='smaller'
               variant='normal'
               onClick={clearBrandFilter}
            >
            <CloseIcon className="w-4 h-4 pt-0.5 mr-0.5" /> Clear
            </Button>
         </div>
         <ul class="text-xs" >
            {
            brands && brands.map((val, key) => (
            <li class="rounded-md py-1" key={`brand-${val.brand_id}`}>
               <div class="flex w-full items-center py-2 font-semibold text-body-dark outline-none transition-all ease-in-expo  focus:text-accent focus:outline-none focus:ring-0 ltr:text-left rtl:text-right text-body-dark text-sm text-body dark:text-zinc-400">
                  {/* <Checkbox 
                     name={val.name} 
                     value={val.brand_id}
                     label={val.name}
                     theme='primary'
                     onChange={handleChange}
                  /> */}
                  <Radio
                     name='brand'
                     label={val.name}
                     id={val.brand_id}
                     value={val.brand_id}
                     className="mr-2"
                     onChange={handleChange}
                  />
               </div>
            </li>
            ))
            }
         </ul>

         
      </div>
   )
}

export default BrandFilter