import React, {useState} from "react";
import {ArrowNextIcon} from "../../custom-icons";
import * as Yup from 'yup';
import {
   Control,
   FieldErrors,
   useForm,
   useFormState,
   useWatch,
   Controller
} from 'react-hook-form';
import {
   API_ENDPOINT, 
   random_str, 
   toast_anim_error, 
   LINK_EXPIRE
} from '../config/Common'

import { yupResolver } from '@hookform/resolvers/yup';
import Input from '../../ui/input.jsx'
import Button from '../../ui/Button.tsx'
import axios from 'axios';  

const SignupSchema = Yup.object().shape({
    email: Yup.string()
      .required('You must need to provide your email address.')
      .email('Invalid email.'),
})
const defaultValues = {  
    email:'',
}
const ForgotPassword = () => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const { register, handleSubmit , control, reset, setValue, setError, formState: { errors },} = useForm({
        defaultValues: defaultValues,
        resolver: yupResolver(SignupSchema),
    });

    const onSubmit = (values) => {
        setLoading(true)
        setSuccess(false)
        axios.post(`${API_ENDPOINT}/forgot_password_link`,values,{
            headers: {
                'Content-Type': 'application/json',
            }
        }).then( (result)=> {
            setLoading(false)
            console.log('result', result.data)
            let data = result.data
            if(data.status === 1){
                setSuccess(true)
            }
            if(data.status === 0){
                toast_anim_error('Account is not exist.')
            }
        })
    }
    return(
        <>
            <p className="mt-4 mb-7 px-2 text-center text-sm leading-relaxed text-body sm:mt-5 sm:mb-10 sm:px-0 md:text-base">We'll send you a link to reset your password</p>
            <form onSubmit={handleSubmit(onSubmit)}>
                
                <Input                
                    label={`Email`}
                    {...register('email')}
                    variant="outline"
                    className="mb-5"
                    type="email"
                    error={errors.email?.message}
                    required
                />                    
                <Button 
                    className="w-full"
                    loading={loading}
                >
                    Submit Email <ArrowNextIcon className="w-5" />
                </Button>
                {success && <p className="my-5 text-sm text-start text-green-500">Please check your inbox, we sent link to reset your password. <br /><span className="text-body">Note: Link will expire after {LINK_EXPIRE} hours.</span></p>}
            </form>
        </>
    )
 
}
export default ForgotPassword