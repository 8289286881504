import React, {useState, useEffect, useRef} from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import ScrollPageTop from '../routes/ScrollPageTop'
import {API_ENDPOINT, APP_URL, APP_NAME, LIMIT} from './config/Common'
import {Loading} from "../custom-icons";
import ProductCard from '../cards/ProductCard'
import {Links} from '../routes/PathLink'
import InfiniteScroll from "react-infinite-scroll-component";
import NoRecords from './NoRecords'
import Seo from './seo/seo'

const FeaturedProducts = () => {
   const [process, setProcess] = useState(false)
   const [products, setProducts] = useState(null)
   const [totalRec, setTotalRec] = useState(0)
   
   const [page, setPage] = useState(1)
   const myRef = useRef(null)

   const [metaData] = useState({
      url: `${APP_URL}${Links.FEATURED_PRODUCTS}`,
      title:  `Featured products - ${APP_NAME}`,
      description:'Featured products'
   })
   const [hasMore, setHasMore] = useState(true)
   useEffect(() => {
      getProducts(1)
      
   },[])
   

   const getProducts = (pg) => {
      let paginate = `page=${pg}&limit=${LIMIT}`;
		fetch(`${API_ENDPOINT}/get_featured_products?${paginate}`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log('cat products======',result)
			//setProducts(result.data)
         if(pg === 1){
            setProducts(result.data)
         }else{
            setProducts([...products, ...result.data])
         }
         let nextPage = page + 1;
         setPage(nextPage)
      

         if(result.data.length < LIMIT){
            setHasMore(false)
         }else{
            setHasMore(true)
         }
         setTotalRec(result.total)
         setProcess(true)
         
         /* let p = document.getElementById('product-list')
         p.scrollIntoView( { behavior: 'smooth', block: 'start' } ); */
		})
	}

   const fetchData = () => {
      console.log('page==',page)
		//this.getProducts(this.state.page)
      getProducts(page)
	}

   return(
      <ScrollPageTop>
      
      <Seo 
         title={metaData.title}
         desc={metaData.description}
         url={metaData.url}
         image={`./img/home-bg.jpg`}
      />
      <Header />
      <div className="mx-auto w-full py-5 px-5 xl:px-8 2xl:px-14">
      <h1 className="text-center text-2xl text-body text-heading py-5">Featured Products</h1>
      <div className="flex flex-col lg:flex-row bg-gray-100 dark:bg-gray-800">
      
				<main ref={myRef} id="product-list" className="block w-full lg:mt-6 xl:overflow-hidden ltr:xl:pl-0 ltr:xl:pr-5 rtl:xl:pr-0 rtl:xl:pl-5">
            {
            process
            ?
					<div name="grid"  className="px-4 xl:px-0">
						<div className="w-full pb-6">
                  {
                  products && totalRec > 0 ?
                  <InfiniteScroll
							dataLength={products.length} //This is important field to render the next data
							next={fetchData}
							hasMore={hasMore}
							loader={
								<h4 className="h-20 mt-5"><Loading className="w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />Loading...</h4>
							}
                     scrollThreshold={0.7}
							>
							<div layout className="grid grid-cols-[repeat(auto-fill,minmax(250px,1fr))] gap-3">
                     {
								products && products.map((item, key) => (
                           <ProductCard 
                              item={item} 
                              key={`product-${item.p_id}`} 
                              
                           />
								))
							}                     
							</div>      
                  </InfiniteScroll>               
                  :
                     <NoRecords message="Sorry, No Product Found :(" />
                  
                  }
						</div>
					</div>
               :
                  <div className="w-full h-96 flex items-center justify-center">
                     <Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />
                  </div>
               }
				</main>
			</div>
         </div>
      <Footer />
      </ScrollPageTop>
   )
}

export default FeaturedProducts