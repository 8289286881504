import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import {
   API_ENDPOINT, 
   toast_anim_error,
   toast_anim, 
   APP_NAME, 
   APP_URL,
   IMG_URL,
   machine_condition ,
   accessoriesOptions,
   usageOptions,
   internalsysOptions,
   pngjpgonly
} from '../components/config/Common'
import ScrollPageTop from '../routes/ScrollPageTop'
import LeftMenuLayout from '../layouts/LeftMenuLayout';
import {Links} from '../routes/PathLink'
import classNames from 'classnames';
import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import {
  Control,
  FieldErrors,
  useForm,
  useFormState,
  useWatch,
  Controller
} from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';  
import moment from 'moment';

import FileInput from '../ui/file-input.jsx';
import Input from '../ui/input.jsx'
import Card from '../ui/card.tsx'
import Description from '../ui/description.tsx';
import TextArea from '../ui/text-area.tsx'
import Label from '../ui/label.tsx'
import Radio from '../ui/radio/radio.tsx';
import SelectInput from '../ui/select-input.jsx'
import Button from '../ui/Button.tsx'
import Select from "react-select";
import { useNavigate, useParams } from 'react-router-dom';


const usertype = localStorage.getItem('user_type');
let productValidationSchema = {};
if(usertype == 'user'){
    productValidationSchema = yup.object().shape({
   product_name: yup.string().required('Title is required'),  
   quantity : yup.number()
      .typeError('Quantity must be a number')
      .positive('Quantity must be positive')
      .integer('Quantity must be integer')
      .required('Quantity is required'),
   cat_id:yup.object().nullable().required('Category is required'),
   /* sub_cat:yup.object().nullable().required('Sub Category is required'), */
   brand_id:yup.object().nullable().required('Brand is required'),
   brand_other_name:yup.string().when('brand_id', {
      is: (brand_id) => brand_id?.brand_id == 'Other',//just an e.g. you can return a function
      then: yup.string().required('Brand is required'),
      otherwise:yup.string(),
   }),
   model_id:yup.object().nullable().required('Model is required'),
   model_other_name:yup.string().when('model_id', {
      is: (model_id) => model_id?.model_id == 'Other',//just an e.g. you can return a function
      then: yup.string().required('Model is required'),
      otherwise:yup.string(),
   }),
   usage: yup.object().nullable().required('Usage is required'),
   tube_count:yup.string().required('Usage info level is required'),
   tube_magnet_year:yup.string().required('Internal system infomation year is required'),
   internalsys:yup.object().nullable().required('Internal system infomation is required'),
   accessories:yup.array().min(1, "Pick at least 1 accessory"),
   yom:yup.string().required('YOM (Year of Manufacture) is required'),
 
});
}else{

    productValidationSchema = yup.object().shape({
   product_name: yup.string().required('Title is required'),  
   quantity : yup.number()
      .typeError('Quantity must be a number')
      .positive('Quantity must be positive')
      .integer('Quantity must be integer')
      .required('Quantity is required'),
   cat_id:yup.object().nullable().required('Category is required'),
   /* sub_cat:yup.object().nullable().required('Sub Category is required'), */
   brand_id:yup.object().nullable().required('Brand is required'),
    brand_other_name:yup.string().when('brand_id', {
      is: (brand_id) => brand_id?.brand_id == 'Other',//just an e.g. you can return a function
      then: yup.string().required('Brand is required'),
      otherwise:yup.string(),
   }),
   model_id:yup.object().nullable().required('Model is required'),
   usage: yup.object().nullable().required('Usage is required'),
   tube_count:yup.string().required('Usage info is required'),
   tube_magnet_year:yup.string().required('Internal system infomation Year is required'),
    internalsys:yup.object().nullable().required('Internal system infomation is required'),
   accessories:yup.array().min(1, "Pick at least 1 accessory"),
   yom:yup.string().required('YOM (Year of Manufacture) is required'),
});

}

/*const productValidationSchema = yup.object().shape({
   product_name: yup.string().required('Title is required'),  
   price: yup.number()
      .typeError('Price must be a number')
      .positive('Price must be positive')
      .required('Price is required'),
   quantity : yup.number()
      .typeError('Quantity must be a number')
      .positive('Quantity must be positive')
      .integer('Quantity must be integer')
      .required('Quantity is required'),
   cat_id:yup.object().nullable().required('Category is required'),
   /* sub_cat:yup.object().nullable().required('Sub Category is required'), */
   /*brand_id:yup.object().nullable().required('Brand is required'),
   model_id:yup.object().nullable().required('Model is required'),
   tube_count:yup.string().required('Tube count/Helium level is required'),
   tube_magnet_year:yup.string().required('Tube/Magnet Year is required'),
   accessories:yup.string().required('Accessories is required'),
   yom:yup.string().required('YOM (Year of Manufacture) is required'),
});
*/

//yup.object().nullable().required('form:error-catgeory-required')

let defaultValues = {  
  product_name: '',  
  image:''
};
 var warranty_period = [];
     for (var i = 1; i <= 36; i++) {
      let tempval ={
         label:i+' Year',
         id:i,
      }
      warranty_period.push(tempval);
     }
     
const EditProduct = () => {
   const navigate = useNavigate();
   const [metaData, setMetaData] = useState({
      url: `${APP_URL}${Links.ADD_PRODUCT}`,
      title:  `Add product - ${APP_NAME}`,
      description:'Add product'
   })
   const [product, setProduct] = useState(null)
   const [category, setCategory] = useState(null)
   const [subCategory, setSubCategory] = useState(null)
   const [subSubCategory, setSubSubCategory] = useState(null)
   const [accessToken, setAccessToken] = useState(localStorage.getItem('access_token'))
   const [user_type, setUserType] = useState(localStorage.getItem('user_type'))
   const [accessories_value, setAccessories_value] = useState(null)

   const [loading, setLoading] = useState(false)

   const [brands, setBrands] = useState(null)
   const [models, setModels] = useState(null)
   const [brandselval, setBrandselval] = useState(null)
   const [modelselval, setModelelval] = useState(null)
   const [isother,setIsother] = useState(false);

     
   const {p_id} = useParams()   

   useEffect(() => {
      //console.log(p_id)
      getProductForEdit(p_id)
      getParentCategory()
      getBrandsForSelect()
   },[p_id])

   const { register, handleSubmit , control, reset, setValue, setError, formState: { errors },} = useForm(
      {
        defaultValues: defaultValues,
        resolver: yupResolver(productValidationSchema),
      });

   const getProductForEdit = async (p_id) => {
      await fetch(`${API_ENDPOINT}/get_product/${p_id}`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
            'Authorization': 'Bearer '+accessToken, 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log('edit product======',result)
			setProduct(result.data)			
         let data = result.data
         let accessories = {};
         if(data?.accessories){
            /*let acclist = data?.accessories;

            accessories = {
               'id':data?.accessories,
               'label':data?.accessories,
            };
            */
             let acclabel = [];
            let value = '';
           console.log("accessoriesOptions",data?.accessories);
             let accarray = data?.accessories?.split(',');
             if(accarray?.length > 0){
               accarray.map((item,inx)=>{
                 //if(value.id == item){
                   console.log("MATCH VALUES");
                   let value = {
                     'id':item,
                     'label':item,
                   };
                   //return value.label;
                   //acclabel.push(value);
                   acclabel = [value,...acclabel];

                 //}
               });
             }
             
       
            //console.log("REturn accessories data",acclabel);

            accessories = acclabel;
         }

         let usage ={};
         let tube_count = '';
         if(data?.tube_count){
            let usage_data = data?.tube_count.split("-");
            console.log("DATA",usage_data[0]);
            if(usage_data[1]){
               usage = {
               'id':usage_data[0],
               'label':usage_data[0],
              };
              tube_count = usage_data[1];
            }else{
             tube_count = usage_data[0];
            }

         }


         let internalsystminfo ={};
         let tube_magnet_year = '';
         if(data?.tube_magnet_year){
            let usage_data = data?.tube_magnet_year.split("-");
            console.log("DATA",usage_data[0]);
            if(usage_data[1]){
               internalsystminfo = {
               'id':usage_data[0],
               'label':usage_data[0],
              };
              tube_magnet_year = usage_data[1];
            }else{
             tube_magnet_year = usage_data[0];
            }

         }

         let warrprd = {};
         if(data?.warranty_for && data?.warranty_for > 0){
             warrprd = {label:data?.warranty_for+' Year',
               id:data?.warranty_for };
         }
        

        

         defaultValues = {
            product_name:data.product_name,
            description:data.description,
            price:data.price,
            sale_price:data.sale_price,
            warranty_price:data.warranty_price,
            inStock:data.in_stock == 1 ? 'yes' : 'no',
            condition:data.machine_condition.toString(),
            quantity: data.quantity,
            cat_id:data.cat,
            sub_cat:data.child_cat,
            sub_sub_cat:data.sub_sub_cat,
            product_image:data.product_image,
            gallery_img:data.gallery,
            brand_id:data.brand,
            model_id:data.model,
            yom: data.yom,
            tube_magnet_year:tube_magnet_year,
            internalsys:internalsystminfo,
            tube_count:tube_count,
            delivery_period:data.delivery_period,
            accessories:data.accessories,
            availability_status:data.availability_status,
            dealer_price:data?.dealer_price,
            negotiable_price:data?.negotiable_price,
            accessories:accessories,
            usage:usage,
            is_inuse:data.is_inuse == 1 ? 'yes' : 'no',
            warranty_for:warrprd,
            withwarrant:data?.withwarrant,
            withinstalation:data?.withinstalation,
            isCOD:data?.isCOD === 1 ? '1' : '0'
         };
         reset(defaultValues)
         getSubCategoryByCat(data.cat.cat_id)
         getModelsByBrands(data.brand.brand_id)
         getSecondCategoryByCat(data.child_cat.cat_id)
         //console.log('product======',defaultValues)         
		})
   }

   const getParentCategory = () => {
      fetch(`${API_ENDPOINT}/get_parent_category`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log('categories======',result)
			setCategory(result.data)			
		})
   }

   const getBrandsForSelect = () => {
      fetch(`${API_ENDPOINT}/get_brands_for_select`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			//console.log('brands======',result)
         let otheroption ={
            'brand_id':"Other",
            'name':"Other",
         };
			//setBrands(result.data)		
         setBrands( [...result.data, otheroption])       

		})
   }
   
   const handleMainCat = (val,name) => {
      //console.log('cat val======',val.cat_id)
      setError(name.name, null)
      setSubCategory(null)
      setValue('cat_id', val, true);
      getSubCategoryByCat(val.cat_id)
      setValue('sub_cat', '');
      setValue('sub_sub_cat', null, true);
   }

   const handleSelect = (val,name) => {  
      //console.log('cat val======',val,name.name)      
      setError(name.name, null)
      setValue(name.name, val, true);
      
      if(name.name === 'sub_cat'){
         setSubSubCategory(null)
         getSecondCategoryByCat(val.cat_id)
         setValue('sub_sub_cat', null, true);

      }

      if(name.name === 'brand_id'){
         setModels(null)
         getModelsByBrands(val.brand_id)
         setValue('model_id', null, true);
         setBrandselval(val.brand_id);
      }
      if(name.name === 'model_id'){
         setModelelval(val.model_id);
      }
      if(name.name === 'accessories'){
         setAccessories_value(val);
         setIsother(false);
         setAccessories_value(val);
           //if (accessories_list?.accessories && dirtyFields?.accessories) {
            val.map((item,ind)=>{
              if(item.label == "Other"){
                console.log("Others",item.label);
                  setIsother(true);
              }
            }
         );
      }
   }

   const getModelsByBrands = (brand_id) => {
      fetch(`${API_ENDPOINT}/get_models_by_brands/${brand_id}`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			//console.log('models======',result)
         let otheroption ={
            'model_id':"Other",
            'name':"Other",
         };
        
         setModels( [...result.data, otheroption])    
			//setModels(result.data)			
		})
   }

   const getSubCategoryByCat = (cat_id) => {
      fetch(`${API_ENDPOINT}/get_sub_category/${cat_id}`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			//console.log('sub cat======',result)
			setSubCategory(result.data)			
		})
   }

   const getSecondCategoryByCat = (cat_id) => {
      console.log('sub cat',cat_id)
      fetch(`${API_ENDPOINT}/get_sub_category/${cat_id}`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			//console.log('sub sub cat======',result)
         if(result.status === 1){
			   setSubSubCategory(result.data)			
         }else{
            setSubSubCategory(null)
         }
		})
   }
   

   const onSubmit = async (values) => {
      console.log('on submit======',values)
      setLoading(true)
      const input = {
         product_name: values.product_name,
         description: values.description,
         image: values?.image[0]?.newimageforserver,
         gallery: values?.gallery,
         category:values.cat_id.cat_id,
         sub_category:values?.sub_cat?.cat_id,
         sub_sub_cat:values?.sub_sub_cat?.cat_id,
         quantity: values.quantity,
         sku: values.sku,
         price: values.price,
         sale_price: values.sale_price,
         warranty_price:values.warranty_price,
         in_stock:values.inStock === 'yes' ? '1' : '0',
         condition:values.condition,
         brand:values?.brand_id?.brand_id,
         brand_other_name:values?.brand_other_name,
         model:values?.model_id?.model_id,
         model_other_name:values?.model_other_name,
         yom:values.yom,
         internalsys:values?.internalsys?.id,
         tube_magnet_year:values.tube_magnet_year,
         usage:values?.usage?.id,
         tube_count:values.tube_count,
         delivery_period:values.delivery_period,
         accessories:values.accessories,
         availability_status:values.availability_status,
         dealer_price:values?.dealer_price,
         negotiable_price:values?.negotiable_price,
         is_inuse:values?.is_inuse === 'yes' ? '1' : '0',
         warranty_for:values?.warranty_for?.id,
         withwarrant:values?.withwarrant,
         withinstalation:values?.withinstalation,
         isCOD:values.isCOD,
      }
      //console.log('on submit======',input)
      /* const formData = new FormData();
      formData.append('image',values?.image[0]?.newimageforserver) */

      axios.post(`${API_ENDPOINT}/update_seller_product/${p_id}`,input,{
         headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer '+accessToken, 
         }
      }).then( (result)=> {
         //console.log('data=====',result.data)
         setLoading(false)
         if(result.data.status == 1){
            toast_anim('Product updated successfully')
            //reset(result);
            //navigate(Links.MY_PRODUCTS);
         }else{
            toast_anim_error('Error in product update')
            if(result.status === 2){
               Object.entries(result.message).forEach((item,ky) =>{
                  if(item[0] === 'product_name'){                    
                     setError('product_name', { type: 'custom', message: item[1] });
                  }
                  if(item[0] === 'sku'){
                     setError('sku', { type: 'custom', message: item[1] });
                  }
               })
            }
         }
      })
   }

   return(      
      <ScrollPageTop>
         <ReactSEOMetaTags
				render={(el: React.ReactNode) => <Helmet>{el}</Helmet>}
				website={{ ...metaData }}
			/>
         <LeftMenuLayout>
            <div className='w-full'>
               
            <div className="flex items-center justify-between">
                  <h1 className="text-lg text-heading sm:text-xl">
                  Update Product
                  </h1>
                  <Link to={Links.MY_PRODUCTS} className="inline-flex items-center justify-center shrink-0 leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-gray-700 bg-gray-500 text-light border border-transparent hover:bg-gray-700 px-5 py-0 h-12 font-semibold">Back</Link>               
               </div>
               
               <form onSubmit={handleSubmit(onSubmit)}>

               <div className="my-5 flex flex-wrap border-b border-dashed border-border-base pb-8 sm:my-8">
                  <Description
                  title='Featured image'
                  details='Full machine image'
                  className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5"
                  />
                  <Card className="w-full sm:w-8/12 md:w-2/3 mb-8">
                     <FileInput name="image" control={control} multiple={false}  acceptFile={pngjpgonly} />
                     {defaultValues.product_image && defaultValues.product_image != null ?
                        (<div
                           className="relative mt-2 inline-flex flex-col overflow-hidden rounded me-2 border border-border-200" >
                           <figure className="relative h-16 w-28">
                           <img
                              src={`${IMG_URL}${defaultValues.product_image}`}
                              alt="Product feature image"
                              layout="fill"
                              objectfit="contain"
                              className="h-16 w-28"
                           />
                           </figure>
                        </div>)
                        :null
                     }
                  </Card>
               </div>
               {/* <div className="my-5 flex flex-wrap border-b border-dashed border-border-base pb-8 sm:my-8">
                  <Description
                  title='Gallery'
                  details='Upload your product image gallery here'
                  className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5"
                  />
                  <Card className="w-full sm:w-8/12 md:w-2/3 mb-8">
                     <FileInput name="gallery" control={control} />
                     {
                        defaultValues.gallery_img && defaultValues.gallery_img.map((img, k) => (
                           <div
                           className="relative mt-2 inline-flex flex-col overflow-hidden rounded me-2 border border-border-200" >
                           <figure className="relative h-16 w-28">
                           <img
                              src={`${IMG_URL}${img.image}`}
                              alt="Product feature image"
                              layout="fill"
                              objectfit="contain"
                              className="h-16 w-28"
                           />
                           </figure>
                        </div>
                        ))
                     }
                  </Card>
               </div> */}
                              
               <div className="my-5 flex flex-wrap border-b border-dashed border-border-base pb-8 sm:my-8">
                  <Description
                  title='Description'
                  details="Add your product description and necessary information from here"
                  className="w-full px-0 pb-5 sm:w-4/12 sm:py-8 sm:pe-4 md:w-1/3 md:pe-5"
                  />
                  <Card className="w-full sm:w-8/12 md:w-2/3 mb-8">
                     <Input                
                        label={`Title`}
                        {...register('product_name')}                     
                        variant="outline"
                        className="mb-5"
                        error={errors.product_name?.message}
                        required
                     />
                     <TextArea
                        label='Description'
                        {...register('description')}                        
                        variant="outline"
                        className="mb-5"
                     />
                     <Input                        
                        label='SKU'
                        {...register('sku')}                        
                        variant="outline"
                        className="mb-5"
                        error={errors.sku?.message}
                        note='Please add sku in the formate of date (dd/mm/yy)'
                     />
                     {
                     category &&
                     <div className="mb-5">
                        <Label>Category <span className="text-red-500">*</span></Label>
                        <SelectInput
                           name="cat_id"                            
                           control={control}
                           getOptionLabel={(option: any) => option.cat_name}
                           getOptionValue={(option: any) => option.cat_id}                        
                           options={category}
                           onChange={handleMainCat}
                        />
                        <p className="mt-2 text-xs text-body">Select general medical equipment for basic medical health care products</p>
                        <p className="my-2 text-xs text-start text-red-500">{errors.cat_id?.message}</p>
                     </div>
                     }
                     
                     <div className="mb-5">
                        <Label>Sub Category</Label>
                        <SelectInput
                           name="sub_cat" 
                           {...register('sub_cat')}
                           control={control}
                           getOptionLabel={(option: any) => option.cat_name}
                           getOptionValue={(option: any) => option.cat_id}                        
                           options={subCategory}
                           onChange={handleSelect}
                        />
                        <p className="my-2 text-xs text-start text-red-500">{errors.sub_cat?.message}</p>
                     </div>
                     {
                        subSubCategory &&
                        <div className="mb-5">
                           <Label>Sub sub Category</Label>
                           <SelectInput
                              name="sub_sub_cat" 
                              {...register('sub_sub_cat')}
                              control={control}
                              getOptionLabel={(option: any) => option.cat_name}
                              getOptionValue={(option: any) => option.cat_id}                        
                              options={subSubCategory}
                              onChange={handleSelect}
                           />
                        </div>                     
                     }

                     

                     <div className="mb-5">
                        <Label>Brand <span className="text-red-500">*</span></Label>
                        <SelectInput
                           name="brand_id"                            
                           control={control}
                           getOptionLabel={(option: any) => option.name}
                           getOptionValue={(option: any) => option.brand_id}
                           options={brands}
                           onChange={handleSelect}
                        />
                          {brandselval == 'Other'?
                           (<Input                
                              {...register('brand_other_name')}
                              variant="outline"
                              className="mb-5"
                              type="text"
                              placeholder="Brand Name"
                              error={errors.brand_other_name?.message}
                           />)
                        :
                        (null)
                        }
                        <p className="my-2 text-xs text-start text-red-500">{errors.brand_id?.message}</p>
                     </div>
                     <div className="mb-5">
                        <Label>Model <span className="text-red-500">*</span></Label>
                        <SelectInput
                           name="model_id"                            
                           control={control}
                           getOptionLabel={(option: any) => option.name}
                           getOptionValue={(option: any) => option.model_id}                        
                           options={models}
                           onChange={handleSelect}
                        />     
                         {modelselval == 'Other'?
                           (<Input                
                              {...register('model_other_name')}
                              variant="outline"
                              className="mb-5"
                              type="text"
                              placeholder="Model Name"
                              error={errors.model_other_name?.message}
                           />)
                        :
                        (null)
                        }                       
                        <p className="my-2 text-xs text-start text-red-500">{errors.model_id?.message}</p>
                     </div>
                     <Input
                        label='YOM (Year of Manufacture)'
                        type="number"
                        {...register('yom')}                        
                        variant="outline"
                        className="mb-5"
                        placeholder="YYYY" 
                        min="1950" 
                        max={moment().year()}
                        required
                        error={errors.yom?.message}
                     />
                     <Input
                        label='Available unit'
                        type="number"
                        {...register('quantity')}                        
                        variant="outline"
                        className="mb-5"
                        error={errors.quantity?.message}
                        required
                     />
                     {user_type == 'dealer' ? 
                     (<Input
                        label='Dealer Price'
                        type="number"
                        {...register('dealer_price')}                        
                        variant="outline"
                        className="mb-5"
                     />)
                     :
                     (null)
                     }
                     {user_type == 'dealer' ?
                     (<Input
                        {...(user_type == 'dealer' ? {label: 'Price without warranty'} : {label: 'Price without warranty hospital/diagnoistic center'})}

                        type="number"
                        {...register('price')}                        
                        variant="outline"
                        className="mb-5"
                        error={errors.price?.message}
                        
                     />):
                     (null)
                   }
                    {user_type == 'dealer' ?
                      <div className="mb-5">
                        <Label>With WARRANTY</Label>
                        <div className="flex items-center">
                        <Radio
                           {...register('withwarrant')}
                           label="Yes"
                           id="withwarrant_yes"
                           value="yes"
                           className="mr-2"
                        />
                        <Radio
                           {...register('withwarrant')}
                           id="withwarrant_no"
                           label="No"
                           value="no"
                        />
                        </div>
                     </div>
                     :
                        null
                     }
                     {user_type == 'dealer' ?
                      <div className="mb-5">
                        <Label>With INSTALLATION</Label>
                        <div className="flex items-center">
                        <Radio
                           {...register('withinstalation')}
                           label="Yes"
                           id="withinstalation_yes"
                           value="yes"
                           className="mr-2"
                        />
                        <Radio
                           {...register('withinstalation')}
                           id="withinstalation_no"
                           label="No"
                           value="no"
                        />
                        </div>
                     </div>
                      :
                        null
                     }
                    {user_type == 'dealer' ?
                     (<Input
                       {...(user_type == 'dealer' ? {label: 'Price with warranty'} : {label: 'Price with warranty hospital/diagnoistic center'})}

                        type="number"
                        {...register('warranty_price')}                        
                        variant="outline"
                        className="mb-5"
                        
                     />)
                     :(null)
                     }
                     {user_type == 'dealer' ?
                     (<div className="mb-5">
                        <Label>warranty for </Label>
                        <SelectInput
                           name="warranty_for"                            
                           control={control}
                           getOptionLabel={(option: any) => option.label}
                           getOptionValue={(option: any) => option.id}                        
                           options={warranty_period}
                           onChange={handleSelect}
                           isClearable={true}                           
                        />
                     </div>)
                     :
                     (<Input
                        label='Price'                        
                        type="number"
                        {...register('price')}                        
                        variant="outline"
                        className="mb-5"
                        required
                        error={errors.price?.message}
                        {...(user_type == 'dealer' ? {required: true} : {required: true})}
                     />)
                     }
                     
                     {user_type == 'user' ?
                      (<Input
                        label='Negotiable up to'
                        type="number"
                        {...register('negotiable_price')}                        
                        variant="outline"
                        className="mb-5"
                        
                     />)
                     :
                     (null)
                     }
                      <div className="mb-5">
                        <Label>Internal System Information <span className="text-red-500"> *</span></Label>
                        <SelectInput
                           name="internalsys"                            
                           control={control}
                           getOptionLabel={(option: any) => option.label}
                           getOptionValue={(option: any) => option.id}                        
                           options={internalsysOptions}
                           onChange={handleSelect}                           
                        />
                        <p className="my-2 text-xs text-start text-red-500">{errors.internalsys?.message}</p>
                     </div>
                     <Input
                        label='Year'
                        {...register('tube_magnet_year')}                        
                        variant="outline"
                        className="mb-5"
                        placeholder="YYYY"
                        type='number'
                         required
                         error={errors.tube_magnet_year?.message}
                     />
                     <div className="mb-5">
                        <Label>Usage <span className="text-red-500"> *</span></Label>
                        <SelectInput
                           name="usage"                            
                           control={control}
                           getOptionLabel={(option: any) => option.label}
                           getOptionValue={(option: any) => option.id}                        
                           options={usageOptions}
                           onChange={handleSelect}                           
                        />
                        <p className="my-2 text-xs text-start text-red-500">{errors.usage?.message}</p>
                     </div>
                     <Input
                        label='Usage info'
                        {...register('tube_count')}                        
                        variant="outline"
                        className="mb-5"
                        required
                        error={errors.tube_count?.message}
                     />

                     <Input
                        label='Availability period'
                        {...register('delivery_period')}                        
                        variant="outline"
                        className="mb-5"
                        placeholder="Ex. 10-15 days or 3 months"
                     />
                     
                     <div className="mb-5">
                        <Label>Accessories <span className="text-red-500"> *</span></Label>
                        <SelectInput
                           name="accessories"                            
                           control={control}
                           getOptionLabel={(option: any) => option.label}
                           getOptionValue={(option: any) => option.id}                        
                           options={accessoriesOptions}
                           onChange={handleSelect}       
                           isMulti                    
                        />
                        <p className="my-2 text-xs text-start text-red-500">{errors.accessories?.message}</p>
                     </div>
                      {isother?
                           (<Input                
                              {...register('accessories_other')}
                              variant="outline"
                              className="mb-5"
                              type="text"
                              placeholder="Add more accessories comma seperated"
                              error={errors.accessories_label?.message}
                           />)
                        :
                        (null)
                        }

                      {user_type == 'user' ?
                      (<div className="mb-5">
                        <Label>In Use</Label>
                        <div className="flex items-center">
                        <Radio
                           {...register('is_inuse')}
                           label="Yes"
                           id="yes"
                           value='yes'
                           className="mr-2"
                        />
                        <Radio
                           {...register('is_inuse')}
                           id="no"
                           label="No"
                           value='no'
                        />
                        </div>
                     </div>):
                      (
                        <div className="mb-5">
                        <Label>In Stock</Label>
                        <div className="flex items-center">
                        <Radio
                           {...register('inStock')}
                           label="Yes"
                           id="yes"
                           value="yes"
                           className="mr-2"
                        />
                        <Radio
                           {...register('inStock')}
                           id="no"
                           label="No"
                            value='no'
                        />
                        </div>
                     </div>
                      )
                     }

                     
                     <div className="mb-5">
                        <Label>Condition</Label>
                        <div className="flex items-center">
                        {
                           Object.keys(machine_condition).map((key) => (
                           <Radio
                              {...register('condition')}
                              label={machine_condition[key]}
                              id={`cond-${key}`}
                              value={key}
                              className="mr-2"
                              key={`condition-${key}`}
                           />
                           ))
                        }
                        
                        </div>
                     </div>

                     <div className="mb-5">
                        <Label>Availability status</Label>
                        <div className="flex items-center">
                        <Radio
                           {...register('availability_status')}
                           label="Available"
                           id="available"
                           value='available'
                           className="mr-2"
                        />
                        <Radio
                           {...register('availability_status')}
                           id="booked"
                           label="Booked"
                           value='booked'
                           className="mr-2"
                        />
                        <Radio
                           {...register('availability_status')}
                           id="sold"
                           label="Sold"
                           value='sold'
                           className="mr-2"
                        />
                        <Radio
                           {...register('availability_status')}
                           id="outOfStock"
                           label="Out of Stock"
                           value='Out of Stock'
                           className="mr-2"
                        />
                        </div>
                     </div>
                     <div className="mb-5">
                        <Label>COD Available</Label>
                        <div className="flex items-center">
                        <Radio
                           {...register('isCOD')}
                           label="Yes"
                           id="isCODyes"
                           value={1}
                           className="mr-2"
                        />
                        <Radio
                           {...register('isCOD')}
                           id="isCODno"
                           label="No"
                           value={0}
                        />
                        </div>
                     </div>
                  </Card>
               </div>
               <div className="mb-6 flex justify-end">
               <Link to={Links.MY_PRODUCTS} className="inline-flex items-center justify-center shrink-0 leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-gray-700 bg-gray-500 text-light border border-transparent hover:bg-gray-700 px-5 py-0 h-12 font-semibold mr-5">Back</Link>
                  <Button 
                     loading={loading}
                  >
                  Update Product
                  </Button>
               </div>
               </form>
            </div>
         </LeftMenuLayout>
      </ScrollPageTop>   
   )
}

export default EditProduct